import React from "react";
import { Bar } from "react-chartjs-2";
import { ArrowRightAltTwoTone } from "@material-ui/icons";

class BarChart extends React.Component {
  render() {
    const { data, highlight, domain, step } = this.props;

    // calculate frequency of data
    var counts = Array.from(data).reduce(
      (prev, { value, count }) => ({ ...prev, [value]: count }),
      {}
    );
    if (step === 0.1) {
      for (let i = domain[0]; i <= domain[1]; i = Number((i + step).toFixed(1)))
        counts[i] = counts[i] || 0;
    } else {
      for (let i = domain[0]; i <= domain[1]; i += step)
        counts[i] = counts[i] || 0;
    }

    // generate data
    const barDataValues = Object.values(counts);
    console.log("Bar", barDataValues);
    const barDataKeys = Object.keys(counts)
      .map((el) => Number(el))
      .sort((a, b) => a - b);
    const barData = {
      labels: barDataKeys,
      datasets: [
        {
          backgroundColor: barDataKeys.map((val) =>
            val >= highlight[0] && val <= highlight[1]
              ? "rgba(206, 209, 204, 1)"
              : "rgba(238, 238, 238, 1)"
          ),
          hoverBackgroundColor: "rgba(206,209,204,0.4)",
          data: barDataValues,
        },
      ],
    };

    const options = {
      scales: {
        xAxes: [
          {
            display: false,
            ticks: { min: 0 },
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              min: 0,
            },
          },
        ],
      },
    };
    return <Bar data={barData} height={100} options={options} />;
  }
}

export default BarChart;
