import React from "react";
import PropTypes from "prop-types";
import styles from "./TableTotalGuestroomsRevenue.module.sass";

const TableTotalGuestroomsRevenue = ({ id, table }) => {
  return (
    <table id={id} className={styles.table}>
      <thead>
        <tr key={`thead`}>
          {table.thead.map((thEl, index) => (
            <th key={`th ${index}`}>{thEl}</th>
          ))}
        </tr>
      </thead>
      <tfoot>
        {table.tfoot.map((trEl, index) => (
          <tr key={`tfoot ${index}`}>
            {trEl.map((tfEl, index) => (
              <td key={`td ${index}`}>{tfEl}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      <tbody>
        <tr className={styles.firstRow} key="firstRow">
          {table.tbody[0] &&
            table.tbody[0].map((tdEl, index) => <td key={`td ${index}`}></td>)}
        </tr>
        {/* {table.tbody.map((trEl, index) => (
          <tr key={`tr ${index}`}>
            {trEl.map((tdEl, index) => (
              <td key={`td ${index}`}>{tdEl}</td>
            ))}
          </tr>
        ))}
        <tr className={styles.lastRow} key="lastRow">
          {table.tbody[0].map((tdEl, index) => (
            <td key={`td ${index}`}></td>
          ))}
        </tr> */}
      </tbody>
    </table>
  );
};

TableTotalGuestroomsRevenue.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TableTotalGuestroomsRevenue;
