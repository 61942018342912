import percentFormat from "./percentFormat";
import dollarIntegerFormat from "./dollarIntegerFormat";
import dollarFloatFormat from "./dollarFloatFormat";
import withoutDollarFloatFormat from "./withoutDollarFloatFormat";
import withoutDollarIntegerFormat from "./withoutDollarIntegerFormat";

const stabilizedYearOneRevenueToArray = (
  stabilized_year_one_revenue,
  inv_margin_selection
) => {
  const thead = [
    "Initial",
    "",
    "% explicabo",
    "$/nostrum",
    "$/adipiscing",
  ];

  const firstColBody = {
    guestrooms: "Footage",
    meetings: "Viewport",
    food_beverage: "Thread",
  };
  if (
    stabilized_year_one_revenue.custom_rev &&
    stabilized_year_one_revenue.custom_rev.length !== 0
  ) {
    stabilized_year_one_revenue.custom_rev.map((el, index) => {
      firstColBody["label" + index] = el.label;
      stabilized_year_one_revenue["label" + index] = {};
      stabilized_year_one_revenue["label" + index].revenue = el.revenue;
      stabilized_year_one_revenue["label" + index].percent = el.percent;
      stabilized_year_one_revenue["label" + index].per_key = el.per_key;
      stabilized_year_one_revenue["label" + index].per_gsf = el.per_gsf;
    });
  }
  const firstColFoot =
    inv_margin_selection === "gop_margin"
      ? {
          total: "consequat",
          stabilized_gop: "Duis",
          management_fee: "aute",
          property_tax: "irure",
          insurance: "dolor",
          reserves: "eprehe",
          // noi_margin: "NOI Margin",
          stabilized_noi: "olupt",
        }
      : {
          total: "Tiles",
          noi_margin: "Suggest",
          stabilized_noi: "Paid",
        };

  const tbody = [];
  let i = 0;
  for (const keyrow in firstColBody) {
    const tbodyRow = [
      firstColBody[keyrow],
      i === 0
        ? dollarIntegerFormat(stabilized_year_one_revenue[keyrow].revenue)
        : withoutDollarIntegerFormat(
            stabilized_year_one_revenue[keyrow].revenue
          ),
      percentFormat(stabilized_year_one_revenue[keyrow].percent),
      i === 0
        ? dollarIntegerFormat(stabilized_year_one_revenue[keyrow].per_key)
        : withoutDollarIntegerFormat(
            stabilized_year_one_revenue[keyrow].per_key
          ),
      i === 0
        ? dollarFloatFormat(stabilized_year_one_revenue[keyrow].per_gsf)
        : withoutDollarFloatFormat(stabilized_year_one_revenue[keyrow].per_gsf),
    ];
    if (tbodyRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tbody.push([...tbodyRow.map((el) => (el === 0 ? "-" : el))]);
    }
    i++;
  }

  const tfoot =
    inv_margin_selection === "gop_margin"
      ? []
      : [
          [
            firstColFoot.total,
            dollarIntegerFormat(stabilized_year_one_revenue.total.revenue),
            percentFormat(stabilized_year_one_revenue.total.percent),
            dollarIntegerFormat(stabilized_year_one_revenue.total.per_key),
            dollarFloatFormat(stabilized_year_one_revenue.total.per_gsf),
          ],
          [
            firstColFoot.noi_margin,
            percentFormat(stabilized_year_one_revenue.noi_margin),
            "",
            "",
            "",
          ],
          [
            firstColFoot.stabilized_noi,
            dollarIntegerFormat(
              stabilized_year_one_revenue.stabilized_noi.revenue
            ),
            percentFormat(stabilized_year_one_revenue.stabilized_noi.percent),
            dollarIntegerFormat(
              stabilized_year_one_revenue.stabilized_noi.per_key
            ),
            dollarFloatFormat(
              stabilized_year_one_revenue.stabilized_noi.per_gsf
            ),
          ],
        ];

  if (inv_margin_selection === "gop_margin") {
    for (const keyrow in firstColFoot) {
      const tfootRow = [
        firstColFoot[keyrow],
        dollarIntegerFormat(stabilized_year_one_revenue[keyrow].revenue),
        percentFormat(stabilized_year_one_revenue[keyrow].percent),
        dollarIntegerFormat(stabilized_year_one_revenue[keyrow].per_key),
        dollarFloatFormat(stabilized_year_one_revenue[keyrow].per_gsf),
      ];
      if (tfootRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
        tfoot.push([...tfootRow]);
      }
    }
  }

  const stabilizedYearOneRevenue = {
    thead: thead,
    tbody: tbody,
    tfoot: tfoot
      .filter((arr, i) => !!arr[1])
      .map((el) => (el === 0 ? "-" : el)),
  };
  return stabilizedYearOneRevenue;
};

export default stabilizedYearOneRevenueToArray;
