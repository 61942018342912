import React from "react";
import "./App.sass";
import { Switch, Route } from "react-router-dom";
import NotFound from "./NotFound/NotFound";
import Dashboard from "./Dashboard";
import Login from "./Auth/Login/LoginContainer";
import EditUser from "./Auth/EditUser/EditUser";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/editUser" component={EditUser} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default App;
