import React from "react";

const SaveRecalc = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="36px"
      height="36px"
      viewBox="15.5 13.805 609 611.513"
    >
      <title>Save/Recalculate</title>
      <rect x="15.5" y="13.805" fill="#2A3147" width="609" height="611.513" />
      <g transform="translate(-1.0388258e-6,0.08002026)">
        <g>
          <g>
            <polygon
              className="saveRecalc-arrows"
              points="596.418,186.94 598.907,201.16 454.037,148.52 572.228,49.34"
            />
            <path
              fill="#FFFFFF"
              d="M410.947,331.07c43.98,0,79.63,35.65,79.63,79.63c0,21.989-8.909,41.899-23.319,56.31
				s-34.32,23.32-56.311,23.32c-21.989,0-41.899-8.91-56.31-23.32s-23.32-34.32-23.32-56.31
				C331.317,366.72,366.968,331.07,410.947,331.07z M456.447,450.68v-33.5h-91v33.5H456.447z M456.447,404.23v-33.51h-91v33.51
				H456.447z"
            />
            <path
              fill="#FFFFFF"
              d="M467.258,171.95c14.41,14.41,23.319,34.32,23.319,56.31c0,21.99-8.909,41.9-23.319,56.31
				c-14.41,14.41-34.32,23.32-56.311,23.32c-21.989,0-41.899-8.91-56.31-23.32c-14.41-14.41-23.32-34.32-23.32-56.31
				c0-43.98,35.65-79.63,79.63-79.63C432.938,148.63,452.848,157.54,467.258,171.95z M456.447,245.01v-33.5h-91v33.5H456.447z"
            />
            <path
              fill="#FFFFFF"
              d="M228.507,331.07c43.98,0,79.63,35.65,79.63,79.63c0,43.979-35.65,79.63-79.63,79.63
				s-79.63-35.65-79.63-79.63C148.868,366.72,184.527,331.07,228.507,331.07z M252.198,410.7l20.33-20.33l-23.69-23.69l-20.33,20.34
				l-20.33-20.34l-23.69,23.69l20.33,20.33l-20.33,20.33l23.69,23.689l20.33-20.33l20.33,20.33l23.69-23.689L252.198,410.7z"
            />
            <path
              fill="#FFFFFF"
              d="M284.817,171.95c14.41,14.41,23.32,34.32,23.32,56.31c0,21.99-8.91,41.9-23.32,56.31
				c-14.41,14.41-34.32,23.32-56.31,23.32c-43.98,0-79.64-35.65-79.63-79.63c0-43.98,35.65-79.63,79.63-79.63
				C250.498,148.63,270.408,157.54,284.817,171.95z M274.007,245.01v-33.5h-28.75v-28.75h-33.5v28.75h-28.75v33.5h28.75v28.75h33.5
				v-28.75H274.007z"
            />
            <polygon
              className="saveRecalc-arrows"
              points="185.428,490.44 67.228,589.62 43.038,452.03 40.527,437.8"
            />
          </g>
          <g />
        </g>
      </g>
      <g id="POLYLINE">
        <polyline
          className={className}
          fill="none"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
      578.115,182.957 573.208,174.049 567.99,165.318 562.473,156.776 556.661,148.429 550.56,140.294 544.181,132.374 537.528,124.684
      530.61,117.228 523.436,110.02 516.016,103.064 508.358,96.373 500.471,89.953 492.363,83.813 484.048,77.958 475.531,72.398
      466.827,67.139 457.944,62.186 448.895,57.547 439.688,53.226 430.336,49.229 420.853,45.561 411.244,42.226 401.525,39.229
      391.709,36.572 381.807,34.259 371.828,32.293 361.787,30.678 351.696,29.413 341.567,28.502 331.413,27.943 321.244,27.741
      311.077,27.894 300.918,28.4 290.785,29.262 280.688,30.476 270.639,32.042 260.652,33.958 250.737,36.22 240.907,38.829
      231.172,41.778 221.549,45.064 212.046,48.686 202.675,52.635 193.448,56.91 184.375,61.505 175.467,66.414 166.736,71.629
      158.194,77.146 149.849,82.958 141.712,89.06 133.792,95.439 126.101,102.093 118.646,109.009 111.436,116.184 104.482,123.604
      97.791,131.262 91.37,139.15 85.229,147.256 79.376,155.573 73.816,164.088 68.557,172.793 63.604,181.675 58.964,190.725
      54.643,199.931 50.646,209.283 46.979,218.768 43.642,228.376 40.646,238.093 37.989,247.909 35.676,257.815 33.71,267.792
      32.095,277.833 30.831,287.923 29.918,298.053 29.361,308.205 29.16,318.373 29.31,328.543 29.817,338.702 30.679,348.834
      31.893,358.931 33.459,368.98 35.375,378.967 37.637,388.884 40.244,398.714"
        />
      </g>
      <g id="POLYLINE_1_">
        <polyline
          className={className}
          fill="none"
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		66.2,461.277 71.295,470.028 76.691,478.6 82.382,486.976 88.359,495.151 94.619,503.114 101.15,510.854 107.946,518.363
		115.001,525.628 122.303,532.646 129.844,539.407 137.615,545.9 145.607,552.12 153.812,558.058 162.217,563.707 170.814,569.062
		179.592,574.115 188.54,578.857 197.648,583.286 206.903,587.396 216.295,591.185 225.815,594.643 235.449,597.769
		245.186,600.555 255.011,603.002 264.918,605.109 274.892,606.868 284.921,608.279 294.992,609.342 305.096,610.055
		315.217,610.416 325.342,610.424 335.467,610.08 345.566,609.384 355.642,608.339 365.674,606.943 375.648,605.199 385.559,603.11
		395.391,600.679 405.132,597.907 414.771,594.798 424.294,591.355 433.694,587.585 442.956,583.488 452.071,579.075
		461.026,574.347 469.812,569.309 478.419,563.968 486.834,558.331 495.048,552.406 503.051,546.2 510.83,539.719 518.384,532.973
		525.698,525.967 532.765,518.711 539.573,511.214 546.117,503.486 552.389,495.532 558.381,487.367 564.086,479 569.495,470.438
		574.604,461.694 579.405,452.777 583.896,443.698 588.065,434.47 591.916,425.102 595.435,415.606 598.623,405.994
		601.474,396.274 603.986,386.464 606.157,376.572 607.98,366.609 609.459,356.59 610.587,346.525 611.364,336.428 611.791,326.31
		611.867,316.183 611.589,306.059 610.961,295.951 609.979,285.871 608.648,275.831 606.973,265.843 604.946,255.92
		602.581,246.073"
        />
      </g>
    </svg>
  );
};

export default SaveRecalc;
