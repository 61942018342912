import React from "react";
import PropTypes from "prop-types";

const XLSX = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.3125 12.25V15.0625H9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.375 12.25L5.625 15.0625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9375 12.25L15.1875 15.0625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.625 12.25L3.375 15.0625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.1875 12.25C14.3088 13.3483 13.8162 13.9642 12.9375 15.0625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.125 14.7812C10.3536 14.9631 10.6309 15.0617 10.916 15.0625C11.3906 15.0625 11.8125 14.8937 11.8125 14.3312C11.8125 13.4312 10.125 13.825 10.125 12.9812C10.125 12.5312 10.4414 12.25 10.916 12.25C11.2011 12.2508 11.4785 12.3494 11.707 12.5312"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.375 9V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6875 2.25V6.1875H14.625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

XLSX.propTypes = {
  className: PropTypes.string.isRequired,
};

export default XLSX;
