import React from "react";
import PropTypes from "prop-types";
import styles from "./TableGuestrooms.module.sass";
import TooltipLeftBottom from "../../../TooltipLeftBottom/TooltipLeftBottom";

const TableGuestrooms = ({ id, table }) => {
  let maxValuesArray;
  if (table.tbody.length !== 0) {
    const initialMaxValuesArray = table.tbody[0].map(() => 0);
    maxValuesArray = table.tbody.reduce((maxArr, el) => {
      return el.map((elint, index) =>
        Number.parseFloat(elint) > maxArr[index]
          ? Number.parseFloat(elint)
          : maxArr[index]
      );
    }, initialMaxValuesArray);
  }
  return (
    <table id={id} className={styles.tableGuestrooms}>
      <thead>
        <tr key={`thead`}>
          {table.thead.map((thEl, index) => (
            <th key={`th ${index}`}>
              {thEl}
              {` `}
              {index === table.thead.length - 1 && (
                <TooltipLeftBottom
                  title="Prototype room size"
                  text="Some rooms deviate slightly from prototype."
                />
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tfoot>
        {table.tfoot.map((trEl, index) => (
          <tr key={`tfoot ${index}`}>
            {trEl.map((tfEl, index) => (
              <td key={`td ${index}`}>{tfEl}</td>
            ))}
          </tr>
        ))}
      </tfoot>
      <tbody>
        <tr className={styles.firstRow} key="firstRow">
          {table.tbody[0] &&
            table.tbody[0].map((tdEl, index) => <td key={`td ${index}`}></td>)}
        </tr>
        {table.tbody.map((trEl, index) => (
          <tr key={`tr ${index}`}>
            {trEl.map((tdEl, index) => {
              const isPercent = tdEl && !!~tdEl.indexOf("%");
              return (
                <td
                  // style={isPercent ? { "--width": tdEl } : {}}
                  style={
                    isPercent
                      ? {
                          "--width": `${
                            Number.parseFloat(tdEl) / maxValuesArray[index]
                          }`,
                        }
                      : {}
                  }
                  className={isPercent ? styles.percent : ""}
                  key={`td ${index}`}
                >
                  {tdEl}
                </td>
              );
            })}
          </tr>
        ))}
        <tr className={styles.lastRow} key="lastRow">
          {table.tbody[0] &&
            table.tbody[0].map((tdEl, index) => <td key={`td ${index}`}></td>)}
        </tr>
      </tbody>
    </table>
  );
};

TableGuestrooms.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TableGuestrooms;
