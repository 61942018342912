import React from "react";

const Save = ({ className }) => {
  return (
    <svg
      data-name="Group 41 Copy 3"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={className}
    >
      <g
        className={className}
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14" fill="none" />
      </g>
      <g data-name="save (2)" transform="translate(9 9)" stroke="none">
        <g data-name="Group 7">
          <path
            d="M13,13H0V0H10.675L13,2.278V13ZM1.9,6.9h8.938v5.572h1.625V2.5L10.451.531h-.43V4.775H2.437V.531H.542V12.469H1.9V6.9Zm.542.531v5.041h7.854V7.429Zm.542-6.9V4.245h6.5V.531Z"
            fill="#fff"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="0.2"
          />
          <path
            data-name="Shape"
            d="M2,2H0V0H2V2ZM.667.4V1.6h.666V.4Z"
            transform="translate(7 1)"
            fill="#fff"
          />
          <path
            d="M2.733,0H.41A.4.4,0,0,0,0,.381.4.4,0,0,0,.41.762H2.733a.4.4,0,0,0,.41-.381A.4.4,0,0,0,2.733,0Z"
            transform="translate(3 10)"
            fill="#fff"
          />
          <path
            data-name="Path"
            d="M5.056,0H.41A.4.4,0,0,0,0,.381.4.4,0,0,0,.41.762H5.056a.4.4,0,0,0,.41-.381A.4.4,0,0,0,5.056,0Z"
            transform="translate(3 8)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Save;
