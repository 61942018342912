import Workspace from "./Workspace.jsx";
import { connect } from "react-redux";
import {
  setTrueResultCalculated,
  removeModels,
  removeTables,
  changeDataInTable,
} from "../../../redux/Filters/filtersActions";
import { removeProjectInfo } from "../../../redux/ProjectInfo/projectInfoActions";
import * as projectInfoSelectors from "../../../redux/ProjectInfo/projectInfoSelectors";
import * as filtersSelectors from "../../../redux/Filters/filtersSelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    filters: filtersSelectors.getFilters(state),
    tablesForModel: filtersSelectors.getTablesForModel(state),
    filterParameters: filtersSelectors.getFilterParameters(state),
    previousRequestForModels:
      filtersSelectors.getPreviousRequestForModels(state),
  };
};

const mapDispatchToProps = {
  removeProjectInfo: removeProjectInfo,
  removeModels: removeModels,
  removeTables: removeTables,
  setTrueResultCalculated: setTrueResultCalculated,
  changeDataInTable: changeDataInTable,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
