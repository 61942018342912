import sfFormat from "./sfFormat";
import percentFormat from "./percentFormat";
import stringFormat from "./stringFormat";

const guestRoomsToArray = (guest_rooms) => {
  const thead = ["nostrum", "dolor sit", "adipiscing %", "tempor"];

  // const firstColBody = {
  //   standard_king: "Standard King",
  //   accessible_king: "Accessible King",
  //   standard_queen: "Standard Queen/Queen",
  //   accessible_queen: "Accessible Queen/Queen",
  //   king_suite: "King Suite",
  //   inline_queen_suite: "Inline Queen/Queen Suite",
  //   accessible_inline_queen_suite: "Accessible Inline Q/Q Suite",
  // };
  const firstColFoot = {
    total: "incididunt",
  };

  const tbody = [];

  for (let keyrow = 0; keyrow < guest_rooms.by_type.length; keyrow++) {
    const tbodyRow = [
      stringFormat(guest_rooms.by_type[keyrow].name),
      stringFormat(guest_rooms.by_type[keyrow].keys),
      percentFormat(guest_rooms.by_type[keyrow].mix),
      sfFormat(guest_rooms.by_type[keyrow].area),
    ];
    if (tbodyRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tbody.push([...tbodyRow.map((el) => (el === 0 ? "-" : el))]);
    }
  }

  const tfoot = [];

  for (const keyrow in firstColFoot) {
    const tfootRow = [
      firstColFoot[keyrow],
      stringFormat(guest_rooms[keyrow].keys),
      percentFormat(guest_rooms[keyrow].mix),
      sfFormat(guest_rooms[keyrow].area),
    ];
    if (tfootRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tfoot.push([...tfootRow.map((el) => (el === 0 ? "-" : el))]);
    }
  }
  const guestRooms = {
    thead: thead,
    tbody: tbody,
    tfoot: tfoot,
  };
  return guestRooms;
};

export default guestRoomsToArray;
