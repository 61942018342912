import Investment from "./Investment";
import { connect } from "react-redux";
import {
  setProjectInfoKeyInvestmentAssumptions,
  setProjectInfoKeyInvestmentAssumptionsOnBlur,
  setProjectInfoKeyInvestmentAssumptionsOpEx,
  setProjectInfoKeyInvestmentAssumptionsOpExOnBlur,
} from "../../../../../redux/ProjectInfo/projectInfoActions";
import { bindActionCreators } from "redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectInfoKeyInvestmentAssumptions: bindActionCreators(
      setProjectInfoKeyInvestmentAssumptions,
      dispatch
    ),
    setProjectInfoKeyInvestmentAssumptionsOnBlur: bindActionCreators(
      setProjectInfoKeyInvestmentAssumptionsOnBlur,
      dispatch
    ),
    setProjectInfoKeyInvestmentAssumptionsOpEx: bindActionCreators(
      setProjectInfoKeyInvestmentAssumptionsOpEx,
      dispatch
    ),
    setProjectInfoKeyInvestmentAssumptionsOpExOnBlur: bindActionCreators(
      setProjectInfoKeyInvestmentAssumptionsOpExOnBlur,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Investment);
