import ArchiveProjects from "./ArchiveProjects";
import { connect } from "react-redux";
import * as sessionSelectors from "../../../redux/Session/sessionSelectors";

const mapStateToProps = (state) => {
  return {
    user: sessionSelectors.getUser(state),
    projects: state.projectInfo.projectInfo.projects,
  };
};

export default connect(mapStateToProps)(ArchiveProjects);
