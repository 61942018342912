import React from "react";
import PropTypes from "prop-types";

const Mode2d = ({ className }) => {
  return (
    <svg
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" />
      <path d="M23 10H7V20H23V10Z" fill="white" />
    </svg>
  );
};

Mode2d.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Mode2d;
