import percentFormat from "./percentFormat";
import dollarIntegerFormat from "./dollarIntegerFormat";
import dollarFloatFormat from "./dollarFloatFormat";
import withoutDollarFloatFormat from "./withoutDollarFloatFormat";
import withoutDollarIntegerFormat from "./withoutDollarIntegerFormat";

const developmentBudgetToArray = (development_budget) => {
  const thead = [
    "Setup",
    "unde omnis",
    "% iste natus",
    "$error sit",
    "$voluptatem",
  ];

  const firstColBody = {
    land: "Cup",
    hard_costs: "Vidget",
    soft_costs: "Sea",
    guestroom_ffe: "Meat",
    hard_soft_contingency: "Three",
    dev_ffe_overhead: "Voltage",
    const_loan_interest_fee: "Power",
  };
  if (
    development_budget.budget_custom &&
    development_budget.budget_custom.length !== 0
  ) {
    development_budget.budget_custom.map((el, index) => {
      firstColBody["label" + index] = el.label;
      development_budget["label" + index] = {};
      development_budget["label" + index].total_budget = el.total_budget;
      development_budget["label" + index].total_budget_percent =
        el.total_budget_percent;
      development_budget["label" + index].cost_per_key = el.cost_per_key;
      development_budget["label" + index].cost_gsf = el.cost_gsf;
    });
  }
  const firstColFoot = {
    total: "Latitude",
    const_loan_proceed: "Pen",
    equity_required: "Tablet",
  };

  const tbody = [];
  let i = 0;
  for (const keyrow in firstColBody) {
    const tbodyRow = [
      firstColBody[keyrow],
      i === 0
        ? dollarIntegerFormat(development_budget[keyrow].total_budget)
        : withoutDollarIntegerFormat(development_budget[keyrow].total_budget),
      percentFormat(development_budget[keyrow].total_budget_percent),
      i === 0
        ? dollarIntegerFormat(development_budget[keyrow].cost_per_key)
        : withoutDollarIntegerFormat(development_budget[keyrow].cost_per_key),
      i === 0
        ? dollarFloatFormat(development_budget[keyrow].cost_gsf)
        : withoutDollarFloatFormat(development_budget[keyrow].cost_gsf),
    ];
    if (tbodyRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tbody.push([...tbodyRow.map((el) => (el === 0 ? "-" : el))]);
    }
    i++;
  }

  const tfoot = [];

  for (const keyrow in firstColFoot) {
    const tfootRow = [
      firstColFoot[keyrow],
      dollarIntegerFormat(development_budget[keyrow].total_budget),
      percentFormat(development_budget[keyrow].total_budget_percent),
      dollarIntegerFormat(development_budget[keyrow].cost_per_key),
      dollarFloatFormat(development_budget[keyrow].cost_gsf),
    ];
    if (tfootRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tfoot.push([...tfootRow.map((el) => (el === 0 ? "-" : el))]);
    }
  }
  const developmentBudget = {
    thead: thead,
    tbody: tbody,
    tfoot: tfoot,
  };
  return developmentBudget;
};

export default developmentBudgetToArray;
