import React, { Component } from "react";
import "./BuildingProgram.sass";
import styles from "./BuildingProgram.module.sass";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";
import BarChart from "../BarChart/BarChart";
import toastr from "toastr";

const valueFormat = (value) => Number((value * 100).toFixed(0));

const SwimmingPool = {
  NO_POOL: 0,
  OUTDOOR: 1,
};
const MeetingRoom = {
  STANDARD: 0,
  EXTENDED: 1,
};

// const Market = {
//   STANDARD: 1,
//   NO_BEER_WINE: 0
// };
const PortCochere = {
  PORT_COCHER: 1,
  NO_PORT_COCHERE: 0,
};

class BuildingProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.props.setFilterParameters({
      [name]: type === "checkbox" ? checked : Number(value),
    });
  };

  handleChangeParkingRatio =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeySiteAssumptions(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };
  handleChangeParkingRatioOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeySiteAssumptionsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  handleChangeFilter = (e) => {
    this.props.setFilterParameters(e);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.reset();
  };

  reset = () => {
    this.props.setFilterParameters({
      option_pool: SwimmingPool.NO_POOL,
      option_meeting: MeetingRoom.STANDARD,
      option_port_cochere: PortCochere.PORT_COCHER,
    });
  };

  validationMinOpenSpace = ({ minOpenSpace }) => {
    const { minMaxHistograms } = this.props;
    if (minOpenSpace < minMaxHistograms.open_space_percent.min / 100) {
      toastr.warning(
        `The entered value cannot be less than ${
          minMaxHistograms.open_space_percent.min
        }% and more than ${
          minMaxHistograms.open_space_percent.max
        }%. While you tried to enter ${minOpenSpace * 100}%`,
        "Error"
      );
      minOpenSpace = minMaxHistograms.open_space_percent.min / 100;
    }
    if (minOpenSpace > minMaxHistograms.open_space_percent.max / 100) {
      toastr.warning(
        `The entered value cannot be less than ${
          minMaxHistograms.open_space_percent.min
        }% and more than ${
          minMaxHistograms.open_space_percent.max
        }%. While you tried to enter ${minOpenSpace * 100}%`,
        "Error"
      );
      minOpenSpace = minMaxHistograms.open_space_percent.max / 100;
    }
    return { minOpenSpace };
  };

  render() {
    console.log(this.props);
    const { option_pool, option_meeting, minOpenSpace } =
      this.props.filterParameters;
    const { open_space_percent } = this.props.minMaxHistograms;
    const { parking_ratio, parking_plus_spaces } =
      this.props.projectInfo.site_assumptions;

    const { minMaxHistograms } = this.props;
    return (
      <section className={`buildingProgram ${styles.buildingProgram}`}>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <h3 className={styles.title}>Site Program</h3>

          <div className="mx-auto mt-4">
            <h5 className={styles.name}>Parking:</h5>
            <div className={styles.textInput}>
              <div className={styles.inputContainer}>
                <NumberFormat
                  className={styles.inputPercent}
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  decimalScale={2}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  id="parking_ratio"
                  value={parking_ratio}
                  onValueChange={this.handleChangeParkingRatio([
                    "parking_ratio",
                  ])}
                  onBlur={() =>
                    this.handleChangeParkingRatioOnBlur(["parking_ratio"])({
                      floatValue: parking_ratio,
                    })
                  }
                />
                <label
                  htmlFor="parking_ratio"
                  className={styles.inputSuffixPercent}
                ></label>
              </div>
              <span>spaces per key</span>
            </div>
          </div>

          <div className="mx-auto">
            <h5 className={styles.name}>+</h5>
            <div className={styles.textInput}>
              <div className={styles.inputContainer}>
                <NumberFormat
                  className={styles.inputPercent}
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  decimalScale={0}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  id="parking_plus_spaces"
                  value={parking_plus_spaces}
                  onValueChange={this.handleChangeParkingRatio([
                    "parking_plus_spaces",
                  ])}
                  onBlur={() =>
                    this.handleChangeParkingRatioOnBlur([
                      "parking_plus_spaces",
                    ])({ floatValue: parking_plus_spaces })
                  }
                />
                <label
                  htmlFor="parking_plus_spaces"
                  className={styles.inputSuffixPercent}
                ></label>
              </div>
              <span>additional spaces</span>
            </div>
          </div>

          <div className="mx-auto mt-4">
            <h5 className={styles.name}>Minimum Site Open Space:</h5>
            {open_space_percent.length === 0 && (
              <p className={styles.error}>
                No results are available. Your site may be too small or filters
                too stringent
              </p>
            )}
            <BarChart
              data={open_space_percent}
              highlight={[Number(valueFormat(minOpenSpace)), 100]}
              domain={[
                minMaxHistograms.open_space_percent.min,
                minMaxHistograms.open_space_percent.max,
              ]}
              step={1}
            />
            <Slider
              value={valueFormat(minOpenSpace)}
              onChange={(mouseEvent, minOpenSpace) => {
                this.handleChangeFilter({
                  minOpenSpace: Number((minOpenSpace / 100).toFixed(2)),
                });
              }}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              // track="normal"
              track="inverted"
              min={minMaxHistograms.open_space_percent.min}
              step={1}
              max={minMaxHistograms.open_space_percent.max}
            />
            <div className="textInput">
              <div className="inputContainer inputRight">
                <NumberFormat
                  className="inputPercent"
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  decimalScale={0}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  value={valueFormat(minOpenSpace)}
                  onValueChange={({ floatValue = 0 }) => {
                    return this.handleChangeFilter({
                      minOpenSpace: Number((floatValue / 100).toFixed(2)),
                    });
                  }}
                  onBlur={() => {
                    const floatValue = this.validationMinOpenSpace({
                      minOpenSpace: minOpenSpace,
                    });
                    this.handleChangeFilter(floatValue);
                  }}
                />
                <label htmlFor="KingMixMin" className="inputSuffixPercent">
                  %
                </label>
              </div>
            </div>
          </div>
        </form>
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <h3 className={styles.title}>Building Program</h3>

          <div className="mx-auto mt-4">
            <h5 className={styles.name}>Swimming pool:</h5>
            <div className={styles.radio}>
              <input
                type="radio"
                id="aOne"
                checked={option_pool === SwimmingPool.NO_POOL}
                name="option_pool"
                value={SwimmingPool.NO_POOL}
                onChange={this.handleChange}
              />
              <label htmlFor="aOne">No pool</label>
              <input
                type="radio"
                id="aTwo"
                checked={option_pool === SwimmingPool.OUTDOOR}
                name="option_pool"
                value={SwimmingPool.OUTDOOR}
                onChange={this.handleChange}
              />
              <label htmlFor="aTwo">outdoor</label>
            </div>
          </div>
          <div className="mx-auto mt-4">
            <h5 className={styles.name}>Meeting Room:</h5>
            <div className={styles.radio}>
              <input
                type="radio"
                id="bOne"
                checked={option_meeting === MeetingRoom.STANDARD}
                name="option_meeting"
                value={MeetingRoom.STANDARD}
                onChange={this.handleChange}
              />
              <label htmlFor="bOne">Standard</label>
              <input
                type="radio"
                id="bTwo"
                checked={option_meeting === MeetingRoom.EXTENDED}
                name="option_meeting"
                value={MeetingRoom.EXTENDED}
                onChange={this.handleChange}
              />
              <label htmlFor="bTwo">Extended</label>
            </div>
          </div>
          {/* <div className="mx-auto mt-4">
            <h5 className={styles.name}>Market:</h5>
            <div className={styles.radio}>
              <input
                type="radio"
                id="cOne"
                checked={market === Market.STANDARD}
                name="market"
                value={Market.STANDARD}
                onChange={this.handleChange}
              />
              <label htmlFor="cOne">Standard</label>
              <input
                type="radio"
                id="cTwo"
                checked={market === Market.NO_BEER_WINE}
                name="market"
                value={Market.NO_BEER_WINE}
                onChange={this.handleChange}
              />
              <label htmlFor="cTwo">No Beer/Wine</label>
            </div>
          </div> */}
          {/* <div className="mx-auto mt-4">
            <h5 className={styles.name}>Port Cochere</h5>
            <div className={styles.radio}>
              <input
                type="radio"
                id="dOne"
                checked={option_port_cochere === PortCochere.PORT_COCHER}
                name="option_port_cochere"
                value={PortCochere.PORT_COCHER}
                onChange={this.handleChange}
              />
              <label htmlFor="dOne">Port Cochere</label>
              <input
                type="radio"
                id="dTwo"
                checked={option_port_cochere === PortCochere.NO_PORT_COCHERE}
                name="option_port_cochere"
                value={PortCochere.NO_PORT_COCHERE}
                onChange={this.handleChange}
              />
              <label htmlFor="dTwo">No Port Cochere</label>
            </div>
          </div> */}
          {/* <button className={styles.submit} type="submit">
            <Svg icon="RoundArrow" className={styles.roundArrow} />
          </button> */}
        </form>
      </section>
    );
  }
}

export default BuildingProgram;
