import CarouselReact from "./CarouselReact";
import { connect } from "react-redux";
import * as filtersSelectors from "../../../../redux/Filters/filtersSelectors";

const mapStateToProps = (state) => {
  return {
    tablesForModel: filtersSelectors.getTablesForModel(state),
  };
};

export default connect(mapStateToProps, null)(CarouselReact);
