import React from "react";
import PropTypes from "prop-types";

const Aerial = ({ className }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 10.3889V4.61152C13.1246 4.52807 13.1021 4.44621 13.0599 4.3742C13.0178 4.30219 12.9573 4.24258 12.8848 4.20137L7.72852 1.30098C7.65904 1.26086 7.58023 1.23975 7.5 1.23975C7.41977 1.23975 7.34096 1.26086 7.27148 1.30098L2.11523 4.20137C2.04267 4.24258 1.98225 4.30219 1.94007 4.3742C1.89789 4.44621 1.87545 4.52807 1.875 4.61152V10.3889C1.87545 10.4723 1.89789 10.5542 1.94007 10.6262C1.98225 10.6982 2.04267 10.7578 2.11523 10.799L7.27148 13.6994C7.34096 13.7395 7.41977 13.7606 7.5 13.7606C7.58023 13.7606 7.65904 13.7395 7.72852 13.6994L12.8848 10.799C12.9573 10.7578 13.0178 10.6982 13.0599 10.6262C13.1021 10.5542 13.1246 10.4723 13.125 10.3889Z"
        fill="white"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0605 4.37109L7.55273 7.5L1.93945 4.37109"
        stroke="#F18442"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.55273 7.5L7.5 13.7578"
        stroke="#F18442"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Aerial.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Aerial;
