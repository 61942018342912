import React, { Component } from "react";
import styles from "./Workspace.module.sass";
import Tabs from "./Tabs/Tabs";
import Table from "./Table/Table";
import TableTotalGuestroomsRevenue from "./TableTotalGuestroomsRevenue/TableTotalGuestroomsRevenue";
import TableInvestmentReturns from "./TableInvestmentReturns/TableInvestmentReturns";
import TableGopMargin from "./TableGopMargin/TableGopMargin";
import SidePanel from "./SidePanel/SidePanelContainer";
import TableGuestrooms from "./TableGuestrooms/TableGuestrooms";
import isEmpty from "../../../helpers/isEmpty";
import percentFormat from "../../../helpers/percentFormat";
import buildingProgramToArray from "../../../helpers/buildingProgramToArray";
import developmentBudgetToArray from "../../../helpers/developmentBudgetToArray";
import guestRoomsToArray from "../../../helpers/guestRoomsToArray";
import guestroomsRevenueToArray from "../../../helpers/guestroomsRevenueToArray";
import investmentReturnsToArray from "../../../helpers/investmentReturnsToArray";
import stabilizedYearOneRevenueToArray from "../../../helpers/stabilizedYearOneRevenueToArray";
import { changeDataInTable } from "../../../redux/Filters/filtersActions";
import { resetTables } from "../../../redux/Filters/filtersReducer";
// Example of table format that we use for React component
// const tableExample = {
//   thead: ["Development Budget", "Total Budget", "% Total", "$/Key", "$/GSF"],
//   tbody: [
//     ["Land", "$7,000,000", "23.2%", "$41,667", "$126.61"],
//     ["Hard Costs", "$10,750,647", "35.7%", "$63,992", "$194.45"],
//     ["Soft Costs", "$5,528,772", "18.4%", "$32,909", "$100.00"],
//     ["Contingency Allowance", "$813,971", "2.7%", "$4,845", "$14.72"],
//     ["Guestroom FF&E", "$2,856,000", "9.5%", "$17,000", "$51.66"],
//     ["Dev. Fee and Overhead", "$997,469", "3.3%", "$5,937", "$18.04"],
//     ["Const. Loan Interest & Fees", "$2,174,377", "7.2%", "$12,943", "$39.33"]
//   ],
//   tfoot: [
//     ["Total Budget", "30,121,236", "100.00%", "179.293", "$544.81"],
//     ["Const. Loan Proceeds", "$15,060,618", "50.0%", "", ""],
//     ["Equity Required", "15,060,618", "50.0%", "", ""]
//   ]
// };
let countScrollHeightRequest = 0;
let headHeight = 170;
class Workspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "program",
      scrollHeight: 0,
      progressBarValue: 0,
      isStartProgress: false,
      startTime: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ scrollHeight: document.documentElement.clientHeight });
  }

  componentDidUpdate() {
    const { projectInfo } = this.props;
    document.title = projectInfo.projectName
      ? `Relocate demo | ${projectInfo.projectName}`
      : `Relocate demo |`;
    //Calculate height of tablesContainer
    const header = document.getElementById("header");
    const tabs = document.getElementById("tabs");
    if (tabs) {
      const headerHeight = parseInt(getComputedStyle(header).height);
      const tabsHeight = parseInt(getComputedStyle(tabs).height);
      const tabsMarginTop = parseInt(getComputedStyle(tabs).marginTop);
      const tabsMarginBottom = parseInt(getComputedStyle(tabs).marginBottom);

      headHeight = headerHeight + tabsHeight + tabsMarginTop + tabsMarginBottom;
      countScrollHeightRequest++;
      if (countScrollHeightRequest < 5) {
        const scrollHeight = Math.max(
          document.documentElement.scrollHeight,
          document.documentElement.clientHeight
        );
        if (scrollHeight !== this.state.scrollHeight) {
          this.setState({ scrollHeight });
        }
      }
    }
  }

  handleCategorySelected = (category) => {
    this.setState({
      category,
    });
  };

  render() {
    console.log(this.props);
    const blended_revenue =
      (this.props.projectInfo &&
        this.props.projectInfo.revenue_assumptions &&
        this.props.projectInfo.revenue_assumptions.blended_revenue) ||
      false;
    const inv_margin_selection =
      this.props.projectInfo &&
      this.props.projectInfo.investment_assumptions &&
      this.props.projectInfo.investment_assumptions.inv_margin_selection;
    const { category, scrollHeight } = this.state;
    const {
      building_program,
      development_budget,
      guest_rooms,
      guestrooms_revenue,
      investment_returns,
      stabilized_year_one_revenue,
    } = this.props.filters.tablesForModel;

    const buildingProgramArray = buildingProgramToArray(building_program);
    const developmentBudget = developmentBudgetToArray(development_budget);
    const guestRooms = guestRoomsToArray(guest_rooms);
    const guestroomsRevenue = guestroomsRevenueToArray(guestrooms_revenue);
    const investmentReturns = investmentReturnsToArray(investment_returns);
    const stabilizedYearOneRevenue = stabilizedYearOneRevenueToArray(
      stabilized_year_one_revenue,
      inv_margin_selection
    );
    const items = [
      {
        id: "1",
        icon: "house",
        head: "House",
        value1: `${guest_rooms.total.keys} rooms`,
        value2: `${(building_program.total_gross.area / 1000).toFixed(1)}eos`,
        to: "Guestrooms",
      },
      {
        id: "2",
        icon: "budget",
        head: "Budget",
        value1: `$${(development_budget.total.total_budget / 1000000).toFixed(
          1
        )}K`,
        value2: `$${(development_budget.total.cost_per_key / 1000).toFixed(
          0
        )}quia`,
        to: "DevelopmentBudget",
      },
      {
        id: "3",
        icon: "investment",
        head: "Investment",
        value1: `${investment_returns.multiple_on_invested_equity.toFixed(2)}x`,
        value2: `${percentFormat(
          investment_returns.stabilized_yield_cost
        )} cost`,
        to: "GuestroomsRevenue",
      },
    ];
    return (
      <>
        {!isEmpty(this.props.projectInfo) && (
          <section className={`${styles.workspace} m-0`}>
            <div className={`${styles.colon}`}>
              <Tabs
                items={items}
                category={category}
                onSelect={this.handleCategorySelected}
              />
              <div
                className={styles.tablesContainer}
                id="tablesContainer"
                style={{
                  height: `${scrollHeight - headHeight}px`,
                }}
              >
                <TableGuestrooms id="Guestrooms" table={guestRooms} />
                <Table id="BuildingProgram" table={buildingProgramArray} />
                <Table id="DevelopmentBudget" table={developmentBudget} />
                {blended_revenue ? (
                  <TableTotalGuestroomsRevenue
                    id="GuestroomsRevenue"
                    table={guestroomsRevenue}
                  />
                ) : (
                  <Table id="GuestroomsRevenue" table={guestroomsRevenue} />
                )}
                {inv_margin_selection === "gop_margin" ? (
                  <TableGopMargin
                    id="StabilizedYearOneRevenue"
                    table={stabilizedYearOneRevenue}
                  />
                ) : (
                  <Table
                    id="StabilizedYearOneRevenue"
                    table={stabilizedYearOneRevenue}
                  />
                )}
                <TableInvestmentReturns
                  id="InvestmentReturns"
                  table={investmentReturns}
                />
              </div>
            </div>
            <SidePanel />
          </section>
        )}
      </>
    );
  }
}

export default Workspace;
