import React, { Component } from "react";
import "./Location.sass";
import "./mapbox-gl-geocoder.css";
import { validate } from "indicative/validator";
import ErrorNotification from "../ErrorNotification/ErrorNotification";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import mapboxgl from "mapbox-gl";
import axios from "axios";

const rules = {
  projectName: "required|string|min:3",
  formatted_address: "required|string|min:2",
  place: "equals:true",
  "coordinatesWindow.lat": "range:51.5564543,51.5593456",
  "coordinatesWindow.lng": "range:-0.0294028,-0.0249335",
};

const messages = {
  "projectName.required":
    "Please choose a unique Project name for your account",
  "projectName.min": "Project name must be at least 3 characters long",
  "place.equals": "Please select a location from the drop down list",
  "formatted_address.required": "Please choose a location",
  "formatted_address.min": "Street name must be at least 2 characters long",
  "coordinatesWindow.lat.range": "Select place at parcel",
  "coordinatesWindow.lng.range": "Select place at parcel",
};

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: props.projectName,
      formatted_address: "",
      address: {
        ...props.address,
      },
      errors: null,
      isReadyToGoNext: false,
    };
  }

  onClick = (event) => {
    const { map } = this.props;

    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.lngLat.lat},${event.lngLat.lng}.json?language=en&access_token=pk.eyJ1Ijoib2xla3NhbmRycGV0cnlrIiwiYSI6ImNrNGNudW91dzA4YnUzbnBmc3l5OGl3c3AifQ.GmdMReeE1fkKHjU1FE18Vw`
      )
      .then((res) => console.log(res));

    Geocode.setApiKey("AIzaSyBTbF_DeN5HfFlznaGxOnX2z0zbo4m84Do");
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.enableDebug();
    Geocode.fromLatLng(event.lngLat.lat, event.lngLat.lng).then(
      (response) => {
        const address = response.results[0].address_components.reduce(
          (addr, e) => {
            switch (("E.types:", e.types)) {
              case "street_number":
                return { ...addr, streetNumber: e.short_name };
              case "route":
                return { ...addr, street: e.short_name };
              case "locality":
                return { ...addr, city: e.short_name };
              case "administrative_area_level_1":
                return { ...addr, state: e.short_name };
              case "postal_code":
                return { ...addr, zip: e.short_name };
              case "country":
                return { ...addr, country: e.short_name };
              default:
                return { ...addr };
            }
          },
          {
            formatted_address: response.results[0].formatted_address,
            streetNumber: "",
            street: "",
            lat: event.lngLat.lat,
            lng: event.lngLat.lng,
            zoom: 16,
            city: "",
            state: "",
            zip: "",
            country: "",
          }
        );
        this.setState({
          address,
          errors: null,
          isReadyToGoNext: true,
          formatted_address: address.formatted_address,
        });
      },
      (error) => {
        console.error(error);
      }
    );

    const coordinates = {
      lng: event.lngLat.lng,
      lat: event.lngLat.lat,
      zoom: 16,
    };

    var currentMarkers = this.props.currentMarkers;

    // remove markers
    if (currentMarkers !== null) {
      for (var i = currentMarkers.length - 1; i >= 0; i--) {
        currentMarkers[i].remove();
      }
    }
    // tmp marker
    var oneMarker = new mapboxgl.Marker({ color: "#307FF4" })
      .setLngLat([event.lngLat.lng, event.lngLat.lat])
      .addTo(map);
    map.flyTo({ center: [event.lngLat.lng, event.lngLat.lat], zoom: 16 });

    // save tmp marker into currentMarkers
    currentMarkers.push(oneMarker);
    this.setState({ coordinatesWindow: coordinates, currentMarkers });
  };

  componentDidMount() {
    const { map } = this.props;
    // Handle clicks on the map
    map.on("click", this.onClick);
    //fix bug with autocomplete="new-password"
    const geocoder = document.querySelector(".geocoder");
    geocoder.setAttribute("autocomplete", "off");
  }
  componentWillUnmount() {
    const { map } = this.props;
    map.off("click", this.onClick);
    // marker.remove();
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      errors: null,
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { projectName, address, coordinatesWindow } = this.state;

    validate(
      {
        projectName,
        formatted_address: address.formatted_address,
        coordinatesWindow,
      },
      rules,
      messages
    )
      .then(() => {
        this.props.handleLocation({ projectName, address });
        this.props.nextStep();
      })
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach((error) => {
          formattedErrors[error.field] = error.message;
        });

        this.setState({
          errors: formattedErrors,
        });
      });
  };

  placeHandler = (place) => {
    const { map, currentMarkers } = this.props;
    if (!place.geometry) {
      this.setState({
        errors: {
          formatted_address: "Please select a location from the drop down list",
        },
      });
      return;
    }

    const address = place.address_components.reduce(
      (addr, e) => {
        switch (e.types[0]) {
          case "street_number":
            return { ...addr, streetNumber: e.short_name };
          case "route":
            return { ...addr, street: e.short_name };
          case "locality":
            return { ...addr, city: e.short_name };
          case "administrative_area_level_1":
            return { ...addr, state: e.short_name };
          case "postal_code":
            return { ...addr, zip: e.short_name };
          case "country":
            return { ...addr, country: e.short_name };
          default:
            return { ...addr };
        }
      },
      {
        formatted_address: place.formatted_address,
        streetNumber: "",
        street: "",
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        zoom: 16,
        city: "",
        state: "",
        zip: "",
        country: "",
      }
    );

    this.setState({
      address,
      errors: null,
      isReadyToGoNext: true,
      formatted_address: place.formatted_address,
    });
    const coordinatesWindow = {
      lng: address.lng,
      lat: address.lat,
      zoom: 16,
    };

    // remove markers
    if (currentMarkers !== null) {
      for (var i = currentMarkers.length - 1; i >= 0; i--) {
        currentMarkers[i].remove();
      }
    }
    // tmp marker
    var oneMarker = new mapboxgl.Marker({ color: "#307FF4" })
      .setLngLat([address.lng, address.lat])
      .addTo(map);

    // save tmp marker into currentMarkers
    currentMarkers.push(oneMarker);
    this.props.setCoordinatesWindow({ coordinatesWindow });
    this.props.setCurrentMarkers({ currentMarkers });
    map.flyTo({ center: [address.lng, address.lat], zoom: 16 });
  };

  render() {
    const { errors, isReadyToGoNext, projectName } = this.state;
    const { streetNumber, street, city, state, zip } = this.state.address;

    return (
      <div className="text col-4 p-4">
        <h5 className="title text-right">Project Location</h5>
        <div className="mb-5">
          <div className="mb-5">
            <h2 className="mt-5 mb-5 pb-2">Let&apos;s get Started!</h2>
            <div className="text-left">
              <h4 className="title">What is your project name?</h4>
              <input
                autoFocus
                className={errors && errors.projectName && "error"}
                type="text"
                name="projectName"
                value={projectName}
                onChange={this.handleChange}
                placeholder="Enter a Project name"
              />
              {errors && <ErrorNotification label={errors.projectName} />}
            </div>
          </div>

          <div className="mb-5 pt-2">
            <div className="text-left address">
              <h4 className="title">Project Address</h4>
              <Autocomplete
                autoComplete="off"
                autoFocus={!street}
                name="formatted_address"
                // defaultValue={this.props.address.formatted_address}
                value={this.state.formatted_address}
                onChange={this.onChange}
                style={{ width: "100%" }}
                onPlaceSelected={(place) => this.placeHandler(place)}
                types={["address"]}
                placeholder="Enter a location"
                componentRestrictions={{ country: "gb" }}
                className={`geocoder ${
                  errors && errors.formatted_address && "error"
                }`}
              />
              {errors && <ErrorNotification label={errors.formatted_address} />}
              {errors && (
                <ErrorNotification label={errors["coordinatesWindow.lat"]} />
              )}
              {errors && (
                <ErrorNotification label={errors["coordinatesWindow.lng"]} />
              )}
            </div>
          </div>

          {/*<div className="street">*/}
          {/*  <span className="title">*/}
          {/*    {streetNumber || street ? `${streetNumber || ""} ${street}` : ""}*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div className="city">*/}
          {/*  <span className="title mr-2">{`${city} ${state} ${zip}`}</span>*/}
          {/*</div>*/}
        </div>
        <div className="controls">
          <button
            autoFocus={isReadyToGoNext}
            type="submit"
            className="next"
            onClick={this.handleSubmit}
            disabled={!!errors}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Location;
