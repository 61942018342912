import Brand from "./Brand";
import { connect } from "react-redux";
import * as sessionSelectors from "../../../../redux/Session/sessionSelectors";
import {
  logoSelectedSwitch,
  logoSelectedSwitchToFalse, logoValidateMessage, logoValidateMessageFalse,
} from "../../../../redux/ProjectInfo/projectInfoActions";

const mapStateToProps = (state) => {
  return {
    isDemoModeOff: sessionSelectors.getIsDemoModeOff(state),
    brands: state.projectInfo.projectInfo.brands,
    logoSelected: state.projectInfo.projectInfo.logoSelected,
    validateMessage: state.projectInfo.projectInfo.validateMessage,
  };
};

export default connect(mapStateToProps, {
  logoSelectedSwitch,
  logoSelectedSwitchToFalse,
  logoValidateMessage,
  logoValidateMessageFalse,
})(Brand);
