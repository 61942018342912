import React from "react";
import PropTypes from "prop-types";
import Filters from "./Filters";
import Concept from "./Concept";
import BuildingProgram from "./BuildingProgram/BuildingProgramContainer";
import Revenue from "./Revenue/RevenueContainer";
import Budget from "./Budget/BudgetContainer";
import Investment from "./Investment/InvestmentContainer";

const Tabs = ({ tabComponent }) => {
  return (
    <>
      {tabComponent === "Filters" && <Filters />}
      {tabComponent === "Concept" && <Concept />}
      {tabComponent === "BuildingProgram" && <BuildingProgram />}
      {tabComponent === "Revenue" && <Revenue />}
      {tabComponent === "Budget" && <Budget />}
      {tabComponent === "Investment" && <Investment />}
    </>
  );
};

Tabs.propTypes = {
  tabComponent: PropTypes.string.isRequired,
};

export default Tabs;
