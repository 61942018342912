import React, { Component } from "react";
import "./Create.sass";
import Steps from "./Steps/Steps";
import Location from "./Location/Location";
import Site from "./Site/Site";
import Setback from "./Setback/Setback";
import Brand from "./Brand/BrandContainer";
import toastr from "toastr";
import {
  length,
  cleanCoords,
  polygonToLine,
  lineString,
  lineToPolygon,
  multiLineString,
  bearing,
  along,
} from "@turf/turf";
import mapboxgl from "mapbox-gl";

import $ from "jquery";
import toISOString from "../../../helpers/toISOString";

mapboxgl.accessToken =
  "pk.eyJ1IjoicGFyYWZpbnRlY2giLCJhIjoiY2tpYmt5NHdpMTBsZDMyczM2NGp5aHAwaiJ9.Cs_A-nZpo0hCnLs6iprAMA";
const lovelandAPIToken =
  "s8s29o-XybbxRkZxj8xMXx2JzSSQ7GPrSav8VwALJz-69PnTvEr1eXxnNygQzrcv";

let map = null;
let marker = null;
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "streets-v11",
      currentMarkers: [],
      projectName: "",
      coordinatesWindow: {
        lat: 51.557735,
        lng: -0.0273803,
        zoom: 2,
      },
      address: {
        value: "",
        streetNumber: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        lat: "",
        lng: "",
        zoom: "",
      },
      selectedParcels: {
        type: "FeatureCollection",
        features: [],
      },
      sitePolygon: {
        type: "Feature",
        geometry: { coordinates: [], type: "Polygon" },
        properties: {},
      },
      differenceSitePolygon: null,
      data: {},
      projectSite: {},
      projectSetback: {},
      setbackInputs: {},
      steps: 4,
      step: 1,
    };
  }
  mapInit = () => {
    const { lng, lat, zoom } = this.state.coordinatesWindow;
    const { view } = this.state;
    map = new mapboxgl.Map({
      container: this.mapContainer,
      // container: "reference",
      style: "mapbox://styles/mapbox/" + view,
      center: [-0.0273803, 51.557735],
      zoom: 15,
    });
    map.on("load", function () {
      // Add a data source containing GeoJSON data.
      map.addSource("maine", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [-0.0273803, 51.557735],
                [-0.0273803, 51.557735],
                [-0.0273803, 51.557735],
                [-0.0273803, 51.557735],
                [-0.0273803, 51.557735],
              ],
            ],
          },
        },
      });
      // Add a new layer to visualize the polygon.
      map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
      // Add a #E5E5E5 outline around the polygon.
      map.addLayer({
        id: "outline",
        type: "line",
        source: "maine",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });
    });
    // var draw = new MapboxDraw({
    //   displayControlsDefault: false,
    //   controls: {
    //     polygon: true,
    //     trash: true,
    //   },
    //   defaultMode: "draw_polygon",
    // });
    // map.addControl(draw);
    //
    // map.on("draw.create", updateArea);
    // map.on("draw.delete", updateArea);
    // map.on("draw.update", updateArea);
    //
    // function updateArea(e) {
    //   var data = draw.getAll();
    //   console.log({ data });
    // }
    var currentMarkers = this.state.currentMarkers;

    // remove markers
    if (currentMarkers !== null) {
      for (var i = currentMarkers.length - 1; i >= 0; i--) {
        currentMarkers[i].remove();
      }
    }
    // tmp marker
    var oneMarker = new mapboxgl.Marker({ color: "#307FF4" });

    // save tmp marker into currentMarkers
    currentMarkers.push(oneMarker);
    this.setState({ currentMarkers });

    if (lng !== -0.0273803 && lat !== 51.557735) {
      oneMarker.setLngLat([lng, lat]).addTo(map);
      map.flyTo({ center: [lng, lat], zoom: 16 });
    }

    //HandleChange for MapScroll
    map.on("move", () => {
      const { lng, lat } = map.getCenter();

      this.setState({
        coordinatesWindow: {
          lng: lng.toFixed(7),
          lat: lat.toFixed(7),
          zoom: map.getZoom().toFixed(2),
        },
      });
    });
    // site part
    map.on("load", () => {
      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());
      // Get parcel metadata
      var parcelCreate = $.ajax({
        url:
          "https://tiles.makeloveland.com/api/v1/parcels?format=mvt&token=" +
          lovelandAPIToken,
        type: "GET",
        contentType: "application/json",
        dataType: "json",
      });
      parcelCreate.fail(function (jqXHR, textStatus, errorThrown) {
        toastr.error(
          `Error getting parcel layer ${jqXHR} ${textStatus} ${errorThrown}`,
          "Error"
        );
      });
      // $.when(parcelCreate).then(setup);
      setup(null);
      var selectedParcels = {
        ...this.state.selectedParcels,
      };

      // Add the parcel data to the map
      function setup(layerData) {
        var data2 = {
          id: "parsels2",
        };
        var data = {
          id: "parsels",
        };
        var data3 = {
          id: "parsels3",
        };
        /*map.addSource(data3.id, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [
                [
                  [-0.0775568, 51.5092],
                  [-0.0742837, 51.5092],
                  [-0.0741668, 51.5071],
                  [-0.0775568, 51.5071],
                  [-0.0775568, 51.5092],
                ],
              ],
            },
          },
        });*/
        /*map.addLayer({
          id: "parcels3",
          type: "line",
          source: data3.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "hsl(203, 41%, 39%)",
          },
        });*/

        /*map.addSource(data2.id, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [
                [
                  [-0.0743071, 51.5113],
                  [-0.0742779, 51.5092],
                  [-0.0811163, 51.5092],
                  [-0.0813209, 51.5113],
                  [-0.0743071, 51.5113],
                ],
              ],
            },
          },
        });*/
        /*map.addLayer({
          id: "parcels2",
          type: "line",
          source: data2.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "hsl(203, 41%, 39%)",
          },
        });*/
        // Register a Mapbox GL source using the tile URL we just got
        map.addSource(data.id, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [
                [
                  [-0.0294028, 51.5593456],
                  [-0.0249335, 51.5593456],
                  [-0.0249335, 51.5564543],
                  [-0.0294028, 51.5564543],
                  [-0.0294028, 51.5593456],
                ],
              ],
            },
          },
        });

        // Add parcel outlines to the map with basic styles
        map.addLayer({
          id: "parcels",
          type: "line",
          source: data.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "hsl(203, 41%, 39%)",
          },
        });

        // Make an invisible fill layer so the shapes are more clickable
        map.addLayer({
          id: "parcel-touchable",
          type: "fill",
          source: data.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-opacity": 0,
          },
        });
        /*map.addLayer({
          id: "parcel-touchable2",
          type: "fill",
          source: data2.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-opacity": 0,
          },
        });*/
        /*map.addLayer({
          id: "parcel-touchable3",
          type: "fill",
          source: data3.id,
          // "source-layer": data.id,
          // minzoom: 10,
          // maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-opacity": 0,
          },
        });*/
        // map.addLayer({
        //   id: "parcel-touchable",
        //   type: "fill",
        //   source: data2.id,
        //   // "source-layer": data.id,
        //   // minzoom: 10,
        //   // maxzoom: 20,
        //   layout: {
        //     visibility: "visible",
        //   },
        //   paint: {
        //     "fill-opacity": 0,
        //   },
        // });

        // Add a geojson layer to track selected parcels

        map.addSource("selected", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [
                [
                  // [-0.074254, 51.511265],
                  // [-0.074079, 51.5071],
                  // [-0.07063, 51.5071],
                  // [-0.070864, 51.511247],
                  // [-0.074254, 51.511265],
                ],
              ],
            },
          },
        });
        map.addLayer({
          id: "selected",
          type: "fill",
          source: "selected",
          paint: {
            "fill-color": "#088",
            "fill-opacity": 0.4,
          },
        });
        map.addSource("selecte", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [
                [
                  [-0.074254, 51.511265],
                  [-0.074079, 51.5071],
                  [-0.07063, 51.5071],
                  [-0.070864, 51.511247],
                  [-0.074254, 51.511265],
                ],
              ],
            },
          },
        });
        map.addLayer({
          id: "selecte",
          type: "line",
          source: "selected",
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "#307FF4",
            "line-width": 3,
          },
        });
      }
      // Load Loveland's parcel data END
    });
  };

  setSitePolygon = ({ sitePolygon, differenceSitePolygon }) => {
    this.setState({ sitePolygon, differenceSitePolygon });
  };

  mapInitBack = () => {
    const { lng, lat, zoom } = this.state.coordinatesWindow;
    const { view } = this.state;
    map = new mapboxgl.Map({
      container: this.mapContainer,
      // container: "reference",
      style: "mapbox://styles/mapbox/" + view,
      center: [lng, lat],
      zoom: zoom,
    });

    //HandleChange for MapScroll
    map.on("move", () => {
      const { lng, lat } = map.getCenter();

      this.setState({
        coordinatesWindow: {
          lng: lng.toFixed(7),
          lat: lat.toFixed(7),
          zoom: map.getZoom().toFixed(2),
        },
      });
    });
    return map;
  };
  componentDidMount() {
    this.mapInit();
  }

  handleClearParcels = () => {
    this.setState({
      selectedParcels: {
        type: "FeatureCollection",
        features: [],
      },
      sitePolygon: {
        type: "Feature",
        geometry: { coordinates: [], type: "Polygon" },
        properties: {},
      },
      differenceSitePolygon: null,
    });
  };
  mapReset = () => {
    map.needReset = true;
  };
  nextStep = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  prevStep = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  };

  setCoordinatesWindow = ({ coordinatesWindow }) => {
    this.setState({ coordinatesWindow });
  };

  setSelectedParcels = (selectedParcels) => {
    this.setState({ selectedParcels });
  };

  setCurrentMarkers = ({ currentMarkers }) => {
    this.setState({ currentMarkers });
  };
  setProjectSetback = (projectSetback) => {
    this.setState({ projectSetback });
  };

  handleLocation = ({ projectName, address }) => {
    this.setState((prevState) => {
      if (prevState.address.formatted_address === address.formatted_address) {
        return { projectName, address };
      } else {
        return {
          projectName,
          address,
          selectedParcels: {
            type: "FeatureCollection",
            features: [],
          },
        };
      }
    });
  };
  handleSite = ({
    selectedParcels,
    lotData,
    projectSite,
    sitePolygon,
    applyedPolygons,
  }) => {
    // 1 first, we shift the first coordinate to a point that is a corner:
    const shiftedPolygon = shiftPolygonOrigin(sitePolygon);

    function shiftPolygonOrigin(polygon) {
      let cleanPoly = cleanCoords(polygon);
      let segmentedPoly = polygonToLine(cleanPoly);
      let vertices = segmentedPoly.geometry.coordinates.length;

      for (let i = 0; i < vertices; i++) {
        let firstAngle = getAngle360(
          segmentedPoly.geometry.coordinates[0],
          segmentedPoly.geometry.coordinates[1]
        );

        let lastAngle = getAngle360(
          segmentedPoly.geometry.coordinates[vertices - 1],
          segmentedPoly.geometry.coordinates[vertices - 2]
        );

        let vertexAngle = Math.abs(firstAngle - lastAngle);

        if (vertexAngle < 155 || vertexAngle > 205) {
          break;
        }
        let shifted = shiftByOne(segmentedPoly.geometry.coordinates);
        segmentedPoly = lineString(shifted);
      }
      let cleanedLine = cleanCoords(segmentedPoly);
      return lineToPolygon(cleanedLine);
    }

    function getAngle360(p1, p2) {
      let bearingVar = bearing(p1, p2);
      let angle;
      if (bearingVar < 0) {
        angle = 360 + bearingVar;
      } else {
        angle = bearingVar;
      }
      return angle;
    }

    function shiftByOne(array) {
      array.pop(); // remove last item
      let firstLast = array[array.length - 1]; // copy new last item
      array.unshift(firstLast); // add to beginning
      return array;
    }

    // 2 convert the polygon to a multiLineString
    const siteMultiline = getMultilineString(shiftedPolygon);

    function getMultilineString(polygon) {
      let vertices = polygon.geometry.coordinates[0].length - 1;
      let coords = new Array(1); // vertices);
      for (var j = 0; j < coords.length; j++) {
        coords[j] = [];
      }
      j = 0;
      coords[0][0] = polygon.geometry.coordinates[0][0];
      // alert("made it to 78" + coords);
      let firstJAngle = getAngle360(
        polygon.geometry.coordinates[0][0],
        polygon.geometry.coordinates[0][1]
      );
      //alert(firstJAngle);
      for (let i = 1; i < vertices; i++) {
        // leave off last
        let beforeAngle = getAngle360(
          polygon.geometry.coordinates[0][i],
          polygon.geometry.coordinates[0][i - 1]
        );
        let afterAngle = getAngle360(
          polygon.geometry.coordinates[0][i],
          polygon.geometry.coordinates[0][i + 1]
        );
        //let firstJAngle = getAngle360(coords[j][0], coords[j][1]);
        //alert(firstAngle);
        let vertexAngle = Math.abs(beforeAngle - afterAngle);
        let vsFirstJAngle = Math.abs(firstJAngle - afterAngle);

        if (
          (vsFirstJAngle > 30 && vsFirstJAngle < 330) ||
          vertexAngle < 165 ||
          vertexAngle > 195
        ) {
          coords[j].push(polygon.geometry.coordinates[0][i]);
          j = j + 1;
          coords[j] = [];
          // alert(firstJAngle + " | " + afterAngle);
          firstJAngle = afterAngle;
        }

        coords[j].push(polygon.geometry.coordinates[0][i]);
        // alert(coords[j])
      }
      //alert(coords)
      coords[j].push(polygon.geometry.coordinates[0][vertices]); // last coord
      let multiline = multiLineString(coords);
      return multiline;
    }

    // 3 label the midpoints of the multiLineString

    const labelFeatureCollection = getLabelFeatureCollection(siteMultiline);

    function getLabelFeatureCollection(multiLineString) {
      let featureCollection = []; // Initialize empty collection
      let sides = multiLineString.geometry.coordinates.length; // #sides
      for (var i = 0; i < sides; i++) {
        let lineStringVar = lineString(multiLineString.geometry.coordinates[i]);
        let lengthLineString = length(lineStringVar);
        var midpoint = along(lineStringVar, lengthLineString / 2);
        var midpointLetter = toLetters(i + 1);
        featureCollection.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: midpoint.geometry.coordinates,
          },
          properties: {
            name: midpointLetter,
          },
        });
      }
      return featureCollection;
    }

    ////////////////////////////////////////////////////////////////////
    // simplify to reduce coordinates
    const setbackInputs = getSetbackInputsDefault(siteMultiline);

    function getSetbackInputsDefault(polygon) {
      var featureCollection = [];
      var sides = polygon.geometry.coordinates.length;
      for (var i = 0; i < sides; i++) {
        var midpointLetter = toLetters(i + 1);
        featureCollection.push({
          side: midpointLetter,
          frontage: "None",
          setback: 0,
        });
      }
      return featureCollection;
    }

    function toLetters(num) {
      var mod = num % 26;
      var pow = (num / 26) | 0;
      var out = mod ? String.fromCharCode(64 + mod) : (pow--, "Z");
      return pow ? toLetters(pow) + out : out;
    }

    this.setState({
      selectedParcels,
      lotData,
      projectSite,
      labelFeatureCollection,
      sitePolygon,
      shiftedPolygon,
      siteMultiline,
      setbackInputs,
      applyedPolygons,
    });
  };
  resetSite = () => {
    this.setState({
      selectedParcels: {
        type: "FeatureCollection",
        features: [],
      },
      lotData: "",
      projectSite: {},
      sitePolygon: {
        type: "Feature",
        geometry: { coordinates: [], type: "Polygon" },
        properties: {},
      },
    });
  };
  handleSetback = ({ setbackInputs, projectSetback }) => {
    this.setState({ setbackInputs, projectSetback });
  };
  handleChange = (input) => (event) => {
    let checked = `${input}Checked`;
    this.setState({
      [input]: event.target.value,
      [checked]: true,
    });
  };

  parcelCoordinates = (lat = undefined, lng = undefined) => {
    this.setState({ parcelLatLng: [lat, lng] });
  };

  runParafin = async () => {
    const { _id, families } = this.props.user;
    if (!this.state.brand) {
      toastr.warning("Please select any brand", "Error");
      return false;
    }

    const dateNow = new Date();

    const data = {
      projectName: this.state.projectName,
      userId: _id,
      dateCreated: toISOString(dateNow),
      dateLastEdited: toISOString(dateNow),
      isActive: true,
      familyId: JSON.parse(this.state.brand).familyId,
      brandId: JSON.parse(this.state.brand).brandId,
      brandName: JSON.parse(this.state.brand).brandName,
      area_ac: Number(this.state.lotData.acres),
      area_sf: Number(this.state.lotData.sqft),
      view: this.state.view,
      address: {
        formatted_address: this.state.address.formatted_address,
        zip: this.state.address.zip,
        street: this.state.address.street,
        streetNumber: this.state.address.streetNumber,
        city: this.state.address.city,
        state: this.state.address.state,
        country: this.state.address.country,
      },
      projectLocation: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [this.state.address.lng, this.state.address.lat],
        },
      },
      projectSite: this.state.projectSite,
      projectSetback: this.state.projectSetback,
      setbackInput: this.state.setbackInputs,
    };

    this.props.setFalseResultCalculated();
  };

  onChange = (e) => {
    const {
      sitePolygon,
      step,
      projectSite,
      shiftedPolygon,
      labelFeatureCollection,
      projectSetback,
    } = this.state;
    e.persist();
    var parcelCreate = $.ajax({
      url:
        "https://tiles.makeloveland.com/api/v1/parcels?format=mvt&token=" +
        lovelandAPIToken,
      type: "GET",
      contentType: "application/json",
      dataType: "json",
    });
    parcelCreate.fail(function (jqXHR, textStatus, errorThrown) {
      toastr.error(
        `Error getting parcel layer ${jqXHR} ${textStatus} ${errorThrown}`,
        "Error"
      );
    });
    $.when(parcelCreate).then(setup);

    var selectedParcels = {
      ...this.state.selectedParcels,
    };

    // Add the parcel data to the map
    function setup(layerData) {
      var data2 = {
        id: "parsels2",
      };
      var data = {
        id: "parsels",
      };
      var data3 = {
        id: "parsels3",
      };

      // Register a Mapbox GL source using the tile URL we just got
      /*map.addSource(data3.id, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [-0.0775568, 51.5092],
                [-0.0742837, 51.5092],
                [-0.0741668, 51.5071],
                [-0.0775568, 51.5071],
                [-0.0775568, 51.5092],
              ],
            ],
          },
        },
      });*/

      // Add parcel outlines to the map with basic styles
      /*map.addLayer({
        id: "parcels3",
        type: "line",
        source: data3.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "red",
        },
      });*/

      // Make an invisible fill layer so the shapes are more clickable
      map.addLayer({
        id: "parcel-touchable",
        type: "fill",
        source: data.id,
        "source-layer": data.id,
        minzoom: 10,
        maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-opacity": 0,
        },
      });

      /*map.addSource(data2.id, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [-0.0743071, 51.5113],
                [-0.0742779, 51.5092],
                [-0.0811163, 51.5092],
                [-0.0813209, 51.5113],
                [-0.0743071, 51.5113],
              ],
            ],
          },
        },
      });*/
      /*map.addLayer({
        id: "parcels2",
        type: "line",
        source: data2.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "red",
        },
      });*/
      map.addSource(data.id, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [-0.074254, 51.511265],
                [-0.074079, 51.5071],
                [-0.07063, 51.5071],
                [-0.070864, 51.511247],
                [-0.074254, 51.511265],
              ],
            ],
          },
        },
      });
      map.addLayer({
        id: "parcels",
        type: "line",
        source: data.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "red",
        },
      });
      // Add a geojson layer to track selected parcels
      map.addLayer({
        id: "parcel-touchable",
        type: "fill",
        source: data.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-opacity": 0,
        },
      });
      /*map.addLayer({
        id: "parcel-touchable2",
        type: "fill",
        source: data2.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-opacity": 0,
        },
      });*/
      /*map.addLayer({
        id: "parcel-touchable3",
        type: "fill",
        source: data3.id,
        // "source-layer": data.id,
        // minzoom: 10,
        // maxzoom: 20,
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-opacity": 0,
        },
      });*/
      map.addSource("selected", { type: "geojson", data: sitePolygon });
      map.addLayer({
        id: "selected",
        type: "fill",
        source: "selected",
        paint: {
          "fill-color": "#FFF",
          "fill-opacity": 0.4,
        },
      });
      map.addSource("selecte", { type: "geojson", data: sitePolygon });
      map.addLayer({
        id: "selecte",
        type: "line",
        source: "selected",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "#307FF4",
          "line-width": e.target.value === "streets-v11" ? 3 : 4,
        },
      });
      if (step === 3) {
        map.addSource("parcelOutline", {
          type: "geojson",
          data: projectSite,
        });

        map.addLayer({
          id: "parcelFill",
          type: "fill",
          source: "parcelOutline",
          paint: {
            "fill-color": "#888888",
            "fill-opacity": 0.2,
          },
        });

        map.addLayer({
          id: "parcelLine",
          type: "line",
          source: "parcelOutline",
          paint: {
            "line-color": "#0000FF",
            "line-width": 1,
          },
        });

        map.addSource("simpParcelOutline", {
          type: "geojson",
          data: shiftedPolygon,
        });

        map.addLayer({
          id: "simpParcelFill",
          type: "fill",
          source: "simpParcelOutline",
          paint: {
            "fill-color": "#088",
            "fill-opacity": 0.4,
          },
        });

        map.addLayer({
          id: "simpParcelLine",
          type: "line",
          source: "simpParcelOutline",
          paint: {
            "line-color": "#307FF4",
            "line-width": 4,
          },
        });

        map.addSource("parcelMidpoints", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: labelFeatureCollection,
          },
        });

        map.addLayer({
          id: "midpoints",
          type: "circle",
          source: "parcelMidpoints",
          paint: {
            "circle-radius": 12,
            "circle-color": "#ffffff",
            "circle-stroke-width": 2,
            "circle-stroke-color": "#333333",
          },
        });

        map.addLayer({
          id: "midpointLetter",
          type: "symbol",
          source: "parcelMidpoints",
          filter: ["==", "$type", "Point"],
          layout: {
            "text-anchor": "center",
            "text-field": "{name}",
            "symbol-placement": "point",
            "text-size": 16,
            "text-max-width": 40,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          },
        });

        map.addSource("setbackOutline", {
          type: "geojson",
          data: projectSetback,
        });

        map.addLayer({
          id: "setbackFill",
          type: "fill",
          source: "setbackOutline",
          paint: {
            "fill-color": "#FA0",
            "fill-opacity": 0.6,
          },
        });

        map.addLayer({
          id: "setbackLine",
          type: "line",
          source: "setbackOutline",
          paint: {
            "line-color": "#333333",
            "line-width": 4,
          },
        });
        map.moveLayer("midpoints");
        map.moveLayer("midpointLetter");
      }
    }

    if (e.target.value === "satellite-v9") {
      this.setState({ view: "streets-v11" });
      map.setStyle("mapbox://styles/mapbox/streets-v11");
    } else {
      this.setState({ view: "satellite-v9" });
      map.setStyle("mapbox://styles/mapbox/satellite-v9");
    }
  };
  addNewProject = () => {
    const numFormat = (num) => (num < 10 ? "0" + num : num);
    const dateFormat = (date) =>
      `${numFormat(date.getDate())}.${numFormat(
        date.getMonth() + 1 > 12 ? 1 : date.getMonth() + 1
      )}.${date.getFullYear()}`;
    const dateNow = new Date();
    const formatted = dateFormat(dateNow);
    const {
      projectName,
      address,
      selectedParcels,
      sitePolygon,
      projectSite,
      shiftedPolygon,
      siteMultiline,
      labelFeatureCollection,
      setbackInputs,
      differenceSitePolygon,
      applyedPolygons,
    } = this.state;
    // st = should be address
    // LotArea `${this.props.area_sf} sf / ${this.props.area_ac} ac`
    const project = {
      name: projectName,
      created: formatted,
      lastEdited: formatted,
      lotArea: "184,478 sf / 4.24 ac",
      st: "Chicago Riverwalk",
      st1: "Chicago",
      st2: "",
      // st3: address.country,
      st3: "US",
      st4: "03456",
      img: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      projectSite,
      area_sf: this.props.area_sf,
      area_ac: this.props.area_ac,
    };

    let projects = localStorage.getItem("projects");
    if (projects === null) {
      projects = [];
    } else {
      projects = JSON.parse(projects);
    }
    projects.push(project);
    localStorage.setItem("projects", JSON.stringify(projects));

    this.props.addProject(project);
  };
  render() {
    const { steps, step, currentMarkers } = this.state;
    const {
      projectName,
      address,
      selectedParcels,
      sitePolygon,
      projectSite,
      shiftedPolygon,
      siteMultiline,
      labelFeatureCollection,
      setbackInputs,
      differenceSitePolygon,
      applyedPolygons,
    } = this.state;
    const { lng, lat, zoom } = this.state.coordinatesWindow;

    return (
      <section id="test" className="create">
        <Steps steps={steps} step={step} />
        <section
          className="location container row d-flex mx-auto mt-5 px-0"
          style={{ right: 0 }}
        >
          {step >= 1 && step <= 3 && (
            <div style={{ textAlign: "left" }} className="col-8 p-0">
              <div
                style={{
                  display: "inlineBlock",
                  position: "absolute",
                  marginTop: "12px",
                  marginLeft: "12px",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  color: "#ffffff",
                  zIndex: "1",
                  borderRadius: "50px",
                  fontWeight: "700",
                  paddingLeft: "10px",
                  padding: "5px 10px",
                  fontSize: "12px",
                }}
              >
                <div>{`Longitude: ${lng} Latitude: ${lat} Zoom: ${zoom}`}</div>
                <button
                  className="toggleBtn"
                  name="view"
                  value={this.state.view}
                  onClick={this.onChange}
                >
                  {this.state.view === "streets-v11"
                    ? "Show Satellite"
                    : "Show Streets"}
                </button>
              </div>
              <div
                // id="reference"
                ref={(ref) => (this.mapContainer = ref)}
                style={{ width: "100%", height: "100%" }}
              ></div>
            </div>
          )}
          {step === 1 && map && (
            <Location
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleLocation={this.handleLocation}
              setCurrentMarkers={this.setCurrentMarkers}
              setCoordinatesWindow={this.setCoordinatesWindow}
              address={address}
              projectName={projectName}
              map={map}
              currentMarkers={currentMarkers}
            />
          )}
          {step === 2 && (
            <Site
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleSite={this.handleSite}
              resetSite={this.resetSite}
              setSelectedParcels={this.setSelectedParcels}
              handleClearParcels={this.handleClearParcels}
              setSitePolygon={this.setSitePolygon}
              address={address}
              selectedParcels={selectedParcels}
              sitePolygon={sitePolygon}
              map={map}
              currentMarkers={currentMarkers}
              differenceSitePolygon={differenceSitePolygon}
              addAreaAsAndAreaSf={this.props.addAreaAsAndAreaSf}
            />
          )}
          {step === 3 && (
            <Setback
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleSetback={this.handleSetback}
              handleChange={this.handleChange}
              mapInitBack={this.mapInitBack}
              setCoordinatesWindow={this.setCoordinatesWindow}
              setProjectSetback={this.setProjectSetback}
              coordinatesWindow={this.state.coordinatesWindow}
              projectSite={projectSite}
              shiftedPolygon={shiftedPolygon}
              labelFeatureCollection={labelFeatureCollection}
              setbackInputs={setbackInputs}
              siteMultiline={siteMultiline}
              map={map}
              selectedParcels={selectedParcels}
              sitePolygon={sitePolygon}
            />
          )}
          {step === 4 && (
            <Brand
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              runParafin={this.runParafin}
              mapReset={this.mapReset}
              addNewProject={this.addNewProject}
            />
          )}
        </section>
      </section>
    );
  }
}

export default Create;
