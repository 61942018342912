export const ActionType = {
  LOGIN_REQUEST: "session/LOGIN_REQUEST",
  LOGIN_SUCCESS: "session/LOGIN_SUCCESS",
  LOGIN_ERROR: "session/LOGIN_ERROR",
  SIGNUP_REQUEST: "session/SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "session/SIGNUP_SUCCESS",
  SIGNUP_ERROR: "session/SIGNUP_ERROR",
  REFRESH_USER_REQUEST: "session/REFRESH_USER_REQUEST",
  REFRESH_USER_SUCCESS: "session/REFRESH_USER_SUCCESS",
  REFRESH_USER_ERROR: "session/REFRESH_USER_ERROR",
  EDIT_USER_REQUEST: "session/EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "session/EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "session/EDIT_USER_ERROR",
  SET_LOGGED_OUT_DUE_TO_INACTIVITY_MODAL_OPEN:
    "session/SET_LOGGED_OUT_DUE_TO_INACTIVITY_MODAL_OPEN",
  SET_LOGGED_OUT_DUE_TO_INACTIVITY_MODAL_CLOSE:
    "session/SET_LOGGED_OUT_DUE_TO_INACTIVITY_MODAL_CLOSE",
  RESET_ERROR: "RESET_ERROR",
  DEMO_MODE_TOGGLE: "DEMO_MODE_TOGGLE",
};

/*
 * Login
 */

export const loginRequest = () => ({
  type: ActionType.LOGIN_REQUEST,
});

export const loginSuccess = (response) => ({
  type: ActionType.LOGIN_SUCCESS,
  payload: { response },
});

export const loginError = (error) => ({
  type: ActionType.LOGIN_ERROR,
  payload: { error },
});

export const resetError = () => ({
  type: ActionType.RESET_ERROR,
});

/*
 * Signup
 */
export const signupRequest = () => ({
  type: ActionType.SIGNUP_REQUEST,
});

export const signupSuccess = (response) => ({
  type: ActionType.SIGNUP_SUCCESS,
  payload: { response },
});

export const signupError = (error) => ({
  type: ActionType.SIGNUP_ERROR,
  payload: { error },
});

export const demoModeToggle = () => ({
  type: ActionType.DEMO_MODE_TOGGLE,
});
