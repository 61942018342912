const withoutDollarFloatFormat = (value) => {
  return (
    value &&
    `${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value)}`.replace(/[$]/g, "")
  );
};

export default withoutDollarFloatFormat;
