import React from "react";
import PropTypes from "prop-types";

const Password = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.8247 9.60515L7.17015 5.16858C7.8593 3.68086 7.64469 2.17461 6.58239 1.11231C5.8334 0.363315 4.79521 -0.044009 3.73782 0.00378015C2.66413 0.0486662 1.6887 0.528567 0.991065 1.35416C-0.275793 2.85438 -0.170835 5.11364 1.23023 6.49752C2.20812 7.46357 3.58642 7.78425 4.94081 7.36777L5.47565 7.9131C5.51763 7.95576 5.57502 7.9801 5.63509 7.9801H6.53438V8.65004C6.53438 8.77353 6.63442 8.87335 6.75769 8.87335H7.42763V9.54329C7.42763 9.66678 7.52768 9.76661 7.65095 9.76661H8.5442V10.6599C8.5442 10.7834 8.64425 10.8832 8.76752 10.8832H9.21414V11.2798C9.21414 11.4953 9.23268 11.6154 9.4024 11.7851C9.52656 11.9097 9.69159 11.9854 9.88408 11.9997H11.6706C11.7939 11.9997 11.8939 11.8999 11.8939 11.7764V9.76661C11.8939 9.70564 11.8689 9.64736 11.8247 9.60515ZM11.4473 11.5533L9.90128 11.554C9.83228 11.5489 9.76729 11.5189 9.71839 11.4698C9.66077 11.4122 9.66077 11.4122 9.66077 11.28V10.6601C9.66077 10.5366 9.56073 10.4368 9.43746 10.4368H8.99083V9.54352C8.99083 9.42002 8.89079 9.3202 8.76752 9.3202H7.87426V8.65026C7.87426 8.52677 7.77422 8.42695 7.65095 8.42695H6.98101V7.75701C6.98101 7.63351 6.88096 7.53369 6.75769 7.53369H5.72889L5.16301 6.95643C5.10294 6.89502 5.0125 6.87358 4.93099 6.90171C3.69718 7.32601 2.43121 7.05625 1.54421 6.18019C0.307949 4.95844 0.214827 2.96559 1.33229 1.64246C1.94841 0.913113 2.80928 0.489711 3.75636 0.449961C4.69182 0.411774 5.60562 0.767289 6.2664 1.42807C7.23536 2.39726 7.39302 3.74183 6.69874 5.11744C6.6543 5.2052 6.67283 5.31172 6.74407 5.37961L11.4471 9.86263V11.5533H11.4473Z" />
      <path d="M2.00314 1.90174C1.75013 2.15498 1.61078 2.49151 1.61078 2.84926C1.61078 3.20701 1.75013 3.54354 2.00314 3.79656C2.25616 4.0498 2.59269 4.18914 2.95066 4.18914C3.30863 4.18914 3.64494 4.0498 3.89818 3.79656C4.15119 3.54354 4.29054 3.20701 4.29054 2.84926C4.29054 2.49151 4.15119 2.15498 3.89818 1.90174C3.37563 1.37919 2.52547 1.37919 2.00314 1.90174ZM3.58219 3.48079C3.24454 3.81844 2.65633 3.81844 2.31891 3.48079C2.1503 3.31197 2.05741 3.08776 2.05741 2.84926C2.05741 2.61076 2.1503 2.38633 2.31891 2.21751C2.49309 2.04355 2.72176 1.95645 2.95066 1.95645C3.17956 1.95645 3.40823 2.04355 3.58241 2.21751C3.75102 2.38633 3.84391 2.61076 3.84391 2.84926C3.84391 3.08776 3.75102 3.31197 3.58219 3.48079Z" />
    </svg>
  );
};

Password.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Password;
