import React from "react";
import PropTypes from "prop-types";
import styles from "./Tooltip.module.sass";

const Tooltip = ({ title, text }) => {
  return (
    <div className={styles.tooltip}>
      <span className={styles.tooltipIcon}>?</span>
      <span className={styles.tooltipContent}>
        <span className={styles.tooltipTitle}>{title}</span>
        <span className={styles.tooltipText}> {text}</span>
      </span>
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
