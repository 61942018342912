import React from "react";
import PropTypes from "prop-types";

const Family = ({ className }) => {
  return (
    <svg
      width="36px"
      height="36px"
      //   viewBox="0 0 36 36"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Family</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Users/original">
          <circle
            className={className}
            // stroke="#174FA3"
            strokeWidth="5.555555556%"
            cx="50%"
            cy="50%"
            r="47%"
          ></circle>
          <g transform-origin="50% 50%" transform="scale(0.6)">
            <g>
              <path
                d="m403.73 289.381c-.03 2.01-.86 3.93-2.31 5.33-1.46 1.39-3.41 2.15-5.42 2.08-7.7-.24-15.46-.44-23.25-.6-59-1.26-119.36-.43-166.57 2.32-1.45 71.933 3.89 172.737 3.94 173.777.11 2.06-.62 4.07-2.04 5.56-1.42 1.5-3.39 2.34-5.44 2.34-.48 0-47.99-.05-86.17-4.17-3.89-.42-6.8-3.75-6.7-7.65 5.08-191.658 1.71-360.905.03-424.777-.11-4.02 2.97-7.41 6.98-7.68 45.31-3.14 146.66-7.48 253.53-5.19 7.56.16 15.14.36 22.74.59 3.93.12 7.11 3.26 7.26 7.2.92 23.411 1.68 47.392 2.26 71.273.05 2.06-.75 4.05-2.22 5.51-1.46 1.45-3.45 2.24-5.51 2.17-7.34-.23-14.78-.42-22.28-.59-55.51-1.2-114.51-.69-162.84 1.46-1.11 13.181-3.4 46.112-3.39 92.804 60.25-1.7 113.05-2.54 166.68-1.33 7.89.17 15.8.39 23.76.66 4.03.14 7.23 3.44 7.24 7.48.07 23.651-.03 47.682-.28 71.433z"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Family.propTypes = { className: PropTypes.string.isRequired };

export default Family;
