import React, { Component } from "react";
import "./Concept.sass";
import { Scrollbars } from "react-custom-scrollbars";
import toastr from "toastr";
import jsPDF from "jspdf";
import Svg from "../../../../Svg/Svg";
import phoneIcon from "../../../../../assets/img/Phone.png";
import emailIcon from "../../../../../assets/img/Email.png";
import MyCompany from "../../../../../assets/img/logoCompany.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import Tables from "../../../../../api/Tables.json";

import buildingProgramToArray from "../../../../../helpers/buildingProgramToArray";
import developmentBudgetToArray from "../../../../../helpers/developmentBudgetToArray";
// import guestRoomsToArray from "../../../../../helpers/";
import guestroomsRevenueToArray from "../../../../../helpers/guestroomsRevenueToArray";
import investmentReturnsToArray from "../../../../../helpers/investmentReturnsToArray";
import stabilizedYearOneRevenueToArray from "../../../../../helpers/stabilizedYearOneRevenueToArray";
import stringFormat from "../../../../../helpers/stringFormat";

// import html2canvas from 'html2canvas';
// import { Base64 } from 'js-base64';

const Sort = {
  EQUITY_MULT: "multiple_on_invested_equity",
  KEYS: "room_count",
  BUDGET_KEY: "total_cost_per_key",
  YIELD: "inv_cash_on_cost",
};

class Concept extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmHidingOptionID: null,
    };
  }

  componentDidMount() {
    const chapter = document.querySelector(".concept");

    chapter.lastElementChild.firstElementChild.addEventListener(
      "scroll",
      this.populate
    );
  }
  populate = () => {
    const { filterParameters, projectInfo, filters, page } = this.props;
    const chapter = document.querySelector(".concept");
    let windowRelativeBottom =
      chapter.lastElementChild.firstElementChild.scrollHeight -
      chapter.lastElementChild.firstElementChild.scrollTop;

    if (
      windowRelativeBottom <
        chapter.lastElementChild.firstElementChild.clientHeight + 100 &&
      !filters.loading
    ) {
      // console.log("send request for addition program");
      const projectFilters = {
        deleted: filters.models.reduce((resultArray, model) => {
          return model.isHidden ? [...resultArray, model.code] : resultArray;
        }, []),
        filters: {
          filterParameters: { ...filters.filterParameters },
          activeProgramId: filters.activeProgramId,
        },
        starred: filters.models.reduce((resultArray, model) => {
          return model.isStarred ? [...resultArray, model.code] : resultArray;
        }, []),
      };
      const { models } = filters;
    }
  };

  componentWillUnmount() {
    const chapter = document.querySelector(".concept");
    chapter.lastElementChild.firstElementChild.removeEventListener(
      "scroll",
      this.populate
    );
    this.props.resetPage();
  }

  table = (pdf, marginTop, marginLeft, tableWidth, table) => {
    const firstColWidth = 120;
    marginTop = marginTop + 13;
    const colWidth = (tableWidth - firstColWidth) / (table.thead.length - 1);
    table.thead.map((thEl, index) => {
      if (index === 0) {
        pdf.setFontStyle("bold");
        pdf.setTextColor("#333333");
        pdf.text(thEl, marginLeft + 8, marginTop, {
          align: "left",
        });
      } else if (index === 1) {
        pdf.setFontSize(7);
        pdf.setFontStyle("normal");
        pdf.setTextColor("#333333");
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop,
          {
            align: "right",
          }
        );
      } else {
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop, //576px
          {
            align: "right",
          }
        );
      }
      return 0;
    });
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    marginTop = marginTop + 8; //584
    const topLine = marginTop;
    pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

    marginTop = marginTop + 3; //587
    table.tbody.map((trEl, index) => {
      if (index % 2 === 0) {
        marginTop = marginTop + 10; //595, 617
        pdf.setLineWidth(13);
        pdf.setDrawColor("#F4F7FC");
        pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
        marginTop = marginTop + 3; //598, 620
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      } else {
        marginTop = marginTop + 13; //609
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      }
      return 0;
    });

    if (table.tfoot.length !== 0) {
      marginTop = marginTop + 8; //628
      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

      marginTop = marginTop + 0; //587 was 3
      table.tfoot.map((trEl, index) => {
        if (index % 2 !== 0) {
          marginTop = marginTop + 10; //595, 617
          pdf.setLineWidth(13);
          pdf.setDrawColor("#F4F7FC");
          pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
          marginTop = marginTop + 3; //598, 620
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
        } else {
          marginTop = marginTop + 13; //609
          pdf.setFontStyle("bold");
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
          pdf.setFontStyle("normal");
        }
        return 0;
      });
    }

    marginTop = marginTop + 8; //edge
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    pdf.line(
      marginLeft + firstColWidth,
      topLine,
      marginLeft + firstColWidth,
      marginTop
    );
    return marginTop;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //   pdf.addImage(
    //     result.toDataURL("image/jpeg"),
    //     "jpg",
    //     marginLeft,
    //     marginTop,
    //     pdfPageWidth - 245,
    //     result.height / (result.width / (pdfPageWidth - 245))
    //   );
    //   marginTop += result.height / 2.1 + 10;
    ////////////////////////////////////////////////////////////////////////////////////////////
  };

  tableTotalGuestroomsRevenue = (
    pdf,
    marginTop,
    marginLeft,
    tableWidth,
    table
  ) => {
    const firstColWidth = 120;
    marginTop = marginTop + 13;
    const colWidth = (tableWidth - firstColWidth) / (table.thead.length - 1);
    table.thead.map((thEl, index) => {
      if (index === 0) {
        pdf.setFontStyle("bold");
        pdf.setTextColor("#333333");
        pdf.text(thEl, marginLeft + 8, marginTop, {
          align: "left",
        });
      } else if (index === 1) {
        pdf.setFontSize(7);
        pdf.setFontStyle("normal");
        pdf.setTextColor("#333333");
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop,
          {
            align: "right",
          }
        );
      } else {
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop, //576px
          {
            align: "right",
          }
        );
      }
      return 0;
    });
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    marginTop = marginTop + 8; //584
    const topLine = marginTop;
    pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

    if (table.tfoot.length !== 0) {
      // marginTop = marginTop + 8; //628
      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

      marginTop = marginTop + 0; //587 was 3
      table.tfoot.map((trEl, index) => {
        if (index % 2 !== 0) {
          marginTop = marginTop + 10; //595, 617
          pdf.setLineWidth(13);
          pdf.setDrawColor("#F4F7FC");
          pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
          marginTop = marginTop + 3; //598, 620
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
        } else {
          marginTop = marginTop + 13; //609
          pdf.setFontStyle("bold");
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl === undefined ? "" : tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
          pdf.setFontStyle("normal");
        }
        return 0;
      });
    }

    marginTop = marginTop + 8; //edge
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    pdf.line(
      marginLeft + firstColWidth,
      topLine,
      marginLeft + firstColWidth,
      marginTop
    );
    return marginTop;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //   pdf.addImage(
    //     result.toDataURL("image/jpeg"),
    //     "jpg",
    //     marginLeft,
    //     marginTop,
    //     pdfPageWidth - 245,
    //     result.height / (result.width / (pdfPageWidth - 245))
    //   );
    //   marginTop += result.height / 2.1 + 10;
    ////////////////////////////////////////////////////////////////////////////////////////////
  };

  tableGopMargin = (pdf, marginTop, marginLeft, tableWidth, table) => {
    const firstColWidth = 120;
    marginTop = marginTop + 13;
    const colWidth = (tableWidth - firstColWidth) / (table.thead.length - 1);
    table.thead.map((thEl, index) => {
      if (index === 0) {
        pdf.setFontStyle("bold");
        pdf.setTextColor("#333333");
        pdf.text(thEl, marginLeft + 8, marginTop, {
          align: "left",
        });
      } else if (index === 1) {
        pdf.setFontSize(7);
        pdf.setFontStyle("normal");
        pdf.setTextColor("#333333");
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop,
          {
            align: "right",
          }
        );
      } else {
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop, //576px
          {
            align: "right",
          }
        );
      }
      return 0;
    });
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    marginTop = marginTop + 8; //584
    const topLine = marginTop;
    pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

    marginTop = marginTop + 3; //587
    table.tbody.map((trEl, index) => {
      if (index % 2 === 0) {
        marginTop = marginTop + 10; //595, 617
        pdf.setLineWidth(13);
        pdf.setDrawColor("#F4F7FC");
        pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
        marginTop = marginTop + 3; //598, 620
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      } else {
        marginTop = marginTop + 13; //609
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      }
      return 0;
    });

    if (table.tfoot.length !== 0) {
      marginTop = marginTop + 8; //628
      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

      marginTop = marginTop + 0; //587 was 3
      table.tfoot.map((trEl, index) => {
        if (index === 0 || index === 1 || index === trEl.length + 1) {
          marginTop = marginTop + 10; //595, 617
          marginTop = marginTop + 3; //598, 620
          pdf.setFontStyle("bold");
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl + "",
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
          pdf.setFontStyle("normal");
        } else {
          if (index % 2 === 0) {
            marginTop = marginTop + 10; //595, 617
            pdf.setLineWidth(13);
            pdf.setDrawColor("#F4F7FC");
            pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
            marginTop = marginTop + 3; //598, 620
            trEl.map((tfEl, index) => {
              if (index === 0) {
                pdf.setTextColor("#333333");
                pdf.text(tfEl, marginLeft + 16, marginTop, {
                  align: "left",
                });
              } else if (index === 1) {
                pdf.text(
                  tfEl,
                  marginLeft - 8 + firstColWidth + colWidth * index,
                  marginTop,
                  {
                    align: "right",
                  }
                );
              } else {
                pdf.text(
                  tfEl + "",
                  marginLeft - 8 + firstColWidth + colWidth * index,
                  marginTop,
                  {
                    align: "right",
                  }
                );
              }
              return 0;
            });
          } else {
            marginTop = marginTop + 13; //609
            // pdf.setFontStyle("bold");
            trEl.map((tfEl, index) => {
              if (index === 0) {
                pdf.setTextColor("#333333");
                pdf.text(tfEl, marginLeft + 16, marginTop, {
                  align: "left",
                });
              } else if (index === 1) {
                pdf.text(
                  tfEl,
                  marginLeft - 8 + firstColWidth + colWidth * index,
                  marginTop,
                  {
                    align: "right",
                  }
                );
              } else {
                pdf.text(
                  tfEl + "",
                  marginLeft - 8 + firstColWidth + colWidth * index,
                  marginTop,
                  {
                    align: "right",
                  }
                );
              }
              return 0;
            });
            pdf.setFontStyle("normal");
          }
        }
        if (index === trEl.length) {
          pdf.setLineWidth(0.6);
          pdf.setDrawColor("#333333");
          pdf.line(
            marginLeft,
            marginTop + 3,
            marginLeft + tableWidth,
            marginTop + 3
          );
        }
        return 0;
      });
    }

    marginTop = marginTop + 8; //edge
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    pdf.line(
      marginLeft + firstColWidth,
      topLine,
      marginLeft + firstColWidth,
      marginTop
    );
    return marginTop;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //   pdf.addImage(
    //     result.toDataURL("image/jpeg"),
    //     "jpg",
    //     marginLeft,
    //     marginTop,
    //     pdfPageWidth - 245,
    //     result.height / (result.width / (pdfPageWidth - 245))
    //   );
    //   marginTop += result.height / 2.1 + 10;
    ////////////////////////////////////////////////////////////////////////////////////////////
  };

  tableInvestmentReturns = (pdf, marginTop, marginLeft, tableWidth, table) => {
    const firstColWidth = 120;
    marginTop = marginTop + 13;
    const colWidth = (tableWidth - firstColWidth) / (table.thead.length - 1);
    table.thead.map((thEl, index) => {
      if (index === 0) {
        pdf.setFontStyle("bold");
        pdf.setTextColor("#333333");
        pdf.text(thEl, marginLeft + 8, marginTop, {
          align: "left",
        });
      } else if (index === 1) {
        pdf.setFontSize(7);
        pdf.setFontStyle("normal");
        pdf.setTextColor("#333333");
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop,
          {
            align: "right",
          }
        );
      } else {
        pdf.text(
          thEl,
          marginLeft - 8 + firstColWidth + colWidth * index,
          marginTop, //576px
          {
            align: "right",
          }
        );
      }
      return 0;
    });
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    marginTop = marginTop + 8; //584
    const topLine = marginTop;
    pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

    marginTop = marginTop + 3; //587
    table.tbody.map((trEl, index) => {
      if (index === 0 || index === table.tbody.length - 1) {
        pdf.setFontStyle("bold");
      } else {
        pdf.setFontStyle("normal");
      }
      if (index % 2 === 0) {
        marginTop = marginTop + 10; //595, 617
        pdf.setLineWidth(13);
        pdf.setDrawColor("#F4F7FC");
        pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
        marginTop = marginTop + 3; //598, 620
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      } else {
        marginTop = marginTop + 13; //609
        trEl.map((tdEl, index) => {
          if (index === 0) {
            pdf.setTextColor("#333333");
            pdf.text(tdEl + "", marginLeft + 8, marginTop, {
              align: "left",
            });
          } else if (index === 1) {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          } else {
            pdf.text(
              tdEl + "",
              marginLeft - 8 + firstColWidth + colWidth * index,
              marginTop,
              {
                align: "right",
              }
            );
          }
          return 0;
        });
      }
      return 0;
    });

    if (table.tfoot.length !== 0) {
      marginTop = marginTop + 8; //628
      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);

      marginTop = marginTop + 3; //587
      table.tfoot.map((trEl, index) => {
        if (index % 2 !== 0) {
          marginTop = marginTop + 10; //595, 617
          pdf.setLineWidth(13);
          pdf.setDrawColor("#F4F7FC");
          pdf.line(marginLeft, marginTop, marginLeft + tableWidth, marginTop);
          marginTop = marginTop + 3; //598, 620
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
        } else {
          marginTop = marginTop + 13; //609
          pdf.setFontStyle("bold");
          trEl.map((tfEl, index) => {
            if (index === 0) {
              pdf.setTextColor("#333333");
              pdf.text(tfEl, marginLeft + 8, marginTop, {
                align: "left",
              });
            } else if (index === 1) {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            } else {
              pdf.text(
                tfEl,
                marginLeft - 8 + firstColWidth + colWidth * index,
                marginTop,
                {
                  align: "right",
                }
              );
            }
            return 0;
          });
          pdf.setFontStyle("normal");
        }
        return 0;
      });
    }

    marginTop = marginTop + 8; //edge
    pdf.setLineWidth(0.6);
    pdf.setDrawColor("#333333");
    pdf.line(
      marginLeft + firstColWidth,
      topLine,
      marginLeft + firstColWidth,
      marginTop
    );
    return marginTop;
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //   pdf.addImage(
    //     result.toDataURL("image/jpeg"),
    //     "jpg",
    //     marginLeft,
    //     marginTop,
    //     pdfPageWidth - 245,
    //     result.height / (result.width / (pdfPageWidth - 245))
    //   );
    //   marginTop += result.height / 2.1 + 10;
    ////////////////////////////////////////////////////////////////////////////////////////////
  };

  loadImage = (url) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.src = url;
    });
  };

  // loadImage = url => {
  //   return new Promise(resolve => {
  //     let img = new Image();
  //     img.onload = () => {
  //       var canvas = document.createElement("canvas"),
  //         ctx = canvas.getContext("2d");

  //       canvas.width = img.naturalWidth;
  //       canvas.height = img.naturalHeight;

  //       ctx.drawImage(img, 0, 0);
  //       resolve(canvas);
  //     };
  //     img.src = url;
  //   });
  // };

  loadImageFetch = (url) => {
    return new Promise((resolve) => {
      fetch(url, { mode: "no-cors" })
        .then((response) => response.blob())
        .then((blob) => {
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            resolve(base64data);
          };
        });
    });
  };

  saveAsExcel = (evt, programId) => {
    const { firstName, lastName } = this.props.projectInfo.user;
    evt.stopPropagation();
    const { projectInfo, user } = this.props;
    const inv_margin_selection =
      this.props.projectInfo &&
      this.props.projectInfo.investment_assumptions &&
      this.props.projectInfo.investment_assumptions.inv_margin_selection;
    const {
      building_program,
      development_budget,
      guest_rooms,
      guestrooms_revenue,
      investment_returns,
      stabilized_year_one_revenue,
    } = this.props.filters.tablesForModel;
    const wb = new ExcelJS.Workbook();

    wb.creator = `${firstName} ${lastName}`;
    wb.lastModifiedBy = `${firstName} ${lastName}`;
    wb.created = new Date(projectInfo.dateCreated);
    wb.modified = new Date(projectInfo.dateLastEdited);

    let totalGuestrooms;
    let totalGrossBuildingAreaLine;
    let stabilizedNOILine;
    let totalBudgetLine;

    const ws = wb.addWorksheet("Sheet1", {
      views: [{ showGridLines: false }],
    });

    ws.columns = [
      { header: "Id", key: "id", width: 27 },
      { header: "Name", key: "name", width: 12 },
      // { header: "D.O.B.", key: "dob", width: 10, outlineLevel: 1 },
    ];

    const font = {
      name: "Lato",
      family: 4,
      color: { argb: "FF784315" },
      size: 8,
      underline: "none",
      bold: false,
    };

    const headerSheet = () => {
      const A1 = ws.getCell("A1");
      A1.value = `${user.companyName}`;
      A1.font = font;
      A1.alignment = { vertical: "middle", horizontal: "left" };
      const B1 = ws.getCell("B1");
      B1.value = `${projectInfo.projectName}`;
      B1.font = font;
      B1.alignment = { vertical: "middle" };
      const A2 = ws.getCell("A2");
      A2.value = `${firstName} ${lastName}`;
      A2.font = font;
      A2.alignment = { vertical: "middle", horizontal: "left" };
      const B2 = ws.getCell("B2");
      B2.value = `${projectInfo.address.streetNumber} ${projectInfo.address.street}`;
      B2.font = font;
      B2.alignment = { vertical: "middle" };
      const A3 = ws.getCell("A3");
      A3.value = {
        text: user.email,
        hyperlink: `mailto:${user.email}`,
        tooltip: user.email,
      };
      A3.font = font;
      A3.alignment = { vertical: "middle", horizontal: "left" };
      const B3 = ws.getCell("B3");
      B3.value = `${projectInfo.address.city}, ${projectInfo.address.state} ${projectInfo.address.zip}`;
      B3.font = font;
      B3.alignment = { vertical: "middle" };
      const A4 = ws.getCell("A4");
      A4.value = new Date(projectInfo.dateLastEdited);
      A4.font = font;
      A4.numFmt = "[$-x-sysdate]dddd, mmmm dd, yyyy";
      A4.alignment = { vertical: "middle", horizontal: "left" };
      const B4 = ws.getCell("B4");
      B4.font = font;
      B4.value = `Lot area:`;
      B4.alignment = { vertical: "middle" };
      const C4 = ws.getCell("C4");
      C4.value = projectInfo.area_sf;
      C4.font = font;
      C4.alignment = { vertical: "middle", horizontal: "right" };
      C4.numFmt = '#,##0" sf"';
      const D1 = ws.getCell("D1");
      D1.value = `Option #${programId
        .substr(programId.length - 5)
        .toUpperCase()}`;
      D1.font = font;
      D1.alignment = { vertical: "middle", horizontal: "left" };
      const D4 = ws.getCell("D4");
      D4.value = projectInfo.area_ac;
      D4.font = font;
      D4.alignment = { vertical: "middle", horizontal: "right" };
      D4.numFmt = '#,##0.00" ac"';
    };
    headerSheet();

    /* generate an guestRooms table */
    const guestRoomsToExcel = (positionLine, guest_rooms) => {
      let pl = positionLine;
      //head
      const thead = ["Guestrooms", "Keys", "Mix %", "* Area"];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      const C = ws.getCell(`C${pl}`);
      C.value = thead[2];
      C.font = { ...font, bold: true };
      C.alignment = { vertical: "middle", horizontal: "right" };
      const D = ws.getCell(`D${pl}`);
      D.value = thead[3];
      D.font = { ...font, bold: true };
      D.alignment = { vertical: "middle", horizontal: "right" };

      pl++;

      const firstColFoot = {
        total: "Total",
      };
      totalGuestrooms = pl + guest_rooms.by_type.length;
      //body
      for (
        let keyrow = 0;
        keyrow < guest_rooms.by_type.length;
        pl++, keyrow++
      ) {
        const A = ws.getCell(`A${pl}`);
        A.value = stringFormat(guest_rooms.by_type[keyrow].name);
        A.font = font;
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (keyrow % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.value = guest_rooms.by_type[keyrow].keys;
        B.font = font;
        B.alignment = { vertical: "middle" };
        if (keyrow % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=B${pl}/$B$${totalGuestrooms}`,
          result: guest_rooms.by_type[keyrow].mix,
        };
        C.font = font;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (keyrow % 2 === 0) {
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = guest_rooms.by_type[keyrow].area;
        D.font = font;
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = '#,##0" sf"';
        if (keyrow % 2 === 0) {
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
      }
      //foot
      for (const keyrow in firstColFoot) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColFoot[keyrow];
        A.font = { ...font, bold: true };
        A.alignment = { vertical: "middle", horizontal: "left" };
        A.border = {
          top: { style: "thin" },
        };
        const B = ws.getCell(`B${pl}`);
        B.value = {
          formula: "=SUM(B8:B14)",
          result: guest_rooms[keyrow].keys,
        };
        B.font = { ...font, bold: true };
        B.alignment = { vertical: "middle" };
        B.border = {
          top: { style: "thin" },
        };
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: "=SUM(C8:C14)",
          result: guest_rooms[keyrow].mix,
        };
        C.font = { ...font, bold: true };
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        C.border = {
          top: { style: "thin" },
        };
        const D = ws.getCell(`D${pl}`);
        D.value = guest_rooms[keyrow].area;
        D.font = { ...font, bold: true };
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = '#,##0" sf"';
      }
    };
    guestRoomsToExcel(7, guest_rooms);
    const A16 = ws.getCell(`A16`);
    A16.value = {
      richText: [
        { font: { ...font, bold: true }, text: "* " },
        {
          font: { ...font },
          text: "Some rooms have minor deviations from prototype.",
        },
      ],
    };
    A16.font = font;
    A16.alignment = { vertical: "middle", horizontal: "left" };

    /* generate an buildingProgram table */
    const buildingProgramToExcel = (positionLine, building_program) => {
      let pl = positionLine;
      //head
      const thead = ["Building Program", "Area", "%", "Area/Key"];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      const C = ws.getCell(`C${pl}`);
      C.value = thead[2];
      C.font = { ...font, bold: true };
      C.alignment = { vertical: "middle", horizontal: "right" };
      const D = ws.getCell(`D${pl}`);
      D.value = thead[3];
      D.font = { ...font, bold: true };
      D.alignment = { vertical: "middle", horizontal: "right" };
      pl++;
      const firstColBody = {
        guestrooms: "Guestrooms",
        guestrooms_corridors_support: "Guestrooms Support",
        lobby: "Public Areas",
        food_beverage: "Food and Beverage Front-of-House",
        recreation: "Recreation Facilities",
        retail: "Retail",
        meeting_spaces: "Function Spaces",
        back: "Back of House",
      };
      const firstColFoot = {
        total_net: "Total Net Building Area",
        walls_shafts: "Walls and Shafts",
        total_gross: "Total Gross Building Area",
      };
      //body
      let i = 0;
      for (const keyrow in firstColBody) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColBody[keyrow];
        A.font = font;
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.value = building_program[keyrow].area;
        B.font = font;
        B.alignment = { vertical: "middle", horizontal: "right" };
        B.numFmt = '#,##0" sf"';
        if (i % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=B${pl}/$B$29`,
          result: building_program[keyrow].percent,
        };
        C.font = font;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = {
          formula: `=B${pl}/$B$${totalGuestrooms}`,
          result: building_program[keyrow].area_key,
        };
        D.font = font;
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = '#,##0" sf/key"';
        if (i % 2 === 0) {
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      //foot
      i = 0;
      for (const keyrow in firstColFoot) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColFoot[keyrow];
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.font = { ...font, bold: true };
          A.border = {
            top: { style: "thin" },
          };
        } else {
          A.font = { ...font };
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.alignment = { vertical: "middle", horizontal: "right" };
        B.numFmt = '#,##0" sf"';
        if (i % 2 === 0) {
          B.font = { ...font, bold: true };
          B.border = {
            top: { style: "thin" },
          };
          B.value = {
            formula: pl !== 29 ? `=SUM(B19:B26)` : `=B27+B28`,
            result: building_program[keyrow].area,
          };
        } else {
          B.font = { ...font };
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
          B.value = building_program[keyrow].area;
        }
        const C = ws.getCell(`C${pl}`);
        if (pl !== 29) {
          C.value = {
            formula: `=B${pl}/$B$29`,
            result: building_program[keyrow].percent,
          };
        } else {
          C.value = building_program[keyrow].percent;
        }
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.font = { ...font, bold: true };
          C.border = {
            top: { style: "thin" },
          };
        } else {
          C.font = { ...font };
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = {
          formula: `=B${pl}/$B$${totalGuestrooms}`,
          result: building_program[keyrow].area_key,
        };
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = '#,##0" sf/key"';
        if (i % 2 === 0) {
          D.font = { ...font, bold: true };
          D.border = {
            top: { style: "thin" },
          };
        } else {
          D.font = { ...font };
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      totalGrossBuildingAreaLine = pl - 1;
    };
    buildingProgramToExcel(18, building_program);

    /* generate an developmentBudget table */
    const developmentBudgetToExcel = (positionLine, development_budget) => {
      let pl = positionLine;
      //head
      const thead = [
        "Development Budget",
        "Total Budget",
        "% Total",
        "$/Key",
        "$/GSF",
      ];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      const C = ws.getCell(`C${pl}`);
      C.value = thead[2];
      C.font = { ...font, bold: true };
      C.alignment = { vertical: "middle", horizontal: "right" };
      const D = ws.getCell(`D${pl}`);
      D.value = thead[3];
      D.font = { ...font, bold: true };
      D.alignment = { vertical: "middle", horizontal: "right" };
      const E = ws.getCell(`E${pl}`);
      E.value = thead[4];
      E.font = { ...font, bold: true };
      E.alignment = { vertical: "middle", horizontal: "right" };
      pl++;
      const firstColBody = {
        land: "Land",
        hard_costs: "Hard Costs",
        soft_costs: "Soft Costs",
        guestroom_ffe: "Guestroom FF&E",
        hard_soft_contingency: "Contingency Allowance",
        dev_ffe_overhead: "Dev. Fee and Overhead",
        const_loan_interest_fee: "Const. Loan Interest & Fees",
      };
      if (
        development_budget.budget_custom &&
        development_budget.budget_custom.length !== 0
      ) {
        development_budget.budget_custom.map((el, index) => {
          firstColBody["label" + index] = el.label;
          development_budget["label" + index] = {};
          development_budget["label" + index].total_budget = el.total_budget;
          development_budget["label" + index].total_budget_percent =
            el.total_budget_percent;
          development_budget["label" + index].cost_per_key = el.cost_per_key;
          development_budget["label" + index].cost_gsf = el.cost_gsf;
        });
      }
      const firstColFoot = {
        total: "Total Budget",
        const_loan_proceed: "Const. Loan Proceeds",
        equity_required: "Equity Required",
      };
      //body
      let i = 0;
      totalBudgetLine = pl + Object.keys(firstColBody).length;
      for (const keyrow in firstColBody) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColBody[keyrow];
        A.font = font;
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.value = development_budget[keyrow].total_budget;
        B.font = font;
        B.alignment = { vertical: "middle", horizontal: "right" };
        B.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=$B${pl}/$B$${totalBudgetLine}`,
          result: development_budget[keyrow].total_budget_percent,
        };
        C.font = font;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = {
          formula: `=$B${pl}/$B$${totalGuestrooms}`,
          result: development_budget[keyrow].cost_per_key,
        };
        D.font = font;
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const E = ws.getCell(`E${pl}`);
        E.value = {
          formula: `=$B${pl}/$B$29`,
          result: development_budget[keyrow].cost_gsf,
        };
        E.font = font;
        E.alignment = { vertical: "middle", horizontal: "right" };
        E.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          E.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      //foot
      i = 0;
      const lengthOfColFoot = Object.keys(firstColFoot).length;
      for (const keyrow in firstColFoot) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColFoot[keyrow];
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.font = { ...font, bold: true };
          A.border = {
            top: { style: "thin" },
          };
        } else {
          A.font = { ...font };
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.alignment = { vertical: "middle", horizontal: "right" };
        B.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          B.font = { ...font, bold: true };
          B.border = {
            top: { style: "thin" },
          };
          B.value = {
            formula:
              pl !== totalBudgetLine + 2
                ? `=SUM(B32:B${totalBudgetLine - 1})`
                : `=B${totalBudgetLine}-B${totalBudgetLine + 1}`,
            result: development_budget[keyrow].total_budget,
          };
        } else {
          B.font = { ...font };
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
          B.value = development_budget[keyrow].total_budget;
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=$B${pl}/$B$${totalBudgetLine}`,
          result: development_budget[keyrow].total_budget_percent,
        };
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.font = { ...font, bold: true };
          C.border = {
            top: { style: "thin" },
          };
        } else {
          C.font = { ...font };
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        if (pl === totalBudgetLine) {
          D.value = {
            formula: `=$B${pl}/$B$${totalGuestrooms}`,
            result: development_budget[keyrow].cost_per_key,
          };
        } else {
          D.value = development_budget[keyrow].cost_per_key;
        }
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = "$#,##0;[Red]-$#,##0";
        if (lengthOfColFoot - i > 2) {
          if (i % 2 === 0) {
            D.font = { ...font, bold: true };
            D.border = {
              top: { style: "thin" },
            };
          } else {
            D.font = { ...font };
            D.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }
        }
        const E = ws.getCell(`E${pl}`);

        if (pl === totalBudgetLine) {
          E.value = {
            formula: `=$B${pl}/$B$29`,
            result: development_budget[keyrow].cost_gsf,
          };
        } else {
          E.value = development_budget[keyrow].cost_gsf;
        }
        E.alignment = { vertical: "middle", horizontal: "right" };
        E.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (lengthOfColFoot - i > 2) {
          if (i % 2 === 0) {
            E.font = { ...font, bold: true };
            E.border = {
              top: { style: "thin" },
            };
          } else {
            E.font = { ...font };
            E.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }
        }
        pl++;
        i++;
      }
      return totalBudgetLine + 3;
    };
    let startLineOfNextTable = developmentBudgetToExcel(31, development_budget);

    /* generate an guestroomsRevenue table */
    const guestroomsRevenueToExcel = (positionLine, guestrooms_revenue) => {
      let pl = positionLine;
      //head
      const thead = [
        "Guestrooms Revenue",
        "Keys",
        "Mix %",
        "ADR",
        "Occ.",
        "RevPAR",
        "Revenue",
      ];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      const C = ws.getCell(`C${pl}`);
      C.value = thead[2];
      C.font = { ...font, bold: true };
      C.alignment = { vertical: "middle", horizontal: "right" };
      const D = ws.getCell(`D${pl}`);
      D.value = thead[3];
      D.font = { ...font, bold: true };
      D.alignment = { vertical: "middle", horizontal: "right" };
      const E = ws.getCell(`E${pl}`);
      E.value = thead[4];
      E.font = { ...font, bold: true };
      E.alignment = { vertical: "middle", horizontal: "right" };
      const F = ws.getCell(`F${pl}`);
      F.value = thead[5];
      F.font = { ...font, bold: true };
      F.alignment = { vertical: "middle", horizontal: "right" };
      const G = ws.getCell(`G${pl}`);
      G.value = thead[6];
      G.font = { ...font, bold: true };
      G.alignment = { vertical: "middle", horizontal: "right" };
      pl++;

      const firstColFoot = {
        total: "Total",
      };

      //body
      let i = 0;
      const totalLine = pl + guestrooms_revenue.by_type.length;
      for (
        let keyrow = 0;
        keyrow < guestrooms_revenue.by_type.length;
        keyrow++
      ) {
        const A = ws.getCell(`A${pl}`);
        A.value = stringFormat(guestrooms_revenue.by_type[keyrow].name);
        A.font = font;
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.value = guestrooms_revenue.by_type[keyrow].keys;
        B.font = font;
        B.alignment = { vertical: "middle" };
        if (i % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=$B${pl}/$B$${totalLine}`,
          result: guestrooms_revenue.by_type[keyrow].mix,
        };
        C.font = font;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = guestrooms_revenue.by_type[keyrow].adr;
        D.font = font;
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const E = ws.getCell(`E${pl}`);
        E.value = guestrooms_revenue.by_type[keyrow].occup;
        E.font = font;
        E.alignment = { vertical: "middle", horizontal: "right" };
        E.numFmt = "0.00%";
        if (i % 2 === 0) {
          E.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const F = ws.getCell(`F${pl}`);
        F.value = {
          formula: `=D${pl}*E${pl}`,
          result: guestrooms_revenue.by_type[keyrow].rev_par,
        };
        F.font = font;
        F.alignment = { vertical: "middle", horizontal: "right" };
        F.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          F.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const G = ws.getCell(`G${pl}`);
        G.value = {
          formula: `=B${pl}*F${pl}*365`,
          result: guestrooms_revenue.by_type[keyrow].revenue,
        };
        G.font = font;
        G.alignment = { vertical: "middle", horizontal: "right" };
        G.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          G.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      //foot
      i = 0;
      for (const keyrow in firstColFoot) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColFoot[keyrow];
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.font = { ...font, bold: true };
          A.border = {
            top: { style: "thin" },
          };
        } else {
          A.font = { ...font };
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        B.value = {
          formula: `=SUM(B${positionLine + 1}:B${totalLine - 1})`,
          result: guestrooms_revenue[keyrow].keys,
        };
        B.alignment = { vertical: "middle" };
        if (i % 2 === 0) {
          B.font = { ...font, bold: true };
          B.border = {
            top: { style: "thin" },
          };
        } else {
          B.font = { ...font };
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = guestrooms_revenue[keyrow].mix;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.font = { ...font, bold: true };
          C.border = {
            top: { style: "thin" },
          };
        } else {
          C.font = { ...font };
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = {
          formula: `=((B${positionLine + 1}*D${positionLine + 1})+(B${
            positionLine + 2
          }*D${positionLine + 2})+(B${positionLine + 3}*D${
            positionLine + 3
          })+(B${positionLine + 4}*D${positionLine + 4}))/$B${totalLine}`,
          result: guestrooms_revenue[keyrow].adr,
        };
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          D.font = { ...font, bold: true };
          D.border = {
            top: { style: "thin" },
          };
        } else {
          D.font = { ...font };
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }

        const E = ws.getCell(`E${pl}`);
        E.value = {
          formula: `=((B${positionLine + 1}*E${positionLine + 1})+(B${
            positionLine + 2
          }*E${positionLine + 2})+(B${positionLine + 3}*E${
            positionLine + 3
          })+(B${positionLine + 4}*E${positionLine + 4}))/$B${totalLine}`,
          result: guestrooms_revenue[keyrow].occup,
        };
        E.alignment = { vertical: "middle", horizontal: "right" };
        E.numFmt = "0.00%";
        if (i % 2 === 0) {
          E.font = { ...font, bold: true };
          E.border = {
            top: { style: "thin" },
          };
        } else {
          E.font = { ...font };
          E.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const F = ws.getCell(`F${pl}`);
        F.value = {
          formula: `=((B${positionLine + 1}*F${positionLine + 1})+(B${
            positionLine + 2
          }*F${positionLine + 2})+(B${positionLine + 3}*F${
            positionLine + 3
          })+(B${positionLine + 4}*F${positionLine + 4}))/$B${totalLine}`,
          result: guestrooms_revenue[keyrow].rev_par,
        };
        F.alignment = { vertical: "middle", horizontal: "right" };
        F.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          F.font = { ...font, bold: true };
          F.border = {
            top: { style: "thin" },
          };
        } else {
          F.font = { ...font };
          F.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const G = ws.getCell(`G${pl}`);
        G.value = {
          formula: `=SUM(G${positionLine + 1}:G${totalLine - 1})`,
          result: guestrooms_revenue[keyrow].sum,
        };
        G.alignment = { vertical: "middle", horizontal: "right" };
        G.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          G.font = { ...font, bold: true };
          G.border = {
            top: { style: "thin" },
          };
        } else {
          G.font = { ...font };
          G.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      return totalLine + 1;
    };
    startLineOfNextTable = guestroomsRevenueToExcel(
      startLineOfNextTable + 1,
      guestrooms_revenue
    );

    /* generate an stabilizedYearOneRevenue table */
    const stabilizedYearOneRevenueToExcel = (
      positionLine,
      stabilized_year_one_revenue,
      inv_margin_selection
    ) => {
      let pl = positionLine;
      //head
      const thead = [
        "First Stabilized Year Revenue",
        "",
        "% Revenue",
        "$/Key",
        "$/GSF",
      ];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      const C = ws.getCell(`C${pl}`);
      C.value = thead[2];
      C.font = { ...font, bold: true };
      C.alignment = { vertical: "middle", horizontal: "right" };
      const D = ws.getCell(`D${pl}`);
      D.value = thead[3];
      D.font = { ...font, bold: true };
      D.alignment = { vertical: "middle", horizontal: "right" };
      const E = ws.getCell(`E${pl}`);
      E.value = thead[4];
      E.font = { ...font, bold: true };
      E.alignment = { vertical: "middle", horizontal: "right" };
      pl++;

      const firstColBody = {
        guestrooms: "Guestrooms",
        meetings: "F&B: Meetings",
        food_beverage: "F&B: Food, Beverage",
      };
      if (
        stabilized_year_one_revenue.custom_rev &&
        stabilized_year_one_revenue.custom_rev.length !== 0
      ) {
        stabilized_year_one_revenue.custom_rev.map((el, index) => {
          firstColBody["label" + index] = el.label;
          stabilized_year_one_revenue["label" + index] = {};
          stabilized_year_one_revenue["label" + index].revenue = el.revenue;
          stabilized_year_one_revenue["label" + index].percent = el.percent;
          stabilized_year_one_revenue["label" + index].per_key = el.per_key;
          stabilized_year_one_revenue["label" + index].per_gsf = el.per_gsf;
        });
      }

      const firstColFoot =
        inv_margin_selection === "gop_margin"
          ? {
              total: "Total Revenue",
              stabilized_gop: "Stabilized GOP",
              management_fee: "Management Fee",
              property_tax: "Property Taxes",
              insurance: "Insurance",
              reserves: "Reserves",
              // noi_margin: "NOI Margin",
              stabilized_noi: "Stabilized NOI",
            }
          : {
              total: "Total Revenue",
              noi_margin: "NOI Margin",
              stabilized_noi: "Stabilized NOI",
            };

      //body
      let i = 0;
      const totalRevenueLine = pl + Object.keys(firstColBody).length;
      for (const keyrow in firstColBody) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColBody[keyrow];
        A.font = font;
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        if (pl === positionLine + 1) {
          B.value = {
            formula: `=G${positionLine - 2}`,
            result: stabilized_year_one_revenue[keyrow].revenue,
          };
        } else {
          B.value = stabilized_year_one_revenue[keyrow].revenue;
        }
        B.font = font;
        B.alignment = { vertical: "middle" };
        B.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const C = ws.getCell(`C${pl}`);
        C.value = {
          formula: `=$B${pl}/B$${totalRevenueLine}`,
          result: stabilized_year_one_revenue[keyrow].percent,
        };
        C.font = font;
        C.alignment = { vertical: "middle" };
        C.numFmt = "0.00%";
        if (i % 2 === 0) {
          C.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const D = ws.getCell(`D${pl}`);
        D.value = {
          formula: `=$B${pl}/$B$${totalGuestrooms}`,
          result: stabilized_year_one_revenue[keyrow].per_key,
        };
        D.font = font;
        D.alignment = { vertical: "middle", horizontal: "right" };
        D.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          D.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const E = ws.getCell(`E${pl}`);
        E.value = {
          formula: `=$B${pl}/$B$${totalGrossBuildingAreaLine}`,
          result: stabilized_year_one_revenue[keyrow].per_gsf,
        };
        E.font = font;
        E.alignment = { vertical: "middle", horizontal: "right" };
        E.numFmt = "$#,##0.00;[Red]-$#,##0.00";
        if (i % 2 === 0) {
          E.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        pl++;
        i++;
      }
      //foot
      i = 0;
      const totalStabilizedLine = pl + Object.keys(firstColFoot).length - 1;
      if (inv_margin_selection === "gop_margin") {
        for (const keyrow in firstColFoot) {
          const A = ws.getCell(`A${pl}`);
          A.value = firstColFoot[keyrow];
          A.alignment = { vertical: "middle", horizontal: "left" };
          if (i > 1 && pl < totalStabilizedLine) {
            A.alignment = { indent: 1 };
            A.font = { ...font };
            if (i % 2 === 0) {
              A.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFF5E3" },
              };
            }
          } else {
            A.font = { ...font, bold: true };
          }
          if (i === 0 || pl === totalStabilizedLine) {
            A.border = {
              top: { style: "thin" },
            };
          }

          const B = ws.getCell(`B${pl}`);

          B.alignment = { vertical: "middle" };
          if (pl === totalRevenueLine) {
            B.value = {
              formula: `=SUM(B${positionLine + 1}:B${totalRevenueLine - 1})`,
              result: stabilized_year_one_revenue[keyrow].revenue,
            };
          } else if (pl === totalStabilizedLine) {
            B.value = {
              formula: `=B${totalRevenueLine + 1}-SUM(B${
                totalRevenueLine + 2
              }:B${totalStabilizedLine - 1})`,
              result: stabilized_year_one_revenue[keyrow].revenue,
            };
          } else {
            B.value = stabilized_year_one_revenue[keyrow].revenue;
          }

          B.numFmt = "$#,##0;[Red]-$#,##0";

          if (i > 1 && pl < totalStabilizedLine) {
            B.font = { ...font };
            if (i % 2 === 0) {
              B.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFF5E3" },
              };
            }
          } else {
            B.font = { ...font, bold: true };
          }
          if (i === 0 || pl === totalStabilizedLine) {
            B.border = {
              top: { style: "thin" },
            };
          }

          const C = ws.getCell(`C${pl}`);
          C.value = {
            formula: `=$B${pl}/B$${totalRevenueLine}`,
            result: stabilized_year_one_revenue[keyrow].percent,
          };
          C.alignment = { vertical: "middle" };
          C.numFmt = "0.00%";
          if (i > 1 && pl < totalStabilizedLine) {
            C.font = { ...font };
            if (i % 2 === 0) {
              C.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFF5E3" },
              };
            }
          } else {
            C.font = { ...font, bold: true };
          }
          if (i === 0 || pl === totalStabilizedLine) {
            C.border = {
              top: { style: "thin" },
            };
          }

          const D = ws.getCell(`D${pl}`);
          D.value = {
            formula: `=$B${pl}/$B$${totalGuestrooms}`,
            result: stabilized_year_one_revenue[keyrow].per_key,
          };
          D.alignment = { vertical: "middle", horizontal: "right" };
          D.numFmt = "$#,##0;[Red]-$#,##0";
          if (i > 1 && pl < totalStabilizedLine) {
            D.font = { ...font };
            if (i % 2 === 0) {
              D.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFF5E3" },
              };
            }
          } else {
            D.font = { ...font, bold: true };
          }
          if (i === 0 || pl === totalStabilizedLine) {
            D.border = {
              top: { style: "thin" },
            };
          }

          const E = ws.getCell(`E${pl}`);
          E.value = {
            formula: `=$B${pl}/$B$${totalGrossBuildingAreaLine}`,
            result: stabilized_year_one_revenue[keyrow].per_gsf,
          };
          E.alignment = { vertical: "middle", horizontal: "right" };
          E.numFmt = "$#,##0.00;[Red]-$#,##0.00";
          if (i > 1 && pl < totalStabilizedLine) {
            E.font = { ...font };
            if (i % 2 === 0) {
              E.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFF5E3" },
              };
            }
          } else {
            E.font = { ...font, bold: true };
          }
          if (i === 0 || pl === totalStabilizedLine) {
            E.border = {
              top: { style: "thin" },
            };
          }

          pl++;
          i++;
        }
      } else {
        for (const keyrow in firstColFoot) {
          const A = ws.getCell(`A${pl}`);
          A.value = firstColFoot[keyrow];
          A.alignment = { vertical: "middle", horizontal: "left" };
          if (i % 2 === 0) {
            A.font = { ...font, bold: true };
            A.border = {
              top: { style: "thin" },
            };
          } else {
            A.font = { ...font };
            A.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }
          const B = ws.getCell(`B${pl}`);

          B.alignment = { vertical: "middle" };
          if (i % 2 === 0) {
            B.font = { ...font, bold: true };
            B.border = {
              top: { style: "thin" },
            };
            B.numFmt = "$#,##0;[Red]-$#,##0";
            B.value = {
              formula:
                pl === totalRevenueLine
                  ? `=SUM(B${positionLine + 1}:B${totalRevenueLine - 1})`
                  : `=B${totalRevenueLine}*B${totalRevenueLine + 1}`,
              result: stabilized_year_one_revenue[keyrow].revenue,
            };
          } else {
            B.font = { ...font };
            B.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
            B.numFmt = "0.00%";
            B.value = stabilized_year_one_revenue[keyrow];
          }
          const C = ws.getCell(`C${pl}`);
          C.alignment = { vertical: "middle" };
          C.numFmt = "0.00%";
          if (i % 2 === 0) {
            C.value = {
              formula: `=$B${pl}/B$${totalRevenueLine}`,
              result: stabilized_year_one_revenue[keyrow].percent,
            };
            C.font = { ...font, bold: true };
            C.border = {
              top: { style: "thin" },
            };
          } else {
            C.value = stabilized_year_one_revenue[keyrow].percent;
            C.font = { ...font };
            C.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }
          const D = ws.getCell(`D${pl}`);
          D.value = {
            formula: `=$B${pl}/$B$${totalGuestrooms}`,
            result: stabilized_year_one_revenue[keyrow].per_key,
          };
          D.alignment = { vertical: "middle", horizontal: "right" };
          D.numFmt = "$#,##0;[Red]-$#,##0";
          if (i % 2 === 0) {
            D.font = { ...font, bold: true };
            D.border = {
              top: { style: "thin" },
            };
          } else {
            D.font = { ...font };
            D.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }

          const E = ws.getCell(`E${pl}`);
          E.value = {
            formula: `=$B${pl}/$B$${totalGrossBuildingAreaLine}`,
            result: stabilized_year_one_revenue[keyrow].per_gsf,
          };
          E.alignment = { vertical: "middle", horizontal: "right" };
          E.numFmt = "$#,##0.00;[Red]-$#,##0.00";
          if (i % 2 === 0) {
            E.font = { ...font, bold: true };
            E.border = {
              top: { style: "thin" },
            };
          } else {
            E.font = { ...font };
            E.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFF5E3" },
            };
          }

          pl++;
          i++;
        }
      }
      stabilizedNOILine = pl - 1;
      return pl;
    };
    startLineOfNextTable = stabilizedYearOneRevenueToExcel(
      startLineOfNextTable + 1,
      stabilized_year_one_revenue,
      projectInfo.investment_assumptions.inv_margin_selection
    );

    /* generate an investmentReturns table */
    const investmentReturnsToExcel = (positionLine, investment_returns) => {
      let pl = positionLine;
      //head
      const thead = ["Investment Returns", ""];
      const A = ws.getCell(`A${pl}`);
      A.value = thead[0];
      A.font = { ...font, bold: true };
      A.alignment = { vertical: "middle", horizontal: "left" };
      const B = ws.getCell(`B${pl}`);
      B.value = thead[1];
      B.font = { ...font, bold: true };
      B.alignment = { vertical: "middle", horizontal: "right" };
      pl++;

      const firstColBody = {
        stabilized_yield_cost: "Stabilized Yield/Cost",
        pretax_profit_margin: "Pre-tax Profit Margin",
        pretax_profit_margin_percent: "Pre-tax Profit Margin %",
        pretax_profit_margin_per_key: "Pre-tax Profit Margin /Key",
        multiple_on_invested_equity: "Multiple on Invested Equity",
      };

      //body
      let i = 0;
      const lengthOfColBody = Object.keys(firstColBody).length;
      for (const keyrow in firstColBody) {
        const A = ws.getCell(`A${pl}`);
        A.value = firstColBody[keyrow];
        if (i === 0 || i === lengthOfColBody - 1) {
          A.font = { ...font, bold: true };
        } else {
          A.font = font;
        }
        A.alignment = { vertical: "middle", horizontal: "left" };
        if (i % 2 === 0) {
          A.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
        }
        const B = ws.getCell(`B${pl}`);
        switch (i) {
          case 0:
            B.value = {
              formula: `=B${stabilizedNOILine}/B${totalBudgetLine}`,
              result: investment_returns[keyrow],
            };
            break;
          case 1:
            B.value = investment_returns[keyrow];
            break;
          case 2:
            B.value = {
              formula: `=B${pl - 1}/B${totalBudgetLine}`,
              result: investment_returns[keyrow],
            };
            break;
          case 3:
            B.value = {
              formula: `=B${pl - 2}/B${totalGuestrooms}`,
              result: investment_returns[keyrow],
            };
            break;
          case 4:
            B.value = {
              formula: `=(B${totalBudgetLine + 2}+B${pl - 3})/B${
                totalBudgetLine + 2
              }`,
              result: investment_returns[keyrow],
            };
            break;

          default:
            break;
        }
        if (i === 0 || i === lengthOfColBody - 1) {
          B.font = { ...font, bold: true };
        } else {
          B.font = font;
        }
        B.alignment = { vertical: "middle" };
        B.numFmt = "$#,##0;[Red]-$#,##0";
        if (i % 2 === 0) {
          B.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFF5E3" },
          };
          if (i !== lengthOfColBody - 1) {
            B.numFmt = "0.00%";
          } else {
            B.numFmt = "0.00";
          }
        } else {
          B.numFmt = "$#,##0;[Red]-$#,##0";
        }
        pl++;
        i++;
      }
    };

    investmentReturnsToExcel(startLineOfNextTable + 1, investment_returns);

    ////////////////////////////////////////
    wb.xlsx.writeBuffer().then((buf) => {
      saveAs(
        new Blob([buf]),
        `Report ${projectInfo.projectName} #${programId
          .substr(programId.length - 5)
          .toUpperCase()}.xlsx`
      );
    });
  };

  jsPdfGenerator = (evt, printProgramId) => {
    this.props.fetchDataForPDFStart(printProgramId);
    const { projectInfo, user, family } = this.props;
    const { familyLogoURL, family_name: familyName, brand_contact } = family;
    const blended_revenue =
      (this.props.projectInfo &&
        this.props.projectInfo.revenue_assumptions &&
        this.props.projectInfo.revenue_assumptions.blended_revenue) ||
      false;
    const inv_margin_selection =
      this.props.projectInfo &&
      this.props.projectInfo.investment_assumptions &&
      this.props.projectInfo.investment_assumptions.inv_margin_selection;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    evt.stopPropagation();

    const {
      building_program,
      development_budget,
      // guest_rooms,
      guestrooms_revenue,
      investment_returns,
      stabilized_year_one_revenue,
    } = this.props.tablesForModel;
    const buildingProgram = buildingProgramToArray(building_program);
    const developmentBudget = developmentBudgetToArray(development_budget);
    // const guestRooms = guestRoomsToArray(guest_rooms);
    const guestroomsRevenue = guestroomsRevenueToArray(guestrooms_revenue);
    const investmentReturns = investmentReturnsToArray(investment_returns);
    const stabilizedYearOneRevenue = stabilizedYearOneRevenueToArray(
      stabilized_year_one_revenue,
      inv_margin_selection
    );
    const { jobTitle, company, phone, email } = family.brand_contact;
    const logo = document.getElementById("logo");
    const logoOfHotel = document.getElementById("logoOfHead"); // logo of brand
    let requestsForPlan = Tables[0].images.map(({ url }) =>
      this.loadImage(url)
    );
    Promise.all([
      this.loadImage(`./img/logo.jpg`),

      // html2canvas(carouselImg_0),
      this.loadImage(Tables[0].images[0].url),

      this.loadImage(familyLogoURL),
      this.loadImage(brand_contact.contactPhotoURL),
      ...requestsForPlan,
    ]).then((results) => {
      var pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [612, 792],
        putOnlyUsedFonts: true,
      });
      var marginLeft = 210;

      var pdfInternals = pdf.internal;
      var pdfPageSize = pdfInternals.pageSize;
      var pdfPageWidth = pdfPageSize.width;
      // var pdfPageHeight = pdfPageSize.height;

      var marginLeftFirstCol = 40;
      var marginTopFirstCol = 40 - 6;
      //Image part
      ///////////////////////////////////////

      results.forEach((result, num) => {
        // if (num > 3) {
        //   pdf.addImage(
        //     result.toDataURL("image/jpeg"),
        //     "jpg",
        //     marginLeft,
        //     marginTop,
        //     pdfPageWidth - 245,
        //     result.height / (result.width / (pdfPageWidth - 245))
        //   );
        //   marginTop += result.height / 2.1 + 10;
        // }
        switch (num) {
          case 0:
            pdf.addImage(
              result,
              "jpg",
              marginLeftFirstCol + 5,
              marginTopFirstCol,
              150,
              logo.height / (logo.width / 150)
            );
            marginTopFirstCol += logo.height / (logo.width / 150) + 95;

            marginTopFirstCol +=
              logoOfHotel.height / (logoOfHotel.width / 115) + 50;
            break;
          case 4: //mapParcel
            pdf.addImage(
              result,
              "jpg",
              marginLeftFirstCol,
              marginTopFirstCol - 100,
              150,
              result.height / (result.width / 150)
            );
            marginTopFirstCol += result.height / (result.width / 150) + 15;
            break;
          case 5: //HousePlan
            // console.log(btoa(response.data.images[0].url));
            // var imgData = "data:image/jpeg;base64," + Base64.encode(result);
            // var c = document.createElement("canvas");
            // var ctx = c.getContext("2d");
            // ctx.drawImage(result, 100, 100);
            // console.log(c.toDataURL());

            pdf.addImage(
              result,
              // result.toDataURL("image/jpeg"),
              "JPEG",
              marginLeftFirstCol,
              marginTopFirstCol - 100,
              150,
              result.height / (result.width / 150)
            );
            marginTopFirstCol += result.height / (result.width / 150) + 198;
            break;
          /* case 2: //familyLogoURL
            pdf.addImage(
              result,
              "JPEG",
              marginLeftFirstCol + 30,
              marginTopFirstCol,
              70,
              result.height / (result.width / 70),
              "e",
              "MEDIUM"
            );
            break;*/
          case 3: //contactPhotoURL
            pdf.addImage(
              result,
              "JPEG",
              marginLeftFirstCol + 120,
              666,
              35,
              result.height / (result.width / 35)
            );
            break;

          default:
            break;
        }
      });

      pdf.addImage(phoneIcon, "JPEG", marginLeftFirstCol + 105, 673, 9, 9);
      marginTopFirstCol += 9 + 3;

      pdf.addImage(emailIcon, "JPEG", marginLeftFirstCol + 105, 686, 9, 9);
      //Text part
      ///////////////////////////////////////
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(70, 169, 189);
      // pdf.line(marginLeftFirstCol, 60, 190, 60); // Line for logo
      pdf.setFontSize(11);
      pdf.setTextColor("#595959");
      pdf.setFontStyle("bold");
      const companyNameByString = user.companyName
        .split(" ")
        .reduce((prev, el) => {
          let newString = "";
          if (prev.length === 0) {
            newString = el;
          } else {
            newString = `${prev[prev.length - 1]} ${el}`;
            if (pdf.getTextDimensions(newString).w > 150) {
              newString = el;
            } else {
              prev.pop();
            }
          }
          return [...prev, newString];
        }, []);
      const companyNameByTwoString =
        companyNameByString.length > 2
          ? companyNameByString.slice(0, 2)
          : companyNameByString;
      const textHeight = pdf.getTextDimensions(companyNameByTwoString).h;
      pdf.text(
        companyNameByTwoString,
        marginLeftFirstCol + 150,
        125 - textHeight,
        {
          align: "right",
        }
      ); // Company name
      pdf.setFontSize(8);
      pdf.setFontStyle("normal");
      pdf.text(
        `${projectInfo.user.firstName} ${projectInfo.user.lastName}`,
        marginLeftFirstCol + 150,
        126,
        {
          align: "right",
        }
      ); // First Lastname `${projectInfo.user_email}`
      pdf.text(`${email}`, marginLeftFirstCol + 150, 137, {
        align: "right",
      }); // user email
      const dateNow = new Date();
      pdf.text(
        `${
          months[dateNow.getMonth()]
        } ${dateNow.getDate()}, ${dateNow.getFullYear()}`, // ${dateNow.toLocaleTimeString()}`,
        marginLeftFirstCol + 150,
        148,
        {
          align: "right",
        }
      ); // "March 31, 2020"

      pdf.setFontSize(11);
      pdf.setFontStyle("bold");
      pdf.text(`${projectInfo.projectName}`, marginLeftFirstCol + 150, 183, {
        align: "right",
      }); // Project Name
      pdf.setFontStyle("normal");
      pdf.setFontSize(8);
      pdf.text(
        `${projectInfo.address.streetNumber} ${projectInfo.address.street}`,
        marginLeftFirstCol + 150,
        195,
        {
          align: "right",
        }
      ); // 4960 A Street
      pdf.text(
        `${projectInfo.address.city}, ${projectInfo.address.state} ${projectInfo.address.country}`,
        marginLeftFirstCol + 150,
        205,
        {
          align: "right",
        }
      );

      const lotArea = `${new Intl.NumberFormat("en-US").format(
        (+projectInfo.area_sf).toFixed(0)
      )} sf / ${(+projectInfo.area_ac).toFixed(2)} ac`;
      const lotAreaWidth = pdf.getTextDimensions(lotArea).w;
      pdf.setTextColor("#a6a6a6");
      pdf.text("Lot area:", marginLeftFirstCol + 150 - lotAreaWidth - 5, 215, {
        align: "right",
      });
      pdf.setTextColor("#595959");
      pdf.text(lotArea, marginLeftFirstCol + 150, 215, {
        align: "right",
      }); // 49,437 sf / 1.13 ac

      const { floors, height, far, parking } = Tables[0].design_numbers;

      pdf.setFontSize(8);
      pdf.setFontStyle("bold");
      pdf.text(
        `Option #${printProgramId
          .substr(printProgramId.length - 5)
          .toUpperCase()}`,
        marginLeftFirstCol + 150,
        460, //510
        {
          align: "right",
        }
      ); // "Option #30641"
      pdf.setFontStyle("normal");
      pdf.setTextColor("#a6a6a6");
      pdf.text("Floors:", marginLeftFirstCol + 100, 470, {
        align: "right",
      });
      pdf.setTextColor("#333333");
      pdf.text(`${floors}`, marginLeftFirstCol + 150, 470, {
        align: "right",
      });
      pdf.setTextColor("#a6a6a6");
      pdf.text("Height:", marginLeftFirstCol + 100, 482, {
        align: "right",
      });
      pdf.setTextColor("#333333");
      pdf.text(`${height} ft`, marginLeftFirstCol + 150, 482, {
        align: "right",
      });
      pdf.setTextColor("#a6a6a6");
      pdf.text("FAR:", marginLeftFirstCol + 100, 492, {
        align: "right",
      });
      pdf.setTextColor("#333333");
      pdf.text(`${far.toFixed(2)}`, marginLeftFirstCol + 150, 492, {
        align: "right",
      });
      pdf.setTextColor("#a6a6a6");
      pdf.text("Parking:", marginLeftFirstCol + 100, 500, {
        align: "right",
      });
      pdf.setTextColor("#333333");
      pdf.text(`${parking} spaces`, marginLeftFirstCol + 150, 500, {
        align: "right",
      });
      const { by_type, total } = Tables[0].guest_rooms_pdf;

      pdf.setFontStyle("bold");
      pdf.setTextColor("#333333");
      pdf.text("Guestrooms", marginLeftFirstCol + 8, 536, {
        align: "left",
      });
      pdf.setFontSize(7);
      pdf.setFontStyle("normal");
      pdf.setTextColor("#333333");
      pdf.text("Keys", marginLeftFirstCol + 108, 536, {
        align: "right",
      });
      pdf.text("Mix %", marginLeftFirstCol + 142, 536, {
        align: "right",
      });

      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeftFirstCol, 544, 190, 544);

      pdf.setLineWidth(11);
      pdf.setDrawColor("#F4F7FC");
      pdf.line(marginLeftFirstCol, 555, 190, 555);

      pdf.setTextColor("#333333");
      pdf.text(by_type[0].name, marginLeftFirstCol + 8, 558, {
        align: "left",
      });
      pdf.text(`${by_type[0].keys}`, marginLeftFirstCol + 108, 558, {
        align: "right",
      });
      pdf.text(
        `${(by_type[0].mix * 100).toFixed(1)}%`,
        marginLeftFirstCol + 142,
        558,
        {
          align: "right",
        }
      );

      pdf.text(by_type[1].name, marginLeftFirstCol + 8, 569, {
        align: "left",
      });
      pdf.text(`${by_type[1].keys}`, marginLeftFirstCol + 108, 569, {
        align: "right",
      });
      pdf.text(
        `${(by_type[1].mix * 100).toFixed(1)}%`,
        marginLeftFirstCol + 142,
        569,
        {
          align: "right",
        }
      );

      pdf.setLineWidth(11);
      pdf.setDrawColor("#F4F7FC");
      pdf.line(marginLeftFirstCol, 577, 190, 577);

      pdf.text(by_type[2].name, marginLeftFirstCol + 8, 580, {
        align: "left",
      });
      pdf.text(`${by_type[2].keys}`, marginLeftFirstCol + 108, 580, {
        align: "right",
      });
      pdf.text(
        `${(by_type[2].mix * 100).toFixed(1)}%`,
        marginLeftFirstCol + 142,
        580,
        {
          align: "right",
        }
      );

      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(marginLeftFirstCol, 588, 190, 588);

      pdf.setLineWidth(0.6);
      pdf.setDrawColor("#333333");
      pdf.line(115, 544, 115, 605);

      pdf.setFontStyle("bold");
      pdf.text("Total", marginLeftFirstCol + 8, 602, {
        align: "left",
      });
      pdf.text(`${total.keys}`, marginLeftFirstCol + 108, 602, {
        align: "right",
      });
      pdf.text(`${total.mix * 100}%`, marginLeftFirstCol + 142, 602, {
        align: "right",
      });
      const { firstName, lastName } = projectInfo.user;
      pdf.setFontSize(10);
      pdf.setFontStyle("bold");
      pdf.setTextColor("#595959");
      pdf.text(`${firstName} ${lastName}`, marginLeftFirstCol + 150, 630, {
        align: "right",
      });
      pdf.setFontStyle("normal");
      pdf.setTextColor("#333333");
      pdf.setFontSize(8);
      pdf.text(`${jobTitle}`, marginLeftFirstCol + 150, 641, {
        align: "right",
        maxWidth: 100,
        lineHeightFactor: 1.4,
      });

      pdf.setFontStyle("bold");
      pdf.text(`${company}`, marginLeftFirstCol + 150, 663, {
        align: "right",
      });

      pdf.setFontStyle("normal");
      pdf.setTextColor("#0563c1");
      pdf.setFontSize(8);
      pdf.text(`${phone}`, marginLeftFirstCol + 100, 680, {
        align: "right",
      });
      pdf.text(`${email}`, marginLeftFirstCol + 100, 693, {
        align: "right",
      });
      //adding 5 Tables
      /////////////////////////////////////////////////////////////////////////////////////////
      const top = this.table(
        pdf,
        20,
        marginLeft,
        pdfPageWidth - 245,
        buildingProgram
      );
      const top1 = this.table(
        pdf,
        top + 2,
        marginLeft,
        pdfPageWidth - 245,
        developmentBudget
      );
      const top2 = blended_revenue
        ? this.tableTotalGuestroomsRevenue(
            pdf,
            top1 + 2,
            marginLeft,
            pdfPageWidth - 245,
            guestroomsRevenue
          )
        : this.table(
            pdf,
            top1 + 2,
            marginLeft,
            pdfPageWidth - 245,
            guestroomsRevenue
          );
      const top3 =
        inv_margin_selection === "gop_margin"
          ? this.tableGopMargin(
              pdf,
              top2 + 2,
              marginLeft,
              pdfPageWidth - 245,
              stabilizedYearOneRevenue
            )
          : this.table(
              pdf,
              top2 + 2,
              marginLeft,
              pdfPageWidth - 245,
              stabilizedYearOneRevenue
            );
      this.tableInvestmentReturns(
        pdf,
        top3 + 2,
        marginLeft,
        pdfPageWidth - 245,
        investmentReturns
      );
      ////////////////////////// End of first Page
      results.forEach((result, num) => {
        if (num > 3) {
          pdf.addPage();
          const widthOfResult = 690;
          const heightOfResult = 450;
          // 325 - x center of design in center of page view
          // 385 - y center of design in center of page view
          if (result.width / result.height > widthOfResult / heightOfResult) {
            const x = 325 + result.height / (result.width / widthOfResult) / 2;
            const y =
              385 +
              widthOfResult / 2 -
              result.height / (result.width / widthOfResult);
            const width = widthOfResult;
            const height = result.height / (result.width / widthOfResult);
            if (result.source) {
              // const canvas = await drawSVGToCanvas(
              //   result.source,
              //   result.viewBox,
              //   result.width * 3,
              //   result.height * 3,
              // );
              // pdf.addImage(canvas, 'JPEG', x, y, width, height, null, 'MEDIUM', 90);
            } else {
              pdf.addImage(
                result,
                "JPEG",
                x,
                y,
                width,
                height,
                null,
                "MEDIUM",
                90
              );
            }
          } else {
            const x = 325 + heightOfResult / 2;
            const y =
              385 +
              result.width / (result.height / heightOfResult) / 2 -
              heightOfResult;
            const width = result.width / (result.height / heightOfResult);
            const height = heightOfResult;
            if (result.source) {
              // const canvas = await drawSVGToCanvas(
              //   result.source,
              //   result.viewBox,
              //   result.width * 3,
              //   result.height * 3,
              // );
              // pdf.addImage(canvas, 'JPEG', x, y, width, height, null, 'MEDIUM', 90);
            } else {
              pdf.addImage(
                result,
                "JPEG",
                x,
                y,
                width,
                height,
                null,
                "MEDIUM",
                90
              );
            }
          }
          const widthOflogo = 200;
          pdf.addImage(
            results[0],
            "JPEG",
            33 + logo.height / (logo.width / widthOflogo),
            785 - (40 + logo.height / (logo.width / widthOflogo)),
            widthOflogo,
            logo.height / (logo.width / widthOflogo),
            null,
            "NONE",
            90
          );

          const widthOflogoOfHotel = 120;
          // pdf.addImage(
          //   logoOfHotel,
          //   "jpg",
          //   3 + logoOfHotel.height / (logoOfHotel.width / widthOflogoOfHotel),
          //   40 +
          //     widthOflogoOfHotel -
          //     logoOfHotel.height / (logoOfHotel.width / widthOflogoOfHotel),
          //   widthOflogoOfHotel,
          //   logoOfHotel.height / (logoOfHotel.width / widthOflogoOfHotel),
          //   null,
          //   "NONE",
          //   90
          // );

          // Page number
          pdf.setFontStyle("normal");
          // pdf.setTextColor("#0563c1");
          pdf.setFontSize(10);
          pdf.text(`Page ${num - 2} of ${results.length - 3}`, 306, 752, {
            align: "center",
          });
          // Name of plan
          pdf.setFontStyle("bold");
          pdf.setFontSize(14);
          pdf.text(
            `${Tables[0].images[num - 4].name}`,
            572,
            396 + Tables[0].images[num - 4].name.length * 3.5,
            {
              angle: 90,
            }
          );
          const marginLeftLeftCol = 525;
          const marginLeftRightCol = 325;
          // Left colon
          const { firstName, lastName } = projectInfo.user;
          pdf.setFontSize(11);
          pdf.setTextColor("#595959");
          pdf.setFontStyle("bold");
          pdf.text(`${user.companyName}`, 51, marginLeftLeftCol, {
            align: "left",
            angle: 90,
          }); // Company name
          pdf.setFontSize(8);
          pdf.setFontStyle("normal");
          pdf.text(`${firstName} ${lastName}`, 62, marginLeftLeftCol, {
            align: "left",
            angle: 90,
          }); // First Lastname `${projectInfo.user_email}`
          pdf.text(`${email}`, 73, marginLeftLeftCol, {
            align: "left",
            angle: 90,
          }); // user email

          const dateNow = new Date();
          pdf.text(
            `${
              months[dateNow.getMonth()]
            } ${dateNow.getDate()}, ${dateNow.getFullYear()}`,
            84,
            marginLeftLeftCol,
            {
              align: "left",
              angle: 90,
            }
          ); // "March 31, 2020"

          // Right colon
          pdf.setFontSize(11);
          pdf.setFontStyle("bold");
          pdf.text(`${projectInfo.projectName}`, 51, marginLeftRightCol, {
            align: "left",
            angle: 90,
          }); // Project Name
          pdf.setFontStyle("normal");
          pdf.setFontSize(8);
          pdf.text(
            `${projectInfo.address.streetNumber} ${projectInfo.address.street}`,
            62,
            marginLeftRightCol,
            {
              angle: 90,
              align: "left",
            }
          ); // 4960 A Street
          pdf.text(
            `${projectInfo.address.city}, ${projectInfo.address.state} ${projectInfo.address.zip}`,
            73,
            marginLeftRightCol,
            {
              angle: 90,
              align: "left",
            }
          ); // "Anchorage, AK 99503"
          pdf.setTextColor("#a6a6a6");
          pdf.text("Lot area:", 84, marginLeftRightCol, {
            angle: 90,
            align: "left",
          });
          pdf.setTextColor("#595959");
          pdf.text(
            `${new Intl.NumberFormat("en-US").format(
              (+projectInfo.area_sf).toFixed(3)
            )} sf / ${(+projectInfo.area_ac).toFixed(2)} ac`,
            84,
            marginLeftRightCol - 35,
            {
              angle: 90,
              align: "left",
            }
          ); // 49,437 sf / 1.13 ac
          // End of colon
        }
      });
      /////////////////////////// End of PDF
      this.props.fetchDataForPDFSuccess(printProgramId);
      pdf.save(
        `Report ${projectInfo.projectName} #${printProgramId
          .substr(printProgramId.length - 5)
          .toUpperCase()}.pdf`
      );
    });
  };

  confirmHidingOptionHandlerCancel = (evt) => {
    evt.stopPropagation();
    this.setState({ confirmHidingOptionID: null });
  };

  confirmHidingOptionHandler = (evt, confirmHidingOptionID) => {
    evt.stopPropagation();
    this.setState({ confirmHidingOptionID });
  };

  modelHiddenHandler = (evt, id, activeProgramId, sortedResults, index) => {
    evt.stopPropagation();
    if (id === activeProgramId) {
      if (sortedResults[index + 1]) {
        this.getProgramDetailsHandler(sortedResults[index + 1].program_id);
      } else {
        if (sortedResults[index - 1]) {
          this.getProgramDetailsHandler(sortedResults[index - 1].program_id);
        } else {
          this.props.setActiveProgramId(null);
        }
      }
    }
    this.props.toggleModelHidden(id);
    this.setState({ confirmHidingOptionID: null });
  };

  handleChange = (e) => {
    this.props.setSorting(`${e.currentTarget.value}`);
  };

  getProgramDetailsHandler = (activeProgramId) => {
    this.props.setActiveProgramId(activeProgramId);
  };

  render() {
    const {
      error,
      filteredResults,
      activeProgramId,
      filters,
      // projectInfo,
    } = this.props;

    const { confirmHidingOptionID } = this.state;

    const withoutHidden = filteredResults.filter(
      (program) => program.isHidden === false
    );
    const starred = withoutHidden.filter(
      (program) => program.isStarred === true
    );
    const unStarred = withoutHidden.filter(
      (program) => program.isStarred === false
    );
    if (filters.filterParameters.sorting === Sort.BUDGET_KEY) {
      starred.sort(
        (a, b) =>
          a[filters.filterParameters.sorting] -
          b[filters.filterParameters.sorting]
      );
      unStarred.sort(
        (a, b) =>
          a[filters.filterParameters.sorting] -
          b[filters.filterParameters.sorting]
      );
    } else {
      starred.sort((a, b) => {
        return (
          b[filters.filterParameters.sorting] -
          a[filters.filterParameters.sorting]
        );
      });
      unStarred.sort(
        (a, b) =>
          b[filters.filterParameters.sorting] -
          a[filters.filterParameters.sorting]
      );
    }
    const sortedResults = [...starred, ...unStarred];

    return (
      <section className="concept">
        <img src={MyCompany} style={{ display: "none" }} id="logoOfHead" />
        <div className="sort-panel">
          <p>
            <span>Sorted by:</span>
            <input
              type="radio"
              id="multiple_on_invested_equity"
              checked={filters.filterParameters.sorting === Sort.EQUITY_MULT}
              name="sorting"
              value={Sort.EQUITY_MULT}
              onChange={this.handleChange}
            />
            <label htmlFor="multiple_on_invested_equity">Inv equity</label>
          </p>
          <p>
            <input
              type="radio"
              id="Keys"
              checked={filters.filterParameters.sorting === Sort.KEYS}
              name="sorting"
              value={Sort.KEYS}
              onChange={this.handleChange}
            />
            <label htmlFor="Keys">Rooms</label>
            <input
              type="radio"
              id="BudgetKey"
              checked={filters.filterParameters.sorting === Sort.BUDGET_KEY}
              name="sorting"
              value={Sort.BUDGET_KEY}
              onChange={this.handleChange}
            />
            <label htmlFor="BudgetKey">Cash on room</label>
            <input
              type="radio"
              id="Yield"
              checked={filters.filterParameters.sorting === Sort.YIELD}
              name="sorting"
              value={Sort.YIELD}
              onChange={this.handleChange}
            />
            <label htmlFor="Yield">Cash on cost</label>
          </p>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={300}
          autoHideDuration={300}
          // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
          style={{ height: "calc(100vh - 160px)" }}
        >
          {sortedResults && sortedResults[0]
            ? sortedResults.map((e, index) => {
                const starredStyle = e.isStarred
                  ? { opacity: 1, stroke: "#ffc000" }
                  : {};
                return (
                  <div className="cardContainer" key={e.program_id}>
                    <div
                      className={`option my-2 ${
                        e.program_id === activeProgramId ? "active" : ""
                      }`}
                      onClick={() => {
                        this.getProgramDetailsHandler(e.program_id);
                        this.props.changeDataInTable(Tables[index]);
                      }}
                    >
                      <div className="firstRow">
                        <h5 className="pl-1 mb-0">{`#${e.program_id
                          .substr(e.program_id.length - 5)
                          .toUpperCase()}`}</h5>
                        <div className="buttonContainer">
                          <button
                            onClick={(evt) =>
                              this.confirmHidingOptionHandler(evt, e.program_id)
                            }
                          >
                            <Svg icon="Trash" className="iconHidden" />
                          </button>
                          <button
                            onClick={(evt) =>
                              this.jsPdfGenerator(evt, e.program_id)
                            }
                          >
                            <Svg icon="PDF" className="iconPDF" />
                          </button>
                          <button
                            onClick={(evt) =>
                              this.saveAsExcel(evt, e.program_id)
                            }
                          >
                            <Svg icon="XLSX" className="iconPDF" />
                          </button>
                          <button
                            style={starredStyle}
                            onClick={(evt) => {
                              evt.stopPropagation();
                              this.props.toggleModelStarred(e.program_id);
                            }}
                          >
                            <Svg icon="Star" className="iconStar" />
                          </button>
                        </div>
                      </div>
                      <div className="secondRow">
                        <div className="info text-left d-flex py-3">
                          <div className="keys">{`${e.floors} floors`}</div>
                          <div className="budget">
                            ${(e.budget_total / 1000000).toFixed(1)}M
                          </div>
                          <div
                            className={`investment ${
                              filters.filterParameters.sorting ===
                              Sort.EQUITY_MULT
                                ? "boldStyleForSort"
                                : ""
                            }`}
                          >
                            {e.multiple_on_invested_equity.toFixed(2)}x
                          </div>
                        </div>
                      </div>
                      <div className="thirdRow">
                        <div className="info text-left d-flex py-3">
                          <div
                            className={`keys ${
                              filters.filterParameters.sorting === Sort.KEYS
                                ? "boldStyleForSort"
                                : ""
                            }`}
                          >
                            {e.room_count} fugiat
                          </div>
                          <div
                            className={`budget ${
                              filters.filterParameters.sorting ===
                              Sort.BUDGET_KEY
                                ? "boldStyleForSort"
                                : ""
                            }`}
                          >
                            ${(e.total_cost_per_key / 1000).toFixed(0)}Sed ut
                          </div>
                          <div
                            className={`investment ${
                              filters.filterParameters.sorting === Sort.YIELD
                                ? "boldStyleForSort"
                                : ""
                            }`}
                          >
                            {(e.inv_cash_on_cost * 100).toFixed(1)}error sit
                          </div>
                        </div>
                      </div>
                    </div>
                    {confirmHidingOptionID === e.program_id && (
                      <div className="confirmHidingOption">
                        <Svg icon="Close" className="iconDangerSign" />
                        <div className="confirmMessage">
                          <p className="confirmHead">Delete?</p>
                          <p className="confirmText">
                            Without possibility to return
                          </p>
                          <p className="confirmButtons">
                            <button
                              onClick={(evt) =>
                                this.confirmHidingOptionHandlerCancel(evt)
                              }
                            >
                              Cancel
                            </button>
                            <button
                              autoFocus="true"
                              onClick={(evt) =>
                                this.modelHiddenHandler(
                                  evt,
                                  e.program_id,
                                  activeProgramId,
                                  sortedResults,
                                  index
                                )
                              }
                            >
                              Delete
                            </button>
                          </p>
                        </div>
                      </div>
                    )}
                    {filters.loadingPDF.length > 0 &&
                      filters.loadingPDF.find((id) => id === e.program_id) && (
                        <div className="loadingPDF">
                          <div className="loadingPDF__icon">
                            <CircularProgress />
                          </div>
                          <div className="loadingPDF__message">
                            <p className="loadingPDF__text">
                              Please wait while the file is downloading.
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })
            : !filters.loading && (
                <p className="mt-2 text-left">
                  ed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo.
                  <br /> Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                  aut odit aut fugit, sed quia consequuntur magni dolores eos
                  qui ratione voluptatem.
                </p>
              )}
          {filters.loading && (
            <div className="loaderProgram">
              <CircularProgress />
            </div>
          )}
        </Scrollbars>
      </section>
    );
  }
}

export default Concept;
