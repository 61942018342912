import React, { Component } from "react";
import "./NotFound.sass";
import img from "../../assets/img/404.png";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableHeight: 0,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <section className="not-found">
        <div className="container">
          <h1>Oops!</h1>
          <img src={img} alt="404" />
        </div>
      </section>
    );
  }
}
export default NotFound;
