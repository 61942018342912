import MapInner from "./MapInner";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
  };
};

export default connect(mapStateToProps, null)(MapInner);
