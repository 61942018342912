import Budget from "./Budget";
import { connect } from "react-redux";
import {
  setProjectInfoKeyBudgetAssumptionsKeyBudgetFfe,
  setProjectInfoKeyBudgetAssumptions,
  setProjectInfoKeyBudgetAssumptionsKeySoftCosts,
  setProjectInfoKeyBudgetAssumptionsKeyHardCosts,
  setProjectInfoKeyBudgetAssumptionsKeyBudgetFfeOnBlur,
  setProjectInfoKeyBudgetAssumptionsOnBlur,
  setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur,
  setProjectInfoKeyBudgetAssumptionsKeyHardCostsOnBlur,
} from "../../../../../redux/ProjectInfo/projectInfoActions";
import { bindActionCreators } from "redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectInfoKeyBudgetAssumptionsKeySoftCosts: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeySoftCosts,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsKeyHardCosts: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeyHardCosts,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsKeyBudgetFfe: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeyBudgetFfe,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptions: bindActionCreators(
      setProjectInfoKeyBudgetAssumptions,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsKeyHardCostsOnBlur: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeyHardCostsOnBlur,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsKeyBudgetFfeOnBlur: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsKeyBudgetFfeOnBlur,
      dispatch
    ),
    setProjectInfoKeyBudgetAssumptionsOnBlur: bindActionCreators(
      setProjectInfoKeyBudgetAssumptionsOnBlur,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
