import React from "react";
import PropTypes from "prop-types";

const Revenue = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#174FA2" />
      <path
        d="M18 8.25C16.0716 8.25 14.1866 8.82183 12.5832 9.89317C10.9798 10.9645 9.73013 12.4873 8.99218 14.2688C8.25422 16.0504 8.06114 18.0108 8.43735 19.9021C8.81355 21.7934 9.74215 23.5307 11.1057 24.8943C12.4693 26.2579 14.2066 27.1865 16.0979 27.5627C17.9892 27.9389 19.9496 27.7458 21.7312 27.0078C23.5127 26.2699 25.0355 25.0202 26.1068 23.4168C27.1782 21.8134 27.75 19.9284 27.75 18C27.745 15.4157 26.7162 12.9386 24.8888 11.1112C23.0614 9.28378 20.5843 8.25496 18 8.25ZM19.125 22.5H18.75V23.25C18.75 23.4489 18.671 23.6397 18.5303 23.7803C18.3897 23.921 18.1989 24 18 24C17.8011 24 17.6103 23.921 17.4697 23.7803C17.329 23.6397 17.25 23.4489 17.25 23.25V22.5H15.75C15.5511 22.5 15.3603 22.421 15.2197 22.2803C15.079 22.1397 15 21.9489 15 21.75C15 21.5511 15.079 21.3603 15.2197 21.2197C15.3603 21.079 15.5511 21 15.75 21H19.125C19.4234 21 19.7095 20.8815 19.9205 20.6705C20.1315 20.4595 20.25 20.1734 20.25 19.875C20.25 19.5766 20.1315 19.2905 19.9205 19.0795C19.7095 18.8685 19.4234 18.75 19.125 18.75H16.875C16.1788 18.75 15.5111 18.4734 15.0188 17.9812C14.5266 17.4889 14.25 16.8212 14.25 16.125C14.25 15.4288 14.5266 14.7611 15.0188 14.2688C15.5111 13.7766 16.1788 13.5 16.875 13.5H17.25V12.75C17.25 12.5511 17.329 12.3603 17.4697 12.2197C17.6103 12.079 17.8011 12 18 12C18.1989 12 18.3897 12.079 18.5303 12.2197C18.671 12.3603 18.75 12.5511 18.75 12.75V13.5H20.25C20.4489 13.5 20.6397 13.579 20.7803 13.7197C20.921 13.8603 21 14.0511 21 14.25C21 14.4489 20.921 14.6397 20.7803 14.7803C20.6397 14.921 20.4489 15 20.25 15H16.875C16.5766 15 16.2905 15.1185 16.0795 15.3295C15.8685 15.5405 15.75 15.8266 15.75 16.125C15.75 16.4234 15.8685 16.7095 16.0795 16.9205C16.2905 17.1315 16.5766 17.25 16.875 17.25H19.125C19.8212 17.25 20.4889 17.5266 20.9812 18.0188C21.4734 18.5111 21.75 19.1788 21.75 19.875C21.75 20.5712 21.4734 21.2389 20.9812 21.7312C20.4889 22.2234 19.8212 22.5 19.125 22.5Z"
        fill="white"
      />
    </svg>
  );
};

Revenue.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Revenue;
