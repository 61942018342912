import React from "react";
import PropTypes from "prop-types";

const Admin = ({ className }) => {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path d="m191.5 192h-131c-5.522 0-10 4.478-10 10s4.478 10 10 10h131c5.522 0 10-4.478 10-10s-4.478-10-10-10z" />
        <path d="m275.5 232c16.542 0 30-13.458 30-30s-13.458-30-30-30-30 13.458-30 30 13.458 30 30 30zm0-40c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10z" />
        <path d="m363.5 232c16.542 0 30-13.458 30-30s-13.458-30-30-30-30 13.458-30 30 13.458 30 30 30zm0-40c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10z" />
        <path d="m60.5 84h131c5.522 0 10-4.478 10-10s-4.478-10-10-10h-131c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
        <path d="m275.5 104c16.542 0 30-13.458 30-30s-13.458-30-30-30-30 13.458-30 30 13.458 30 30 30zm0-40c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10z" />
        <path d="m363.5 104c16.542 0 30-13.458 30-30s-13.458-30-30-30-30 13.458-30 30 13.458 30 30 30zm0-40c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10z" />
        <path d="m191.5 320h-131c-5.522 0-10 4.478-10 10s4.478 10 10 10h131c5.522 0 10-4.478 10-10s-4.478-10-10-10z" />
        <path d="m437.934 267.935c-.507-.669-1.031-1.323-1.59-1.944 4.757-5.31 7.656-12.317 7.656-19.991v-88c0-7.678-2.902-14.688-7.664-20 4.761-5.312 7.664-12.322 7.664-20v-88c0-16.542-13.458-30-30-30h-384c-16.542 0-30 13.458-30 30v88c0 7.678 2.902 14.688 7.664 20-4.762 5.312-7.664 12.322-7.664 20v88c0 7.678 2.902 14.688 7.664 20-4.762 5.312-7.664 12.322-7.664 20v88c0 16.542 13.458 30 30 30h227.566c9.631 61.113 62.657 108 126.434 108 70.579 0 128-57.421 128-128 0-51.32-30.364-95.675-74.066-116.065zm54.066 116.065c0 25.151-8.643 48.321-23.113 66.698-6.512-12.919-15.958-24.379-27.545-33.166-7.393-5.606-15.425-10.029-23.902-13.219 8.448-8.52 13.676-20.236 13.676-33.154 0-25.979-21.136-47.114-47.115-47.114s-47.115 21.136-47.115 47.115c0 12.917 5.228 24.633 13.676 33.153-8.509 3.203-16.57 7.646-23.985 13.283-11.551 8.78-20.966 20.214-27.462 33.102-14.472-18.377-23.115-41.547-23.115-66.698 0-59.552 48.448-108 108-108s108 48.448 108 108zm-135.115-12.841c0-14.951 12.164-27.114 27.115-27.114s27.115 12.163 27.115 27.114v.001c0 14.951-12.164 27.114-27.115 27.114s-27.115-12.164-27.115-27.115zm-336.885-341.159c0-5.514 4.486-10 10-10h384c5.514 0 10 4.486 10 10v88c0 5.514-4.486 10-10 10h-148.389c-5.522 0-10 4.478-10 10s4.478 10 10 10h148.389c5.514 0 10 4.486 10 10v88c0 5.514-4.486 10-10 10h-384c-5.514 0-10-4.486-10-10v-88c0-5.514 4.486-10 10-10h145.612c5.522 0 10-4.478 10-10s-4.478-10-10-10h-145.612c-5.514 0-10-4.486-10-10zm10 354c-5.514 0-10-4.486-10-10v-88c0-5.514 4.486-10 10-10h285.369c-35.665 22.744-59.369 62.655-59.369 108zm284.047 82.212c11.05-28.512 38.974-47.938 69.953-47.938 30.957 0 58.901 19.434 69.952 47.939-18.859 16.071-43.29 25.787-69.952 25.787s-51.094-9.717-69.953-25.788z" />
        <path d="m220.609 148c2.631 0 5.21-1.07 7.07-2.931 1.86-1.859 2.93-4.439 2.93-7.069s-1.069-5.21-2.93-7.07-4.439-2.93-7.07-2.93c-2.63 0-5.21 1.069-7.069 2.93-1.86 1.86-2.931 4.44-2.931 7.07s1.07 5.21 2.931 7.069c1.859 1.861 4.439 2.931 7.069 2.931z" />
      </g>
    </svg>
  );
};

Admin.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Admin;
