import React, { Component } from "react";
import "./Filters.sass";
import "react-input-range/lib/css/index.css";
import toastr from "toastr";
import Slider from "@material-ui/core/Slider";
import BarChart from "../BarChart/BarChart";
import { Scrollbars } from "react-custom-scrollbars";
import NumberFormat from "react-number-format";
import SliderWithHistogram from "./SliderWithHistogram/SliderWithHistogram";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { roomCountMin, roomCountMax } = this.props.filterParameters;
    const { minMaxHistograms } = this.props;
    const roomCountInitial = {
      roomCountMin:
        roomCountMin === 0 ? minMaxHistograms.room_count.min : roomCountMin,
      roomCountMax:
        roomCountMax === 0 ? minMaxHistograms.room_count.max : roomCountMax,
    };
    this.props.setFilterParameters(roomCountInitial);
  }

  handleChangeFloors = (e) => {
    const { floors } = this.props.filterParameters;
    const { name, value, checked } = e.target;
    let newFloors;
    if (checked) {
      newFloors = [...floors, Number(value)];
    } else {
      newFloors = floors.filter((el) => el !== Number(value));
    }
    this.props.setFilterParameters({ [name]: newFloors });
  };

  handleChange = (e) => {
    const value = Object.keys(e).reduce((acc, key) => {
      return { ...acc, [`${key}Min`]: e[key].min, [`${key}Max`]: e[key].max };
    }, {});

    this.props.setFilterParameters(value);
  };
  handleChangeForRoomTypePercentages = (e) => {
    this.props.setFilterParametersForRoomTypePercentages(e);
  };
  validationBuildingHeight = ({ buildingHeight }) => {
    const { minMaxHistograms } = this.props;
    if (buildingHeight.min < minMaxHistograms.height.min) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.height.min} and more than ${minMaxHistograms.height.max}. While you tried to enter ${buildingHeight.min}`,
        "Error"
      );
      buildingHeight.min = minMaxHistograms.height.min;
    }
    if (buildingHeight.min > minMaxHistograms.height.max) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.height.min} and more than ${minMaxHistograms.height.max}. While you tried to enter ${buildingHeight.min}`,
        "Error"
      );
      buildingHeight.min = minMaxHistograms.height.max;
    }
    if (buildingHeight.max < minMaxHistograms.height.min) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.height.min} and more than ${minMaxHistograms.height.max}. While you tried to enter ${buildingHeight.max}`,
        "Error"
      );
      buildingHeight.max = minMaxHistograms.height.min;
    }
    if (buildingHeight.max > minMaxHistograms.height.max) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.height.min} and more than ${minMaxHistograms.height.max}. While you tried to enter ${buildingHeight.max}`,
        "Error"
      );
      buildingHeight.max = minMaxHistograms.height.max;
    }
    if (buildingHeight.min > buildingHeight.max) {
      const tempMax = buildingHeight.max;
      buildingHeight.max = buildingHeight.min;
      buildingHeight.min = tempMax;
    }
    return { buildingHeight };
  };
  validationRoomCount = ({ roomCount }) => {
    const { minMaxHistograms } = this.props;
    if (roomCount.min < minMaxHistograms.room_count.min) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.room_count.min} and more than ${minMaxHistograms.room_count.max}. While you tried to enter ${roomCount.min}`,
        "Error"
      );
      roomCount.min = minMaxHistograms.room_count.min;
    }
    if (roomCount.min > minMaxHistograms.room_count.max) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.room_count.min} and more than ${minMaxHistograms.room_count.max}. While you tried to enter ${roomCount.min}`,
        "Error"
      );
      roomCount.min = minMaxHistograms.room_count.max;
    }
    if (roomCount.max < minMaxHistograms.room_count.min) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.room_count.min} and more than ${minMaxHistograms.room_count.max}. While you tried to enter ${roomCount.max}`,
        "Error"
      );
      roomCount.max = minMaxHistograms.room_count.min;
    }
    if (roomCount.max > minMaxHistograms.room_count.max) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.room_count.min} and more than ${minMaxHistograms.room_count.max}. While you tried to enter ${roomCount.max}`,
        "Error"
      );
      roomCount.max = minMaxHistograms.room_count.max;
    }
    if (roomCount.min > roomCount.max) {
      const tempMax = roomCount.max;
      roomCount.max = roomCount.min;
      roomCount.min = tempMax;
    }
    return { roomCount };
  };
  validationFloorAreaRatio = ({ floorAreaRatio }) => {
    const { minMaxHistograms } = this.props;
    if (floorAreaRatio.max < minMaxHistograms.floor_area_ratio.min) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.floor_area_ratio.min} and more than ${minMaxHistograms.floor_area_ratio.max}. While you tried to enter ${floorAreaRatio.max}`,
        "Error"
      );
      floorAreaRatio.max = minMaxHistograms.floor_area_ratio.min;
    }
    if (floorAreaRatio.max > minMaxHistograms.floor_area_ratio.max) {
      toastr.warning(
        `The entered value cannot be less than ${minMaxHistograms.floor_area_ratio.min} and more than ${minMaxHistograms.floor_area_ratio.max}. While you tried to enter ${floorAreaRatio.max}`,
        "Error"
      );
      floorAreaRatio.max = minMaxHistograms.floor_area_ratio.max;
    }
    return { floorAreaRatio };
  };

  validationPercent = (obj) => {
    const key = Object.keys(obj)[0];
    const { family_name } = this.props.family;
    const { room_mix } = this.props.filterParameters;
    const { minMaxHistograms } = this.props;
    const otherKeys = Object.keys(room_mix).filter((el) => el !== key);
    const sumOfMin = otherKeys.reduce(
      (sum, el) => (sum += room_mix[el].min),
      0
    );

    if (obj[key].min > 0.99 - sumOfMin) {
      toastr.warning(`sum of minimums must be less than 100%`, "Error");
      obj[key].min = 0.99 - sumOfMin;
    }
    const sumOfMax = otherKeys.reduce(
      (sum, el) => (sum += room_mix[el].max),
      0
    );
    if (obj[key].max < 1.01 - sumOfMax) {
      toastr.warning(`sum of maximums must be greater than 100%`, "Error");
      obj[key].max = 1.01 - sumOfMax;
    }
    if (obj[key].min < minMaxHistograms.room_mix[key].min / 100) {
      toastr.warning(
        `Designs with less than ${minMaxHistograms.room_mix[key].min}% ${obj[key].name} is not recommended by ${family_name} for this brand.  Please contact us at support@parafin3d.com if you would like adjustments to be considered for this brand.`,
        "Error"
      );
      obj[key].min = minMaxHistograms.room_mix[key].min / 100;
    }
    if (obj[key].max > minMaxHistograms.room_mix[key].max / 100) {
      toastr.warning(
        `Designs which exceed ${minMaxHistograms.room_mix[key].max}% ${obj[key].name} rooms are not recommended by ${family_name} for this brand. Please contact us at support@parafin3d.com if you would like adjustments to be considered for this brand.`,
        "Error"
      );
      obj[key].max = minMaxHistograms.room_mix[key].max / 100;
    }
    if (obj[key].min > obj[key].max) {
      const tempMax = obj[key].max;
      obj[key].max = obj[key].min;
      obj[key].min = tempMax;
    }

    return obj;
  };

  render() {
    const {
      floorAreaRatioMax,
      buildingHeightMin,
      buildingHeightMax,
      roomCountMin,
      roomCountMax,
      room_mix,
      floors,
    } = this.props.filterParameters;
    const { height, room_count, floor_area_ratio } =
      this.props.minMaxHistograms;

    const { error, minMaxHistograms, filterHistograms, roomMixKeys } =
      this.props;

    return (
      <section className="filters">
        <Scrollbars
          id="panContainer"
          autoHide
          autoHideTimeout={300}
          autoHideDuration={300}
          // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
          style={{ height: "calc(100vh - 80px)" }}
          renderTrackHorizontal={this.renderTrackHorizontal}
          universal={false}
        >
          <div className="building pb-5">
            <h3 className="title">Building Filters</h3>
            <div className="mx-auto mt-4">
              <h5 className="name">Building Total Floors</h5>
              <ul className="floor-list">
                <li className="floor-list-item">
                  <input
                    className="visuallyHidden"
                    type="checkbox"
                    checked={floors.includes(3)}
                    onChange={this.handleChangeFloors}
                    name="floors"
                    value={3}
                    id="floor_number_3"
                  />
                  <label htmlFor="floor_number_3">3</label>
                </li>
                <li className="floor-list-item">
                  <input
                    className="visuallyHidden"
                    type="checkbox"
                    checked={floors.includes(4)}
                    onChange={this.handleChangeFloors}
                    name="floors"
                    value={4}
                    id="floor_number_4"
                  />
                  <label htmlFor="floor_number_4">4</label>
                </li>
                <li className="floor-list-item">
                  <input
                    className="visuallyHidden"
                    type="checkbox"
                    checked={floors.includes(5)}
                    onChange={this.handleChangeFloors}
                    name="floors"
                    value={5}
                    id="floor_number_5"
                  />
                  <label htmlFor="floor_number_5">5</label>
                </li>
                <li className="floor-list-item">
                  <input
                    className="visuallyHidden"
                    type="checkbox"
                    checked={floors.includes(6)}
                    onChange={this.handleChangeFloors}
                    name="floors"
                    value={6}
                    id="floor_number_6"
                  />
                  <label htmlFor="floor_number_6">6</label>
                </li>
              </ul>
            </div>
            <div className="mx-auto mt-3">
              <h5 className="name">Building Height</h5>
              <BarChart
                data={height}
                highlight={[buildingHeightMin, buildingHeightMax]}
                domain={[minMaxHistograms.height, minMaxHistograms.height]}
                step={1}
              />
              <Slider
                value={[buildingHeightMin, buildingHeightMax]}
                onChange={(mouseEvent, buildingHeight) => {
                  return this.handleChange({
                    buildingHeight: {
                      min: buildingHeight[0],
                      max: buildingHeight[1],
                    },
                  });
                }}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                track="normal"
                min={minMaxHistograms.height.min}
                step={1}
                max={minMaxHistograms.height.max}
              />

              <div className="textInput">
                <div className="inputContainer">
                  <NumberFormat
                    className="inputFT"
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={""}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="BuildingHeightMin"
                    value={buildingHeightMin}
                    onValueChange={({ floatValue = 0 }) => {
                      return this.handleChange({
                        buildingHeight: {
                          min: Number(floatValue.toFixed(0)),
                          max: buildingHeightMax,
                        },
                      });
                    }}
                    onBlur={() => {
                      const floatValue = this.validationBuildingHeight({
                        buildingHeight: {
                          min: buildingHeightMin,
                          max: buildingHeightMax,
                        },
                      });
                      this.handleChange(floatValue);
                    }}
                  />
                  <label htmlFor="BuildingHeightMin" className="inputSuffixFT">
                    ft
                  </label>
                </div>
                -
                <div className="inputContainer">
                  <NumberFormat
                    className="inputFT"
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="BuildingHeightMax"
                    value={buildingHeightMax}
                    onValueChange={({ floatValue = 0 }) => {
                      return this.handleChange({
                        buildingHeight: {
                          min: buildingHeightMin,
                          max: Number(floatValue.toFixed(0)),
                        },
                      });
                    }}
                    onBlur={() => {
                      const floatValue = this.validationBuildingHeight({
                        buildingHeight: {
                          min: buildingHeightMin,
                          max: buildingHeightMax,
                        },
                      });
                      this.handleChange(floatValue);
                    }}
                  />
                  <label htmlFor="BuildingHeightMax" className="inputSuffixFT">
                    ft
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-3">
              <h5 className="name">Total Keys</h5>
              <BarChart
                data={room_count}
                highlight={[roomCountMin, roomCountMax]}
                domain={[
                  minMaxHistograms.room_count.min,
                  minMaxHistograms.room_count.max,
                ]}
                step={1}
              />
              <Slider
                value={[roomCountMin, roomCountMax]}
                onChange={(mouseEvent, roomCount) => {
                  this.handleChange({
                    roomCount: {
                      min: roomCount[0],
                      max: roomCount[1],
                    },
                  });
                }}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                track="normal"
                min={minMaxHistograms.room_count.min}
                step={1}
                max={minMaxHistograms.room_count.max}
              />

              <div className="textInput">
                <NumberFormat
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  prefix={""}
                  decimalScale={0}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  value={roomCountMin}
                  onValueChange={({ floatValue = 0 }) => {
                    return this.handleChange({
                      roomCount: {
                        min: Number(floatValue.toFixed(0)),
                        max: roomCountMax,
                      },
                    });
                  }}
                  onBlur={() => {
                    const floatValue = this.validationRoomCount({
                      roomCount: {
                        min: roomCountMin,
                        max: roomCountMax,
                      },
                    });
                    this.handleChange(floatValue);
                  }}
                />
                -
                <NumberFormat
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  decimalScale={0}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  value={roomCountMax}
                  onValueChange={({ floatValue = 0 }) => {
                    return this.handleChange({
                      roomCount: {
                        min: roomCountMin,
                        max: Number(floatValue.toFixed(0)),
                      },
                    });
                  }}
                  onBlur={() => {
                    const floatValue = this.validationRoomCount({
                      roomCount: {
                        min: roomCountMin,
                        max: roomCountMax,
                      },
                    });
                    this.handleChange(floatValue);
                  }}
                />
              </div>
            </div>
            <div className="mx-auto mt-3">
              <h5 className="name">Floor Area Ratio</h5>
              <BarChart
                data={floor_area_ratio}
                highlight={[0, floorAreaRatioMax]}
                domain={[
                  minMaxHistograms.floor_area_ratio.min,
                  minMaxHistograms.floor_area_ratio.max,
                ]}
                step={0.1}
              />
              <Slider
                value={floorAreaRatioMax}
                onChange={(mouseEvent, floorAreaRatio) => {
                  this.handleChange({
                    floorAreaRatio: {
                      // min: floorAreaRatio[0],
                      max: floorAreaRatio,
                    },
                  });
                }}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                track="normal"
                min={minMaxHistograms.floor_area_ratio.min}
                step={0.1}
                max={minMaxHistograms.floor_area_ratio.max}
              />

              <div className="textInput">
                <NumberFormat
                  className="inputRight"
                  thousandSeparator={true}
                  decimalSeparator={"."}
                  thousandsGroupStyle={"thousand"}
                  displayType={"input"}
                  decimalScale={1}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  allowLeadingZeros={false}
                  value={floorAreaRatioMax}
                  onValueChange={({ floatValue = 0 }) => {
                    return this.handleChange({
                      floorAreaRatio: {
                        max: Number(floatValue.toFixed(1)),
                      },
                    });
                  }}
                  onBlur={() => {
                    const floatValue = this.validationFloorAreaRatio({
                      floorAreaRatio: {
                        max: floorAreaRatioMax,
                      },
                    });
                    this.handleChange(floatValue);
                  }}
                />
              </div>
            </div>
          </div>

          {room_mix && (
            <div className="room mt-2 pb-5">
              <h3 className="title">Room Mix Filters</h3>
              {roomMixKeys.map((key) => (
                <SliderWithHistogram
                  key={key}
                  id={key}
                  filterParameter={room_mix[key]}
                  filterHistogram={filterHistograms.room_mix[key]}
                  minMaxHistogram={minMaxHistograms.room_mix[key]}
                  handleChangeForRoomTypePercentages={
                    this.handleChangeForRoomTypePercentages
                  }
                  validationPercent={this.validationPercent}
                />
              ))}
            </div>
          )}
        </Scrollbars>
      </section>
    );
  }
}

export default Filters;
