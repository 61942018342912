import React, { Component } from "react";
import styles from "./Revenue.module.sass";
import NumberFormat from "react-number-format";
import toastr from "toastr";
import { Scrollbars } from "react-custom-scrollbars";
import Tooltip from "../../../../TooltipLeftBottom/TooltipLeftBottom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Svg from "../../../../Svg/Svg";
import { v4 as uuidv4 } from "uuid";
import { setGuestRoomsRevenueByType } from "../../../../../redux/Filters/filtersActions";

const percentFormat = (value) => Number((value * 100).toFixed(5));

const revenueInputMode = {
  BLENDED: true,
  UNBLENDED: false,
};

const rev_meetings_units = {
  GSF_MEETINGS: "per_gsf_meetings",
  OR: "per_operating_room_night",
  PERCENT: "percent_rev_rooms",
  TOTAL: "total",
};
const rev_fb_units = {
  GSF_F_AND_B: "per_gsf_f_b",
  OR: "per_operating_room_night",
  PERCENT: "percent_rev_rooms",
  TOTAL: "total",
};
const rev_custom_input_units = {
  PER_KEY: "per_key",
  OR: "per_occ_room",
  PERCENT: "percent_rev_rooms",
  TOTAL: "total_annual",
};

class Revenue extends Component {
  constructor(props) {
    super(props);
    const { rev_rooms_adr_list, rev_rooms_occ_list } =
      this.props.projectInfo.revenue_assumptions;
    this.state = {
      rev_rooms_adr_list,
      rev_rooms_occ_list,
    };
  }

  percentIntegerFormat = (value) => `${(value * 100).toFixed(0)}`;
  dollarFloatFormat = (value) => {
    const retVal = `${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value)}`;
    return retVal;
  };
  dollarFloatFormatMeetingRooms = (value, rev_meetings_units) => {
    if (rev_meetings_units === "per_gsf_meetings") {
      const retVal = `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value)} /sf`;

      return retVal;
    } else {
      const retVal = `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value)} POR`;

      return retVal;
    }
  };

  dollarFloatFormatFoodAndBeverage = (value, rev_fb_units) => {
    if (rev_fb_units === "per_gsf_f_b") {
      const retVal = `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value)} /sf`;

      return retVal;
    } else {
      const retVal = `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value)} POR`;

      return retVal;
    }
  };

  validationOCC = (floatValue) => {
    if (floatValue < 0.1) {
      toastr.warning(
        `The entered value cannot be less than 0.1 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0.1;
    } else if (floatValue > 100) {
      toastr.warning(
        `The entered value cannot be less than 0.1 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100;
    } else {
      return floatValue;
    }
  };
  handleChangeRevRoomsOcc =
    (names) =>
    ({ floatValue }) => {
      const { rev_rooms_occ_list } = this.props.projectInfo.revenue_assumptions;
      const { by_type } = this.props.filters.tablesForModel.guestrooms_revenue;
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccList(
        rev_rooms_occ_list.map((el) => {
          return names.includes(el.key)
            ? { ...el, value: floatValue / 100 }
            : el;
        })
      );
      this.props.setGuestRoomsRevenueByType(
        by_type.map((el) => {
          return names.includes(el.key)
            ? { ...el, occup: floatValue / 100 }
            : el;
        })
      );
    };
  handleChangeRevRoomsOccOnBlur =
    (names) =>
    ({ floatValue }) => {
      const { rev_rooms_occ_list } = this.props.projectInfo.revenue_assumptions;
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur(
        rev_rooms_occ_list.map((el) => {
          return names.includes(el.key)
            ? { ...el, value: floatValue / 100 }
            : el;
        })
      );
      this.setState((prev) => {
        return {
          rev_rooms_occ_list: prev.rev_rooms_occ_list.map((el) => {
            return names.includes(el.key)
              ? { ...el, value: floatValue / 100 }
              : el;
          }),
        };
      });
    };
  handleChangeRevRoomsOccOnBlurBlended = ({ floatValue }) => {
    const { rev_rooms_occ_list } = this.props.projectInfo.revenue_assumptions;
    this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur(
      rev_rooms_occ_list.map((el) => {
        return { ...el, value: floatValue / 100 };
      })
    );
  };

  validationADR = (floatValue) => {
    if (floatValue < 0.01) {
      toastr.warning(
        `The entered value cannot be less than 0.01 and more than 1000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0.01;
    } else if (floatValue > 1000) {
      toastr.warning(
        `The entered value cannot be less than 0.01 and more than 1000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 1000;
    } else {
      return floatValue;
    }
  };
  handleChangeRevRoomsAdr =
    (names) =>
    ({ floatValue }) => {
      console.log(floatValue);
      const { rev_rooms_adr_list } = this.props.projectInfo.revenue_assumptions;
      const { by_type } = this.props.filters.tablesForModel.guestrooms_revenue;
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrList(
        rev_rooms_adr_list.map((el) => {
          return names.includes(el.key) ? { ...el, value: floatValue } : el;
        })
      );
      this.props.setGuestRoomsRevenueByType(
        by_type.map((el) => {
          return names.includes(el.key) ? { ...el, adr: floatValue } : el;
        })
      );
    };
  handleChangeRevRoomsAdrOnBlur =
    (names) =>
    ({ floatValue }) => {
      const { rev_rooms_adr_list } = this.props.projectInfo.revenue_assumptions;
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur(
        rev_rooms_adr_list.map((el) => {
          return names.includes(el.key) ? { ...el, value: floatValue } : el;
        })
      );
      this.setState((prev) => {
        return {
          rev_rooms_adr_list: prev.rev_rooms_adr_list.map((el) => {
            return names.includes(el.key) ? { ...el, value: floatValue } : el;
          }),
        };
      });
    };
  handleChangeRevRoomsAdrOnBlurBlended = ({ floatValue }) => {
    const { rev_rooms_adr_list } = this.props.projectInfo.revenue_assumptions;
    this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur(
      rev_rooms_adr_list.map((el) => {
        return { ...el, value: floatValue };
      })
    );
  };

  handleChangeRevFb =
    (names) =>
    ({ floatValue }) => {
      const { rev_fb_units } = this.props.projectInfo.revenue_assumptions;
      if (rev_fb_units === "percent_rev_rooms") {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevFb(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      } else {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevFb(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      }
    };

  handleChangeRevFbOnBlur =
    (names) =>
    ({ floatValue }) => {
      const { rev_fb_units } = this.props.projectInfo.revenue_assumptions;
      if (rev_fb_units === "percent_rev_rooms") {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      } else {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      }
    };

  handleChangeRevMeetings =
    (names) =>
    ({ floatValue }) => {
      const { rev_meetings_units } = this.props.projectInfo.revenue_assumptions;
      if (rev_meetings_units === "percent_rev_rooms") {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevMeetings(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      } else {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevMeetings(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      }
    };

  handleChangeRevMeetingsOnBlur =
    (names) =>
    ({ floatValue }) => {
      const { rev_meetings_units } = this.props.projectInfo.revenue_assumptions;
      if (rev_meetings_units === "percent_rev_rooms") {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      } else {
        this.props.setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      }
    };

  handleChangeRadioBlended = (e) => {
    const { revenue_assumptions } = this.props.projectInfo;
    const { name, value } = e.target;
    this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
      [name]: value === "true" ? true : false,
    });

    if (value === "true") {
      const floatValueADR = this.validationADR(
        revenue_assumptions.default_blended_adr
      );
      this.handleChangeRevRoomsAdrOnBlurBlended({ floatValue: floatValueADR });

      const floatValueOCC = this.validationOCC(
        percentFormat(revenue_assumptions.default_blended_occ)
      );
      this.handleChangeRevRoomsOccOnBlurBlended({ floatValue: floatValueOCC });
    } else {
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur([
        ...this.state.rev_rooms_adr_list,
      ]);
      this.props.setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur([
        ...this.state.rev_rooms_occ_list,
      ]);
    }
  };

  handleChangeRadio = (e) => {
    const { name, value } = e.target;
    this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
      [name]: value,
    });
  };

  addCustomRevenueLine = (e) => {
    const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
      ? this.props.projectInfo.revenue_assumptions.rev_custom
      : [];
    const id = uuidv4(); //create uniq id
    let label = "";
    let name = "";
    let isRevenueCustomIncludeThisLabel = true;
    for (let i = rev_custom.length + 1; isRevenueCustomIncludeThisLabel; i++) {
      label = `Custom Revenue ${i}`;
      name = `Custom ${i}`;
      isRevenueCustomIncludeThisLabel = false;
      rev_custom.map((el) => {
        if (el.label === label) {
          isRevenueCustomIncludeThisLabel = true;
        }
      });
    }
    this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
      rev_custom: [
        ...rev_custom,
        { id, name, value: 0, label, units: rev_custom_input_units.PER_KEY },
      ],
    });
  };

  onClose = (id) => {
    const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
      ? this.props.projectInfo.revenue_assumptions.rev_custom
      : [];

    this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
      rev_custom: rev_custom.filter((el) => el.id !== id),
    });
  };

  handleChangeRevenueAssumptionsKeyRevenueCustomValue =
    ({ id, units }) =>
    ({ floatValue }) => {
      const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
        ? this.props.projectInfo.revenue_assumptions.rev_custom
        : [];

      this.props.setProjectInfoKeyRevenueAssumptions({
        rev_custom: rev_custom.map((obj) => {
          if (obj.id === id) {
            return {
              ...obj,
              value:
                units === "percent_rev_rooms" ? floatValue / 100 : floatValue,
            };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeRevenueAssumptionsKeyRevenueCustomValueOnBlur =
    ({ id, units }) =>
    ({ floatValue }) => {
      const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
        ? this.props.projectInfo.revenue_assumptions.rev_custom
        : [];

      this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
        rev_custom: rev_custom.map((obj) => {
          if (obj.id === id) {
            return {
              ...obj,
              value:
                units === "percent_rev_rooms" ? floatValue / 100 : floatValue,
            };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeRevenueAssumptionsKeyRevenueCustomLabel =
    (id) =>
    ({ target }) => {
      const { value } = target;

      const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
        ? this.props.projectInfo.revenue_assumptions.rev_custom
        : [];
      this.props.setProjectInfoKeyRevenueAssumptions({
        rev_custom: rev_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, label: value };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeRevenueAssumptionsKeyRevenueCustomLabelOnBlur =
    (id) =>
    ({ target }) => {
      const { value } = target;

      const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
        ? this.props.projectInfo.revenue_assumptions.rev_custom
        : [];
      this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
        rev_custom: rev_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, label: value };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  validationRevenueCustomLabel = ({ id, label }) => {
    const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
      ? this.props.projectInfo.revenue_assumptions.rev_custom
      : [];

    let isRevenueCustomIncludeThisLabel = false;
    let newLabel = "";

    rev_custom.map((el) => {
      if (el.label === label && el.id !== id) {
        isRevenueCustomIncludeThisLabel = true;
      }
    });

    if (isRevenueCustomIncludeThisLabel) {
      toastr.warning(
        `The entered string cannot be the same as there is in other inputs. While you tried to enter ${label}`,
        "Error"
      );
      for (let i = 1; isRevenueCustomIncludeThisLabel; i++) {
        newLabel = `Custom Revenue ${i}`;
        isRevenueCustomIncludeThisLabel = false;
        rev_custom.map((el) => {
          if (el.label === newLabel) {
            isRevenueCustomIncludeThisLabel = true;
          }
        });
      }
      return { value: newLabel };
    } else {
      return { value: label };
    }
  };

  handleChangeRadioCustomInput = (id) => (e) => {
    const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
      ? this.props.projectInfo.revenue_assumptions.rev_custom
      : [];
    const { value } = e.target;
    this.props.setProjectInfoKeyRevenueAssumptionsOnBlur({
      rev_custom: rev_custom.map((obj) => {
        if (obj.id === id) {
          return { ...obj, units: value };
        } else {
          return { ...obj };
        }
      }),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.reset();
  };

  render() {
    const { revenue_assumptions } = this.props.projectInfo;
    console.log(this.props);
    const rev_custom = this.props.projectInfo.revenue_assumptions.rev_custom
      ? this.props.projectInfo.revenue_assumptions.rev_custom
      : [];
    const { rev_rooms_adr_list, rev_rooms_occ_list } =
      this.props.projectInfo.revenue_assumptions;
    const rev_rooms_adr_list_keys = rev_rooms_adr_list.map((item) => item.key);
    const rev_rooms_occ_list_keys = rev_rooms_occ_list.map((item) => item.key);

    return (
      <section className={styles.revenue}>
        <Scrollbars
          id="panContainer"
          autoHide
          autoHideTimeout={300}
          autoHideDuration={300}
          // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
          style={{ height: "calc(100vh - 80px)" }}
          renderTrackHorizontal={this.renderTrackHorizontal}
          universal={false}
        >
          <div className={styles.blockPanel} onSubmit={this.handleSubmit}>
            <h3 className={styles.title}>Revenue</h3>
            <div className="mx-auto">
              <div className={styles.radioBlended}>
                <input
                  type="radio"
                  id="BLENDED"
                  checked={
                    revenue_assumptions.blended_revenue ===
                    revenueInputMode.BLENDED
                  }
                  name="blended_revenue"
                  value={revenueInputMode.BLENDED}
                  onChange={this.handleChangeRadioBlended}
                />
                <label htmlFor="BLENDED">Blended</label>
                <input
                  type="radio"
                  id="UNBLENDED"
                  checked={
                    revenue_assumptions.blended_revenue ===
                    revenueInputMode.UNBLENDED
                  }
                  name="blended_revenue"
                  value={revenueInputMode.UNBLENDED}
                  onChange={this.handleChangeRadioBlended}
                />
                <label htmlFor="UNBLENDED">Unblended</label>
                <Tooltip
                  title=""
                  text="omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem."
                />
              </div>
            </div>
            {revenue_assumptions.blended_revenue ? (
              <>
                <div className="mx-auto">
                  <div className={styles.textInput}>
                    <div>
                      <label className={styles.titleOfInput} htmlFor="ADR">
                        ADR
                      </label>
                      <NumberFormat
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        prefix={"$"}
                        decimalScale={2}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="ADR"
                        value={revenue_assumptions.rev_rooms_adr_list[0].value}
                        onValueChange={this.handleChangeRevRoomsAdr(
                          rev_rooms_adr_list_keys
                        )}
                        onBlur={() => {
                          const floatValue = this.validationADR(
                            revenue_assumptions.rev_rooms_adr_list[0].value
                          );
                          this.handleChangeRevRoomsAdrOnBlurBlended({
                            floatValue,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label
                        className={styles.titleOfInput}
                        htmlFor="Occupancy"
                      >
                        Occupancy
                      </label>
                      <div className={styles.inputContainer}>
                        <NumberFormat
                          className={styles.inputPercent}
                          thousandSeparator={true}
                          decimalSeparator={"."}
                          thousandsGroupStyle={"thousand"}
                          displayType={"input"}
                          decimalScale={1}
                          allowNegative={false}
                          fixedDecimalScale={true}
                          allowEmptyFormatting={true}
                          allowLeadingZeros={false}
                          id="Occupancy"
                          value={percentFormat(
                            revenue_assumptions.rev_rooms_occ_list[0].value
                          )}
                          onValueChange={this.handleChangeRevRoomsOcc(
                            rev_rooms_occ_list_keys
                          )}
                          onBlur={() => {
                            const floatValue = this.validationOCC(
                              percentFormat(
                                revenue_assumptions.rev_rooms_occ_list[0].value
                              )
                            );
                            this.handleChangeRevRoomsOccOnBlurBlended({
                              floatValue,
                            });
                          }}
                        />
                        <label
                          htmlFor="Occupancy"
                          className={styles.inputSuffixPercent}
                        >
                          %
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.infoMessage} mx-auto`}>
                  <InfoOutlinedIcon
                    style={{
                      color: "#7f7f7f",
                      fontSize: 20,
                      display: "flex",
                      margin: "auto",
                    }}
                  />
                  <p>
                    ab illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo.
                  </p>
                  <p>
                    <span>Unblended</span>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut fugit.
                  </p>
                </div>
              </>
            ) : (
              <>
                {!!revenue_assumptions.rev_rooms_adr_list &&
                  rev_rooms_adr_list_keys.length !== 0 &&
                  rev_rooms_adr_list_keys.map((key) => {
                    return (
                      <div className="mx-auto" key={key}>
                        <div className={styles.textInput}>
                          <div>
                            <label
                              className={styles.titleOfInput}
                              htmlFor={`${key}ADR`}
                            >
                              {
                                revenue_assumptions.rev_rooms_adr_list.filter(
                                  (el) => key === el.key
                                )[0].name
                              }
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalSeparator={"."}
                              thousandsGroupStyle={"thousand"}
                              displayType={"input"}
                              prefix={"$"}
                              decimalScale={2}
                              allowNegative={false}
                              fixedDecimalScale={true}
                              allowEmptyFormatting={true}
                              allowLeadingZeros={false}
                              id={`${key}ADR`}
                              value={
                                revenue_assumptions.rev_rooms_adr_list.filter(
                                  (el) => key === el.key
                                )[0].value
                              }
                              onValueChange={this.handleChangeRevRoomsAdr([
                                key,
                              ])}
                              onBlur={() => {
                                const floatValue = this.validationADR(
                                  revenue_assumptions.rev_rooms_adr_list.filter(
                                    (el) => key === el.key
                                  )[0].value
                                );
                                this.handleChangeRevRoomsAdrOnBlur([key])({
                                  floatValue,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <label
                              className={styles.titleOfInput}
                              htmlFor={`${key}Occup`}
                            >
                              {
                                revenue_assumptions.rev_rooms_occ_list.filter(
                                  (el) => key === el.key
                                )[0].name
                              }
                            </label>
                            <div className={styles.inputContainer}>
                              <NumberFormat
                                className={styles.inputPercent}
                                thousandSeparator={true}
                                decimalSeparator={"."}
                                thousandsGroupStyle={"thousand"}
                                displayType={"input"}
                                decimalScale={1}
                                allowNegative={false}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                                allowLeadingZeros={false}
                                id={`${key}Occup`}
                                value={percentFormat(
                                  revenue_assumptions.rev_rooms_occ_list.filter(
                                    (el) => key === el.key
                                  )[0].value
                                )}
                                onValueChange={this.handleChangeRevRoomsOcc([
                                  key,
                                ])}
                                onBlur={() => {
                                  const floatValue = this.validationOCC(
                                    percentFormat(
                                      revenue_assumptions.rev_rooms_occ_list.filter(
                                        (el) => key === el.key
                                      )[0].value
                                    )
                                  );
                                  this.handleChangeRevRoomsOccOnBlur([key])({
                                    floatValue,
                                  });
                                }}
                              />
                              <label
                                htmlFor={`${key}Occup`}
                                className={styles.inputSuffixPercent}
                              >
                                %
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
            {/* <button className={styles.submit} type="submit">
            <Svg icon="RoundArrow" className={styles.roundArrow} />
          </button> */}
          </div>
          <div className={styles.blockPanel} onSubmit={this.handleSubmit}>
            <h3 className={styles.title}>sed quia consequuntur</h3>

            <div className="mx-auto">
              <h5 className={styles.name}>magni dolores</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.MeetingRooms}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={
                      revenue_assumptions.rev_meetings_units ===
                      "percent_rev_rooms"
                        ? ""
                        : "$"
                    }
                    decimalScale={
                      revenue_assumptions.rev_meetings_units ===
                      "percent_rev_rooms"
                        ? 1
                        : 0
                    }
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="rev_meetings"
                    value={
                      revenue_assumptions.rev_meetings_units ===
                      "percent_rev_rooms"
                        ? percentFormat(
                            revenue_assumptions.rev_meetings[
                              revenue_assumptions.rev_meetings_units
                            ]
                          )
                        : revenue_assumptions.rev_meetings[
                            revenue_assumptions.rev_meetings_units
                          ]
                    }
                    onValueChange={this.handleChangeRevMeetings([
                      revenue_assumptions.rev_meetings_units,
                    ])}
                    onBlur={() =>
                      this.handleChangeRevMeetingsOnBlur([
                        revenue_assumptions.rev_meetings_units,
                      ])({
                        floatValue:
                          revenue_assumptions.rev_meetings_units ===
                          "percent_rev_rooms"
                            ? percentFormat(
                                revenue_assumptions.rev_meetings[
                                  revenue_assumptions.rev_meetings_units
                                ]
                              )
                            : revenue_assumptions.rev_meetings[
                                revenue_assumptions.rev_meetings_units
                              ],
                      })
                    }
                  />
                  <label htmlFor="rev_meetings" className={styles.inputSuffix}>
                    {revenue_assumptions.rev_meetings_units ===
                    "per_gsf_meetings"
                      ? "/sf"
                      : revenue_assumptions.rev_meetings_units ===
                        "per_operating_room_night"
                      ? "POR"
                      : revenue_assumptions.rev_meetings_units ===
                        "percent_rev_rooms"
                      ? "%"
                      : ""}
                  </label>
                </div>
                {/* <select
                name="rev_meetings_units"
                value={revenue_assumptions.rev_meetings_units}
                onChange={this.handleChangeSelect}
              >
                <option value="per_gsf_meetings">
                  $/sf of Meeting Rooms Space
                </option>
                <option value="per_operating_room_night">$ per operating room night</option>
              </select> */}
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  id="rev_meetings_unitsOne"
                  checked={
                    revenue_assumptions.rev_meetings_units ===
                    rev_meetings_units.GSF_MEETINGS
                  }
                  name="rev_meetings_units"
                  value={rev_meetings_units.GSF_MEETINGS}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_meetings_unitsOne">Per gsf meetings</label>
                <input
                  type="radio"
                  id="rev_meetings_unitsTwo"
                  checked={
                    revenue_assumptions.rev_meetings_units ===
                    rev_meetings_units.OR
                  }
                  name="rev_meetings_units"
                  value={rev_meetings_units.OR}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_meetings_unitsTwo">
                  Per operating room night
                </label>
                <input
                  type="radio"
                  id="rev_meetings_unitsFour"
                  checked={
                    revenue_assumptions.rev_meetings_units ===
                    rev_meetings_units.PERCENT
                  }
                  name="rev_meetings_units"
                  value={rev_meetings_units.PERCENT}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_meetings_unitsFour">
                  Percent rev rooms
                </label>
                <input
                  type="radio"
                  id="rev_meetings_unitsThree"
                  checked={
                    revenue_assumptions.rev_meetings_units ===
                    rev_meetings_units.TOTAL
                  }
                  name="rev_meetings_units"
                  value={rev_meetings_units.TOTAL}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_meetings_unitsThree">Total</label>
              </div>
            </div>
            <div className="mx-auto">
              <h5 className={styles.name}>ipsum quia dolor</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.MeetingRooms}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={
                      revenue_assumptions.rev_fb_units === "percent_rev_rooms"
                        ? ""
                        : "$"
                    }
                    decimalScale={
                      revenue_assumptions.rev_fb_units === "percent_rev_rooms"
                        ? 1
                        : 0
                    }
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="rev_fb"
                    value={
                      revenue_assumptions.rev_fb_units === "percent_rev_rooms"
                        ? percentFormat(
                            revenue_assumptions.rev_fb[
                              revenue_assumptions.rev_fb_units
                            ]
                          )
                        : revenue_assumptions.rev_fb[
                            revenue_assumptions.rev_fb_units
                          ]
                    }
                    onValueChange={this.handleChangeRevFb([
                      revenue_assumptions.rev_fb_units,
                    ])}
                    onBlur={() =>
                      this.handleChangeRevFbOnBlur([
                        revenue_assumptions.rev_fb_units,
                      ])({
                        floatValue:
                          revenue_assumptions.rev_fb_units ===
                          "percent_rev_rooms"
                            ? percentFormat(
                                revenue_assumptions.rev_fb[
                                  revenue_assumptions.rev_fb_units
                                ]
                              )
                            : revenue_assumptions.rev_fb[
                                revenue_assumptions.rev_fb_units
                              ],
                      })
                    }
                  />
                  <label htmlFor="rev_fb" className={styles.inputSuffix}>
                    {revenue_assumptions.rev_fb_units === "per_gsf_f_b"
                      ? "/sf"
                      : revenue_assumptions.rev_fb_units ===
                        "per_operating_room_night"
                      ? "POR"
                      : revenue_assumptions.rev_fb_units === "percent_rev_rooms"
                      ? "%"
                      : ""}
                  </label>
                </div>
                {/* <select
                name="rev_fb_units"
                value={revenue_assumptions.rev_fb_units}
                onChange={this.handleChangeSelect}
              >
                <option value="per_gsf_f_b">$/sf of F&B FOH Space</option>
                <option value="per_operating_room_night">$ per operating room night</option>
              </select> */}
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  id="rev_fb_unitsOne"
                  checked={
                    revenue_assumptions.rev_fb_units ===
                    rev_fb_units.GSF_F_AND_B
                  }
                  name="rev_fb_units"
                  value={rev_fb_units.GSF_F_AND_B}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_fb_unitsOne">sit amet, consectetur</label>
                <input
                  type="radio"
                  id="rev_fb_unitsTwo"
                  checked={revenue_assumptions.rev_fb_units === rev_fb_units.OR}
                  name="rev_fb_units"
                  value={rev_fb_units.OR}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_fb_unitsTwo">adipisci velit, sed</label>
                <input
                  type="radio"
                  id="rev_fb_unitsFour"
                  checked={
                    revenue_assumptions.rev_fb_units === rev_fb_units.PERCENT
                  }
                  name="rev_fb_units"
                  value={rev_fb_units.PERCENT}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_fb_unitsFour">quia non numquam eius</label>
                <input
                  type="radio"
                  id="rev_fb_unitsThree"
                  checked={
                    revenue_assumptions.rev_fb_units === rev_fb_units.TOTAL
                  }
                  name="rev_fb_units"
                  value={rev_fb_units.TOTAL}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="rev_fb_unitsThree">modi tempora</label>
              </div>
            </div>
            {rev_custom.length !== 0 &&
              rev_custom.map((revCustomInput, index) => (
                <div className="mx-auto" key={index}>
                  <h5 className={styles.name}>{revCustomInput.name}</h5>
                  <div className={styles.textInput}>
                    <input
                      className={styles.CustomInputLabel}
                      type="text"
                      value={revCustomInput.label}
                      onChange={this.handleChangeRevenueAssumptionsKeyRevenueCustomLabel(
                        revCustomInput.id
                      )}
                      onBlur={() => {
                        const target = this.validationRevenueCustomLabel({
                          id: revCustomInput.id,
                          label: revCustomInput.label,
                        });
                        this.handleChangeRevenueAssumptionsKeyRevenueCustomLabelOnBlur(
                          revCustomInput.id
                        )({ target });
                      }}
                    ></input>
                    <button
                      className={styles.CustomButtonClose}
                      onClick={() => this.onClose(revCustomInput.id)}
                    >
                      <Svg icon="Close" className={styles.icon} />
                    </button>
                  </div>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.MeetingRooms}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        prefix={
                          revCustomInput.units === "percent_rev_rooms"
                            ? ""
                            : "$"
                        }
                        decimalScale={
                          revCustomInput.units === "percent_rev_rooms" ? 1 : 0
                        }
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id={"CustomInputValue_" + revCustomInput.id}
                        value={
                          revCustomInput.units === "percent_rev_rooms"
                            ? percentFormat(revCustomInput.value)
                            : revCustomInput.value
                        }
                        onValueChange={this.handleChangeRevenueAssumptionsKeyRevenueCustomValue(
                          { id: revCustomInput.id, units: revCustomInput.units }
                        )}
                        onBlur={() =>
                          this.handleChangeRevenueAssumptionsKeyRevenueCustomValueOnBlur(
                            {
                              id: revCustomInput.id,
                              units: revCustomInput.units,
                            }
                          )({
                            floatValue:
                              revCustomInput.units === "percent_rev_rooms"
                                ? percentFormat(revCustomInput.value)
                                : revCustomInput.value,
                          })
                        }
                      />
                      <label
                        htmlFor={"CustomInputValue_" + revCustomInput.id}
                        className={styles.inputSuffix}
                      >
                        {revCustomInput.units === "per_gsf_f_b"
                          ? "/sf"
                          : revCustomInput.units === "per_operating_room_night"
                          ? "POR"
                          : revCustomInput.units === "percent_rev_rooms"
                          ? "%"
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className={styles.radio}>
                    <input
                      type="radio"
                      id={"unitsOne_" + revCustomInput.id}
                      checked={
                        revCustomInput.units === rev_custom_input_units.PER_KEY
                      }
                      value={rev_custom_input_units.PER_KEY}
                      onChange={this.handleChangeRadioCustomInput(
                        revCustomInput.id
                      )}
                    />
                    <label htmlFor={"unitsOne_" + revCustomInput.id}>
                      ncidunt ut labore
                    </label>
                    <input
                      type="radio"
                      id={"unitsTwo_" + revCustomInput.id}
                      checked={
                        revCustomInput.units === rev_custom_input_units.OR
                      }
                      value={rev_custom_input_units.OR}
                      onChange={this.handleChangeRadioCustomInput(
                        revCustomInput.id
                      )}
                    />
                    <label htmlFor={"unitsTwo_" + revCustomInput.id}>
                      dolore magnam
                    </label>
                    <input
                      type="radio"
                      id={"unitsFour_" + revCustomInput.id}
                      checked={
                        revCustomInput.units === rev_custom_input_units.PERCENT
                      }
                      value={rev_custom_input_units.PERCENT}
                      onChange={this.handleChangeRadioCustomInput(
                        revCustomInput.id
                      )}
                    />
                    <label htmlFor={"unitsFour_" + revCustomInput.id}>
                      aliquam quaerat
                    </label>
                    <input
                      type="radio"
                      id={"unitsThree_" + revCustomInput.id}
                      checked={
                        revCustomInput.units === rev_custom_input_units.TOTAL
                      }
                      value={rev_custom_input_units.TOTAL}
                      onChange={this.handleChangeRadioCustomInput(
                        revCustomInput.id
                      )}
                    />
                    <label htmlFor={"unitsThree_" + revCustomInput.id}>
                      Ut enim ad
                    </label>
                  </div>
                </div>
              ))}

            <div className="mx-auto">
              <button
                className={styles.customRevenueLine}
                onClick={this.addCustomRevenueLine}
              >
                <Svg icon="Plus" className={styles.add} />
                <p>Add new</p>
              </button>
            </div>
          </div>
        </Scrollbars>
      </section>
    );
  }
}

export default Revenue;
