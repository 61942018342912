import React from "react";
import PropTypes from "prop-types";

const Envelope = ({ className }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.125 3.28125L7.5 8.4375L1.875 3.28125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.875 3.28125H13.125V11.25C13.125 11.3743 13.0756 11.4935 12.9877 11.5815C12.8998 11.6694 12.7806 11.7188 12.6562 11.7188H2.34375C2.21943 11.7188 2.1002 11.6694 2.01229 11.5815C1.92439 11.4935 1.875 11.3743 1.875 11.25V3.28125Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.47461 7.5L2.02148 11.584"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9785 11.584L8.52539 7.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Envelope.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Envelope;
