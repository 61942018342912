import React, { Component } from "react";
import "./Brand.sass";
import noImage from "../../../../assets/img/no-image.png";
import { Link } from "react-router-dom";
import { logoValidateMessageFalse } from "../../../../redux/ProjectInfo/projectInfoActions";

class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectHandler = (e) => {
    if (!e.imageURL) return false;
    this.setState({ selectedBrand: e });
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  getBrands = () => {};

  componentDidMount() {
    this.props.mapReset();
    this.getBrands();
  }

  render() {
    const brands = this.props.brands;
    const { isDemoModeOff } = this.props;
    const heightItem = document.documentElement.clientHeight / 4.472553699;

    return (
      <>
        <div className="brand items col-8 row m-0 p-3">
          {/* <Scrollbars
            id="panContainer"
            autoHide
            autoHideTimeout={300}
            autoHideDuration={300}
            // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
            style={{ height: "calc(100vh - 80px)" }}
          > */}
          {brands &&
            brands.length &&
            brands.map((e, index) => (
              <div
                id="card"
                key={index}
                className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 p-2 ${
                  e.isActive
                    ? "active"
                    : isDemoModeOff
                    ? "disabled coming-soon"
                    : ""
                }`}
                // style={{ height: "33%" }}
                style={{ height: `${heightItem}px` }}
                onClick={() => {
                  this.props.logoSelectedSwitch();
                }}
              >
                <input
                  type="radio"
                  value={JSON.stringify(e)}
                  id={index}
                  name="brand"
                  className="d-none"
                  onClick={this.props.handleChange("brand")}
                />
                <label
                  htmlFor={index}
                  className="w-100 h-100"
                  onClick={() => this.selectHandler(e)}
                >
                  <div
                    className="item col-12 h-100 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      border:
                        this.state.selectedBrand === e
                          ? "2px solid #307FF4"
                          : null,
                    }}
                  >
                    <img
                      src={e.imageURL || noImage}
                      className="w-100"
                      alt="logo"
                    />
                    {!e.imageURL && <p>{e.brandName}</p>}
                  </div>
                </label>
              </div>
            ))}
          {/* </Scrollbars> */}
        </div>
        <div className="text col-4 p-4">
          <h5 className="title text-right">Hotel Brand</h5>

          <div className="mt-5 mb-5 pt-2">
            <h2 className="mt-5 mb-5 pb-2">Select a hotel brand</h2>
            {this.props.validateMessage && (
              <p className="validateMessage">Please select any brand</p>
            )}
            <div className="text-left">
              <h4 className="title">
                Choose among hotel brands available on board. To suggest a brand
                currently unavailable, email <a href="#">support@support.com</a>
                .
              </h4>
            </div>
          </div>

          <div className="controls">
            <span
              onClick={() => {
                this.props.logoSelectedSwitchToFalse();
                this.props.logoValidateMessageFalse();
              }}
            >
              <button type="button" className="prev" onClick={this.back}>
                Prev
              </button>
            </span>
            {this.props.logoSelected ? (
              <Link to={"/dashboard/projects"} className="next">
                <button
                  autoFocus={true}
                  type="submit"
                  className="next"
                  onClick={() => {
                    this.props.addNewProject();
                    this.props.logoSelectedSwitchToFalse();
                    this.props.logoValidateMessageFalse();
                  }}
                >
                  Add Project
                </button>
              </Link>
            ) : (
              <button
                autoFocus={true}
                type="submit"
                className="next"
                onClick={this.props.logoValidateMessage}
              >
                Add Project
              </button>
            )}
            {/*<Link to={"/dashboard/project"} className="next">*/}
            {/*  <button*/}
            {/*    autoFocus={true}*/}
            {/*    type="submit"*/}
            {/*    className="next"*/}
            {/*    onClick={this.props.addNewProject}*/}
            {/*  >*/}
            {/*    Add Project*/}
            {/*  </button>*/}
            {/*</Link>*/}
          </div>
        </div>
      </>
    );
  }
}

export default Brand;
