import React, { Component } from "react";
import mapboxgl from "mapbox-gl";
import Svg from "../../../../Svg/Svg";
import * as turf from "@turf/turf";
import styles from "./MapInner.module.scss";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import debounce from "lodash.debounce";

import rotateIconPath from "../../../../../assets/img/icons/rotate.png";
import { BuildingRenderer } from "../../../../../BuildingRenderer/BuildingRenderer";
import { getLayerFeaturesInsideFeature } from "../../../../../MapUtils/MapUtils";

mapboxgl.accessToken =
  "pk.eyJ1Ijoib2xla3NhbmRycGV0cnlrIiwiYSI6ImNrNGNudW91dzA4YnUzbnBmc3l5OGl3c3AifQ.GmdMReeE1fkKHjU1FE18Vw";

let modelTransform;

const DEFAULT_FOV = 0.6435011087932844;
const PLAN_FOV = 0.1;

// Would come from an API endpoint
const coordinatesParcel = [
  [-20.257, 84.055],
  [-20.257, -36.07],
  [-20.257, -36.07],
  [177.075, 91.779],
  [177.075, 91.779],
  [177.075, 123.305],
  [177.075, 123.305],
  [-7.727, 123.305],
  [-7.727, 123.305],
  [-7.727, 84.055],
  [-7.727, 84.055],
  [-20.257, 84.055],
];

export default class Viewer3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrowObjectOnMap: false,
      map: false,
      current: this.props.current,
      isNext: this.props.isNext,
      threeDView: this.props.threeDView,
    };
  }

  handlerIndex = (index) => (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;

    this.setState((prevState) => ({
      ...prevState,
      current: index,
      isNext: index > prevState.current,
      threeDView: index === 0 ? true : false,
    }));
  };

  handlerPrev = (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length;

    if (index < 1) {
      index = length;
    }

    index = index - 1;

    this.setState({
      current: index,
      isNext: false,
    });
  };

  handlerNext = (evt) => {
    evt && evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length - 1;

    if (index === length) {
      index = -1;
    }

    index = index + 1;

    this.setState({
      current: index,
      isNext: true,
    });
  };

  goToHistoryClick = (curIndex, index) => {
    let next = curIndex < index;
    this.setState({
      current: index,
      isNext: next,
      timerId: null,
    });
  };

  onChange = (e) => {
    e.stopPropagation();
    e.persist();

    const { projectSite } = this.props.projectInfo;
    const { map } = this.state;

    map.fitBounds(projectSite.bbox, {
      padding: 50,
      duration: 0,
    });

    if (e.target.value === "satellite-v9") {
      this.props.setProjectInfoKeyView("streets-v11");
      map.setStyle("mapbox://styles/mapbox/streets-v11");
    } else {
      this.props.setProjectInfoKeyView("satellite-v9");
      map.setStyle("mapbox://styles/mapbox/satellite-v9");
    }
  };

  load3DView() {
    const { map } = this.state;
    let { bearing, pitch } = this.props["3d_settings"];
    bearing = bearing ? bearing : 0;
    pitch = pitch ? pitch : 45;
    if (map.getSource("buildingOutline")) {
      map.setPaintProperty("buildingLine", "line-width", 0);
    }
    map.setLayoutProperty("3d-model", "visibility", "visible");
    if (map.getLayer("3d-buildings")) {
      map.setLayoutProperty("3d-buildings", "visibility", "visible");
    }
    map.setBearing(bearing);
    map.setPitch(pitch);
    this.setFOV(map, DEFAULT_FOV);
    map.dragRotate._mousePitch.enable();
    map.triggerRepaint();
  }

  load2DView() {
    const { map } = this.state;
    const { projectSite } = this.props.projectInfo;
    if (map.getSource("buildingOutline")) {
      map.setPaintProperty("buildingLine", "line-width", 0);
    }
    map.setLayoutProperty("3d-model", "visibility", "visible");
    if (map.getLayer("3d-buildings")) {
      map.setLayoutProperty("3d-buildings", "visibility", "visible");
    }
    map.setPitch(0);
    map.setBearing(0);
    this.setFOV(map, PLAN_FOV);
    map.dragRotate._mousePitch.disable();
    map.flyTo({
      center: turf.centerOfMass(projectSite).geometry.coordinates,
    });
    map.triggerRepaint();
  }

  setFOV(map, fov) {
    map.transform._fov = fov;
    map.transform._calcMatrices();
  }

  initMap = () => {
    let { bearing, pitch, coordinates, buildingPolygon } =
      this.props["3d_settings"];
    bearing = bearing ? bearing : 0;
    pitch = pitch ? pitch : 45;
    const { projectSite, view, projectId } = this.props.projectInfo;
    const { current } = this.state;

    let tempCoordinates;
    if (coordinates) {
      tempCoordinates = [...coordinates];
    } else {
      const centrOfParcel = turf.centerOfMass(projectSite);
      tempCoordinates = [...centrOfParcel.geometry.coordinates];
    }

    var givenModelLocation = {
      properties: {},
      geometry: {
        coordinates: tempCoordinates,
        type: "Point",
      },
    };

    // Creating new building shape, based on distance from given starting coordinate
    var newCoordinateShape = [];
    var feetToKm = 0.0003048;
    var referenceCoordinate = givenModelLocation.geometry.coordinates;
    coordinatesParcel.forEach((feetCoordinate, index) => {
      var thisBearing =
        Math.atan2(feetCoordinate[0] - 0, feetCoordinate[1] - 0) * 57.2958;
      var thisDistance = Math.sqrt(
        Math.pow(0 - feetCoordinate[0], 2) + Math.pow(0 - feetCoordinate[1], 2)
      );
      var newCoordinate = turf.destination(
        referenceCoordinate,
        thisDistance * feetToKm,
        thisBearing
      );
      newCoordinateShape.push(newCoordinate.geometry.coordinates);
    });
    if (buildingPolygon) {
      var tempProjectLocation = givenModelLocation;
      // console.log("ACTIVE")
    } else {
      // console.log("NOTACTIVE")
      buildingPolygon = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [newCoordinateShape],
        },
      };
      var centroidBoundsToCentroidBuilding = turf.bearing(
        turf.centroid(buildingPolygon).geometry.coordinates,
        givenModelLocation.geometry.coordinates
      );
      var centroidDistancetoCentroidBuilding = turf.distance(
        turf.centroid(buildingPolygon).geometry.coordinates,
        givenModelLocation.geometry.coordinates
      );
      buildingPolygon = turf.transformTranslate(
        buildingPolygon,
        centroidDistancetoCentroidBuilding,
        centroidBoundsToCentroidBuilding
      );
      var tempProjectLocation = turf.destination(
        givenModelLocation,
        centroidDistancetoCentroidBuilding,
        centroidBoundsToCentroidBuilding
      );
    }
    referenceCoordinate = tempProjectLocation.geometry.coordinates;

    // Getting bounds to find bottom-right point
    var buildingBounds = turf.bbox({
      type: "FeatureCollection",
      features: [buildingPolygon],
    });
    var buildingBoundsPoly = turf.bboxPolygon(buildingBounds);
    var buildingBoundsPoints = buildingBoundsPoly.geometry.coordinates[0].map(
      (coord) => {
        return {
          type: "Feature",
          properties: {},
          geometry: { coordinates: coord, type: "Point" },
        };
      }
    );
    // Get closest point on feature to this bottom-right corner
    var buildingAsLine = turf.polygonToLine(buildingPolygon);
    var closestPointOnBuilding = turf.nearestPointOnLine(
      buildingAsLine,
      buildingBoundsPoints[1]
    );
    var coordinateIndex = closestPointOnBuilding.properties.index;
    var coordinateByIndex =
      buildingAsLine.geometry.coordinates[coordinateIndex];
    var bottomRightPoint = {
      type: "Feature",
      properties: {},
      geometry: { coordinates: coordinateByIndex, type: "Point" },
    };
    // Make bounds for zooming and display
    var buildingBoundsPointsGeoJSON = {
      type: "FeatureCollection",
      features: [bottomRightPoint],
    };

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      antialias: true,
      style: view
        ? "mapbox://styles/mapbox/" + view
        : "mapbox://styles/mapbox/streets-v11",
    });

    this.setState({ map });

    // Replacing provided bounds, so that the temporary hard-coded building can fit
    map.fitBounds(turf.bbox(projectSite), {
      padding: 10,
      duration: 0,
    });
    let previousFeaturesInsideSite = [];
    const hideBuildingsInsideProjectSite = debounce(
      () => {
        previousFeaturesInsideSite = getLayerFeaturesInsideFeature({
          map,
          source: "composite",
          sourceLayer: "building",
          layer: "3d-buildings",
          feature: projectSite,
          previousFeaturesInsideSite,
          onResultsChanged: (inside) =>
            map.setFilter("3d-buildings", [
              "all",
              ["==", ["get", "extrude"], "true"],
              ["!", ["in", ["id"], ["literal", inside]]],
            ]),
        });
      },
      500,
      { leading: false, trailing: true }
    );

    map.on("render", () => {
      if (map.loaded()) {
        hideBuildingsInsideProjectSite();
      }
    });

    map.on("style.load", () => {
      // 3D buildings layer
      var layers = map.getStyle().layers;

      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      if (map.getSource("composite")) {
        map.addLayer(
          {
            id: "3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: false,
            type: "fill-extrusion",
            minzoom: 15,
            layout: {
              visibility: "none",
            },
            paint: {
              "fill-extrusion-color": "#aaa",

              // use an 'interpolate' expression to add a smooth transition effect to the
              // buildings as the user zooms in
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );
      }

      // Generated enveloping box based on temporary building
      map.addSource("parcelOutline", {
        type: "geojson",
        data: projectSite,
      });

      map.addLayer({
        id: "parcelFill",
        type: "fill",
        source: "parcelOutline",
        //'layout': {},
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.4,
        },
      });

      map.addLayer({
        id: "parcelLine",
        type: "line",
        source: "parcelOutline",
        paint: {
          "line-color": "#307FF4",
          "line-width": 4,
        },
      });

      map.addSource("buildingOutline", {
        type: "geojson",
        data: buildingPolygon,
      });

      map.addLayer({
        id: "buildingLine",
        type: "line",
        source: "buildingOutline",
        paint: {
          "line-color": "#333",
        },
      });

      map.addLayer({
        id: "buildingFill",
        type: "fill-extrusion",
        source: "buildingOutline",
        paint: {
          "fill-extrusion-color": "#333",
          "fill-extrusion-opacity": 0,
          "fill-extrusion-height": 20,
        },
      });

      if (!map.hasImage("rotate-icon")) {
        map.loadImage(rotateIconPath, (error, image) => {
          if (error) throw error;

          map.addImage("rotate-icon", image, { sdf: true });
          map.addSource("buildingBounds", {
            type: "geojson",
            data: buildingBoundsPointsGeoJSON,
          });

          this.change3DLayer();

          map.addLayer({
            id: "buildingIconsCircle",
            type: "circle",
            source: "buildingBounds",
            //'layout': {},
            paint: {
              "circle-radius": 10,
              "circle-color": "#FFF",
            },
          });

          map.addLayer({
            id: "buildingIcons",
            type: "symbol",
            source: "buildingBounds",
            //'layout': {},
            layout: {
              "icon-image": "rotate-icon",
              "icon-size": 0.11,
              "icon-allow-overlap": true,
            },
          });

          // Various dragging functionality
          var rotating = false;
          var dragging = false;
          var initialRotation3D = false;
          var initialDragPoint = false;
          var initialBearing = false;
          var draggedShape = false;
          var newReferenceCoordinate = false;

          // Dragging
          map.on("mousedown", "buildingFill", () => {
            dragging = true;
            if (rotating) {
              rotating = false;
            }
            map.dragPan.disable();
          });
          // Rotating
          map.on("mousedown", "buildingIcons", () => {
            rotating = true;
            if (dragging) {
              dragging = false;
            }
            map.dragPan.disable();
          });

          map.on("mouseout", () => {
            dragging = false;
            rotating = false;
            initialDragPoint = false;
            initialBearing = false;
            if (draggedShape) {
              referenceCoordinate = newReferenceCoordinate;
              buildingPolygon = draggedShape;
            }
            map.dragPan.enable();
          });

          map.on("mouseup", () => {
            dragging = false;
            rotating = false;
            initialDragPoint = false;
            initialBearing = false;
            if (draggedShape) {
              referenceCoordinate = newReferenceCoordinate;
              buildingPolygon = draggedShape;
            }
            map.dragPan.enable();
            const bearingFromMap = map.getBearing();
            const pitchFromMap = map.getPitch();
          });

          map.on("mousemove", (e) => {
            if (dragging && buildingPolygon) {
              // Get distance from centroid to initial drag point
              if (!initialDragPoint) {
                initialDragPoint = [e.lngLat.lng, e.lngLat.lat];
              } else if (buildingPolygon) {
                // Get bearing and distance from initial drag point
                var bearing = turf.bearing(initialDragPoint, [
                  e.lngLat.lng,
                  e.lngLat.lat,
                ]);
                var distance = turf.distance(initialDragPoint, [
                  e.lngLat.lng,
                  e.lngLat.lat,
                ]);
                var possibleDraggedShape = turf.transformTranslate(
                  buildingPolygon,
                  distance,
                  bearing
                );

                // Move 3D shape
                newReferenceCoordinate = turf.transformTranslate(
                  {
                    type: "FeatureCollection",
                    features: [
                      {
                        type: "Feature",
                        properties: {},
                        geometry: {
                          type: "Point",
                          coordinates: referenceCoordinate,
                        },
                      },
                    ],
                  },
                  distance,
                  bearing
                ).features[0].geometry.coordinates;
                var modelOrigin = newReferenceCoordinate;
                var modelAsMercatorCoordinate =
                  mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, 0);
                modelTransform.translateX = modelAsMercatorCoordinate.x;
                modelTransform.translateY = modelAsMercatorCoordinate.y;

                draggedShape = possibleDraggedShape;
                map.getSource("buildingOutline").setData(draggedShape);
                var buildingBounds = turf.bbox({
                  type: "FeatureCollection",
                  features: [draggedShape],
                });
                var buildingBoundsPoly = turf.bboxPolygon(buildingBounds);
                var buildingBoundsPoints =
                  buildingBoundsPoly.geometry.coordinates[0].map((coord) => {
                    return {
                      type: "Feature",
                      properties: {},
                      geometry: { coordinates: coord, type: "Point" },
                    };
                  });
                var buildingAsLine = turf.polygonToLine(draggedShape);
                var coordinateByIndex =
                  buildingAsLine.geometry.coordinates[coordinateIndex];
                var bottomRightPoint = {
                  type: "Feature",
                  properties: {},
                  geometry: { coordinates: coordinateByIndex, type: "Point" },
                };
                map.getSource("buildingBounds").setData({
                  type: "FeatureCollection",
                  features: [bottomRightPoint],
                });

                if (!turf.booleanContains(projectSite, possibleDraggedShape)) {
                  map.setPaintProperty("parcelFill", "fill-color", "#FF4C4C");
                } else {
                  map.setPaintProperty("parcelFill", "fill-color", "#088");
                }
              }
            }
            if (rotating) {
              // Get bearing from centroid to drag point
              if (!initialBearing && buildingPolygon) {
                var shapeCentroid = turf.centroid(buildingPolygon);
                var bearing = turf.bearing(shapeCentroid.geometry.coordinates, [
                  e.lngLat.lng,
                  e.lngLat.lat,
                ]);
                initialBearing = bearing;
                initialRotation3D = modelTransform.rotateY;
              } else if (buildingPolygon) {
                var shapeCentroid = turf.centroid(buildingPolygon);
                var bearing = turf.bearing(shapeCentroid.geometry.coordinates, [
                  e.lngLat.lng,
                  e.lngLat.lat,
                ]);
                var possibleDraggedShape = turf.transformRotate(
                  buildingPolygon,
                  bearing - initialBearing,
                  { pivot: referenceCoordinate }
                );
                var distance = turf.distance(shapeCentroid, [
                  e.lngLat.lng,
                  e.lngLat.lat,
                ]);

                // Move 3D shape
                newReferenceCoordinate = referenceCoordinate;
                var modelOrigin = newReferenceCoordinate;
                var modelRotate = [
                  Math.PI / 2,
                  initialRotation3D +
                    ((initialBearing - bearing) * Math.PI) / 180,
                  0,
                ];
                var modelAsMercatorCoordinate =
                  mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, 0);
                modelTransform.translateX = modelAsMercatorCoordinate.x;
                modelTransform.translateY = modelAsMercatorCoordinate.y;
                modelTransform.rotateY = modelRotate[1];

                draggedShape = possibleDraggedShape;
                map.getSource("buildingOutline").setData(draggedShape);
                var buildingAsLine = turf.polygonToLine(draggedShape);
                var coordinateByIndex =
                  buildingAsLine.geometry.coordinates[coordinateIndex];
                var bottomRightPoint = {
                  type: "Feature",
                  properties: {},
                  geometry: { coordinates: coordinateByIndex, type: "Point" },
                };
                map.getSource("buildingBounds").setData({
                  type: "FeatureCollection",
                  features: [bottomRightPoint],
                });

                if (!turf.booleanContains(projectSite, possibleDraggedShape)) {
                  map.setPaintProperty("parcelFill", "fill-color", "#FF4C4C");
                } else {
                  map.setPaintProperty("parcelFill", "fill-color", "#088");
                }
              }
            }
          });

          this.setView(current);
        });
      }
    });
  };

  change3DLayer = () => {
    const { map } = this.state;
    if (map.getLayer("3d-model")) {
      map.removeLayer("3d-model");
    }
    map.addLayer(this.add3DLayer());
  };

  add3DLayer = () => {
    // hardcoded variabls for floors and pool
    const { coordinates } = this.props["3d_settings"];
    const { projectSite } = this.props.projectInfo;
    const { gltf_link } = this.props.filters.tablesForModel;
    var floor =
      this.props.tablesForModel.design_numbers.floors !== 0
        ? this.props.tablesForModel.design_numbers.floors
        : 5;
    var pool = this.props.filterParameters.option_pool == 0 ? false : true;

    // parameters to ensure the model is georeferenced correctly on the map
    let tempCoordinates;
    if (coordinates) {
      tempCoordinates = [...coordinates];
    } else {
      const centrOfParcel = turf.centerOfMass(projectSite);
      tempCoordinates = [...centrOfParcel.geometry.coordinates];

      tempCoordinates = turf.transformTranslate(
        {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: tempCoordinates,
              },
            },
          ],
        },
        0.035,
        -135
      ).features[0].geometry.coordinates;
    }
    var modelOrigin = tempCoordinates;
    var modelAltitude = 0;
    var modelRotate = [Math.PI / 2, 0, 0];
    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    );

    if (this.props["3d_settings"].modelTransform) {
      // console.log("ACTIVE")
      modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: this.props["3d_settings"].modelTransform.rotateX,
        rotateY: this.props["3d_settings"].modelTransform.rotateY,
        rotateZ: this.props["3d_settings"].modelTransform.rotateZ,
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };
    } else {
      // console.log("ACTIVE")
      modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };
    }

    // configuration of the custom layer for a 3D model per the CustomLayerInterface
    var customLayer = {
      id: "3d-model",
      type: "custom",
      renderingMode: "3d",
      onAdd: async (map, gl) => {
        if (!this.buildingRenderer) {
          this.buildingRenderer = new BuildingRenderer({
            context: gl,
            canvas: map.getCanvas(),
          });
        }

        await this.buildingRenderer.loadGltf({
          gltf_link,
        });
        map.triggerRepaint();
      },
      render: (gl, matrix) => {
        if (this.buildingRenderer && !this.buildingRenderer.loading) {
          this.buildingRenderer.render({ matrix, modelTransform });
        }
      },
    };
    return customLayer;
  };

  componentDidMount() {
    const { isDrowObjectOnMap } = this.state;
    if (this.props["3d_settings"] && !isDrowObjectOnMap) {
      this.setState({
        isDrowObjectOnMap: true,
      });
      this.initMap();
    }
  }

  componentWillUnmount() {
    this.buildingRenderer = this.buildingRenderer.destroy();
    this.buildingRenderer = undefined;
    this.state.map && this.state.map.remove();
  }

  componentDidUpdate(prevProps, prevState) {
    const { map } = this.state;
    const { isDrowObjectOnMap, current } = this.state;
    const { projectSite } = this.props.projectInfo;
    const { loadingTablesForModel } = this.props.filters;

    if (!this.props["3d_settings"]) return;
    let { bearing, buildingPolygon, coordinates, modelTransform, pitch } =
      this.props["3d_settings"];
    bearing = bearing ? bearing : 0;
    pitch = pitch ? pitch : 45;
    const { view } = this.props.projectInfo;

    if (this.props["3d_settings"]) {
      if (!isDrowObjectOnMap) {
        this.initMap();
        this.setState({
          current: 0,
          isDrowObjectOnMap: true,
        });
      }
    }

    if (
      prevProps.tablesForModel.gltf_link !== this.props.tablesForModel.gltf_link
    ) {
      this.change3DLayer();
    }
    if (current !== prevState.current) {
      if (current === 0) {
        this.load3DView();
      } else if (current === 1) {
        this.load2DView();
      }
    }

    if (
      this.props["3d_settings"] &&
      !!map &&
      map.getBearing() === 0 &&
      current === 0
    ) {
      map.setBearing(bearing);
      map.setPitch(pitch);
    }
  }

  setView(current) {
    if (current === 0) {
      this.load3DView();
    } else if (current === 1) {
      this.load2DView();
    }
  }

  render() {
    const { address, area_sf, area_ac, view } = this.props.projectInfo;
    let index = this.state.current,
      isnext = this.state.isNext,
      src = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].url
        : "",
      name = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].name
        : "";
    const { far, floors, height } = this.props.tablesForModel.design_numbers;
    const { parking_spaces } = this.props.tablesForModel;
    return (
      <div className={`${styles.MapInner}`}>
        <div className={styles.MapHead}>
          <div className={`${styles.MapLocation}`}>
            <span>
              {`${(address && address.streetNumber) || ""}`}{" "}
              {(address && address.street) || "noData"}
              {`, `}
            </span>
            <span className="font-weight-bold">
              {(address && address.city) || "noData"}
            </span>
            <span>, {(address && address.state) || "noData"}</span>
          </div>
          <div className={`${styles.MapLotArea}`}>
            <span className={styles.gray}>Lot area: </span>
            <span className="font-weight-bold">
              {new Intl.NumberFormat("en-US").format(
                Number(area_sf).toFixed(0)
              )}{" "}
              sf /{" "}
              {new Intl.NumberFormat("en-US").format(
                Number(area_ac).toFixed(2)
              )}{" "}
              acres
            </span>
          </div>
        </div>
        {/* {this.props.showInfoColumns && (
          <div className={styles.columnContainer}>
            <div className={styles.floors}>
              <span>Floors: </span>{" "}
              <span className={styles.bold}>{floors}</span>
            </div>
            <div className={styles.height}>
              <span>Height: </span>{" "}
              <span className={styles.bold}>{`${height} ft`}</span>
            </div>
            <div className={styles.parking}>
              <span>Parking: </span>{" "}
              <span className={styles.bold}>{parking_spaces}</span>
            </div>
            <div className={styles.far}>
              <span>FAR: </span>{" "}
              <span className={styles.bold}>{far.toFixed(2)}</span>
            </div>
          </div>
        )} */}
        <div className={styles.MapBox}>
          {/* <div
            style={{
              display: "inlineBlock",
              position: "absolute",
              marginTop: "10px",
              marginLeft: "10px",
              zIndex: "1",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            <button
              className={styles.toggleBtn}
              name="view"
              value={this.props.view}
              onClick={this.onChange}
            >
              {this.props.view === "streets-v11"
                ? "Show Satellite"
                : "Show Streets"}
            </button>
          </div> */}
          {/* <div id="map-inner" ref={(ref) => (this.mapContainer = ref)}
            className={styles.mapInner}></div> */}
        </div>
        <div className={styles.carousel}>
          <div className={styles.MapBox}>
            <div
              style={{
                position: "absolute",
                marginTop: 10,
                marginLeft: 10,
                zIndex: 3,
                fontWeight: 700,
                fontSize: 12,
                top: 0,
              }}
            >
              <button
                className={styles.toggleBtn}
                name="view"
                value={view}
                onClick={this.onChange}
              >
                {view === "streets-v11" ? "Show Satellite" : "Show Streets"}
              </button>
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              zIndex: 10,
              fontWeight: 700,
              fontSize: 12,
              right: 10,
              top: 10,
              cursor: "pointer",
            }}
            onClick={this.props.toggleIsModalOpen}
          >
            {this.props.isModal ? (
              <FullscreenExitIcon
                style={{
                  color: "#7f7f7f",
                  fontSize: 35,
                  display: "flex",
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: 5,
                }}
              />
            ) : (
              <FullscreenIcon
                style={{
                  color: "#7f7f7f",
                  fontSize: 35,
                  display: "flex",
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: 5,
                }}
              />
            )}
          </div>
          {/* <ReactCSSTransitionGroup
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: isnext ? `${styles.enter_next}` : `${styles.enter_prev}`,
              enterActive: `${styles.enter_active}`,
              leave: `${styles.leave}`,
              leaveActive: isnext
                ? `${styles.leave_active_next}`
                : `${styles.leave_active_prev}`,
            }}
          >
            <div className={styles.carousel_slide} key={index}>
              {(index === 0 || index === 1) && ( */}
          <div
            id="map-inner"
            ref={(ref) => (this.mapContainer = ref)}
            className={styles.mapInner}
          />
          {/* )} */}
          {index > 1 && (
            <div
              style={{
                position: "absolute",
                zIndex: 5,
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <img
                alt={`carouselImg_${index}`}
                id={`carouselImg_${index}`}
                style={{
                  zIndex: 5,
                  height: "100%",
                  margin: "0 auto",
                }}
                src={`${src}`}
                title={name}
              />
            </div>
          )}
          {/* </div>
          </ReactCSSTransitionGroup> */}
        </div>
        <div className={styles.carousel_control_container}>
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__prev}`}
            onClick={this.handlerPrev}
          >
            <Svg icon="ArrowLeft" className={styles.ArrowLeft} />
          </button>
          {this.props.tablesForModel.images.map((image, index) => (
            <button
              key={index}
              className={`${styles.carousel_control} ${
                index === this.state.current
                  ? styles.carousel_control__active
                  : ""
              } ${styles.carousel_control__index}`}
              onClick={this.handlerIndex(index)}
              title={index === 1 ? "Site Plan" : image.name}
            >
              {index === 0 && <Svg icon="Aerial" className={styles.Icon} />}
              {index === 1 && <Svg icon="Mode2d" className={styles.Icon} />}
              {index > 1 && (
                <span className={styles.IconNumbers}>{index - 1}</span>
              )}
            </button>
          ))}
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__next}`}
            onClick={this.handlerNext}
          >
            <Svg icon="ArrowRight" className={styles.ArrowRight} />
          </button>
        </div>
      </div>
    );
  }
}
