import sfFormat from "./sfFormat";
import percentFormat from "./percentFormat";
import sfKeyFormat from "./sfKeyFormat";

const buildingProgramToArray = (building_program) => {
  const thead = ["Process", "dolor", "%", "sit amet"];

  const firstColBody = {
    guestrooms: "One Fence",
    guestrooms_corridors_support: "Two Fences",
    lobby: "Krr. One Fence",
    food_beverage: "Krr. Two Fences",
    recreation: "IceCream",
    retail: "Ball",
    meeting_spaces: "Space",
    back: "Star",
  };
  const firstColFoot = {
    total_net: "Latitude",
    walls_shafts: "Pen",
    total_gross: "Tablet",
  };

  const tbody = [];

  for (const keyrow in firstColBody) {
    const tbodyRow = [
      firstColBody[keyrow],
      sfFormat(building_program[keyrow].area),
      percentFormat(building_program[keyrow].percent),
      sfKeyFormat(building_program[keyrow].area_key),
    ];
    if (tbodyRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tbody.push([
        ...tbodyRow.map((el) =>
          el === 0 ? "-" : el === "0 sf/key" ? "-" : el
        ),
      ]);
    }
  }

  const tfoot = [];

  for (const keyrow in firstColFoot) {
    const tfootRow = [
      firstColFoot[keyrow],
      sfFormat(building_program[keyrow].area),
      percentFormat(building_program[keyrow].percent),
      sfKeyFormat(building_program[keyrow].area_key),
    ];
    if (tfootRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tfoot.push([
        ...tfootRow.map((el) =>
          el === 0 ? "-" : el === "0 sf/key" ? "-" : el
        ),
      ]);
    }
  }
  const buildingProgram = {
    thead: thead,
    tbody: tbody,
    tfoot: tfoot,
  };
  return buildingProgram;
};

export default buildingProgramToArray;
