const initialState = {
  //Concept Table Data
  sortByName: "Table 1:",
  table2Id: "#12CVG3",
  table2SecondFirst: "25 parts",
  table2SecondSecond: 100,
  table2SecondThird: 145,
  table2ThirdFirst: 130,
  table2ThirdSecond: 111,
  table2ThirdThird: 23,

  //Build Program Table
  paddingRightText: "nuts padding",
  buildProgramTablePlusText: "plus padding",
  buildProgramTableHeadOfSlider: "Slider",
  buildProgramTable2Head: "Build Program Table 2",
  buildProgramTable2Selector1: "Selector 1",
  buildProgramTable2Selector1Case1: "Case 1",
  buildProgramTable2Selector1Case2: "Case 2",
  buildProgramTable2Selector2: "Selector 2",
  buildProgramTable2Selector2Case1: "Case 1",
  buildProgramTable2Selector2Case2: "Case 2",

  //Revenue BlablaTableData
  //blended
  adrData: 1000,
  occupancyData: 0.5,
  //unblended
  doubleTypeADRdata: 500,
  doubleTypeOcc: 0.2,
  doubleTypeOccName1: "Money",
  doubleTypeOccName2: "Pro",

  //Revenue BlaBlaBlaTable2Data
  firstInput: 20,
  secondInput: 300,
  //Revenue BlaBlaBlaTable2PlusData
  input1Data: "Random",
  input2Data: 400,
  customName: "User",

  //Budget Page Data
  budgetTable1Head: "Table 1",
  budgetTable1HeadOfFirstInput: "First Input Head",
  budgetTable1FirstInputData: 2500,
  budgetTable1FirstInputDataComment1: 60000000,
  budgetTable1FirstInputDataComment2: 25000000,
  budgetTable1HeadOfSecondInput: "Second Input Head",
  budgetTable1SecondInputData: 600,
  budgetTable1button1: "Button 1",
  budgetTable1button2: "Button 2",
  budgetTable1HeadOfThirdInput: "Third Input",
  budgetTable1ThirdInputData: 60,
  budgetTable1button3: "Button 3",
  budgetTable1button4: "Button 4",
  budgetTable1PlusInput1: "Input Budget First Form",
  budgetTable1PlusButton: "Plus",
  budgetTable1HeadOfFourthInput: "Fourth Input Head",
  budgetTable1FourthInputData: 0.08,
  budgetTable1FourthInputDataComment: "Fourth Input Comment",
  budgetTable1HeadOfFifthInput: "Fifth Input Head",
  budgetTable1FifthInputData: 0.15,
  budgetTable1FifthInputDataComment: "Fifth Input Comment",
  budgetTable2Head: "Table 2",
  budgetTable2Inputs: " ",

  //Investment
  investmentTable1Head: "Table 1",
  investmentTable1Button1: "Button 1",
  investmentTable1Button2: "Button 2",
  investmentTable1HeadOfInput1Button1: "Head Of Input 1 Button 1",
  investmentTable1Input1Button1Data: 0.35,
  investmentTable1Input1Button1Comment: "Comment",

  investmentTable1HeadOfInput1Button2: "Head Of Input 1 Button 2",
  investmentTable1Input1Button2Data: 0.35,
  investmentTable1Input1Button2Comment: "Comment",

  investmentTable1HeadOfInput2Button2: "Head Of Input 2 Button 2",
  investmentTable1Input2Button2Data: 0.35,
  investmentTable1Input2Button2Comment: "Comment",

  investmentTable1HeadOfInput3Button2: "Head Of Input 3 Button 2",
  investmentTable1Input3Button2Data: 1100,
  investmentTable1Input3Button2Comment: "Comment",

  investmentTable1HeadOfInput4Button2: "Head Of Input 4 Button 2",
  investmentTable1Input4Button2Data: 0.35,
  investmentTable1Input4Button2Comment: "Comment",

  investmentTable1HeadOfInput5Button2: "Head Of Input 5 Button 2",
  investmentTable1Input5Button2Data: 0.35,
  investmentTable1Input5Button2Comment: "Comment",

  investmentTable2Head: "Table 2",

  investmentTable2HeadOfInput1: "Head Of Input 1",
  investmentTable2Input1Data: 0.1,
  investmentTable2HeadOfInput2: "Head Of Input 2",
  investmentTable2Input2Data: 0.05,
  investmentTable2HeadOfInput3: "Head Of Input 3",
  investmentTable2Input3Data: 0.7,
  investmentTable2HeadOfInput4: "Head Of Input 4",
  investmentTable2Input4Data: 0.05,
  investmentTable2HeadOfInput5: "Head Of Input 5",
  investmentTable2Input5Data: 0.01,
  investmentTable2HeadOfInput6: "Head Of Input 6",
  investmentTable2Input6Data: 40,

  //HeaderData
  projectName: "DinDevProject",
  afterPrivetNumber: 10000,
  location: "Kiev St.John McCain 7",
  companyName: "DinDev",
  headerHelloImgLink: `http://online-altair.com/wp-content/uploads/2015/06/1111.png`,

  //Development Contact Data
  developmentHead: "Head of Contacts",
  nameOfDeveloper: "Roman Ivanov",
  photoOfDeveloperURL: `https://thumbs.dreamstime.com/b/%D0%B2%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%B7%D0%BD%D0%B0%D1%87%D0%BA%D0%B0-%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D0%BC%D0%B5%D0%BD%D0%B0-%D0%BC%D1%83%D0%B6%D1%87%D0%B8%D0%BD%D1%8B-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8F-%D0%B0%D0%B2%D0%B0%D1%82%D0%B0%D1%80-%D0%BC%D1%83%D0%B6%D1%81%D0%BA%D0%BE%D0%B9-182095609.jpg`,
  developerTitle: "Hello! My name is Roman Ivanov. I am developer",
  companyNameOfDeveloper: "DinDev company",
  phoneNumberOfDeveloper: "068-987-65-43",
  emailOfDeveloper: "romanivanov@mail.com",
  dinDevLogoUrl: `https://hcti.io/v1/image/fba429e5-caf3-4725-aeea-10a9fe32df03`,

  //Data Table
  dataTableMenuCase1Name: "CASE 1",
  dataTableMenuCase2Name: "CASE 2",
  dataTableMenuCase3Name: "CASE 3",
  dataTableMenuCase1Value1: 222,
  dataTableMenuCase1Value2: 11111,
  dataTableMenuCase2Value1: 50000000,
  dataTableMenuCase2Value2: 12344,
  dataTableMenuCase3Value1: 0.4,
  dataTableMenuCase3Value2: 0.25,
  firstRowDataTable: "Data1",

  //Map
  mapHeadAdress: "This place for your adress",
  mapInfoElement1Name: "Info 1",
  mapInfoElement1Value: 25,
  mapInfoElement2Name: "Info 2",
  mapInfoElement2Value: 250,
  mapInfoElement3Name: "Info 3",
  mapInfoElement3Value: 345,
  mapInfoElement4Name: "Info 4",
  mapInfoElement4Value: 0.12,
};

const projectInfoLocalReducer = (state = initialState) => {
  return state;
};

export default projectInfoLocalReducer;
