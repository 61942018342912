import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import * as ApiProjects from "../API/projects";

const MATERIALS = {
  default: new THREE.MeshLambertMaterial({
    color: 0xeeeeee,
  }),
  roof: new THREE.MeshLambertMaterial({
    color: 0x555555,
  }),
  window: new THREE.MeshLambertMaterial({
    color: 0xa8b8cc,
  }),
  floor: new THREE.MeshLambertMaterial({
    color: 0x222222,
  }),
  logo: new THREE.MeshLambertMaterial({
    color: 0x111111,
  }),
  normal: new THREE.ShaderMaterial({
    vertexShader: `
        varying vec3 vNormal;
        void main() {
        vNormal = normal;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        varying vec3 vNormal;
        void main() {
        gl_FragColor = vec4(vNormal, 1.0);
        }
  `,
  }),
};

export class BuildingRenderer {
  loading = true;
  camera = new THREE.Camera();
  scene = new THREE.Scene();
  loader = new GLTFLoader();
  renderer;
  gltf;

  directionalLight = new THREE.DirectionalLight(0xffffff, 0.55);
  probe = new THREE.HemisphereLightProbe(0xddeeff, 0xffeedd, 0.55);
  bbox = new THREE.Box3();

  shadowPlaneGeometry = new THREE.PlaneGeometry(2000, 2000);
  shadowPlaneMaterial = new THREE.ShadowMaterial();
  shadowPlane = new THREE.Mesh(
    this.shadowPlaneGeometry,
    this.shadowPlaneMaterial
  );

  // Properties for debug view
  bboxHelper;
  cameraHelpers;
  hereMesh;

  constructor({ context, canvas }) {
    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      context,
      antialias: true,
    });

    this.renderer.outputEncoding = THREE.GammaEncoding;
    this.renderer.physicallyCorrectLights = true;
    this.renderer.toneMapping = THREE.LinearToneMapping;

    this.renderer.autoClear = false;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const shadowMapSize = Math.min(
      this.renderer.capabilities.maxTextureSize,
      8192
    );

    this.directionalLight.castShadow = true;
    this.directionalLight.shadow.mapSize.width = shadowMapSize;
    this.directionalLight.shadow.mapSize.height = shadowMapSize;

    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight.target);
    this.scene.add(this.probe);

    this.shadowPlaneGeometry.rotateX(-Math.PI / 2);
    this.shadowPlaneMaterial.opacity = 0.2;
    this.shadowPlane.position.y = -0.5;
    this.shadowPlane.receiveShadow = true;
    this.scene.add(this.shadowPlane);
  }

  destroy() {
    this.destroyGltf();
    if (this.directionalLight.shadow && this.directionalLight.shadow.map) {
      this.directionalLight.shadow.map.dispose();
    }
    if (this.scene) {
      this.scene.traverse((object) => {
        if (object.isMesh) {
          object.geometry.dispose();
          object.material.dispose();
        }
      });
    }
  }

  destroyGltf() {
    if (this.gltf) {
      this.gltf.scene.traverse((object) => {
        if (object.isMesh) {
          object.geometry.dispose();
          object.material.dispose();
        }
      });
      this.scene.remove(this.gltf.scene);
      this.gltf = undefined;
    }
  }

  async loadGltf({ gltf_link }) {
    this.loading = true;
    this.destroyGltf();

    //   const gltfLink = gltf_link.split("/").slice(3).join("/");
    //   const response = await ApiProjects.get3dModelForProject(gltfLink);

    return new Promise((resolve) => {
      this.loader.load("./img/Victorian-House-Blendswap.gltf", (model) => {
        this.gltf = model;
        this.scene.add(this.gltf.scene);
        // console.log("model is loaded");

        this.gltf.scene.traverse((object) => {
          if (object.isMesh) {
            if (object.name.toLowerCase().includes("roof")) {
              object.material = MATERIALS.roof;
            } else if (object.name.toLowerCase().includes("window")) {
              object.material = MATERIALS.window;
            } else if (object.name.toLowerCase().includes("floor")) {
              object.material = MATERIALS.floor;
            } else if (object.name.toLowerCase().includes("logo")) {
              object.material = MATERIALS.logo;
            } else {
              object.material = MATERIALS.default;
            }

            object.castShadow = true;
            object.receiveShadow = true;
          }
        });

        if (localStorage.getItem("debugViewer") === "true") {
          // eslint-disable-next-line no-console
          console.log({
            domElement: this.renderer.domElement,
            THREE,
            renderer: this.renderer,
          });
          this.bboxHelper = new THREE.Box3Helper(this.bbox, 0xff0000);
          this.scene.add(this.bboxHelper);
          this.cameraHelper = new THREE.CameraHelper(
            this.directionalLight.shadow.camera
          );
          this.scene.add(this.cameraHelper);

          const sphereGeometry = new THREE.SphereBufferGeometry();
          this.sphereMesh = new THREE.Mesh(
            sphereGeometry,
            new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true })
          );

          this.scene.add(this.sphereMesh);
        }
        this.loading = false;
        resolve();
      });
    });
  }
  render({ modelTransform, matrix }) {
    var rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      modelTransform.rotateX
    );

    var rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      modelTransform.rotateZ
    );

    var m = new THREE.Matrix4().fromArray(matrix);
    var l = new THREE.Matrix4()
      .makeTranslation(
        modelTransform.translateX,
        modelTransform.translateY,
        modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          modelTransform.scale,
          -modelTransform.scale,
          modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationZ);

    this.gltf.scene.rotation.set(0, modelTransform.rotateY, 0);

    this.camera.projectionMatrix = m.multiply(l);

    this.bbox.setFromObject(this.gltf.scene);
    const center = new THREE.Vector3();
    this.bbox.getCenter(center);
    const sphere = this.bbox.getBoundingSphere(new THREE.Sphere(center));
    const shadowDistance = sphere.radius * 1.2;

    if (this.sphereMesh) {
      this.sphereMesh.position.copy(center);
      this.sphereMesh.scale.set(shadowDistance, shadowDistance, shadowDistance);
    }

    this.directionalLight.target.position.copy(center);
    this.directionalLight.position
      .copy(this.directionalLight.target.position)
      .add(
        new THREE.Vector3(-3.3, 5, -2.5)
          .normalize()
          .multiplyScalar(shadowDistance)
      );

    this.directionalLight.shadow.camera.near = shadowDistance * 0.1;
    this.directionalLight.shadow.camera.far = shadowDistance * 2;
    this.directionalLight.shadow.camera.left = -shadowDistance;
    this.directionalLight.shadow.camera.right = shadowDistance;
    this.directionalLight.shadow.camera.bottom = -shadowDistance;
    this.directionalLight.shadow.camera.top = shadowDistance;
    this.directionalLight.shadow.camera.updateProjectionMatrix();

    if (this.cameraHelper) {
      this.cameraHelper.update();
    }

    this.renderer.resetState();
    const currentSize = this.renderer.getSize(new THREE.Vector2());
    const newSize = new THREE.Vector2(
      this.renderer.domElement.width,
      this.renderer.domElement.height
    );
    if (!currentSize.equals(newSize)) {
      this.renderer.setSize(newSize.x, newSize.y, false);
    }
    this.renderer.render(this.scene, this.camera);
  }
}
