import React from "react";
import PropTypes from "prop-types";

const ArchiveThinCircle = ({ className }) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Archive</title>
      <desc>Created with sketchtool.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-358.000000, -348.000000)">
          <g transform="translate(338.000000, 261.000000)">
            <g transform="translate(10.000000, 82.000000)">
              <g transform="translate(10.000000, 5.000000)">
                <circle stroke="#307FF4" cx="15" cy="15" r="14.5"></circle>
                <path
                  className={className}
                  d="M18.210437,10.101832 L22.0222855,10.101832 L22.0222855,11.1984258 L20.8635897,11.1984258 L19.9589588,21.499582 C19.9343464,21.7826992 19.6976083,22 19.413992,22 L11.5898479,22 C11.3062588,22 11.0696845,21.7828633 11.044881,21.4997734 L10.1402774,11.1984258 L9,11.1984258 L9,10.101832 L12.8118485,10.101832 L12.8118485,8.91382813 C12.8118485,8.4099375 13.2209284,8 13.7237649,8 L17.2985207,8 C17.8013571,8 18.210437,8.4099375 18.210437,8.91382813 L18.210437,10.101832 Z M17.1161374,10.101832 L17.1161374,9.09659375 L13.9061482,9.09659375 L13.9061482,10.101832 L17.1161374,10.101832 Z M18.1736002,12.8268828 L17.9365074,19.3149531 L16.8429172,19.2749219 L17.08001,12.7866875 L18.1736002,12.8268828 Z M14.963993,12.8067852 L16.0582926,12.8067852 L16.0582926,19.2950469 L14.963993,19.2950469 L14.963993,12.8067852 Z M14.1791773,19.2747578 L13.0856145,19.3149531 L12.8484944,12.8267188 L13.9420845,12.7864961 L14.1791773,19.2747578 Z M18.91282,20.9034062 L19.7651152,11.1984258 L11.2387792,11.1984258 L12.0908834,20.9034062 L18.91282,20.9034062 Z"
                  stroke="#FFFFFF"
                  strokeWidth="0.2"
                  fill="#333333"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ArchiveThinCircle.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ArchiveThinCircle;
