import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.scss";

export default class Modal extends Component {
  backdropRef = createRef();

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    const { onClose } = this.props;
    if (e.code !== "Escape") return;

    onClose(e);
  };

  handleBackdropClick = (e) => {
    e.stopPropagation();
    const { current } = this.backdropRef;
    const { onClose } = this.props;

    if (current && e.target !== current) {
      return;
    }
    onClose(e);
  };

  render() {
    const { children } = this.props;
    return (
      <div
        className={styles.overlay}
        ref={this.backdropRef}
        role="presentation"
        onClick={this.handleBackdropClick}
      >
        <div className={styles.overlay__modal}>{children}</div>
      </div>
    );
  }
}
