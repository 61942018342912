export const ActionType = {
  FETCH_PROJECT_INFO_START: "FETCH_PROJECT_INFO_START",
  FETCH_PROJECT_INFO_SUCCESS: "FETCH_PROJECT_INFO_SUCCESS",
  FETCH_PROJECT_INFO_ERROR: "FETCH_PROJECT_INFO_ERROR",
  SET_PREVIOUS_REQUEST_FOR_PROJECT_INFO:
    "SET_PREVIOUS_REQUEST_FOR_PROJECT_INFO",
  EDIT_PROJECT_INFO_START: "EDIT_PROJECT_INFO_START",
  EDIT_PROJECT_INFO_SUCCESS: "EDIT_PROJECT_INFO_SUCCESS",
  EDIT_PROJECT_INFO_ERROR: "EDIT_PROJECT_INFO_ERROR",
  REMOVE_PROJECT_INFO: "REMOVE_PROJECT_INFO",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_ON_BLUR",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB_ON_BLUR:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB_ON_BLUR",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS_ON_BLUR",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST_ON_BLUR:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST_ON_BLUR",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST_ON_BLUR:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST_ON_BLUR",
  SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_ON_BLUR",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_ON_BLUR",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE_ON_BLUR:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE_ON_BLUR",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS_ON_BLUR",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS_ON_BLUR",
  SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS_ON_BLUR:
    "SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS_ON_BLUR",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC",
  SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS:
    "SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS",
  SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS:
    "SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS",
  SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS:
    "SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS",
  SET_PROJECT_INFO_KEY_VIEW: "SET_PROJECT_INFO_KEY_VIEW",
  SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST:
    "SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST",
  FETCH_3D_SETTINGS_FOR_PROJECT_START: "FETCH_3D_SETTINGS_FOR_PROJECT_START",
  FETCH_3D_SETTINGS_FOR_PROJECT_SUCCESS:
    "FETCH_3D_SETTINGS_FOR_PROJECT_SUCCESS",
  FETCH_3D_SETTINGS_FOR_PROJECT_ERROR: "FETCH_3D_SETTINGS_FOR_PROJECT_ERROR",
  SET_3D_SETTINGS: "SET_3D_SETTINGS",
  SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX:
    "SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX",
  SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX_ON_BLUR:
    "SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX_ON_BLUR",
  ADD_NEW_PROJECT_TO_PROJECTS: "ADD_NEW_PROJECT_TO_PROJECTS",
  LOGO_SELECTED_SWITCH: "LOGO_SELECTED_SWITCH",
  LOGO_SELECTED_SWITCH_TO_FALSE: "LOGO_SELECTED_SWITCH_TO_FALSE",
  LOGO_VALIDATE_MESSAGE: "LOGO_VALIDATE_MESSAGE",
  LOGO_VALIDATE_MESSAGE_FALSE: "LOGO_VALIDATE_MESSAGE_FALSE",
  CHANGE_HEADER_DATA_DISPLAY_FALSE: "CHANGE_HEADER_DATA_DISPLAY_FALSE",
  CHANGE_HEADER_DATA_DISPLAY_TRUE: "CHANGE_HEADER_DATA_DISPLAY_TRUE",
  ADD_PROJECT_LOGIC: "ADD_PROJECT_LOGIC",
  ADD_AREAAC_AND_AREASF: "ADD_AREAAS_AND_AREASF",
};

/*
 * Fetch 3dSettingsForProject
 */
export const addAreaAsAndAreaSf = (payload) => ({
  type: ActionType.ADD_AREAAC_AND_AREASF,
  payload,
});
export const addProjectLogic = (payload) => ({
  type: ActionType.ADD_PROJECT_LOGIC,
  payload,
});
export const changeHeaderDataDisplayTrue = () => ({
  type: ActionType.CHANGE_HEADER_DATA_DISPLAY_TRUE,
});
export const changeHeaderDataDisplayFalse = () => ({
  type: ActionType.CHANGE_HEADER_DATA_DISPLAY_FALSE,
});
export const logoValidateMessageFalse = () => ({
  type: ActionType.LOGO_VALIDATE_MESSAGE_FALSE,
});
export const logoValidateMessage = () => ({
  type: ActionType.LOGO_VALIDATE_MESSAGE,
});
export const logoSelectedSwitchToFalse = () => ({
  type: ActionType.LOGO_SELECTED_SWITCH_TO_FALSE,
});
export const logoSelectedSwitch = () => ({
  type: ActionType.LOGO_SELECTED_SWITCH,
});
export const addNewProjectToProjects = (newProject) => ({
  type: ActionType.ADD_NEW_PROJECT_TO_PROJECTS,
  payload: newProject,
});
export const fetch3dSettingsForProjectStart = () => ({
  type: ActionType.FETCH_3D_SETTINGS_FOR_PROJECT_START,
});

export const fetch3dSettingsForProjectSuccess = (settings) => {
  return {
    type: ActionType.FETCH_3D_SETTINGS_FOR_PROJECT_SUCCESS,
    payload: { settings },
  };
};

export const fetch3dSettingsForProjectError = (error) => ({
  type: ActionType.FETCH_3D_SETTINGS_FOR_PROJECT_ERROR,
  payload: {
    error,
  },
});

export const setProjectInfoKeyRevenueAssumptionsOnBlur = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur = (rev_fb) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB_ON_BLUR,
    payload: { rev_fb },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur = (
  rev_meetings
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS_ON_BLUR,
    payload: { rev_meetings },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyInvestmentAssumptionsOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyInvestmentAssumptionsOpExOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsOnBlur = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeyHardCostsOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeyBudgetFfeOnBlur = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeySiteAssumptionsOnBlur = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS_ON_BLUR,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyRevenueAssumptions = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevFb = (rev_fb) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB,
    payload: { rev_fb },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevMeetings = (
  rev_meetings
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS,
    payload: { rev_meetings },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrList = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccList = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyInvestmentAssumptions = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyInvestmentAssumptionsOpEx = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptions = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeySoftCosts = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeyHardCosts = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyBudgetAssumptionsKeyBudgetFfe = (
  projectInfoKey
) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeySiteAssumptions = (projectInfoKey) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS,
    payload: { projectInfoKey },
  };
};

export const setProjectInfoKeyView = (view) => {
  return {
    type: ActionType.SET_PROJECT_INFO_KEY_VIEW,
    payload: { view },
  };
};

export const removeProjectInfo = (payload) => {
  return {
    type: ActionType.REMOVE_PROJECT_INFO,
    payload: payload,
  };
};

/*
 * Set 3D settings
 */
export const set3DSettings = (payload) => {
  return {
    type: ActionType.SET_3D_SETTINGS,
    payload: payload,
  };
};
