import React from "react";
import PropTypes from "prop-types";
import styles from "./Table.module.sass";

const Table = ({ id, table }) => {
  let maxValuesArray;
  if (table.tbody.length !== 0) {
    const initialMaxValuesArray = table.tbody[0].map(() => 0);
    maxValuesArray = table.tbody.reduce((maxArr, el) => {
      return el.map((elint, index) =>
        Number.parseFloat(elint) > maxArr[index]
          ? Number.parseFloat(elint)
          : maxArr[index]
      );
    }, initialMaxValuesArray);
  }
  if (table.tfoot.length !== 0) {
    maxValuesArray = table.tfoot.reduce(
      (maxArr, el) => {
        if (el[0] === "Walls and Shafts" || el[0] === "Const. Loan Proceeds")
          return el.map((elint, index) =>
            Number.parseFloat(elint) > maxArr[index]
              ? Number.parseFloat(elint)
              : maxArr[index]
          );
        return maxArr;
      },
      [...maxValuesArray]
    );
  }

  return (
    <table id={id} className={styles.table}>
      <thead>
        <tr key={`thead`}>
          {table.thead.map((thEl, index) => (
            <th key={`th ${index}`}>{thEl}</th>
          ))}
        </tr>
      </thead>
      <tfoot>
        {table.tfoot.map((trEl, index) => (
          <tr key={`tfoot ${index}`}>
            {trEl.map((tfEl, index) => {
              const isPercent =
                tfEl &&
                !!~tfEl.indexOf("%") &&
                (trEl[0] === "Walls and Shafts" ||
                  trEl[0] === "Const. Loan Proceeds");
              return (
                <td
                  // style={isPercent ? { "--width": tfEl } : {}}
                  style={
                    isPercent
                      ? {
                          "--width": `${
                            Number.parseFloat(tfEl) / maxValuesArray[index]
                          }`,
                        }
                      : {}
                  }
                  className={isPercent ? styles.percent : ""}
                  key={`td ${index}`}
                >
                  {tfEl}
                </td>
              );
            })}
          </tr>
        ))}
      </tfoot>
      <tbody>
        <tr className={styles.firstRow} key="firstRow">
          {table.tbody[0] &&
            table.tbody[0].map((tdEl, index) => <td key={`td ${index}`}></td>)}
        </tr>
        {table.tbody.map((trEl, index) => (
          <tr key={`tr ${index}`}>
            {trEl.map((tdEl, index) => {
              const isPercent =
                !!~tdEl.indexOf("%") &&
                !(table.thead[0] === "Guestrooms Revenue" && index === 4);
              return (
                <td
                  style={
                    isPercent
                      ? {
                          "--width": `${
                            Number.parseFloat(tdEl) / maxValuesArray[index]
                          }`,
                        }
                      : {}
                  }
                  className={isPercent ? styles.percent : ""}
                  key={`td ${index}`}
                >
                  {tdEl}
                </td>
              );
            })}
          </tr>
        ))}
        <tr className={styles.lastRow} key="lastRow">
          {table.tbody[0] &&
            table.tbody[0].map((tdEl, index) => <td key={`td ${index}`}></td>)}
        </tr>
      </tbody>
    </table>
  );
};

Table.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Table;
