const pad = (number) => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};

const toISOString = (Date) => {
  return (
    Date.getUTCFullYear() +
    "-" +
    pad(Date.getUTCMonth() + 1) +
    "-" +
    pad(Date.getUTCDate()) +
    "T" +
    pad(Date.getUTCHours()) +
    ":" +
    pad(Date.getUTCMinutes()) +
    ":" +
    pad(Date.getUTCSeconds()) +
    "." +
    (Date.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    "Z"
  );
};

export default toISOString;
