import React, { Component } from "react";
import styles from "./MapInner.module.scss";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1Ijoib2xla3NhbmRycGV0cnlrIiwiYSI6ImNrNGNudW91dzA4YnUzbnBmc3l5OGl3c3AifQ.GmdMReeE1fkKHjU1FE18Vw";

let mapInner;

export default class MapInner extends Component {
  initMap = () => {
    const { projectSite, projectLocation } = this.props.projectInfo;
    const { view } = this.props;

    mapInner = new mapboxgl.Map({
      container: "map-inner",
      style: view
        ? "mapbox://styles/mapbox/" + view
        : "mapbox://styles/mapbox/streets-v11",
    });

    // Add zoom and rotation controls to the map.
    mapInner.addControl(new mapboxgl.NavigationControl());

    mapInner.fitBounds(projectSite.bbox, {
      padding: 50,
    });

    mapInner.on("style.load", function () {
      mapInner.addSource("parcelOutlineInner", {
        type: "geojson",
        data: projectSite,
      });

      mapInner.addLayer({
        id: "parcelFillInner",
        type: "fill",
        source: "parcelOutlineInner",
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.4,
        },
      });

      mapInner.addLayer({
        id: "parcelLineInner",
        type: "line",
        source: "parcelOutlineInner",
        paint: {
          "line-color": "#307FF4",
          "line-width": 4,
        },
      });
    });
    new mapboxgl.Marker({ color: "#307FF4" })
      .setLngLat([
        projectLocation.geometry.coordinates[0],
        projectLocation.geometry.coordinates[1],
      ])
      .addTo(mapInner);
  };

  componentDidMount() {
    this.initMap();
  }

  onChange = (e) => {
    e.stopPropagation();
    e.persist();
    const { projectSite } = this.props.projectInfo;

    mapInner.fitBounds(projectSite.bbox, {
      padding: 50,
    });

    if (e.target.value === "satellite-v9") {
      this.props.setView({ view: "streets-v11" });
      mapInner.setStyle("mapbox://styles/mapbox/streets-v11");
    } else {
      this.props.setView({ view: "satellite-v9" });
      mapInner.setStyle("mapbox://styles/mapbox/satellite-v9");
    }
  };

  render() {
    const { address, area_sf, area_ac } = this.props.projectInfo;
    return (
      <div className={`${styles.MapInner}`}>
        <div className={styles.MapHead}>
          <div className={`${styles.MapLocation}`}>
            <span>
              {`${(address && address.streetNumber) || ""}`}{" "}
              {(address && address.street) || "noData"}
              {`, `}
            </span>
            <span className="font-weight-bold">
              {(address && address.city) || "noData"}
            </span>
            <span>, {(address && address.state) || "noData"}</span>
          </div>
          <div className={`${styles.MapLotArea}`}>
            <span className={styles.gray}>Lot area: </span>
            <span className="font-weight-bold">
              {new Intl.NumberFormat("en-US").format(
                Number(area_sf).toFixed(0)
              )}{" "}
              sf /{" "}
              {new Intl.NumberFormat("en-US").format(
                Number(area_ac).toFixed(2)
              )}{" "}
              acres
            </span>
          </div>
        </div>
        <div className={styles.MapBox}>
          <div
            style={{
              display: "inlineBlock",
              position: "absolute",
              marginTop: "10px",
              marginLeft: "10px",
              zIndex: "1",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            <button
              className={styles.toggleBtn}
              name="view"
              value={this.props.view}
              onClick={this.onChange}
            >
              {this.props.view === "streets-v11"
                ? "Show Satellite"
                : "Show Streets"}
            </button>
          </div>
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              zIndex: 3,
              fontWeight: 700,
              fontSize: 12,
              right: 6,
              top: 150,
              cursor: "pointer",
            }}
            onClick={this.props.toggleIsModalOpen}
          >
            <FullscreenExitIcon
              style={{
                color: "#7f7f7f",
                fontSize: 35,
                display: "flex",
                margin: "auto",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            />
          </div>
          <div id="map-inner" className={styles.mapInner}></div>
        </div>
      </div>
    );
  }
}
