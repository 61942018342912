import React from "react";
import NumberFormat from "react-number-format";
import styles from "./InputFieldElement.module.sass";
import PropTypes from "prop-types";

const InputFieldElement = ({
  id,
  budget_ffe: { name, value },
  handleChangeBudgetFfe,
  handleChangeBudgetFfeOnBlur,
  validationGuestroomBudget,
}) => {
  return (
    <>
      <label htmlFor={id}>{name}</label>
      <NumberFormat
        className={styles.BudgetHard}
        thousandSeparator={true}
        decimalSeparator={"."}
        thousandsGroupStyle={"thousand"}
        displayType={"input"}
        prefix={"$"}
        decimalScale={0}
        allowNegative={false}
        fixedDecimalScale={true}
        allowEmptyFormatting={true}
        allowLeadingZeros={false}
        id={id}
        value={value}
        onValueChange={({ floatValue = 0 }) => {
          return handleChangeBudgetFfe({
            [id]: {
              value: floatValue,
              name: name,
            },
          });
        }}
        onBlur={() => {
          const floatValue = validationGuestroomBudget({
            [id]: {
              name: name,
              value: value,
            },
          });
          handleChangeBudgetFfeOnBlur(floatValue);
        }}
      />
      <span className={styles.key}>/key</span>
    </>
  );
};

InputFieldElement.propTypes = {};

export default InputFieldElement;
