import React from "react";
import PropTypes from "prop-types";
import RoundArrow from "./RoundArrow/RoundArrow";
import Filter from "./Filter/Filter";
import Concept from "./Concept/Concept";
import BuildingProgram from "./BuildingProgram/BuildingProgram";
import Revenue from "./Revenue/Revenue";
import Budget from "./Budget/Budget";
import Investment from "./Investment/Investment";
import Add from "./Add/Add";
import Save from "./Save/Save";
import SaveRecalc from "./SaveRecalc/SaveRecalc";
import Export from "./Export/Export";
import Print from "./Print/Print";
import ShowMore from "./ShowMore/ShowMore";
import ArchiveThinCircle from "./ArchiveThinCircle/ArchiveThinCircle";
import PrintThinCircle from "./PrintThinCircle/PrintThinCircle";
import ExportThinCircle from "./ExportThinCircle/ExportThinCircle";
import Location from "./Location/Location";
import Logout from "./Logout/Logout";
import Phone from "./Phone/Phone";
import Envelope from "./Envelope/Envelope";
import Linkedin from "./Linkedin/Linkedin";
import Close from "./Close/Close";
import Star from "./Star/Star";
import ArrowLeft from "./ArrowLeft/ArrowLeft";
import ArrowRight from "./ArrowRight/ArrowRight";
import PDF from "./PDF/PDF";
import User from "./User/User";
import Password from "./Password/Password";
import Aerial from "./Aerial/Aerial";
import Perspective from "./Perspective/Perspective";
import Archive from "./Archive/Archive";
import RestoreArchive from "./RestoreArchive/RestoreArchive";
import Invite from "./Invite/Invite";
import Polygon from "./Polygon/Polygon";
import XLSX from "./XLSX/XLSX";
import DemoMode from "./DemoMode/DemoMode";
import Mode2d from "./Mode2d/Mode2d";
import Plus from "./Plus/Plus";
import Admin from "./Admin/Admin";
import Upload from "./Upload/Upload";
import PlusBig from "./PlusBig/PlusBig";
import Users from "./Users/Users";
import Brand from "./Brand/Brand";
import Family from "./Family/Family";
import Trash from "./Trash/Trash";

const Svg = ({ icon, className }) => {
  return (
    <>
      {icon === "RoundArrow" && <RoundArrow className={className} />}
      {icon === "Filter" && <Filter className={className} />}
      {icon === "Concept" && <Concept className={className} />}
      {icon === "BuildingProgram" && <BuildingProgram className={className} />}
      {icon === "Revenue" && <Revenue className={className} />}
      {icon === "Budget" && <Budget className={className} />}
      {icon === "Investment" && <Investment className={className} />}
      {icon === "Add" && <Add className={className} />}
      {icon === "Save" && <Save className={className} />}
      {icon === "Save/Recalc" && <SaveRecalc className={className} />}
      {icon === "Export" && <Export className={className} />}
      {icon === "Print" && <Print className={className} />}
      {icon === "ShowMore" && <ShowMore className={className} />}
      {icon === "ArchiveThinCircle" && (
        <ArchiveThinCircle className={className} />
      )}
      {icon === "PrintThinCircle" && <PrintThinCircle className={className} />}
      {icon === "ExportThinCircle" && (
        <ExportThinCircle className={className} />
      )}
      {icon === "Location" && <Location className={className} />}
      {icon === "Logout" && <Logout className={className} />}
      {icon === "Phone" && <Phone className={className} />}
      {icon === "Envelope" && <Envelope className={className} />}
      {icon === "Linkedin" && <Linkedin className={className} />}
      {icon === "Close" && <Close className={className} />}
      {icon === "Star" && <Star className={className} />}
      {icon === "ArrowLeft" && <ArrowLeft className={className} />}
      {icon === "ArrowRight" && <ArrowRight className={className} />}
      {icon === "PDF" && <PDF className={className} />}
      {icon === "User" && <User className={className} />}
      {icon === "Password" && <Password className={className} />}
      {icon === "Aerial" && <Aerial className={className} />}
      {icon === "Perspective" && <Perspective className={className} />}
      {icon === "Archive" && <Archive className={className} />}
      {icon === "RestoreArchive" && <RestoreArchive className={className} />}
      {icon === "Invite" && <Invite className={className} />}
      {icon === "Polygon" && <Polygon className={className} />}
      {icon === "XLSX" && <XLSX className={className} />}
      {icon === "DemoMode" && <DemoMode className={className} />}
      {icon === "Mode2d" && <Mode2d className={className} />}
      {icon === "Plus" && <Plus className={className} />}
      {icon === "Admin" && <Admin className={className} />}
      {icon === "Upload" && <Upload className={className} />}
      {icon === "PlusBig" && <PlusBig className={className} />}
      {icon === "Users" && <Users className={className} />}
      {icon === "Brand" && <Brand className={className} />}
      {icon === "Family" && <Family className={className} />}
      {icon === "Trash" && <Trash className={className} />}
    </>
  );
};

Svg.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Svg;
