import React from "react";
import BarChart from "../../BarChart/BarChart";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const valueFormat = (value) => Number((value * 100).toFixed(0));

const SliderWithHistogram = ({
  id,
  filterParameter,
  filterHistogram,
  minMaxHistogram,
  handleChangeForRoomTypePercentages,
  validationPercent,
}) => {
  return (
    <div className="mx-auto mt-3">
      <h5 className="name">{filterParameter.name}</h5>
      <BarChart
        data={filterHistogram}
        highlight={[
          Number(valueFormat(filterParameter.min)),
          Number(valueFormat(filterParameter.max)),
        ]}
        domain={[minMaxHistogram.min, minMaxHistogram.max]}
        step={1}
      />
      <Slider
        value={[
          valueFormat(filterParameter.min),
          valueFormat(filterParameter.max),
        ]}
        onChange={(mouseEvent, arrValEntered) => {
          handleChangeForRoomTypePercentages({
            [id]: {
              min: Number((arrValEntered[0] / 100).toFixed(2)),
              max: Number((arrValEntered[1] / 100).toFixed(2)),
              name: filterParameter.name,
            },
          });
        }}
        onChangeCommitted={(mouseEvent, arrValEntered) => {
          const floatValue = validationPercent({
            [id]: {
              min: Number((arrValEntered[0] / 100).toFixed(2)),
              max: Number((arrValEntered[1] / 100).toFixed(2)),
              name: filterParameter.name,
            },
          });
          handleChangeForRoomTypePercentages(floatValue);
        }}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        track="normal"
        min={minMaxHistogram.min}
        step={1}
        max={minMaxHistogram.max}
      />

      <div className="textInput">
        <div className="inputContainer">
          <NumberFormat
            className="inputPercent"
            thousandSeparator={true}
            decimalSeparator={"."}
            thousandsGroupStyle={"thousand"}
            displayType={"input"}
            prefix={""}
            decimalScale={0}
            allowNegative={false}
            fixedDecimalScale={true}
            allowEmptyFormatting={true}
            allowLeadingZeros={false}
            id={id + "Min"}
            value={valueFormat(filterParameter.min)}
            onValueChange={({ floatValue = 0 }) => {
              return handleChangeForRoomTypePercentages({
                [id]: {
                  min: Number((floatValue / 100).toFixed(2)),
                  max: filterParameter.max,
                  name: filterParameter.name,
                },
              });
            }}
            onBlur={() => {
              const floatValue = validationPercent({
                [id]: {
                  min: filterParameter.min,
                  max: filterParameter.max,
                  name: filterParameter.name,
                },
              });
              handleChangeForRoomTypePercentages(floatValue);
            }}
          />
          <label htmlFor={id + "Min"} className="inputSuffixPercent">
            %
          </label>
        </div>
        -
        <div className="inputContainer">
          <NumberFormat
            className="inputPercent"
            thousandSeparator={true}
            decimalSeparator={"."}
            thousandsGroupStyle={"thousand"}
            displayType={"input"}
            decimalScale={0}
            allowNegative={false}
            fixedDecimalScale={true}
            allowEmptyFormatting={true}
            allowLeadingZeros={false}
            id={id + "Max"}
            value={valueFormat(filterParameter.max)}
            onValueChange={({ floatValue = 0 }) => {
              return handleChangeForRoomTypePercentages({
                [id]: {
                  min: filterParameter.min,
                  max: Number((floatValue / 100).toFixed(2)),
                  name: filterParameter.name,
                },
              });
            }}
            onBlur={() => {
              const floatValue = validationPercent({
                [id]: {
                  min: filterParameter.min,
                  max: filterParameter.max,
                  name: filterParameter.name,
                },
              });
              handleChangeForRoomTypePercentages(floatValue);
            }}
          />
          <label htmlFor={id + "Max"} className="inputSuffixPercent">
            %
          </label>
        </div>
      </div>
    </div>
  );
};

SliderWithHistogram.propTypes = {};

export default SliderWithHistogram;
