import React, { Component } from "react";
import styles from "./Investment.module.sass";
import NumberFormat from "react-number-format";
import toastr from "toastr";
import { Scrollbars } from "react-custom-scrollbars";
import TooltipWithReactTooltip from "../../../../TooltipWithReactTooltip/TooltipWithReactTooltip";

const percentFormat = (value) => Number((value * 100).toFixed(5));

const invMarginMode = {
  NOI_MARGIN: "noi_margin",
  GOP_MARGIN: "gop_margin",
};

class Investment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setProjectInfoKeyInvestmentAssumptionsOnBlur({
      inv_margin_selection: "gop_margin",
    });
  }

  validationNOIMargin = (floatValue) => {
    if (floatValue < 1) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 1;
    } else if (floatValue > 100) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100;
    } else {
      return floatValue;
    }
  };

  validationPercent = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 100) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100;
    } else {
      return floatValue;
    }
  };

  validationPropertyTaxes = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100,000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 100000) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100,000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100000;
    } else {
      return floatValue;
    }
  };

  validationCapRateAtSale = (floatValue) => {
    if (floatValue < 1) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 1;
    } else if (floatValue > 20) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 20;
    } else {
      return floatValue;
    }
  };

  validationSellingCosts = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 20) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 20;
    } else {
      return floatValue;
    }
  };

  validationConstructionLoanLoanToCost = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 100) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 100. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100;
    } else {
      return floatValue;
    }
  };

  validationConstructionLoanInterestRate = (floatValue) => {
    if (floatValue < 3) {
      toastr.warning(
        `The entered value cannot be less than 3 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 3;
    } else if (floatValue > 20) {
      toastr.warning(
        `The entered value cannot be less than 3 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 20;
    } else {
      return floatValue;
    }
  };

  validationConstructionLoanFees = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 10. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 10) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 10. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 10;
    } else {
      return floatValue;
    }
  };

  validationConstructionLoanPeriod = (floatValue) => {
    if (floatValue < 6) {
      toastr.warning(
        `The entered value cannot be less than 6 and more than 60. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 6;
    } else if (floatValue > 60) {
      toastr.warning(
        `The entered value cannot be less than 6 and more than 60. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 60;
    } else {
      return floatValue;
    }
  };

  // handleBlurMonths = ({ target }) => {
  //   const { name, value } = target;
  //   if (value < 6)
  //     return this.props.setProjectInfoKeyInvestmentAssumptions({
  //       [name]: 6,
  //     });
  //   if (value > 60)
  //     return this.props.setProjectInfoKeyInvestmentAssumptions({
  //       [name]: 60,
  //     });
  // };

  handleChange =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptions(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue / 100 }), {})
      );
    };
  handleChangeOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptionsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue / 100 }), {})
      );
    };

  handleChangeOpEx =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptionsOpEx(
        names.reduce(
          (obj, name) => ({
            ...obj,
            [name]: names.includes("op_ex_prop_tax")
              ? floatValue
              : floatValue / 100,
          }),
          {}
        )
      );
    };
  handleChangeOpExOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptionsOpExOnBlur(
        names.reduce(
          (obj, name) => ({
            ...obj,
            [name]: names.includes("op_ex_prop_tax")
              ? floatValue
              : floatValue / 100,
          }),
          {}
        )
      );
    };

  handleChangeMon =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptions(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };
  handleChangeMonOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyInvestmentAssumptionsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  // handleChangeMonths = (e) => {
  //   const { name, value } = e.target;

  //   return this.props.setProjectInfoKeyInvestmentAssumptions({
  //     [name]: Number(value.replace(/[^\d.]+/g, "")),
  //   });
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    this.reset();
  };

  handleChangeRadioInvMargin = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    this.props.setProjectInfoKeyInvestmentAssumptionsOnBlur({
      [name]: value,
    });
  };

  render() {
    const { investment_assumptions } = this.props.projectInfo;
    investment_assumptions.inv_margin_selection =
      investment_assumptions.inv_margin_selection
        ? investment_assumptions.inv_margin_selection
        : "noi_margin";

    return (
      <section className={styles.investment}>
        <Scrollbars
          id="panContainer"
          autoHide
          autoHideTimeout={300}
          autoHideDuration={300}
          // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
          style={{ height: "calc(100vh - 80px)" }}
          renderTrackHorizontal={this.renderTrackHorizontal}
          universal={false}
        >
          <div className={styles.blockPanel} onSubmit={this.handleSubmit}>
            <h3 className={styles.title}>Investment</h3>
            <div className="mx-auto">
              <div className={styles.radioBlended}>
                <input
                  type="radio"
                  id="NOI_MARGIN"
                  checked={
                    investment_assumptions.inv_margin_selection ===
                    invMarginMode.NOI_MARGIN
                  }
                  name="inv_margin_selection"
                  value={invMarginMode.NOI_MARGIN}
                  onChange={this.handleChangeRadioInvMargin}
                />
                <label htmlFor="NOI_MARGIN">First</label>
                <input
                  type="radio"
                  id="GOP_MARGIN"
                  checked={
                    investment_assumptions.inv_margin_selection ===
                    invMarginMode.GOP_MARGIN
                  }
                  name="inv_margin_selection"
                  value={invMarginMode.GOP_MARGIN}
                  onChange={this.handleChangeRadioInvMargin}
                />
                <label htmlFor="GOP_MARGIN">Second</label>
              </div>
            </div>
            {investment_assumptions.inv_margin_selection ===
            invMarginMode.NOI_MARGIN ? (
              <>
                <div className="mx-auto">
                  <label htmlFor="NOIMargin">
                    One Table{" "}
                    <TooltipWithReactTooltip
                      title="eos qui ratione"
                      text="voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur."
                    />
                  </label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPercent}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="NOIMargin"
                        value={percentFormat(
                          investment_assumptions.inv_noi_margin
                        )}
                        onValueChange={this.handleChange(["inv_noi_margin"])}
                        onBlur={() => {
                          const floatValue = this.validationNOIMargin(
                            percentFormat(investment_assumptions.inv_noi_margin)
                          );
                          this.handleChangeOnBlur(["inv_noi_margin"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="NOIMargin"
                        className={styles.inputSuffixPercent}
                      >
                        %
                      </label>
                    </div>
                    <span>Initial</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mx-auto">
                  <label htmlFor="GOPMargin">laboriosam</label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPercent}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="GOPMargin"
                        value={percentFormat(
                          investment_assumptions.inv_gop_margin
                        )}
                        onValueChange={this.handleChange(["inv_gop_margin"])}
                        onBlur={() => {
                          const floatValue = this.validationPercent(
                            percentFormat(investment_assumptions.inv_gop_margin)
                          );
                          this.handleChangeOnBlur(["inv_gop_margin"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="GOPMargin"
                        className={styles.inputSuffixPercent}
                      >
                        %
                      </label>
                    </div>
                    <span>Quis autem vel</span>
                  </div>
                </div>
                <div className="mx-auto">
                  <label htmlFor="ManagementFee">
                    Management fee
                    {/* <TooltipWithReactTooltip title="Management Fee" text="test message" /> */}
                  </label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPercent}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        decimalScale={2}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="ManagementFee"
                        value={percentFormat(
                          investment_assumptions.inv_op_ex.op_ex_mgmt_fee
                        )}
                        onValueChange={this.handleChangeOpEx([
                          "op_ex_mgmt_fee",
                        ])}
                        onBlur={() => {
                          const floatValue = this.validationPercent(
                            percentFormat(
                              investment_assumptions.inv_op_ex.op_ex_mgmt_fee
                            )
                          );
                          this.handleChangeOpExOnBlur(["op_ex_mgmt_fee"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="ManagementFee"
                        className={styles.inputSuffixPercent}
                      >
                        %
                      </label>
                    </div>
                    <span>ui in ea voluptate</span>
                  </div>
                </div>
                <div className="mx-auto">
                  <label htmlFor="PropertyTaxes">
                    Property taxes
                    {/* <TooltipWithReactTooltip title="Property Taxes" text="test message" /> */}
                  </label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPerKey}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        prefix={"$"}
                        decimalScale={0}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="PropertyTaxes"
                        value={investment_assumptions.inv_op_ex.op_ex_prop_tax}
                        onValueChange={this.handleChangeOpEx([
                          "op_ex_prop_tax",
                        ])}
                        onBlur={() => {
                          const floatValue = this.validationPropertyTaxes(
                            investment_assumptions.inv_op_ex.op_ex_prop_tax
                          );
                          this.handleChangeOpExOnBlur(["op_ex_prop_tax"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="PropertyTaxes"
                        className={styles.inputSuffixKey}
                      >
                        /ex
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mx-auto">
                  <label htmlFor="Insurance">
                    Insurance
                    {/*<TooltipWithReactTooltip title="Insurance" text="test message" /> */}
                  </label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPercent}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="Insurance"
                        value={percentFormat(
                          investment_assumptions.inv_op_ex.op_ex_insurance
                        )}
                        onValueChange={this.handleChangeOpEx([
                          "op_ex_insurance",
                        ])}
                        onBlur={() => {
                          const floatValue = this.validationPercent(
                            percentFormat(
                              investment_assumptions.inv_op_ex.op_ex_insurance
                            )
                          );
                          this.handleChangeOpExOnBlur(["op_ex_insurance"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="Insurance"
                        className={styles.inputSuffixPercent}
                      >
                        %
                      </label>
                    </div>
                    <span>consequatur</span>
                  </div>
                </div>
                <div className="mx-auto">
                  <label htmlFor="Reserves">
                    Reserves
                    {/*<TooltipWithReactTooltip title="Reserves" text="test message" /> */}
                  </label>
                  <div className={styles.textInput}>
                    <div className={styles.inputContainer}>
                      <NumberFormat
                        className={styles.inputPercent}
                        thousandSeparator={true}
                        decimalSeparator={"."}
                        thousandsGroupStyle={"thousand"}
                        displayType={"input"}
                        decimalScale={2}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        id="Reserves"
                        value={percentFormat(
                          investment_assumptions.inv_op_ex.op_ex_reserves
                        )}
                        onValueChange={this.handleChangeOpEx([
                          "op_ex_reserves",
                        ])}
                        onBlur={() => {
                          const floatValue = this.validationPercent(
                            percentFormat(
                              investment_assumptions.inv_op_ex.op_ex_reserves
                            )
                          );
                          this.handleChangeOpExOnBlur(["op_ex_reserves"])({
                            floatValue,
                          });
                        }}
                      />
                      <label
                        htmlFor="Reserves"
                        className={styles.inputSuffixPercent}
                      >
                        %
                      </label>
                    </div>
                    <span>Sed ut perspiciatis</span>
                  </div>
                </div>
              </>
            )}
            {/* <button className={styles.submit} type="submit">
            <Svg icon="RoundArrow" className={styles.roundArrow} />
          </button> */}
          </div>
          <div className={styles.blockPanel} onSubmit={this.handleSubmit}>
            <h3 className={styles.title}>Rate at sale</h3>

            <div className="mx-auto">
              <label htmlFor="CapRateAtSale">
                dolor sit amet{" "}
                <TooltipWithReactTooltip
                  title="dolore magnam"
                  text="consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaera."
                />
              </label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="CapRateAtSale"
                    value={percentFormat(investment_assumptions.inv_caprate)}
                    onValueChange={this.handleChange(["inv_caprate"])}
                    onBlur={() => {
                      const floatValue = this.validationCapRateAtSale(
                        percentFormat(investment_assumptions.inv_caprate)
                      );
                      this.handleChangeOnBlur(["inv_caprate"])({ floatValue });
                    }}
                  />
                  <label
                    htmlFor="CapRateAtSale"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <label htmlFor="SellingCosts">
                Selling costs{" "}
                <TooltipWithReactTooltip
                  title="inventore veritatis et"
                  text="quasi architecto beatae vitae dicta sunt explicabo."
                />
              </label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="SellingCosts"
                    value={percentFormat(
                      investment_assumptions.inv_selling_costs
                    )}
                    onValueChange={this.handleChange(["inv_selling_costs"])}
                    onBlur={() => {
                      const floatValue = this.validationSellingCosts(
                        percentFormat(investment_assumptions.inv_selling_costs)
                      );
                      this.handleChangeOnBlur(["inv_selling_costs"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="SellingCosts"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <label htmlFor="ConstLoanLoan-to-Cost">Const Loan to Cost</label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={1}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="ConstLoanLoan-to-Cost"
                    value={percentFormat(investment_assumptions.inv_c_loan_ltc)}
                    onValueChange={this.handleChange(["inv_c_loan_ltc"])}
                    onBlur={() => {
                      const floatValue =
                        this.validationConstructionLoanLoanToCost(
                          percentFormat(investment_assumptions.inv_c_loan_ltc)
                        );
                      this.handleChangeOnBlur(["inv_c_loan_ltc"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="ConstLoanLoan-to-Cost"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <label htmlFor="ConstLoanInterestRate">
                Const loan interest rate
              </label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="ConstLoanInterestRate"
                    value={percentFormat(
                      investment_assumptions.inv_c_loan_interest_rate
                    )}
                    onValueChange={this.handleChange([
                      "inv_c_loan_interest_rate",
                    ])}
                    onBlur={() => {
                      const floatValue =
                        this.validationConstructionLoanInterestRate(
                          percentFormat(
                            investment_assumptions.inv_c_loan_interest_rate
                          )
                        );
                      this.handleChangeOnBlur(["inv_c_loan_interest_rate"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="ConstLoanInterestRate"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <label htmlFor="ConstLoanFees">
                Const loan fees{" "}
                <TooltipWithReactTooltip
                  title="ut aliquid ex ea"
                  text="veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur."
                />
              </label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="ConstLoanFees"
                    value={percentFormat(investment_assumptions.inv_c_loan_fee)}
                    onValueChange={this.handleChange(["inv_c_loan_fee"])}
                    onBlur={() => {
                      const floatValue = this.validationConstructionLoanFees(
                        percentFormat(investment_assumptions.inv_c_loan_fee)
                      );
                      this.handleChangeOnBlur(["inv_c_loan_fee"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="ConstLoanFees"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <label htmlFor="ConstructionLoanPeriod">
                Construction loan period{" "}
                <TooltipWithReactTooltip
                  title="qui ratione voluptatem sequi"
                  text="quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
                />
              </label>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.months}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="ConstructionLoanPeriod"
                    value={`${investment_assumptions.inv_c_loan_period}`}
                    onValueChange={this.handleChangeMon(["inv_c_loan_period"])}
                    // onblur={this.handleChangeMon(["inv_c_loan_period"])}
                    // isAllowed={this.validationConstructionLoanPeriod}
                    onBlur={() => {
                      const floatValue = this.validationConstructionLoanPeriod(
                        investment_assumptions.inv_c_loan_period
                      );
                      this.handleChangeMonOnBlur(["inv_c_loan_period"])({
                        floatValue,
                      });
                    }}
                  />
                  {/* <input
                  className={styles.months}
                  type="number"
                  id="ConstructionLoanPeriod"
                  name="inv_c_loan_period"
                  value={`${investment_assumptions.inv_c_loan_period}`}
                  onChange={this.handleChangeMonths}
                  min={6}
                  max={60}
                  onBlur={this.handleBlurMonths}
                /> */}
                  <label
                    htmlFor="ConstructionLoanPeriod"
                    className={styles.inputSuffixMonths}
                  >
                    nisi ut
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
    );
  }
}

export default Investment;
