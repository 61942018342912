import { combineReducers } from "redux";
import { ActionType } from "./familyActions";

const initialState = {
  brand_contact: {
    company: "Company Name",
    companyWebsiteURL: "https://marriottdevelopment.com",
    contactPhotoURL:
      "https://thumbs.dreamstime.com/b/%D0%B2%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%B7%D0%BD%D0%B0%D1%87%D0%BA%D0%B0-%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D0%BC%D0%B5%D0%BD%D0%B0-%D0%BC%D1%83%D0%B6%D1%87%D0%B8%D0%BD%D1%8B-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8F-%D0%B0%D0%B2%D0%B0%D1%82%D0%B0%D1%80-%D0%BC%D1%83%D0%B6%D1%81%D0%BA%D0%BE%D0%B9-182095609.jpg",
    department: "",
    email: "Name.Surname@mail.com",
    firstName: "Name",
    jobTitle: "Developer job title",
    lastName: "Surname",
    linkedinURL: "https://www.linkedin.com",
    phone: "068-000-00-00",
  },
  familyLogoURL:
    "https://hcti.io/v1/image/fba429e5-caf3-4725-aeea-10a9fe32df03",
  family_name: "Family Name",
};

const familyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_FAMILY_SUCCESS:
      return payload.family;

    default:
      return state;
  }
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case ActionType.FETCH_FAMILY_START:
      return true;

    case ActionType.FETCH_FAMILY_SUCCESS:
    case ActionType.FETCH_FAMILY_ERROR:
      return false;

    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_FAMILY_START:
      return null;

    case ActionType.FETCH_FAMILY_ERROR:
      return payload.error;

    default:
      return state;
  }
};

export default combineReducers({
  family: familyReducer,
  loading: loadingReducer,
  error: errorReducer,
});
