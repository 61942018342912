import React from "react";
import PropTypes from "prop-types";

const Export = ({ className }) => {
  return (
    <svg
      id="Group_41_Copy"
      data-name="Group 41 Copy"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={className}
    >
      <g
        className={className}
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14" fill="none" />
      </g>
      <g data-name="search (1)" transform="translate(8 9)" stroke="none">
        <g>
          <path
            d="M13.46,5.2H.406A.4.4,0,0,1,0,4.813V.387A.4.4,0,0,1,.406,0h3.6A.4.4,0,0,1,4.4.268l.482,1.421H8.99L9.471.268A.4.4,0,0,1,9.858,0h3.6a.4.4,0,0,1,.406.387V4.813A.4.4,0,0,1,13.46,5.2ZM.867.867V4.333H13V.867H10.125L9.648,2.216a.4.4,0,0,1-.383.255H4.6a.4.4,0,0,1-.383-.255L3.742.867Z"
            transform="translate(0 6.8)"
            fill="#fff"
          />
          <path
            data-name="Shape"
            d="M2.947,8.32a.443.443,0,0,1-.368-.2L.077,4.4a.448.448,0,0,1-.024-.461A.441.441,0,0,1,.445,3.7H1.462V.448A.447.447,0,0,1,1.907,0H3.986a.447.447,0,0,1,.446.448V3.7H5.449a.441.441,0,0,1,.392.238.45.45,0,0,1-.024.462l-2.5,3.723A.441.441,0,0,1,2.947,8.32ZM1.283,4.6,2.947,7.071,4.61,4.6H3.986a.447.447,0,0,1-.445-.448V.9H2.352V4.147a.447.447,0,0,1-.445.448Z"
            transform="translate(3.913)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

Export.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Export;
