import React from "react";
import PropTypes from "prop-types";
import styles from "./Tab.module.sass";
import svg from "../../../../Svg";

import { Link } from "react-scroll";

const Tab = ({
  icon,
  head,
  value1,
  value2,
  isActive,
  onIndexSelect,
  value,
  to,
}) => {
  return (
    <Link
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      offset={0}
      duration={400}
      containerId="tablesContainer"
    >
      <button
        className={`${styles.tab} ${isActive && styles.active}`}
        onClick={onIndexSelect}
        value={value}
      >
        <p className={styles.head}>
          <img className={styles.icon} src={svg[icon]} alt="" />
          {`${head}`}
        </p>
        <div>
          <p className={styles.value}>{`${value1}`}</p>
          <p className={styles.value}>{`${value2}`}</p>
        </div>
      </button>
    </Link>
  );
};

Tab.propTypes = {
  icon: PropTypes.string.isRequired,
  head: PropTypes.string.isRequired,
  value1: PropTypes.string.isRequired,
  value2: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onIndexSelect: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  to: PropTypes.string.isRequired,
};

export default Tab;
