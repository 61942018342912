import React, { Component } from "react";
import styles from "./Map.module.scss";
import Modal from "../../../Modal/Modal";
import isEmpty from "../../../../helpers/isEmpty";
import MapInner from "./MapInner/MapInnerContainer";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken =
  "pk.eyJ1Ijoib2xla3NhbmRycGV0cnlrIiwiYSI6ImNrNGNudW91dzA4YnUzbnBmc3l5OGl3c3AifQ.GmdMReeE1fkKHjU1FE18Vw";
let map;

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isDrowObjectOnMap: false,
      coordinate: null,
    };
  }

  setView = ({ view }) => {
    this.props.setProjectInfoKeyView(view);

    const { projectSite } = this.props.projectInfo;

    map.fitBounds(projectSite.bbox, {
      padding: 50,
    });
    map.setStyle("mapbox://styles/mapbox/" + view);
  };

  onChange = (e) => {
    e.stopPropagation();
    e.persist();

    const { projectSite } = this.props.projectInfo;

    map.fitBounds(projectSite.bbox, {
      padding: 50,
    });

    if (e.target.value === "satellite-v9") {
      this.props.setProjectInfoKeyView("streets-v11");
      map.setStyle("mapbox://styles/mapbox/streets-v11");
    } else {
      this.props.setProjectInfoKeyView("satellite-v9");
      map.setStyle("mapbox://styles/mapbox/satellite-v9");
    }
  };

  toggleIsModalOpen = (evt) => {
    evt.stopPropagation();
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  initMap = () => {
    const { projectSite, projectLocation, view } = this.props.projectInfo;

    map = new mapboxgl.Map({
      container: "map-parcel",
      style: view
        ? "mapbox://styles/mapbox/" + view
        : "mapbox://styles/mapbox/streets-v11",
    });
    let bounds = null;
    let i = 0;
    const checkFitBoundsStart = () => {
      map.fitBounds([...projectSite.bbox], {
        padding: 50,
      });
      bounds = map.getBounds();
      i++;
      if (bounds._ne.lng > 176 && i < 10) {
        setTimeout(checkFitBoundsStart, 500);
      }
    };
    checkFitBoundsStart();

    setTimeout(() => {
      const bounds = map.getBounds();
    }, 5000);

    setTimeout(() => {
      const bounds = map.getBounds();
    }, 10000);

    map.on("style.load", function () {
      map.addSource("parcelOutline", {
        type: "geojson",
        data: projectSite,
      });

      map.addLayer({
        id: "parcelFill",
        type: "fill",
        source: "parcelOutline",
        //'layout': {},
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.4,
        },
      });

      map.addLayer({
        id: "parcelLine",
        type: "line",
        source: "parcelOutline",
        paint: {
          "line-color": "#307FF4",
          "line-width": 4,
        },
      });
    });

    new mapboxgl.Marker({ color: "#307FF4" })
      .setLngLat([
        projectLocation.geometry.coordinates[0],
        projectLocation.geometry.coordinates[1],
      ])
      .addTo(map);
  };

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate() {
    const { isDrowObjectOnMap } = this.state;
    const { projectInfo, isDemoModeOff } = this.props;
    const { projectSite } = this.props.projectInfo;

    if (!isEmpty(projectInfo) && !isDrowObjectOnMap) {
      this.setState({
        isDrowObjectOnMap: true,
        coordinate: projectInfo.projectLocation,
      });
      // this.initMap();
      map.fitBounds(projectSite.bbox, {
        padding: 50,
      });
    }
  }

  render() {
    const { isModalOpen } = this.state;
    const { address, area_sf, area_ac, view } = this.props.projectInfo;
    return (
      <div className={`${styles.Map}`}>
        <div className={styles.MapHead}>
          <div className={`${styles.MapLocation}`}>
            <span>
              {`${(address && address.streetNumber) || ""}`}{" "}
              {(address && address.street) || "noData"}
              {`, `}
            </span>
            <span className="font-weight-bold">
              {(address && address.city) || "noData"}
            </span>
            <span>, {(address && address.state) || "noData"}</span>
          </div>
          <div className={`${styles.MapLotArea}`}>
            <span className={styles.gray}>Lot Area: </span>
            <span className="font-weight-bold">
              {new Intl.NumberFormat("en-US").format(
                Number(area_sf).toFixed(0)
              )}{" "}
              sf /{" "}
              {new Intl.NumberFormat("en-US").format(
                Number(area_ac).toFixed(2)
              )}{" "}
              acres
            </span>
          </div>
        </div>
        <div className={styles.MapBox}>
          <div
            style={{
              display: "inlineBlock",
              position: "absolute",
              marginTop: "10px",
              marginLeft: "10px",
              zIndex: "1",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            <button
              className={styles.toggleBtn}
              name="view"
              value={view}
              onClick={this.onChange}
            >
              {view === "streets-v11" ? "Show Satellite" : "Show Streets"}
            </button>
          </div>
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              zIndex: 3,
              fontWeight: 700,
              fontSize: 12,
              right: 10,
              top: 70,
              cursor: "pointer",
            }}
            onClick={this.toggleIsModalOpen}
          >
            <FullscreenIcon
              style={{
                color: "#7f7f7f",
                fontSize: 30,
                display: "flex",
                margin: "auto",
                backgroundColor: "white",
                borderRadius: 5,
              }}
            />
          </div>
          <div id="map-parcel" className={styles.mapParcel}></div>
        </div>
        {isModalOpen && (
          <Modal onClose={this.toggleIsModalOpen}>
            <MapInner
              view={view}
              setView={this.setView}
              toggleIsModalOpen={this.toggleIsModalOpen}
            />
          </Modal>
        )}
      </div>
    );
  }
}
