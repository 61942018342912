import React from "react";
import PropTypes from "prop-types";

const Polygon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      className={className}
      fill="none"
      strokeWidth="1.5"
    >
      <g>
        <ellipse ry="2" rx="2" cy="12" cx="9" />
        <ellipse ry="2" rx="2" cy="8" cx="20.625" />
        <ellipse ry="2" rx="2" cy="17" cx="26" />
        <ellipse ry="2" rx="2" cy="23" cx="8.8" />
        <line y2="19.40332" x2="8.8" y1="16.52832" x1="9" />
        <line y2="13" x2="24" y1="11" x1="23" />
        <line y2="9" x2="17.21875" y1="11.02832" x1="12.21875" />
        <line y2="19" x2="22" y1="22.15332" x1="12.84375" />
      </g>
    </svg>
  );
};

Polygon.propTypes = { className: PropTypes.string.isRequired };

export default Polygon;
