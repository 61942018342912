export const ActionType = {
  FETCH_MODELS_START: "FETCH_MODELS_START",
  FETCH_MODELS_SUCCESS: "FETCH_MODELS_SUCCESS",
  FETCH_MODELS_ERROR: "FETCH_MODELS_ERROR",
  FETCH_MORE_MODELS_START: "FETCH_MORE_MODELS_START",
  FETCH_MORE_MODELS_SUCCESS: "FETCH_MORE_MODELS_SUCCESS",
  FETCH_MORE_MODELS_ERROR: "FETCH_MORE_MODELS_ERROR",
  ADD_PAGE: "ADD_PAGE",
  RESET_PAGE: "RESET_PAGE",
  SET_ACTIVE_PROGRAM_ID: "SET_ACTIVE_PROGRAM_ID",
  SET_ROOM_MIX_KEYS: "SET_ROOM_MIX_KEYS",
  SET_FILTER_HISTOGRAMS: "SET_FILTER_HISTOGRAMS",
  SET_MIN_MAX_HISTOGRAMS: "SET_MIN_MAX_HISTOGRAMS",
  SET_FILTER_PARAMETERS: "SET_FILTER_PARAMETERS",
  SET_FILTER_PARAMETERS_FOR_ROOM_TYPE_PERCENTAGES:
    "SET_FILTER_PARAMETERS_FOR_ROOM_TYPE_PERCENTAGES",
  FETCH_TABLES_FOR_MODEL_START: "FETCH_TABLES_FOR_MODEL_START",
  FETCH_TABLES_FOR_MODEL_SUCCESS: "FETCH_TABLES_FOR_MODEL_SUCCESS",
  FETCH_TABLES_FOR_MODEL_ERROR: "FETCH_TABLES_FOR_MODEL_ERROR",
  FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_START:
    "FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_START",
  FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SUCCESS:
    "FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SUCCESS",
  FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_ERROR:
    "FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_ERROR",
  SET_PREVIOUS_REQUEST_FOR_MODELS: "SET_PREVIOUS_REQUEST_FOR_MODELS",
  SET_SORTING: "SET_SORTING",
  TOGGLE_MODEL_STARRED: "TOGGLE_MODEL_STARRED",
  TOGGLE_MODEL_HIDDEN: "TOGGLE_MODEL_HIDDEN",
  RESULT_CALCULATED: "RESULT_CALCULATED",
  RESULT_NOT_CALCULATED_YET: "RESULT_NOT_CALCULATED_YET",
  FETCH_DATA_FOR_PDF_START: "FETCH_DATA_FOR_PDF_START",
  FETCH_DATA_FOR_PDF_SUCCESS: "FETCH_DATA_FOR_PDF_SUCCESS",
  FETCH_DATA_FOR_PDF_ERROR: "FETCH_DATA_FOR_PDF_ERROR",
  REMOVE_MODELS: "REMOVE_MODELS",
  REMOVE_TABLES: "REMOVE_TABLES",
  WAS_SAVED: "WAS_SAVED",
  REMOVE_MODELS_EXCEPT_ACTIVE: "REMOVE_MODELS_EXCEPT_ACTIVE",
  CHANGE_DATA_IN_TABLE: "CHANGE_DATA_IN_TABLE",
  SET_GUESTROOMS_BY_TYPE: "SET_GUESTROOMS_BY_TYPE",
};

/*
 * Fetch data for PDF
 */
export const changeDataInTable = (payload) => ({
  type: ActionType.CHANGE_DATA_IN_TABLE,
  payload,
});
export const fetchDataForPDFStart = (id) => ({
  type: ActionType.FETCH_DATA_FOR_PDF_START,
  payload: {
    id,
  },
});

export const fetchDataForPDFSuccess = (id) => ({
  type: ActionType.FETCH_DATA_FOR_PDF_SUCCESS,
  payload: {
    id,
  },
});

export const fetchDataForPDFError = (error, id) => ({
  type: ActionType.FETCH_DATA_FOR_PDF_ERROR,
  payload: {
    error,
  },
});

export const addPage = () => ({
  type: ActionType.ADD_PAGE,
});
export const resetPage = () => ({
  type: ActionType.RESET_PAGE,
});

/*
 * Toggle model key isStarred
 */
export const toggleModelStarred = (id) => ({
  type: ActionType.TOGGLE_MODEL_STARRED,
  payload: {
    id,
  },
});

/*
 * Toggle model key isHidden
 */
export const toggleModelHidden = (id) => ({
  type: ActionType.TOGGLE_MODEL_HIDDEN,
  payload: {
    id,
  },
});

/*
 * Remove models except active
 */
export const removeModelsExceptActive = (id) => ({
  type: ActionType.REMOVE_MODELS_EXCEPT_ACTIVE,
  payload: {
    id,
  },
});

/*
 * PreviousRequestForModels Change
 */
export const setPreviousRequestForModels = (previousRequestForModels) => ({
  type: ActionType.SET_PREVIOUS_REQUEST_FOR_MODELS,
  payload: previousRequestForModels,
});

export const setGuestRoomsRevenueByType = (adr) => ({
  type: ActionType.SET_GUESTROOMS_BY_TYPE,
  payload: { adr },
});

/*
 * ActiveProgramId Change
 */
export const setActiveProgramId = (activeProgramId) => ({
  type: ActionType.SET_ACTIVE_PROGRAM_ID,
  payload: activeProgramId,
});

/*
 * setRoomMixKeys Change
 */
export const setRoomMixKeys = (roomMixKeys) => ({
  type: ActionType.SET_ROOM_MIX_KEYS,
  payload: roomMixKeys,
});

/*
 * FilterParameters Change
 */
export const setFilterParameters = (payload) => {
  return {
    type: ActionType.SET_FILTER_PARAMETERS,
    payload: payload,
  };
};

/*
 * FilterHistograms Change
 */
export const setFilterHistograms = (payload) => {
  return {
    type: ActionType.SET_FILTER_HISTOGRAMS,
    payload: payload,
  };
};

/*
 * MinMaxHistograms Change
 */
export const setMinMaxHistograms = (payload) => {
  return {
    type: ActionType.SET_MIN_MAX_HISTOGRAMS,
    payload: payload,
  };
};

export const setFilterParametersForRoomTypePercentages = (payload) => {
  return {
    type: ActionType.SET_FILTER_PARAMETERS_FOR_ROOM_TYPE_PERCENTAGES,
    payload: payload,
  };
};

/*
 * Sorting Change
 */
export const setSorting = (payload) => {
  return {
    type: ActionType.SET_SORTING,
    payload: payload,
  };
};

export const setTrueResultCalculated = () => ({
  type: ActionType.RESULT_CALCULATED,
});

export const setFalseResultCalculated = () => ({
  type: ActionType.RESULT_NOT_CALCULATED_YET,
});

export const removeModels = (payload) => {
  return {
    type: ActionType.REMOVE_MODELS,
    payload: payload,
  };
};
export const removeTables = (payload) => {
  return {
    type: ActionType.REMOVE_TABLES,
    payload: payload,
  };
};
