import React, { Component } from "react";
import "./EditUser.sass";

const rules = {
  firstName: "required|alpha",
  lastName: "required|alpha",
  email: "required|email",
  companyName: "required",
};

const messages = {
  "firstName.required": "Please enter your first name",
  "lastName.required": "Please enter your last name",
  "email.required": "Please enter your email",
  "companyName.required": "Please enter your company name",
  "firstName.alpha": "firstName contains unallowed characters",
  "lastName.alpha": "lastName contains unallowed characters",
  "email.email": "Please enter a valid email address",
};

const user = {
  user: {
    _id: "5d4db8ad455e09be36d17786",
    companyName: "Demo Map Box 3d",
    email: "Name.Surname@gmail.com",
    families: ["5cd743ca8f008a50e8b14ee9"],
    firstName: "Name",
    lastName: "Surname",
    password: "",
    password_set_date: "Tue, 11 May 2021 12:49:13 GMT",
    password_too_old: false,
  },
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    console.log(user.user);
    const { firstName, lastName, email, companyName, families } = user.user;
    this.state = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: "",
      passwordConfirm: "",
      companyName: companyName,
      families: families,
      errors: null,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      errors: null,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      companyName,
      families,
    } = this.state;
    const { editUser } = this.props;

    // eslint-disable-next-line no-undef
    validate(
      { firstName, lastName, email, password, passwordConfirm, companyName },
      rules,
      messages
    )
      .then(() => {
        editUser({
          firstName,
          lastName,
          email,
          password,
          companyName,
          families,
        });
      })
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach((error) => {
          formattedErrors[error.field] = error.response.data;
        });

        this.setState({
          errors: formattedErrors,
        });
      });
  };

  reset = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      companyName: "",
      errors: null,
    });
  };
  redirectToProfile = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    const { firstName, lastName, email, companyName, errors } = this.state;

    return (
      <section className="editUser">
        <div className="container">
          <div className="row justify-content-center">
            <div className="wrapper col-12 col-md-9 col-lg-7 col-xl-6 d-flex justify-content-center">
              <form onSubmit={this.handleSubmit}>
                <h4 className="mb-5 mt-5">Edit user account</h4>
                <div className="input d-sm-flex align-items-center mb-4">
                  <h5>First name</h5>
                  <div className="inputContainer">
                    <input
                      autoFocus
                      className={errors && errors.firstName && "error"}
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="input d-sm-flex align-items-center mb-4">
                  <h5>Last name</h5>
                  <div className="inputContainer">
                    <input
                      className={errors && errors.lastName && "error"}
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="email d-sm-flex align-items-center mb-4">
                  <h5>E-mail </h5>
                  <div className="inputContainer">
                    <input
                      className={errors && errors.email && "error"}
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="input d-sm-flex align-items-center mb-4">
                  <h5>Company Name</h5>
                  <div className="inputContainer">
                    <input
                      className={errors && errors.companyName && "error"}
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="edituser d-block mx-auto mt-5 mb-3"
                  disabled={!!errors}
                  onClick={this.redirectToProfile}
                >
                  Save
                </button>
                <div className="navigation"></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EditUser;
