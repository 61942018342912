import React from "react";
import PropTypes from "prop-types";

const Plus = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.677 11.877 14.877"
      className={className}
    >
      <g>
        <path
          d="M11.177,5.867H6.734V.74a.829.829,0,0,0-1.649,0V5.867H.641A.89.89,0,0,0,0,6.818a.89.89,0,0,0,.641.951H5.085V12.9a.829.829,0,0,0,1.649,0V7.77h4.443a.89.89,0,0,0,.641-.951A.89.89,0,0,0,11.177,5.867Z"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

Plus.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Plus;
