import { combineReducers } from "redux";
import { ActionType } from "./filtersActions";
export const defaultFilterParameters = {
  // projectId: "5e20981c42d8ffc7528759a2",
  // brandId: 103,
  // familyId: "5cd743ca8f008a50e8b14ee9",
  sorting: "inv_cash_on_cost",
  buildingHeightMin: 0,
  buildingHeightMax: 100,
  roomCountMin: 0,
  roomCountMax: 0,
  // floorAreaRatoMin: 0,
  floorAreaRatioMax: 3,
  option_meeting: 0,
  // option_market: 1,
  option_port_cochere: 1,
  option_pool: 0,
  minOpenSpace: 0.2,
  floors: [4, 5, 6],
  // roomTypePercentages: {
  //   kingMin: 0.6,
  //   kingMax: 0.8,
  //   queenMin: 0.2,
  //   queenMax: 0.4,
  //   suiteMin: 0.03,
  //   suiteMax: 0.06,
  // },
  room_mix: null,
  // {
  //   double: {max: 1, min: 0, name: "Queen/Queen"},
  //   single: {max: 1, min: 0, name: "King"},
  //   suite: {max: 1, min: 0, name: "Suite"},
  // },
};

export const resetTables = {
  "3d_settings": {
    bearing: -12.799999999999727,
    buildingPolygon: {
      geometry: {
        coordinates: [
          [
            [-71.14252161819991, 41.683979039357936],
            [-71.14247863063315, 41.68365132998592],
            [-71.14247863063315, 41.68365132998592],
            [-71.14131368198969, 41.68373655479807],
            [-71.14131368198969, 41.68373655479807],
            [-71.14135942694196, 41.68408533606282],
            [-71.14135942694196, 41.68408533606282],
            [-71.14160447271462, 41.68406741020323],
            [-71.14160447271462, 41.68406741020323],
            [-71.14161575325613, 41.684153415376436],
            [-71.14161575325613, 41.684153415376436],
            [-71.1422908120528, 41.684104029894065],
            [-71.1422908120528, 41.684104029894065],
            [-71.14227676658749, 41.68399695317002],
            [-71.14227676658749, 41.68399695317002],
            [-71.14252161819991, 41.683979039357936],
          ],
        ],
        type: "Polygon",
      },
      properties: {},
      type: "Feature",
    },
    coordinates: [-71.14221846174439, 41.683769709807365],
    modelTransform: {
      rotateX: 1.5707963267948966,
      rotateY: 0.09764895170534132,
      rotateZ: 0,
      scale: 3.344934055870973e-8,
      translateX: 0.30238272649515446,
      translateY: 0.37239614155758516,
      translateZ: 0,
    },
    pitch: 57.49999999999999,
  },
  brand_contact: {
    familyContact: {
      company: "Marriott International",
      companyWebsiteURL: "https://marriottdevelopment.com",
      contactPhotoURL:
        "https://thumbs.dreamstime.com/b/%D0%B2%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%B7%D0%BD%D0%B0%D1%87%D0%BA%D0%B0-%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D0%BC%D0%B5%D0%BD%D0%B0-%D0%BC%D1%83%D0%B6%D1%87%D0%B8%D0%BD%D1%8B-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8F-%D0%B0%D0%B2%D0%B0%D1%82%D0%B0%D1%80-%D0%BC%D1%83%D0%B6%D1%81%D0%BA%D0%BE%D0%B9-182095609.jpg",
      department: "",
      email: "Name.Surname@mail.com",
      firstName: "Name",
      jobTitle: "Developer job title",
      lastName: "Surname",
      linkedinURL: "https://www.linkedin.com",
      phone: "068-000-00-00",
    },
    familyLogoURL:
      "https://hcti.io/v1/image/fba429e5-caf3-4725-aeea-10a9fe32df03",
    familyName: "Marriott",
  },
  building_program: {
    back: {
      area: 5891,
      area_key: 30.682291666666668,
      percent: 0.05955498043814511,
    },
    food_beverage: {
      area: 405,
      area_key: 2.109375,
      percent: 0.004094341720836661,
    },
    guestrooms: {
      area: 65913,
      area_key: 343.296875,
      percent: 0.6663465329518687,
    },
    guestrooms_corridors_support: {
      area: 11415,
      area_key: 59.453125,
      percent: 0.11539977961321107,
    },
    lobby: { area: 4638, area_key: 24.15625, percent: 0.046887794817877616 },
    meeting_spaces: {
      area: 721,
      area_key: 3.7552083333333335,
      percent: 0.007288939211662303,
    },
    recreation: {
      area: 1156,
      area_key: 6.020833333333333,
      percent: 0.01168656550441279,
    },
    retail: { area: 108, area_key: 0.5625, percent: 0.0010918244588897764 },
    total_gross: { area: 98917, area_key: 515.1927083333334, percent: 1.0 },
    total_net: {
      area: 90247,
      area_key: 470.0364583333333,
      percent: 0.9123507587169041,
    },
    walls_shafts: {
      area: 8670,
      area_key: 45.15625,
      percent: 0.08764924128309592,
    },
  },
  design_numbers: {
    far: 0.16893279163629768,
    floors: 5,
    height: 54,
    parking: 192,
  },
  development_budget: {
    budget_custom: [],
    const_loan_interest_fee: {
      cost_gsf: 30.16765229878071,
      cost_per_key: 15542.154491867142,
      total_budget: 2984093.6624384914,
      total_budget_percent: 0.0855,
    },
    const_loan_proceed: {
      total_budget: 20941008.157463092,
      total_budget_percent: 0.6,
    },
    dev_ffe_overhead: {
      cost_gsf: 11.906038395826805,
      cost_per_key: 6133.904166666667,
      total_budget: 1177709.6,
      total_budget_percent: 0.0337436361557487,
    },
    equity_required: {
      total_budget: 13960672.104975399,
      total_budget_percent: 0.4000000000000001,
    },
    guestroom_ffe: {
      cost_gsf: 42.267759839056986,
      cost_per_key: 21776.041666666668,
      total_budget: 4181000,
      total_budget_percent: 0.11979365946170879,
    },
    hard_costs: {
      cost_gsf: 200.0,
      cost_per_key: 103038.54166666667,
      total_budget: 19783400,
      total_budget_percent: 0.5668323086808825,
    },
    hard_soft_contingency: {
      cost_gsf: 13.11338799195285,
      cost_per_key: 6755.921875,
      total_budget: 1297137.0,
      total_budget_percent: 0.037165459950533976,
    },
    land: {
      cost_gsf: 35.38320005661312,
      cost_per_key: 18229.166666666668,
      total_budget: 3500000,
      total_budget_percent: 0.10028170488303774,
    },
    soft_costs: {
      cost_gsf: 20.0,
      cost_per_key: 10303.854166666666,
      total_budget: 1978340,
      total_budget_percent: 0.056683230868088255,
    },
    total: {
      cost_gsf: 352.83803858223047,
      cost_per_key: 181779.58470020047,
      total_budget: 34901680.26243849,
      total_budget_percent: 1,
    },
  },
  gltf_link:
    "https://mapboxdemo.dinamicka.info/img/Victorian-House-Blendswap.gltf",
  guest_rooms: {
    by_type: [
      {
        area: 521.0,
        keys: 1,
        mix: 0.005208333333333333,
        name: "One Table",
      },
      { area: 327.0, keys: 135, mix: 0.1222, name: "Two Tables" },
      { area: 521.0, keys: 3, mix: 0.015625, name: "Krr. One Table" },
      { area: 418.0, keys: 6, mix: 0.03125, name: "Krr. Two Tables" },
      { area: 525.0, keys: 3, mix: 0.015625, name: "Furniture" },
      {
        area: 417.0,
        keys: 4,
        mix: 0.020833333333333332,
        name: "Fridge",
      },
      {
        area: 362.0,
        keys: 40,
        mix: 0.20833333333333334,
        name: "Chairs",
      },
    ],
    total: { keys: 192, mix: 1 },
  },
  guest_rooms_pdf: {
    by_type: [
      { keys: 7, mix: 0.03645833333333333, name: "nisi ut aliquip" },
      { keys: 139, mix: 0.7239583333333334, name: "ex ea commodo" },
      { keys: 46, mix: 0.23958333333333334, name: "consequat" },
    ],
    total: { keys: 192, mix: 1 },
  },
  guestrooms_revenue: {
    by_type: [
      {
        adr: 140,
        keys: 40,
        mix: 0.20833333333333334,
        name: "Apple",
        key: "607985ee50b3b987d0288704",
        occup: 0.72,
        rev_par: 100.8,
        revenue: 1471680.0,
      },
      {
        adr: 180,
        keys: 3,
        mix: 0.015625,
        name: "Pine",
        key: "607985ee50b3b987d028871c",
        occup: 0.72,
        rev_par: 129.6,
        revenue: 141911.99999999997,
      },
      {
        adr: 140,
        keys: 4,
        mix: 0.020833333333333332,
        name: "Regard",
        key: "607985ee50b3b987d02886eb",
        occup: 0.72,
        rev_par: 100.8,
        revenue: 147168.0,
      },
      {
        adr: 160,
        keys: 3,
        mix: 0.015625,
        name: "Drawer",
        key: "607985ee50b3b987d0288726",
        occup: 0.72,
        rev_par: 115.19999999999999,
        revenue: 126143.99999999999,
      },
    ],
    total: {
      adr: 143.6,
      keys: 192,
      mix: 1,
      occup: 0.1875,
      rev_par: 26.925,
      sum: 1886904.0,
    },
  },
  images: [
    {
      name: "Aerial View",
      url: "./img/floor-plan-1.jpg",
    },
    {
      name: "Perspective View",
      url: "./img/floor-plan-1.jpg",
    },
    {
      name: "1st Floor Plan",
      url: "./img/floor-plan-1.jpg",
    },
    {
      name: "2nd Floor Plan",
      url: "./img/floor-plan-2.jpg",
    },
    {
      name: "3rd Floor Plan",
      url: "./img/floor-plan-3.jpg",
    },
    {
      name: "4th Floor Plan",
      url: "./img/floor-plan-4.jpg",
    },
    {
      name: "5th Floor Plan",
      url: "./img/floor-plan-5.jpg",
    },
  ],
  investment_returns: {
    multiple_on_invested_equity: 1.3113084629723966,
    pretax_profit_margin: 4346075.375061505,
    pretax_profit_margin_per_key: 22635.809245112003,
    pretax_profit_margin_percent: 0.12452338518895868,
    stabilized_yield_cost: 0.09274419671661517,
  },
  open_space: 496302.551611569,
  open_space_percent: 0.8475972334377313,
  parking_spaces: 192,
  stabilized_year_one_revenue: {
    custom_rev: [],
    food_beverage: {
      per_gsf: 0.4094341720836661,
      per_key: 210.9375,
      percent: 0.0056303378731002475,
      revenue: 40500,
    },
    guestrooms: {
      per_gsf: 71.9454087770555,
      per_key: 37065.75,
      percent: 0.9893579663163994,
      revenue: 7116624.0,
    },
    insurance: {
      per_gsf: 0.727192899097223,
      per_key: 374.6444791666667,
      percent: 0.01,
      revenue: 71931.74,
    },
    management_fee: {
      per_gsf: 2.5451751468402803,
      per_key: 1311.2556770833335,
      percent: 0.035,
      revenue: 251761.09000000003,
    },
    meetings: {
      per_gsf: 0.36444696058311515,
      per_key: 187.76041666666666,
      percent: 0.005011695810500344,
      revenue: 36050,
    },
    noi_margin: 0.45,
    property_tax: {
      per_gsf: 5.823063780745473,
      per_key: 3000.0,
      percent: 0.08007591641742574,
      revenue: 576000,
    },
    reserves: {
      per_gsf: 2.181578697291669,
      per_key: 1123.9334375,
      percent: 0.03,
      revenue: 215795.22,
    },
    stabilized_gop: {
      per_gsf: 39.99560945034726,
      per_key: 20605.446354166666,
      percent: 0.55,
      revenue: 3956245.7,
    },
    stabilized_noi: {
      per_gsf: 32.72368045937503,
      per_key: 16859.0015625,
      percent: 0.45,
      revenue: 3236928.3000000003,
    },
    total: {
      per_gsf: 72.71928990972229,
      per_key: 37464.447916666664,
      percent: 1.0,
      revenue: 7193174.0,
    },
  },
};

export const defaultMinMaxHistograms = {
  height: { min: 0, max: 220 },
  room_count: { min: 50, max: 200 },
  floor_area_ratio: { min: 0, max: 10 },
  open_space_percent: { min: 0, max: 100 },
  // king_room_mix: { min: 0, max: 100 },
  // queen_room_mix: { min: 0, max: 100 },
  // suites_mix: { min: 0, max: 100 },
  room_mix: {
    king: { min: 0, max: 100 },
    queen_queen: { min: 0, max: 100 },
    suite: { min: 0, max: 100 },
  },
};

const models = [
  {
    budget_total: 34901680.26243849,
    code: "5EL07-5LL13-CG01-5CA04-5LR03-5ER01",
    floors: 5,
    inv_cash_on_cost: 0.09274419671661517,
    multiple_on_invested_equity: 1.3113084629723966,
    open_space: 496302.551611569,
    open_space_percent: 0.8475972334377313,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c90472f",
    room_count: 192,
    total_cost_per_key: 181779.58470020047,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34920059.48605795,
    code: "5EL08-5LL13-CG01-5CA04-5LR03-5ER01",
    floors: 5,
    inv_cash_on_cost: 0.09269538333095806,
    multiple_on_invested_equity: 1.3098288072196658,
    open_space: 496241.551611569,
    open_space_percent: 0.8474930561952976,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c904970",
    room_count: 192,
    total_cost_per_key: 181875.3098232185,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34933075.12301804,
    code: "5EL07-5LL13-CG01-5CA04-5LR03-5ER04",
    floors: 5,
    inv_cash_on_cost: 0.09266084616373006,
    multiple_on_invested_equity: 1.3087818993380669,
    open_space: 496253.551611569,
    open_space_percent: 0.847513550079055,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c904730",
    room_count: 192,
    total_cost_per_key: 181943.0995990523,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34951454.34663751,
    code: "5EL08-5LL13-CG01-5CA04-5LR03-5ER04",
    floors: 5,
    inv_cash_on_cost: 0.09261212045419243,
    multiple_on_invested_equity: 1.3073049012677072,
    open_space: 496192.551611569,
    open_space_percent: 0.8474093728366213,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c904971",
    room_count: 192,
    total_cost_per_key: 182038.82472207036,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34953456.75232367,
    code: "5EL07-5LL13-CG01-5CA01-5LR03-5ER01",
    floors: 5,
    inv_cash_on_cost: 0.09260681491208483,
    multiple_on_invested_equity: 1.307144077022571,
    open_space: 496302.551611569,
    open_space_percent: 0.8475972334377313,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c90471b",
    room_count: 192,
    total_cost_per_key: 182049.25391835245,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 36185150.79278294,
    code: "6EL07-6LL10-CG01-6CA01-6LR08-6ER01",
    floors: 6,
    inv_cash_on_cost: 0.09259206681731563,
    multiple_on_invested_equity: 1.3066970253998804,
    open_space: 497025.551611569,
    open_space_percent: 0.8488319899341176,
    parking_spaces: 199,
    program_id: "60cd05609d26cfe11c904f9f",
    room_count: 199,
    total_cost_per_key: 181834.92860694946,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 36192302.24166211,
    code: "6EL07-6LL10-CG01-6CA01-6LR10-6ER01",
    floors: 6,
    inv_cash_on_cost: 0.09257377100877494,
    multiple_on_invested_equity: 1.3061424337034906,
    open_space: 497025.551611569,
    open_space_percent: 0.8488319899341176,
    parking_spaces: 199,
    program_id: "60cd05609d26cfe11c904fa0",
    room_count: 199,
    total_cost_per_key: 181870.86553599048,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 36217975.94313833,
    code: "6EL08-6LL02-CG01-6CA01-6LR13-6ER01",
    floors: 6,
    inv_cash_on_cost: 0.09257345317319447,
    multiple_on_invested_equity: 1.3061327993124572,
    open_space: 496633.551611569,
    open_space_percent: 0.8481625230647076,
    parking_spaces: 199,
    program_id: "60cd05609d26cfe11c904fec",
    room_count: 199,
    total_cost_per_key: 181999.8791112479,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34971240.021869875,
    code: "5EL07-5LL13-CG01-5CA02-5LR03-5ER01",
    floors: 5,
    inv_cash_on_cost: 0.09255972330336959,
    multiple_on_invested_equity: 1.3057166126333901,
    open_space: 496302.551611569,
    open_space_percent: 0.8475972334377313,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c904722",
    room_count: 192,
    total_cost_per_key: 182141.8751139056,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 34971835.97594314,
    code: "5EL08-5LL13-CG01-5CA01-5LR03-5ER01",
    floors: 5,
    inv_cash_on_cost: 0.09255814599572806,
    multiple_on_invested_equity: 1.3056688004955064,
    open_space: 496241.551611569,
    open_space_percent: 0.8474930561952976,
    parking_spaces: 192,
    program_id: "60cd05609d26cfe11c904968",
    room_count: 192,
    total_cost_per_key: 182144.97904137053,
    isStarred: false,
    isHidden: false,
  },
  {
    budget_total: 36225127.3920175,
    code: "6EL08-6LL01-CG01-6CA01-6LR13-6ER01",
    floors: 6,
    inv_cash_on_cost: 0.09255517761792115,
    multiple_on_invested_equity: 1.3055788215432345,
    open_space: 496633.551611569,
    open_space_percent: 0.8481625230647076,
    parking_spaces: 199,
    program_id: "60cd05609d26cfe11c904fb2",
    room_count: 199,
    total_cost_per_key: 182035.81604028895,
    isStarred: false,
    isHidden: false,
  },
];

const modelsReducer = (state = models, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_MODELS_SUCCESS:
    case ActionType.FETCH_MORE_MODELS_SUCCESS:
      return payload.models;
    case ActionType.TOGGLE_MODEL_STARRED:
      return state.map((model) =>
        model.program_id === payload.id
          ? { ...model, isStarred: !model.isStarred }
          : model
      );
    case ActionType.TOGGLE_MODEL_HIDDEN:
      return state.map((model) =>
        model.program_id === payload.id
          ? { ...model, isHidden: !model.isHidden }
          : model
      );
    case ActionType.REMOVE_MODELS_EXCEPT_ACTIVE:
      return state.filter((model) => model.program_id === payload.id);
    case ActionType.REMOVE_MODELS:
      return [];

    default:
      return state;
  }
};

const pageReducer = (state = 2, { type }) => {
  switch (type) {
    case ActionType.ADD_PAGE:
      return state + 1;
    case ActionType.RESET_PAGE:
      return 2;

    default:
      return state;
  }
};

const previousRequestForModelsReducer = (state = "", { type, payload }) => {
  switch (type) {
    case ActionType.SET_PREVIOUS_REQUEST_FOR_MODELS:
      return payload;
    case ActionType.REMOVE_MODELS:
      return "";

    default:
      return state;
  }
};

const activeProgramIdReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SET_ACTIVE_PROGRAM_ID:
      return payload ? payload : null;

    default:
      return state;
  }
};

const roomMixKeysReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.SET_ROOM_MIX_KEYS:
      return payload ? payload : null;

    default:
      return state;
  }
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case ActionType.FETCH_MODELS_START:
    case ActionType.FETCH_MORE_MODELS_START:
    // case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SRART:
    case ActionType.FETCH_DATA_FOR_PDF_START:
      // case ActionType.FETCH_TABLES_FOR_MODEL_START:
      return true;

    case ActionType.FETCH_MODELS_SUCCESS:
    case ActionType.FETCH_MODELS_ERROR:
    case ActionType.FETCH_MORE_MODELS_SUCCESS:
    case ActionType.FETCH_MORE_MODELS_ERROR:
    case ActionType.FETCH_DATA_FOR_PDF_SUCCESS:
    case ActionType.FETCH_DATA_FOR_PDF_ERROR:
      return false;
    // case ActionType.FETCH_TABLES_FOR_MODEL_SUCCESS:
    // case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SUCCESS:
    // case ActionType.FETCH_TABLES_FOR_MODEL_ERROR:
    case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_ERROR:
      return false;

    default:
      return state;
  }
};
const loadingPDFReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_DATA_FOR_PDF_START:
      return [...state, payload.id];

    case ActionType.FETCH_DATA_FOR_PDF_SUCCESS:
    case ActionType.FETCH_DATA_FOR_PDF_ERROR:
      return state.filter((el) => el !== payload.id);

    default:
      return state;
  }
};

const loadingTablesForModelReducer = (state = false, { type }) => {
  switch (type) {
    case ActionType.FETCH_TABLES_FOR_MODEL_START:
      return true;

    case ActionType.FETCH_TABLES_FOR_MODEL_SUCCESS:
    case ActionType.FETCH_TABLES_FOR_MODEL_ERROR:
      return false;

    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_MODELS_START:
    case ActionType.FETCH_MORE_MODELS_START:
    case ActionType.FETCH_DATA_FOR_PDF_START:
    case ActionType.FETCH_TABLES_FOR_MODEL_SRART:
    case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SRART:
      return null;

    case ActionType.FETCH_MODELS_ERROR:
    case ActionType.FETCH_MORE_MODELS_ERROR:
    case ActionType.FETCH_DATA_FOR_PDF_ERROR:
    case ActionType.FETCH_TABLES_FOR_MODEL_ERROR:
    case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_ERROR:
      return payload.error;

    default:
      return state;
  }
};

const setFilterParametersReducer = (
  state = {
    ...defaultFilterParameters,
  },
  action
) => {
  switch (action.type) {
    case ActionType.SET_FILTER_PARAMETERS:
      return { ...state, ...action.payload };
    case ActionType.SET_SORTING:
      return {
        ...state,
        sorting: action.payload ? action.payload : "inv_cash_on_cost",
      };
    case ActionType.SET_FILTER_PARAMETERS_FOR_ROOM_TYPE_PERCENTAGES:
      return {
        ...state,
        room_mix: {
          ...state.room_mix,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

const setFilterHistogramsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ActionType.SET_FILTER_HISTOGRAMS:
      return { ...state, ...payload };
    case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_START:
      return {
        ...state,
        open_space_percent: [
          { count: 0, value: 0 },
          { count: 0, value: 100 },
        ],
        floor_area_ratio: [
          { count: 0, value: 0 },
          { count: 0, value: 100 },
        ],
      };

    default:
      return state;
  }
};

const setMinMaxHistogramsReducer = (
  state = defaultMinMaxHistograms,
  { type, payload }
) => {
  switch (type) {
    case ActionType.SET_MIN_MAX_HISTOGRAMS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

const tablesForModelReducer = (state = resetTables, { type, payload }) => {
  switch (type) {
    case ActionType.CHANGE_DATA_IN_TABLE: {
      return { ...state, ...payload };
    }
    case ActionType.FETCH_TABLES_FOR_MODEL_SUCCESS:
    case ActionType.FETCH_TABLES_FOR_MODEL_WHEN_CALCULATE_PROGRAM_SUCCESS:
      return payload.tablesForModel;

    case ActionType.REMOVE_TABLES:
      return resetTables;
    case ActionType.SET_GUESTROOMS_BY_TYPE:
      return {
        ...state,
        guestrooms_revenue: {
          ...state.guestrooms_revenue,
          by_type: [...payload.adr],
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  models: modelsReducer,
  page: pageReducer,
  previousRequestForModels: previousRequestForModelsReducer,
  filterHistograms: setFilterHistogramsReducer,
  minMaxHistograms: setMinMaxHistogramsReducer,
  filterParameters: setFilterParametersReducer,
  tablesForModel: tablesForModelReducer,
  loading: loadingReducer,
  loadingPDF: loadingPDFReducer,
  error: errorReducer,
  activeProgramId: activeProgramIdReducer,
  roomMixKeys: roomMixKeysReducer,
  loadingTablesForModel: loadingTablesForModelReducer,
});
