import React from "react";
import PropTypes from "prop-types";

const ExportThinCircle = ({ className }) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Export</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.1-My-Projects-..."
          transform="translate(-358.000000, -307.000000)"
        >
          <g id="options" transform="translate(338.000000, 261.000000)">
            <g id="Export" transform="translate(10.000000, 41.000000)">
              <g id="icon" transform="translate(10.000000, 5.000000)">
                <circle
                  id="Oval"
                  stroke="#307FF4"
                  cx="15"
                  cy="15"
                  r="14.5"
                ></circle>
                <g
                  id="search-(1)"
                  transform="translate(8.000000, 9.000000)"
                  fill="#333333"
                  fillRule="nonzero"
                  className={className}
                >
                  <g id="inbox" stroke="none">
                    <path
                      d="M13.4604167,12 L0.40625,12 C0.181860358,12 3.55271368e-14,11.8266297 3.55271368e-14,11.6129407 L3.55271368e-14,7.18705926 C3.55271368e-14,6.9732695 0.181860358,6.8 0.40625,6.8 L4.00917969,6.8 C4.18553874,6.8 4.34169109,6.90835644 4.39585775,7.06821999 L4.87711589,8.48965477 L8.98965657,8.48965477 L9.47080891,7.06821999 C9.52497558,6.90835644 9.68123374,6.8 9.85748698,6.8 L13.4604167,6.8 C13.6848063,6.8 13.8666667,6.9732695 13.8666667,7.18705926 L13.8666667,11.6129407 C13.8666667,11.8267305 13.6848063,12 13.4604167,12 L13.4604167,12 Z M0.866666667,11.1333333 L13,11.1333333 L13,7.66666667 L10.1247926,7.66666667 L9.64779338,9.01595776 C9.59410606,9.16771831 9.4393356,9.27067818 9.26453724,9.27067818 L4.60202457,9.27067818 C4.42733107,9.27067818 4.2725606,9.16781399 4.21887329,9.01595776 L3.74187408,7.66666667 L0.866666667,7.66666667 L0.866666667,11.1333333 Z"
                      id="Shape"
                    ></path>
                    <path
                      d="M6.85965176,8.32 C6.71190391,8.32 6.57389002,8.2460896 6.4911512,8.12290558 L3.98940323,4.39866173 C3.89704634,4.2613495 3.88765998,4.08375436 3.96483653,3.93721792 C4.04201308,3.79068148 4.19323736,3.69902324 4.35790379,3.69902324 L5.37464085,3.69902324 L5.37464085,0.448366453 C5.37464085,0.200714053 5.57395562,0 5.81962266,0 L7.89944911,0 C8.14523202,0 8.34443092,0.200714053 8.34443092,0.448366453 L8.34443092,3.69902324 L9.36128385,3.69902324 C9.52595029,3.69902324 9.67717459,3.79068148 9.75435111,3.93721792 C9.83152766,4.08387111 9.82202543,4.2613495 9.72978441,4.39877848 L7.22803644,8.12290558 C7.14529762,8.2460896 7.00728376,8.32 6.85965176,8.32 Z M5.19641638,4.59575615 L6.85965176,7.07169641 L8.52277126,4.59575615 L7.89944911,4.59575615 C7.65378207,4.59575615 7.4544673,4.39504209 7.4544673,4.14738969 L7.4544673,0.896732907 L6.26472037,0.896732907 L6.26472037,4.14738969 C6.26472037,4.39504209 6.0654056,4.59575615 5.81973856,4.59575615 L5.19641638,4.59575615 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ExportThinCircle.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ExportThinCircle;
