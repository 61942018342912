// @ts-check

import React, { Component } from "react";
import styles from "./Header.module.sass";
import styles2 from "./Header2.module.scss";
import { Link, withRouter } from "react-router-dom";
import userAvatar from "../../../assets/img/user.png";
import Svg from "../../Svg/Svg";
import logo from "../../../assets/img/logo.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMore: false,
    };
  }

  componentDidMount() {
    let windowPath = window.location.pathname
      .split("/")
      .filter((x) => x !== "");
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.code !== "Escape") return;

    this.setState({ isShowMore: false });
  };

  handleClick = (e) => {
    this.setState({ isShowMore: false });
    document.removeEventListener("click", this.handleClick);
  };

  changePassword = () => {
    this.props.history.push("/dashboard/changePassword");
  };
  aboutRelocate = () => {
    this.props.history.push("/dashboard/aboutRelocate");
  };
  adminPanel = () => {
    this.props.history.push("/admin/users");
  };

  seeMore = (event) => {
    event.stopPropagation();
    document.addEventListener("click", this.handleClick);
    this.setState((prevState) => {
      return { isShowMore: !prevState.isShowMore };
    });
  };
  editUser = () => {
    this.props.history.push("/editUser");
  };
  // redirectToLogin = () => {
  //   console.log("redirect");
  //   return <Redirect to={"login"} />;
  // };
  render() {
    /** @type {null | object} */
    const { projectInfo, user, isDemoModeOff, match, location } = this.props;

    const breadCrumbs = location.pathname.split("/");
    breadCrumbs.shift();
    return (
      // !this.props.projectInfo.isAuth && <Link to={"/login"}></Link>
      <header id="header" className={styles.header}>
        <Link to={"/dashboard/projects"}>
          <img id="logo" src={logo} style={{}} alt="logo" />
        </Link>
        {match.url === "/admin" && (
          <div className={`${styles.project} d-flex align-items-center`}>
            <ul className={styles.breadCrumbs}>
              {breadCrumbs.map((breadCrumb, index) => {
                const crumbs = [...breadCrumbs];
                if (index === breadCrumbs.length - 1) {
                  return (
                    <li key={breadCrumb}>
                      <span>{breadCrumb + " >"}</span>
                    </li>
                  );
                }
                return (
                  <li key={breadCrumb}>
                    <Link
                      to={crumbs
                        .slice(0, index + 1)
                        .reduce((res, el) => res + "/" + el, "")}
                    >
                      {breadCrumb}
                    </Link>
                    {"/"}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {projectInfo &&
          this.props.isHeaderDataDisplay &&
          !!Object.keys(projectInfo).length && (
            <div className={`${styles.project} d-flex align-items-center`}>
              <h5 className={styles.title}>
                {projectInfo.projectName || "Without name"}
              </h5>
              <p className="mb-0">
                <span className={styles.gray}>Lot area: </span>
                <span className="font-weight-bold">
                  {/* {projectInfo.lot.totalLot} sf */}
                  {new Intl.NumberFormat("en-US").format(
                    Number(projectInfo.area_sf).toFixed(0)
                  )}{" "}
                  sf /{" "}
                  {new Intl.NumberFormat("en-US").format(
                    Number(projectInfo.area_ac).toFixed(2)
                  )}{" "}
                  ac
                </span>
              </p>
              <div className={`${styles.location} d-flex align-items-baseline`}>
                <Svg icon="Location" className={styles.icon} />
                <div>
                  <span className={styles.gray}>
                    {`${projectInfo.address.streetNumber || ""}`}{" "}
                    {(projectInfo.address && projectInfo.address.street) ||
                      "noData"}
                  </span>
                  <span className="font-weight-bold">
                    {" "}
                    {/*{(projectInfo.address && projectInfo.address.city) ||
                      "noData"}*/}
                  </span>
                  {/*<span className={styles.gray}>*/}
                  {/*  ,{" "}*/}
                  {/*  {(projectInfo.address && projectInfo.address.state) ||*/}
                  {/*    "noData"}*/}
                  {/*</span>*/}
                  {/*<span className={styles.gray}>*/}
                  {/*  ,{" "}*/}
                  {/*  {(projectInfo.address && projectInfo.address.country) ||*/}
                  {/*    "noData"}*/}
                  {/*</span>*/}
                  {/*<span className={styles.gray}>*/}
                  {/*  {" "}*/}
                  {/*  {(projectInfo.address && projectInfo.address.zip) ||*/}
                  {/*    "noData"}*/}
                  {/*</span>*/}
                </div>
              </div>
            </div>
          )}
        <div className={styles.profile}>
          {user.companyName}
          <span
            className={styles.more}
            onClick={(event) => this.seeMore(event)}
          >
            <img
              src={userAvatar}
              alt="userAvatar"
              className={styles.userAvatar}
            />
            <Svg icon="ShowMore" className={styles.iconMenu} />
            <div
              className={styles.moreControls}
              style={{
                display: this.state.isShowMore ? "block" : "none",
              }}
            >
              <Link to={"/login"} className={styles2.linkStyle} title="Log out">
                <Svg icon="Logout" className={styles.icon} />
                <span>Log out, {user.firstName}</span>
              </Link>
              <div onClick={this.editUser} title="Edit user">
                <Svg icon="User" className={styles.icon} />
                <span>Edit your account</span>
              </div>
            </div>
          </span>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
