import Create from "./Create";
import { connect } from "react-redux";
import { setFalseResultCalculated } from "../../../redux/Filters/filtersActions";
import * as sessionSelectors from "../../../redux/Session/sessionSelectors";
import {
  addNewProjectToProjects,
  addAreaAsAndAreaSf,
} from "../../../redux/ProjectInfo/projectInfoActions";

const mapStateToProps = (state) => {
  return {
    user: sessionSelectors.getUser(state),
    area_ac: state.projectInfo.projectInfo.area_ac,
    area_sf: state.projectInfo.projectInfo.area_sf,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFalseResultCalculated: () => dispatch(setFalseResultCalculated()),
    addProject: (project) => {
      dispatch(addNewProjectToProjects(project));
    },
    addAreaAsAndAreaSf: (area_acc, area_sff) =>
      dispatch(addAreaAsAndAreaSf(area_acc, area_sff)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
