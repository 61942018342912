import SidePanel from "./SidePanel";
import { connect } from "react-redux";
import * as filtersSelectors from "../../../../redux/Filters/filtersSelectors";
import * as sessionSelectors from "../../../../redux/Session/sessionSelectors";
import * as familySeletcors from "../../../../redux/Family/familySelectors";
import * as projectInfoSelectors from "../../../../redux/ProjectInfo/projectInfoSelectors";

const mapStateToProps = (state) => {
  return {
    tablesForModel: filtersSelectors.getTablesForModel(state),
    user: sessionSelectors.getUser(state),
    isDemoModeOff: sessionSelectors.getIsDemoModeOff(state),
    family: familySeletcors.getFamily(state),
    projectInfo: projectInfoSelectors.getProjectInfo(state),
  };
};

export default connect(mapStateToProps)(SidePanel);
