import React, { Component } from "react";
import styles from "./Panel.module.sass";
import { Link, Route } from "react-router-dom";
import Tabs from "./Tabs/Tabs";
import Svg from "../../Svg/Svg";
import "./Panel.sass";

const TabComponentName = {
  CONCEPT: "Concept",
  FILTERS: "Filters",
  BUILDING_PROGRAM: "BuildingProgram",
  REVENUE: "Revenue",
  BUDGET: "Budget",
  INVESTMENT: "Investment",
};

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabComponent: TabComponentName.CONCEPT,
    };
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  render() {
    const { projectInfo } = this.props;
    const { tabComponent } = this.state;

    return (
      <section className={styles.panel}>
        <div className={styles.panelControls}>
          <Route
            exact
            path="/dashboard/project"
            render={() => (
              <Link to={"/dashboard/create"} className={styles.link}>
                <Svg icon="Add" className={styles.add} />
              </Link>
            )}
          />
          {/*{projectInfo && !!Object.keys(projectInfo).length && ()}*/}
          <Route
            exact
            path="/dashboard"
            render={() => (
              <div className={styles.containerTabsPills}>
                <ul className={styles.radio}>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.CONCEPT}
                      checked={tabComponent === TabComponentName.CONCEPT}
                      name="tabComponent"
                      value={TabComponentName.CONCEPT}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={TabComponentName.CONCEPT}>
                      <Svg
                        icon="Concept"
                        className={`${styles.icon} ${styles.concept}`}
                      />
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.FILTERS}
                      checked={tabComponent === TabComponentName.FILTERS}
                      name="tabComponent"
                      value={TabComponentName.FILTERS}
                      onChange={this.handleChange}
                      disabled={!projectInfo.brand.delay_enabled}
                    />
                    <label htmlFor={TabComponentName.FILTERS}>
                      <Svg icon="Filter" className={styles.icon} />
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.BUILDING_PROGRAM}
                      checked={
                        tabComponent === TabComponentName.BUILDING_PROGRAM
                      }
                      name="tabComponent"
                      value={TabComponentName.BUILDING_PROGRAM}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={TabComponentName.BUILDING_PROGRAM}>
                      <Svg icon="BuildingProgram" className={styles.icon} />
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.REVENUE}
                      checked={tabComponent === TabComponentName.REVENUE}
                      name="tabComponent"
                      value={TabComponentName.REVENUE}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={TabComponentName.REVENUE}>
                      <Svg icon="Revenue" className={styles.icon} />
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.BUDGET}
                      checked={tabComponent === TabComponentName.BUDGET}
                      name="tabComponent"
                      value={TabComponentName.BUDGET}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={TabComponentName.BUDGET}>
                      <Svg icon="Budget" className={styles.icon} />
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id={TabComponentName.INVESTMENT}
                      checked={tabComponent === TabComponentName.INVESTMENT}
                      name="tabComponent"
                      value={TabComponentName.INVESTMENT}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={TabComponentName.INVESTMENT}>
                      <Svg icon="Investment" className={styles.icon} />
                    </label>
                  </li>
                </ul>
              </div>
            )}
          />
        </div>
        <Route
          exact
          path="/dashboard"
          render={() => (
            <div className={styles.panelContent}>
              <Tabs tabComponent={tabComponent} />
            </div>
          )}
        />
      </section>
    );
  }
}

export default Panel;
