import * as turf from "@turf/turf";
import isEqual from "lodash.isequal";

export function getLayerFeaturesInsideFeature({
  map,
  source,
  sourceLayer,
  layer,
  feature,
  previousFeaturesInsideSite,
  onResultsChanged,
}) {
  if (map && map.getLayer(layer) && map.getSource(source)) {
    const inside = map
      .querySourceFeatures(source, {
        sourceLayer,
      })
      .filter((x) => {
        try {
          if (x.geometry.type === "Polygon") {
            return !turf.booleanDisjoint(feature, x);
          } else if (x.geometry.type === "MultiPolygon") {
            return x.geometry.coordinates
              .map((polygon) => !turf.booleanDisjoint(feature, polygon))
              .reduce((a, b) => a && b);
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      })
      .map((x) => x.id)
      .sort();

    if (!isEqual(inside, previousFeaturesInsideSite)) {
      onResultsChanged(inside);
    }

    return inside;
  } else {
    return [];
  }
}
