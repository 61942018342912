import Filters from "./Filters";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";
import * as filtersSelectors from "../../../../../redux/Filters/filtersSelectors";
import * as familySeletcors from "../../../../../redux/Family/familySelectors";
import {
  setFilterParameters,
  setFilterParametersForRoomTypePercentages,
} from "../../../../../redux/Filters/filtersActions";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    family: familySeletcors.getFamily(state),
    filterParameters: filtersSelectors.getFilterParameters(state),
    filterHistograms: filtersSelectors.getFilterHistograms(state),
    roomMixKeys: filtersSelectors.getRoomMixKeys(state),
    minMaxHistograms: filtersSelectors.getMinMaxHistograms(state),
    error: filtersSelectors.getError(state),
    previousRequestForModels:
      filtersSelectors.getPreviousRequestForModels(state),
    projectInfoLocal: state.projectInfoLocal,
  };
};

const mapDispatchToProps = {
  setFilterParameters: setFilterParameters,
  setFilterParametersForRoomTypePercentages:
    setFilterParametersForRoomTypePercentages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
