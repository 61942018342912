import React, { Component } from "react";
import "./Projects.sass";
import { Link } from "react-router-dom";
import toastr from "toastr";
import Svg from "../../Svg/Svg";

import noImage from "../../../assets/img/no-image.png";
import addFirstZero from "../../../helpers/addFirstZero";
import { addProjectLogic } from "../../../redux/ProjectInfo/projectInfoActions";
import BuildingProgram from "../../Svg/BuildingProgram/BuildingProgram";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: -1,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyPress);
    this.getAllProjects();
    let isHeaderDataDisplay = this.props.isHeaderDataDisplay;
    let windowPath = window.location.pathname
      .split("/")
      .filter((x) => x !== "");
    if (
      isHeaderDataDisplay &&
      windowPath.length >= 2 &&
      windowPath[0] === "dashboard"
    ) {
      this.props.changeHeaderDataDisplayFalse();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    if (e.code !== "Escape") return;

    this.setState({ index: null });
  };

  handleClick = (e) => {
    this.setState({ index: null });
    document.removeEventListener("click", this.handleClick);
  };

  seeMore = (index, event) => {
    event.preventDefault();
    document.addEventListener("click", this.handleClick);
    if (index === this.state.index) {
      this.setState({ index: null });
      return false;
    }

    this.setState({
      index,
    });
  };

  getAllProjects = () => {
    const { user } = this.props;
  };

  archiveProject = (projectId) => {};

  render() {
    const projects = this.props.projects.map((p, index) => (
      <Link
        onClick={() => {
          this.props.addProjectLogic(p);
        }}
        className="wrapper col-6 col-xl-4 mb-3"
        key={index}
        to={`/dashboard`}
      >
        <div
          style={{ height: "100%" }}
          className="project col-12 d-flex justify-content-between"
        >
          <div className="info text-left">
            <span className="more" onClick={(event) => this.seeMore(1, event)}>
              <Svg icon="ShowMore" className="icon" />
              {/*<div*/}
              {/*  className="more-controls"*/}
              {/*>*/}
              {/*  /!* <div>*/}
              {/*        <Svg icon="PrintThinCircle" className="icon" />*/}
              {/*        <span>Print</span>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <Svg icon="ExportThinCircle" className="icon" />*/}
              {/*        <span>Export</span>*/}
              {/*      </div> *!/*/}
              {/*  /!*<div onClick={() => this.archiveProject(1)}>*!/*/}
              {/*  /!*  <Svg icon="Archive" className="icon" />*!/*/}
              {/*  /!*  <span>Archive</span>*!/*/}
              {/*  /!*</div>*!/*/}
              {/*</div>*/}
            </span>
            <h5 className="title mt-4 mb-1" title={"Without name"}>
              {p.name}
            </h5>
            <p className="date mb-0 pb-0">
              <span className="gray">Created: </span>
              <span className="font-weight-bold">{p.created}</span>
            </p>
            <p className="date mb-1 pb-0">
              <span className="gray">Last Edited: </span>
              <span className="font-weight-bold">{p.lastEdited}</span>
            </p>
            <p className=" mb-3 pb-2">
              <span className="gray">Lot area: </span>
              <span className="font-weight-bold">{`${p.area_sf} sf / ${p.area_ac} ac`}</span>
            </p>
            <div className="location d-flex align-items-baseline">
              <Svg icon="Location" className="icon" />
              <div>
                <span className="d-block gray">{p.st}</span>
                <span className="font-weight-bold">{p.st1} </span>
                <span className="gray">{p.st2} </span>
                <span className="gray">{p.st3} </span>
                <span className="gray">{p.st4} </span>
              </div>
            </div>
          </div>
          <div className="logo">
            <img src={p.img} style={{ width: "150px", zIndex: -100 }} />
          </div>
        </div>
      </Link>
    ));
    return (
      <section className="projects container mb-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-end">
            <h2 className="my-5 pt-5">My Projects</h2>
            {/*<Link className="link my-5 pt-5" to={"/dashboard/archive"}>*/}
            {/*  View Archived Projects*/}
            {/*</Link>*/}
          </div>
        </div>
        <div className="row">
          <div className="add col-6 col-xl-4 mb-3">
            <Link to={"/dashboard/create"}>
              <span>Add New Project</span>
            </Link>
          </div>
          {projects}
        </div>
      </section>
    );
  }
}

export default Projects;
