import Projects from "./Projects";
import { connect } from "react-redux";
import * as sessionSelectors from "../../../redux/Session/sessionSelectors";
import {
  addProjectLogic,
  changeHeaderDataDisplayFalse,
} from "../../../redux/ProjectInfo/projectInfoActions";

const mapStateToProps = (state) => {
  return {
    user: sessionSelectors.getUser(state),
    projects: state.projectInfo.projectInfo.projects,
    isHeaderDataDisplay: state.projectInfo.projectInfo.isHeaderDataDisplay,
  };
};

export default connect(mapStateToProps, {
  changeHeaderDataDisplayFalse,
  addProjectLogic,
})(Projects);
