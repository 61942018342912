import React, { Component } from "react";
import styles from "./Budget.module.sass";
import { Scrollbars } from "react-custom-scrollbars";
import NumberFormat from "react-number-format";
import InputFieldElement from "./InputFieldElement/InputFieldElement";
import toastr from "toastr";
import Svg from "../../../../Svg/Svg";
import { v4 as uuidv4 } from "uuid";

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-left",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

const percentFormat = (value) => Number((value * 100).toFixed(5));

const budget_hard_units = {
  GSF: "per_gsf",
  ROOM: "per_room",
};
const budget_soft_units = {
  GSF: "per_gsf",
  PERCENT_OF_HARD: "percent_of_hard",
};

class Budget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validationLandAcquisition = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 1000000000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 1000000000) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 1000000000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 1000000000;
    } else {
      return floatValue;
    }
  };
  validationCosts = (floatValue) => {
    if (floatValue < 0.01) {
      toastr.warning(
        `The entered value cannot be less than 0.01 and more than 100000000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0.01;
    } else if (floatValue > 100000000) {
      toastr.warning(
        `The entered value cannot be less than 0.01 and more than 100000000. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 100000000;
    } else {
      return floatValue;
    }
  };
  validationFee = (floatValue) => {
    if (floatValue < 0) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 0;
    } else if (floatValue > 20) {
      toastr.warning(
        `The entered value cannot be less than 0 and more than 20. While you tried to enter ${floatValue}`,
        "Error"
      );
      return 20;
    } else {
      return floatValue;
    }
  };
  validationGuestroomBudget = (obj) => {
    const key = Object.keys(obj)[0];

    if (obj[key].value < 1) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 100000. While you tried to enter ${obj[key].value}`,
        "Error"
      );
      obj[key].value = 1;
    } else if (obj[key].value > 100000) {
      toastr.warning(
        `The entered value cannot be less than 1 and more than 100000. While you tried to enter ${obj[key].value}`,
        "Error"
      );
      obj[key].value = 100000;
    } else {
      return obj;
    }
  };

  handleChangeBudgetAssumptions =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptions(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  handleChangeBudgetAssumptionsOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptionsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  handleChangeHardCosts =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptionsKeyHardCosts(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  handleChangeHardCostsOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptionsKeyHardCostsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
      );
    };

  handleChangeSoftCosts =
    (names) =>
    ({ floatValue }) => {
      const { budget_soft_units } = this.props.projectInfo.budget_assumptions;
      if (budget_soft_units === "per_gsf") {
        this.props.setProjectInfoKeyBudgetAssumptionsKeySoftCosts(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      } else {
        this.props.setProjectInfoKeyBudgetAssumptionsKeySoftCosts(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      }
    };

  handleChangeSoftCostsOnBlur =
    (names) =>
    ({ floatValue }) => {
      const { budget_soft_units } = this.props.projectInfo.budget_assumptions;
      if (budget_soft_units === "per_gsf") {
        this.props.setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur(
          names.reduce((obj, name) => ({ ...obj, [name]: floatValue }), {})
        );
      } else {
        this.props.setProjectInfoKeyBudgetAssumptionsKeySoftCostsOnBlur(
          names.reduce(
            (obj, name) => ({ ...obj, [name]: floatValue / 100 }),
            {}
          )
        );
      }
    };

  handleChangePercent =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptions(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue / 100 }), {})
      );
    };

  handleChangePercentOnBlur =
    (names) =>
    ({ floatValue }) => {
      this.props.setProjectInfoKeyBudgetAssumptionsOnBlur(
        names.reduce((obj, name) => ({ ...obj, [name]: floatValue / 100 }), {})
      );
    };

  handleChangeBudgetFfe = (e) => {
    this.props.setProjectInfoKeyBudgetAssumptionsKeyBudgetFfe(e);
  };

  handleChangeBudgetFfeOnBlur = (e) => {
    this.props.setProjectInfoKeyBudgetAssumptionsKeyBudgetFfeOnBlur(e);
  };

  handleChangeRadio = (e) => {
    const { name, value } = e.target;

    this.props.setProjectInfoKeyBudgetAssumptionsOnBlur({
      [name]: value,
    });
  };

  addCustomBudgetLine = (e) => {
    const budget_custom = this.props.projectInfo.budget_assumptions
      .budget_custom
      ? this.props.projectInfo.budget_assumptions.budget_custom
      : [];
    const id = uuidv4(); //create uniq id
    let label = "";
    let isBudgetCustomIncludeThisLabel = true;
    for (
      let i = budget_custom.length + 1;
      isBudgetCustomIncludeThisLabel;
      i++
    ) {
      label = `Custom Budget ${i}`;
      isBudgetCustomIncludeThisLabel = false;
      budget_custom.map((el) => {
        if (el.label === label) {
          isBudgetCustomIncludeThisLabel = true;
        }
      });
    }
    this.props.setProjectInfoKeyBudgetAssumptionsOnBlur({
      budget_custom: [...budget_custom, { id, value: 0, label }],
    });
  };

  onClose = (id) => {
    const budget_custom = this.props.projectInfo.budget_assumptions
      .budget_custom
      ? this.props.projectInfo.budget_assumptions.budget_custom
      : [];

    this.props.setProjectInfoKeyBudgetAssumptionsOnBlur({
      budget_custom: budget_custom.filter((el) => el.id !== id),
    });
  };

  handleChangeBudgetAssumptionsKeyBudgetCustomValue =
    (id) =>
    ({ floatValue }) => {
      const budget_custom = this.props.projectInfo.budget_assumptions
        .budget_custom
        ? this.props.projectInfo.budget_assumptions.budget_custom
        : [];
      this.props.setProjectInfoKeyBudgetAssumptions({
        budget_custom: budget_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, value: floatValue };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeBudgetAssumptionsKeyBudgetCustomValueOnBlur =
    (id) =>
    ({ floatValue }) => {
      const budget_custom = this.props.projectInfo.budget_assumptions
        .budget_custom
        ? this.props.projectInfo.budget_assumptions.budget_custom
        : [];
      this.props.setProjectInfoKeyBudgetAssumptionsOnBlur({
        budget_custom: budget_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, value: floatValue };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeBudgetAssumptionsKeyBudgetCustomLabel =
    (id) =>
    ({ target }) => {
      const { value } = target;

      const budget_custom = this.props.projectInfo.budget_assumptions
        .budget_custom
        ? this.props.projectInfo.budget_assumptions.budget_custom
        : [];
      this.props.setProjectInfoKeyBudgetAssumptions({
        budget_custom: budget_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, label: value };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  handleChangeBudgetAssumptionsKeyBudgetCustomLabelOnBlur =
    (id) =>
    ({ target }) => {
      const { value } = target;

      const budget_custom = this.props.projectInfo.budget_assumptions
        .budget_custom
        ? this.props.projectInfo.budget_assumptions.budget_custom
        : [];
      this.props.setProjectInfoKeyBudgetAssumptionsOnBlur({
        budget_custom: budget_custom.map((obj) => {
          if (obj.id === id) {
            return { ...obj, label: value };
          } else {
            return { ...obj };
          }
        }),
      });
    };

  validationBudgetCustomLabel = ({ id, label }) => {
    const budget_custom = this.props.projectInfo.budget_assumptions
      .budget_custom
      ? this.props.projectInfo.budget_assumptions.budget_custom
      : [];

    let isBudgetCustomIncludeThisLabel = false;
    let newLabel = "";

    budget_custom.map((el) => {
      if (el.label === label && el.id !== id) {
        isBudgetCustomIncludeThisLabel = true;
      }
    });

    if (isBudgetCustomIncludeThisLabel) {
      toastr.warning(
        `The entered string cannot be the same as there is in other inputs. While you tried to enter ${label}`,
        "Error"
      );
      for (let i = 1; isBudgetCustomIncludeThisLabel; i++) {
        newLabel = `Custom Budget ${i}`;
        isBudgetCustomIncludeThisLabel = false;
        budget_custom.map((el) => {
          if (el.label === newLabel) {
            isBudgetCustomIncludeThisLabel = true;
          }
        });
      }
      return { value: newLabel };
    } else {
      return { value: label };
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.reset();
  };

  render() {
    const { projectInfo } = this.props;
    const { budget_assumptions } = this.props.projectInfo;
    const budget_custom = this.props.projectInfo.budget_assumptions
      .budget_custom
      ? this.props.projectInfo.budget_assumptions.budget_custom
      : [];
    console.log(budget_assumptions);
    return (
      <section id="panCon" className={styles.budget}>
        <Scrollbars
          id="panContainer"
          autoHide
          autoHideTimeout={300}
          autoHideDuration={300}
          // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
          style={{ height: "calc(100vh - 80px)" }}
        >
          <div className={styles.blockPanel} onSubmit={this.handleSubmit}>
            <h3 className={styles.title}>Bugdet</h3>
            <div className="mx-auto">
              <div className={styles.textInputColumn}>
                <label htmlFor="LandAcquisition">Land aquistion</label>
                <div className={styles.inputAcquisitionContainer}>
                  <NumberFormat
                    className={styles.Acquisition}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={"$"}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="LandAcquisition"
                    value={budget_assumptions.budget_land}
                    onValueChange={this.handleChangeBudgetAssumptions([
                      "budget_land",
                    ])}
                    onBlur={() => {
                      const floatValue = this.validationLandAcquisition(
                        budget_assumptions.budget_land
                      );
                      this.handleChangeBudgetAssumptionsOnBlur(["budget_land"])(
                        { floatValue }
                      );
                    }}
                  />
                  <ul>
                    <li>
                      {`$${(
                        budget_assumptions.budget_land / projectInfo.area_sf
                      ).toFixed(1)}/sed`}
                    </li>
                    <li>
                      {`$${(
                        budget_assumptions.budget_land /
                        projectInfo.area_ac /
                        1000000
                      ).toFixed(1)}adsd`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mx-auto">
              <h5 className={styles.name}>Hard units</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.BudgetHard}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={"$"}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="budget_hard"
                    value={
                      budget_assumptions.budget_hard[
                        budget_assumptions.budget_hard_units
                      ]
                    }
                    onValueChange={this.handleChangeHardCosts([
                      budget_assumptions.budget_hard_units,
                    ])}
                    onBlur={() => {
                      const floatValue = this.validationCosts(
                        budget_assumptions.budget_hard[
                          budget_assumptions.budget_hard_units
                        ]
                      );
                      this.handleChangeHardCostsOnBlur([
                        budget_assumptions.budget_hard_units,
                      ])({ floatValue });
                    }}
                  />
                  <label htmlFor="budget_hard" className={styles.inputSuffix}>
                    {budget_assumptions.budget_hard_units === "per_gsf"
                      ? "/gas"
                      : "/ewr"}
                  </label>
                </div>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  id="budget_hard_unitsOne"
                  checked={
                    budget_assumptions.budget_hard_units ===
                    budget_hard_units.GSF
                  }
                  name="budget_hard_units"
                  value={budget_hard_units.GSF}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="budget_hard_unitsOne">Hard One</label>
                <input
                  type="radio"
                  id="budget_hard_unitsTwo"
                  checked={
                    budget_assumptions.budget_hard_units ===
                    budget_hard_units.ROOM
                  }
                  name="budget_hard_units"
                  value={budget_hard_units.ROOM}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="budget_hard_unitsTwo">Hard Two</label>
              </div>
            </div>
            <div className="mx-auto">
              <h5 className={styles.name}>Soft units</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.BudgetHard}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    prefix={
                      budget_assumptions.budget_soft_units === "per_gsf"
                        ? "$"
                        : ""
                    }
                    decimalScale={
                      budget_assumptions.budget_soft_units === "per_gsf" ? 2 : 1
                    }
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="budget_soft"
                    value={
                      budget_assumptions.budget_soft_units === "per_gsf"
                        ? budget_assumptions.budget_soft[
                            budget_assumptions.budget_soft_units
                          ]
                        : percentFormat(
                            budget_assumptions.budget_soft[
                              budget_assumptions.budget_soft_units
                            ]
                          )
                    }
                    onValueChange={this.handleChangeSoftCosts([
                      budget_assumptions.budget_soft_units,
                    ])}
                    onBlur={() => {
                      const floatValue = this.validationCosts(
                        budget_assumptions.budget_soft_units === "per_gsf"
                          ? budget_assumptions.budget_soft[
                              budget_assumptions.budget_soft_units
                            ]
                          : percentFormat(
                              budget_assumptions.budget_soft[
                                budget_assumptions.budget_soft_units
                              ]
                            )
                      );
                      this.handleChangeSoftCostsOnBlur([
                        budget_assumptions.budget_soft_units,
                      ])({ floatValue });
                    }}
                  />
                  <label htmlFor="budget_soft" className={styles.inputSuffix}>
                    {budget_assumptions.budget_soft_units === "per_gsf"
                      ? "/gsf"
                      : "%"}
                  </label>
                </div>
              </div>
              <div className={styles.radio}>
                <input
                  type="radio"
                  id="budget_soft_unitsOne"
                  checked={
                    budget_assumptions.budget_soft_units ===
                    budget_soft_units.GSF
                  }
                  name="budget_soft_units"
                  value={budget_soft_units.GSF}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="budget_soft_unitsOne">Soft one</label>
                <input
                  type="radio"
                  id="budget_soft_unitsTwo"
                  checked={
                    budget_assumptions.budget_soft_units ===
                    budget_soft_units.PERCENT_OF_HARD
                  }
                  name="budget_soft_units"
                  value={budget_soft_units.PERCENT_OF_HARD}
                  onChange={this.handleChangeRadio}
                />
                <label htmlFor="budget_soft_unitsTwo">Soft two</label>
              </div>
            </div>
            {budget_custom.length !== 0 &&
              budget_custom.map((budgetInput, index) => (
                <div className="mx-auto">
                  <div className={styles.textInput}>
                    <input
                      className={styles.CustomInputLabel}
                      name="firstName"
                      type="text"
                      value={budgetInput.label}
                      onChange={this.handleChangeBudgetAssumptionsKeyBudgetCustomLabel(
                        budgetInput.id
                      )}
                      onBlur={() => {
                        const target = this.validationBudgetCustomLabel({
                          id: budgetInput.id,
                          label: budgetInput.label,
                        });
                        this.handleChangeBudgetAssumptionsKeyBudgetCustomLabelOnBlur(
                          budgetInput.id
                        )({ target });
                      }}
                    ></input>
                    <button
                      className={styles.CustomButtonClose}
                      onClick={() => this.onClose(budgetInput.id)}
                    >
                      <Svg icon="Close" className={styles.icon} />
                    </button>
                  </div>
                  <div className={styles.textInput}>
                    <NumberFormat
                      className={styles.CustomInputValue}
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      thousandsGroupStyle={"thousand"}
                      displayType={"input"}
                      prefix={"$"}
                      decimalScale={0}
                      allowNegative={false}
                      fixedDecimalScale={true}
                      allowEmptyFormatting={true}
                      allowLeadingZeros={false}
                      id={"CustomInputValue_" + budgetInput.id}
                      value={budgetInput.value}
                      onValueChange={this.handleChangeBudgetAssumptionsKeyBudgetCustomValue(
                        budgetInput.id
                      )}
                      onBlur={() => {
                        const floatValue = this.validationLandAcquisition(
                          budgetInput.value
                        );
                        this.handleChangeBudgetAssumptionsKeyBudgetCustomValueOnBlur(
                          budgetInput.id
                        )({ floatValue });
                      }}
                    />
                  </div>
                </div>
              ))}
            <div className="mx-auto">
              <button
                className={styles.customBudgetLine}
                onClick={this.addCustomBudgetLine}
              >
                <Svg icon="Plus" className={styles.add} />
                <p>Add new</p>
              </button>
            </div>

            <div className="mx-auto">
              <h5 className={styles.name}>Dev fee</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="budget_dev_fee"
                    value={percentFormat(budget_assumptions.budget_dev_fee)}
                    onValueChange={this.handleChangePercent(["budget_dev_fee"])}
                    onBlur={() => {
                      const floatValue = this.validationFee(
                        percentFormat(budget_assumptions.budget_dev_fee)
                      );
                      this.handleChangePercentOnBlur(["budget_dev_fee"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="budget_dev_fee"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            <div className="mx-auto">
              <h5 className={styles.name}>Сontingency</h5>
              <div className={styles.textInput}>
                <div className={styles.inputContainer}>
                  <NumberFormat
                    className={styles.inputPercent}
                    thousandSeparator={true}
                    decimalSeparator={"."}
                    thousandsGroupStyle={"thousand"}
                    displayType={"input"}
                    decimalScale={2}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    allowEmptyFormatting={true}
                    allowLeadingZeros={false}
                    id="budget_contingency"
                    value={percentFormat(budget_assumptions.budget_contingency)}
                    onValueChange={this.handleChangePercent([
                      "budget_contingency",
                    ])}
                    onBlur={() => {
                      const floatValue = this.validationFee(
                        percentFormat(budget_assumptions.budget_contingency)
                      );
                      this.handleChangePercentOnBlur(["budget_contingency"])({
                        floatValue,
                      });
                    }}
                  />
                  <label
                    htmlFor="budget_contingency"
                    className={styles.inputSuffixPercent}
                  >
                    %
                  </label>
                </div>
              </div>
            </div>
            {/* <button className={styles.submit} type="submit">
            <Svg icon="RoundArrow" className={styles.roundArrow} />
          </button> */}
          </div>

          {budget_assumptions.budget_ffe_objects && (
            <div
              id="x"
              className={styles.blockPanel}
              onSubmit={this.handleSubmit}
            >
              <h3 className={styles.title}>FFE objects</h3>

              <div className="mx-auto">
                <div className={styles.textInputColumn}>
                  {budget_assumptions.budget_ffe_keys.map((key) => (
                    <InputFieldElement
                      key={key}
                      id={key}
                      budget_ffe={budget_assumptions.budget_ffe_objects[key]}
                      handleChangeBudgetFfe={this.handleChangeBudgetFfe}
                      handleChangeBudgetFfeOnBlur={
                        this.handleChangeBudgetFfeOnBlur
                      }
                      validationGuestroomBudget={this.validationGuestroomBudget}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </Scrollbars>
      </section>
    );
  }
}

export default Budget;
