import Revenue from "./Revenue";
import { connect } from "react-redux";
import {
  setProjectInfoKeyRevenueAssumptions,
  setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrList,
  setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccList,
  setProjectInfoKeyRevenueAssumptionsKeyRevFb,
  setProjectInfoKeyRevenueAssumptionsKeyRevMeetings,
  setProjectInfoKeyRevenueAssumptionsOnBlur,
  setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur,
  setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur,
  setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur,
  setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur,
} from "../../../../../redux/ProjectInfo/projectInfoActions";

import { bindActionCreators } from "redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";
import * as filtersSelectors from "../../../../../redux/Filters/filtersSelectors";
import { setGuestRoomsRevenueByType } from "../../../../../redux/Filters/filtersActions";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    filters: filtersSelectors.getFilters(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectInfoKeyRevenueAssumptions: bindActionCreators(
      setProjectInfoKeyRevenueAssumptions,
      dispatch
    ),
    setGuestRoomsRevenueByType: bindActionCreators(
      setGuestRoomsRevenueByType,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrList: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrList,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccList: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccList,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevFb: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevFb,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevMeetings: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevMeetings,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsOnBlur: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsOnBlur,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur:
      bindActionCreators(
        setProjectInfoKeyRevenueAssumptionsKeyRevRoomsAdrListOnBlur,
        dispatch
      ),
    setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur:
      bindActionCreators(
        setProjectInfoKeyRevenueAssumptionsKeyRevRoomsOccListOnBlur,
        dispatch
      ),
    setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevFbOnBlur,
      dispatch
    ),
    setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur: bindActionCreators(
      setProjectInfoKeyRevenueAssumptionsKeyRevMeetingsOnBlur,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
