import React, { Component } from "react";
import "./Site.sass";
import { area, union, bbox, difference } from "@turf/turf";
import { validate } from "indicative/validator";
import CloseIcon from "@material-ui/icons/Close";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import AddIcon from "@material-ui/icons/Add";
import ErrorNotification from "../ErrorNotification/ErrorNotification";
import Tooltip from "../../../Tooltip/Tooltip";
import Svg from "../../../Svg/Svg";
import styles from "./Site.module.scss";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { addAreaAsAndAreaSf } from "../../../../redux/ProjectInfo/projectInfoActions";

const DrawPolygonMode = {
  ON: true,
  OFF: false,
};

///////////////////////////////////////////////////////////areaCalc
// function getAreaByScript(parcelPolygon) {
//   // needs proj4js (http://proj4js.org/) and turf (https://turfjs.org/getting-started) libraries

//   // get utm Zone (https://github.com/solvi-ab/utm-zone)
//   const getUTMZone = (geojson) => {
//     const firstCoord = getFirstCoord(geojson);
//     const zone = Math.floor((firstCoord[0] + 180) / 6) + 1;
//     return `+proj=utm +zone=${zone} +${
//       firstCoord[1] >= 0 ? "north" : "south"
//     } +datum=WGS84 +units=m +no_defs`;
//   };

//   const getFirstCoord = (geojson) => {
//     const recurse = (coordinates) =>
//       isNaN(coordinates[0]) ? recurse(coordinates[0]) : coordinates;
//     const firstCoord = geojson.coordinates
//       ? recurse(geojson.coordinates)
//       : geojson.geometry
//       ? recurse(geojson.geometry.coordinates)
//       : geojson.geometries
//       ? recurse(geojson.geometries[0].coordinates)
//       : geojson.features
//       ? recurse(geojson.features[0].geometry.coordinates)
//       : null;

//     if (!firstCoord) {
//       throw new Error("Could not find any coordinates in this GeoJSON");
//     }

//     return firstCoord;
//   };

//   //FUNCTIONS:

//   //transform WGS 84 Coordinates to UTM
//   function transformUTM(wgs84Coords) {
//     var l = wgs84Coords.length;
//     var utmCoords = [];
//     for (var i = 0; i < l; i++) {
//       utmCoords.splice(i, 0, proj4(destUTMZone, wgs84Coords[i]));
//     }
//     return utmCoords;
//   }

//   // convert global UTM coords to local with first point as x=0, y=0 (in feet)
//   function convertGlobalToLocal(globalCoords, origin, unitConversion) {
//     var l = globalCoords.length;
//     var localCoords = [];
//     for (var i = 0; i < l; i++) {
//       var localX = (globalCoords[i][0] - origin[0]) * unitConversion;
//       var localY = (globalCoords[i][1] - origin[1]) * unitConversion;
//       localCoords.splice(i, 0, [localX, localY]);
//     }
//     return localCoords;
//   }

//   function calcAreaPolygon(points, signed) {
//     var l = points.length;
//     var det = 0;
//     var isSigned = signed || false;
//     points = points.map(normalize);
//     if (points[0] != points[points.length - 1])
//       points = points.concat(points[0]);
//     for (var i = 0; i < l; i++)
//       det += points[i].x * points[i + 1].y - points[i].y * points[i + 1].x;
//     if (isSigned) return det / 2;
//     else return Math.abs(det) / 2;
//   }

//   function normalize(point) {
//     if (Array.isArray(point))
//       return {
//         x: point[0],
//         y: point[1],
//       };
//     else return point;
//   }

//   // parcel geoJSON.  this will come from parcel data instead of hardcoded.
//   // var parcelPolygon = {
//   //   type: "Feature",
//   //   geometry: {
//   //     type: "Polygon",
//   //     coordinates: [
//   //       [
//   //         [-97.24179804325104, 35.57288930907335],
//   //         [-97.24180340766907, 35.572365712623565],
//   //         [-97.24181413650513, 35.571344689703565],
//   //         [-97.24181950092316, 35.57055491518611],
//   //         [-97.24181950092316, 35.56978695017844],
//   //         [-97.24182486534119, 35.56896225228576],
//   //         [-97.24182486534119, 35.56568955792207],
//   //         [-97.2418463230133, 35.56568955792207],
//   //         [-97.24289238452911, 35.565698285284796],
//   //         [-97.24289774894714, 35.566893924992144],
//   //         [-97.2429084777832, 35.569245879502844],
//   //         [-97.24291384220123, 35.57288930907335],
//   //         [-97.24179804325104, 35.57288930907335]
//   //       ]
//   //     ]
//   //   }
//   // };

//   var centroidPoingt = centroid(parcelPolygon); //Calculates central point;
//   var destUTMZone = getUTMZone(centroidPoingt); // get utm zone from centroid;

//   // transform to utm
//   var utmParcelPolygon = transformUTM(parcelPolygon.geometry.coordinates[0]);

//   // transform to local x, y coords
//   var xyOrigin = utmParcelPolygon[0]; // set first point as (0,0)
//   var xyUnitConversion = 3.28084; // meters to feet
//   var xyParcelPolygon = convertGlobalToLocal(
//     utmParcelPolygon,
//     xyOrigin,
//     xyUnitConversion
//   );
//   var area_sf = calcAreaPolygon(xyParcelPolygon);
//   var area_ac = area_sf / 43560;
//   return { sqft: area_sf, acres: area_ac };
// }

///////////////////////////////////////////////////////////End areaCalc
let draw = null;
const rules = {
  parcels: "required|array|min:1",
};

const messages = {
  "parcels.required": "Please choose a parcels",
  "parcels.min": "You need to choose at least 1 parcel to continue",
};

const calculateAreaUnion = (sitePolygon) => {
  const lotData = { acres: 0, sqft: 0 };
  lotData["acres"] += area(sitePolygon) * 0.000247105;
  lotData["sqft"] += area(sitePolygon) * 10.7639;
  console.log(
    "ogo mozg",
    lotData["acres"].toFixed(2),
    " ",
    Math.round(lotData["sqft"])
  );
  return lotData;
};

class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedParcels: this.props.selectedParcels,
      sitePolygon: this.props.sitePolygon,
      isDrawPolygonMode: false,
      historyPointer: 0,
      historyOfDrawnPolygons: [
        {
          type: "FeatureCollection",
          features: [],
        },
      ],
      errors: null,
    };
  }
  getProjectSite(sitePolygon) {
    if (!sitePolygon.geometry) {
      return {};
    } else {
      return {
        ...sitePolygon,
        bbox: bbox(sitePolygon),
        parcels: this.state.selectedParcels.features.map(
          (parcel) => parcel.properties
        ),
      };
    }
  }

  handleChangeRadio = (e) => {
    const { value } = e.target;

    const isDrawPolygonMode = value === "true" ? true : false;

    const { map } = this.props;
    const forwardingThisFromSite = this;
    if (!isDrawPolygonMode) {
      this.changePolygon();
      map.on("click", this.onClick);
      map.removeControl(draw);

      // map.off("draw.create", updateArea);
      // map.off("draw.delete", updateArea);
      // map.off("draw.update", updateArea);
      this.setState({
        isDrawPolygonMode: false,
        historyPointer: 0,
        historyOfDrawnPolygons: [
          {
            type: "FeatureCollection",
            features: [],
          },
        ],
      });
    } else {
      map.off("click", this.onClick);
      this.setState({ isDrawPolygonMode: true });
      if (draw === null) {
        draw = new MapboxDraw({
          displayControlsDefault: false,
          // defaultMode: "direct_select",
          // controls: {
          //   polygon: true,
          //   trash: true,
          // },
          styles: [
            // ACTIVE (being drawn)
            // line stroke
            {
              id: "gl-draw-line",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["!=", "mode", "static"],
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color": "#fbb03b",
                "line-dasharray": [0.2, 2],
                "line-width": 2,
              },
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "fill-color": "#fbb03b",
                "fill-outline-color": "#fbb03b",
                "fill-opacity": 0.1,
              },
            },
            // polygon outline stroke
            // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
            {
              id: "gl-draw-polygon-stroke-active",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["!=", "mode", "static"],
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color": "#fbb03b",
                "line-dasharray": [0.2, 2],
                "line-width": 2,
              },
            },
            // vertex point halos
            ////////////////////////////////////// red circle
            {
              id: "gl-draw-polygon-and-line-vertex-halo-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "circle-radius": 8,
                "circle-color": "#FFF",
              },
            },
            // vertex points
            {
              id: "gl-draw-polygon-and-line-vertex-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "vertex"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "circle-radius": 5,
                "circle-color": "#fbb03b",
              },
            },
            // midpoint points
            {
              id: "gl-draw-polygon-and-line-midpoint-active",
              type: "circle",
              filter: [
                "all",
                ["==", "meta", "midpoint"],
                ["==", "$type", "Point"],
                ["!=", "mode", "static"],
              ],
              paint: {
                "circle-radius": 6,
                "circle-color": "#D20C0C",
              },
            },
            //////////////////////////////////////////////////#E5E5E5
            // INACTIVE (static, already drawn)
            // line stroke
            {
              id: "gl-draw-line-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "LineString"],
                ["==", "mode", "static"],
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color": "#000",
                "line-width": 3,
              },
            },
            // polygon fill
            {
              id: "gl-draw-polygon-fill-static",
              type: "fill",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"],
              ],
              paint: {
                "fill-color": "#000",
                "fill-outline-color": "#000",
                "fill-opacity": 0.1,
              },
            },
            // polygon outline
            {
              id: "gl-draw-polygon-stroke-static",
              type: "line",
              filter: [
                "all",
                ["==", "$type", "Polygon"],
                ["==", "mode", "static"],
              ],
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-color": "#000",
                "line-width": 3,
              },
            },
          ],
        });

        map.on("draw.create", updateArea);
        map.on("draw.delete", updateArea);
        map.on("draw.update", updateArea);
      }
      try {
        map.addControl(draw, "top-right");
        const id = draw.add({
          features: [{ ...this.state.sitePolygon }],
          type: "FeatureCollection",
        });
      } catch (e) {
        console.error(e);
      }
      // draw = draw.changeMode("direct_select", {
      //   featureId: id,
      // });
    }

    function updateArea(e) {
      const drawnPolygons = draw.getAll();

      if (drawnPolygons.features.length > 0) {
        if (forwardingThisFromSite.state.historyPointer === 0) {
          forwardingThisFromSite.setState((prevState) => ({
            historyOfDrawnPolygons: [
              ...prevState.historyOfDrawnPolygons,
              drawnPolygons,
            ],
          }));
        } else {
          forwardingThisFromSite.setState((prevState) => {
            const newHistoryOfDrawnPolygons =
              prevState.historyOfDrawnPolygons.filter(
                (item, index, arr) =>
                  index <
                  arr.length - forwardingThisFromSite.state.historyPointer
              );
            return {
              historyOfDrawnPolygons: [
                ...newHistoryOfDrawnPolygons,
                drawnPolygons,
              ],
              historyPointer: 0,
            };
          });
        }
      } else {
        if (e.type !== "draw.delete")
          alert("Use the draw tools to draw a polygon!");
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { selectedParcels, sitePolygon } = this.state;
    const lotData = calculateAreaUnion(sitePolygon);
    const projectSite = this.getProjectSite(sitePolygon);
    this.props.addAreaAsAndAreaSf(lotData);
    validate({ parcels: selectedParcels.features }, rules, messages)
      .then(() => {
        this.props.handleSite({
          selectedParcels,
          lotData,
          projectSite,
          sitePolygon,
        });
        this.props.nextStep();
      })
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach((error) => {
          console.log(error);
          formattedErrors[error.field] = error.message;
        });

        this.setState({
          errors: formattedErrors,
        });
      });
  };

  back = (e) => {
    e.preventDefault();
    const { map } = this.props;

    this.props.resetSite();
    this.props.prevStep();
    map.getSource("selected").setData({
      type: "Feature",
      geometry: { coordinates: [], type: "Polygon" },
      properties: {},
    });
  };

  onClick = (e) => {
    const { map, setSelectedParcels, differenceSitePolygon } = this.props;
    const forwardingThisFromSite = this;
    var selectedParcels = {
      ...this.state.selectedParcels,
    };
    setSelectedParcels(selectedParcels);
    var sitePolygon = { ...this.state.sitePolygon };
    // Add the parcel to a list of selected parcels
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["parcel-touchable"],
    });
    if (!features[0]) return;
    if (
      selectedParcels.features.reduce(
        (sum, current) =>
          sum || current.properties.fid === features[0].properties.fid,
        false
      )
    ) {
      selectedParcels.features = selectedParcels.features.filter(
        (current) => current.properties.fid !== features[0].properties.fid
      );
    } else {
      selectedParcels.features = selectedParcels.features.concat(features[0]);
      // Look up more details on this parcel from the Loveland API
      // getParcelDetails(e.lngLat);
    }
    let type;
    forwardingThisFromSite.setState({ selectedParcels });
    if (selectedParcels.features[0]) {
      sitePolygon = differenceSitePolygon
        ? union(...selectedParcels.features, differenceSitePolygon)
        : union(...selectedParcels.features);

      type = sitePolygon.geometry.type;
    } else {
      sitePolygon = differenceSitePolygon
        ? differenceSitePolygon
        : {
            type: "Feature",
            geometry: { coordinates: [], type: "MultiPolygon" },
            properties: {},
          };
    }
    if (type === "MultiPolygon") {
      forwardingThisFromSite.setState({
        errors: {
          parcels:
            'Parcels must be adjacent. Add or remove parcels to complete your site or use the "Edit Site Polygon" tool above',
        },
      });
    } else {
      forwardingThisFromSite.setState({ errors: "" });
    }
    map.getSource("selected").setData(sitePolygon);
    forwardingThisFromSite.setState({ sitePolygon });
    validate({ parcels: selectedParcels.features }, rules, messages)
      .then()
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach((error) => {
          console.log(error);
          formattedErrors[error.field] = error.message;
        });

        this.setState({
          errors: formattedErrors,
        });
      });
  };

  clearAllData = (e) => {
    const { map } = this.props;
    const { isDrawPolygonMode } = this.state;

    if (isDrawPolygonMode) {
      map.on("click", this.onClick);
      map.removeControl(draw);
    }

    map.getSource("selected").setData({
      type: "Feature",
      geometry: { coordinates: [], type: "MultiPolygon" },
      properties: {},
    });
    this.setState({
      selectedParcels: {
        type: "FeatureCollection",
        features: [],
      },
      sitePolygon: {
        type: "Feature",
        geometry: { coordinates: [], type: "Polygon" },
        properties: {},
      },
      isDrawPolygonMode: false,
      historyPointer: 0,
      historyOfDrawnPolygons: [
        {
          type: "FeatureCollection",
          features: [],
        },
      ],
      errors: "",
    });
    this.props.handleClearParcels();
  };

  undo = () => {
    const { historyOfDrawnPolygons, historyPointer } = this.state;
    if (historyOfDrawnPolygons.length - 1 - (historyPointer + 1) < 0) return;
    if (historyOfDrawnPolygons.length - 1 - (historyPointer + 1) === 0) {
      const id = historyOfDrawnPolygons[1]["features"][0]["id"];
      draw.add({
        features: [{ ...this.state.sitePolygon, id }],
        type: "FeatureCollection",
      });
    } else {
      const id = draw.add(
        historyOfDrawnPolygons[
          historyOfDrawnPolygons.length - 1 - (historyPointer + 1)
        ]
      );
    }
    this.setState((prevState) => ({
      historyPointer: prevState.historyPointer + 1,
    }));
  };
  redo = () => {
    const { historyOfDrawnPolygons, historyPointer } = this.state;
    if (historyPointer === 0) return;
    const id = draw.add(
      historyOfDrawnPolygons[
        historyOfDrawnPolygons.length - 1 - (historyPointer - 1)
      ]
    );
    this.setState((prevState) => ({
      historyPointer: prevState.historyPointer - 1,
    }));
  };
  changePolygon = () => {
    const { historyOfDrawnPolygons, historyPointer, selectedParcels } =
      this.state;
    const drawnPolygons =
      historyOfDrawnPolygons[
        historyOfDrawnPolygons.length - 1 - historyPointer
      ];
    const { map } = this.props;
    let type;
    if (drawnPolygons.features.length === 0) return;
    let sitePolygonNew = union(...drawnPolygons.features);
    type = sitePolygonNew.geometry.type;

    if (type === "MultiPolygon") {
      const polygons = sitePolygonNew.geometry.coordinates.map((polygon) => ({
        geometry: {
          coordinates: polygon,
          type: "Polygon",
        },
        type: "Feature",
      }));
      sitePolygonNew = {
        ...union(...polygons),
        properties: sitePolygonNew.properties,
      };
      type = sitePolygonNew.geometry.type;
    }
    const sitePolygon = selectedParcels.features.length
      ? union(...selectedParcels.features)
      : {
          type: "Feature",
          geometry: { coordinates: [], type: "Polygon" },
          properties: {},
        };
    const differenceSitePolygon = difference(sitePolygonNew, sitePolygon);
    if (type === "MultiPolygon") {
      this.setState({
        errors: {
          parcels:
            "Parcels must be adjacent. Edit your polygon to complete your site.",
        },
      });
    } else {
      map.getSource("selected").setData(sitePolygonNew);
      this.setState({
        sitePolygon: { ...sitePolygonNew },
        errors: "",
      });
      this.props.setSitePolygon({ sitePolygonNew, differenceSitePolygon });
    }
  };

  componentDidMount() {
    const { map } = this.props;
    // Handle clicks on the map
    map.on("click", this.onClick);
  }
  componentWillUnmount() {
    const { map, currentMarkers } = this.props;
    map.off("click", this.onClick);
    // if (currentMarkers !== null) {
    //   for (var i = currentMarkers.length - 1; i >= 0; i--) {
    //     currentMarkers[i].remove();
    //   }
    // }
    draw = null;
  }

  render() {
    console.log(this.state);
    const {
      errors,
      sitePolygon,
      isDrawPolygonMode,
      historyOfDrawnPolygons,
      historyPointer,
    } = this.state;
    const lotData = calculateAreaUnion(sitePolygon);
    // lotData["acres"].toFixed(2),
    //     Math.round(lotData["sqft"])
    // this.props.addAreaAsAndAreaSf(lotData);
    return (
      <form className="site text col-4 p-4" onSubmit={this.handleSubmit}>
        <h5 className="title text-right">Project Site</h5>
        <div className="mt-5 mb-5 pt-2">
          <h2 className="mb-5 pb-2">Define the project site</h2>
          <div className="text-left">
            <h4 className="titleFirst">
              Select parcels on the map to include in the project site.
            </h4>

            <div className="buttonContainerSite">
              <div className="radio">
                <input
                  type="radio"
                  id="OFF"
                  checked={isDrawPolygonMode === DrawPolygonMode.OFF}
                  name="isDrawPolygonMode"
                  value={DrawPolygonMode.OFF}
                  onChange={this.handleChangeRadio}
                  disabled={
                    this.state.sitePolygon.geometry.coordinates.length > 0
                      ? false
                      : true
                  }
                />
                <label htmlFor="OFF">
                  <AddIcon
                    style={{
                      color:
                        this.state.sitePolygon.geometry.coordinates.length > 0
                          ? "#fff"
                          : "#333333",
                      fontSize: 30,
                    }}
                  />
                  <span className="buttonText">Select Parcels</span>
                </label>
                <input
                  type="radio"
                  id="ON"
                  checked={isDrawPolygonMode === DrawPolygonMode.ON}
                  name="isDrawPolygonMode"
                  value={DrawPolygonMode.ON}
                  onChange={this.handleChangeRadio}
                  disabled={
                    this.state.sitePolygon.geometry.coordinates.length > 0
                      ? false
                      : true
                  }
                />
                <label htmlFor="ON">
                  <span className="buttonText">Edit Site Polygon</span>
                  <Svg icon="Polygon" className={styles.icon} />
                </label>
              </div>
              <Tooltip title="" text="Optional: manually edit site polygon" />
            </div>

            <div className="undoRedoBtnContainer">
              <button
                type="button"
                className="undoBtn"
                disabled={
                  !(
                    this.state.historyOfDrawnPolygons[
                      historyOfDrawnPolygons.length - 1
                    ].features.length > 0
                  ) ||
                  historyOfDrawnPolygons.length - 1 - (historyPointer + 1) < 0
                }
                onClick={this.undo}
              >
                <UndoIcon
                  style={{
                    color:
                      this.state.historyOfDrawnPolygons[
                        historyOfDrawnPolygons.length - 1
                      ].features.length > 0
                        ? "#fff"
                        : "#333333",
                    fontSize: 30,
                  }}
                />
                <span className="buttonText">Undo</span>
              </button>
              <button
                type="button"
                className="redoBtn"
                disabled={
                  !(
                    this.state.historyOfDrawnPolygons[
                      historyOfDrawnPolygons.length - 1
                    ].features.length > 0
                  ) || !historyPointer
                }
                onClick={this.redo}
              >
                <span className="buttonText">Redo</span>
                <RedoIcon
                  style={{
                    color:
                      this.state.historyOfDrawnPolygons[
                        historyOfDrawnPolygons.length - 1
                      ].features.length > 0
                        ? "#fff"
                        : "#333333",
                    fontSize: 30,
                  }}
                />
              </button>
            </div>
            <div className="buttonContainerSite">
              <button
                type="button"
                className="Btn"
                disabled={
                  this.state.sitePolygon.geometry.coordinates.length > 0
                    ? false
                    : true
                }
                onClick={this.clearAllData}
              >
                <CloseIcon
                  style={{
                    color:
                      this.state.sitePolygon.geometry.coordinates.length > 0
                        ? "#fff"
                        : "#333333",
                    fontSize: 30,
                  }}
                />
                <span className="buttonText">Clear All</span>
              </button>
              <Tooltip
                title=""
                text="Clear all selected parcels and start over"
              />
            </div>
            {errors && <ErrorNotification label={errors.parcels} />}
            <p id="features" className="test pt-2"></p>
            {lotData && lotData !== null ? (
              <div>
                <h4 className="title mt-5">
                  Lot Area:{" "}
                  <span>
                    {new Intl.NumberFormat("en-US").format(
                      Math.round(lotData.sqft)
                    )}{" "}
                    sf
                  </span>
                </h4>
                <h4 className="title">
                  Lot Area:{" "}
                  <span>
                    {new Intl.NumberFormat("en-US").format(
                      lotData.acres.toFixed(2)
                    )}{" "}
                    acres
                  </span>
                </h4>
              </div>
            ) : null}
          </div>
        </div>

        <div className="controls">
          <button
            type="button"
            className="prev"
            onClick={this.back}
            disabled={(!!errors || isDrawPolygonMode) && "disabled"}
          >
            Prev
          </button>
          <button
            autoFocus={true}
            type="submit"
            className="next"
            disabled={(!!errors || isDrawPolygonMode) && "disabled"}
          >
            Next
          </button>
        </div>
      </form>
    );
  }
}

export default Site;
