import React from "react";
import PropTypes from "prop-types";

const Add = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      className={className}
    >
      <g>
        <g>
          <rect width="36" height="36" rx="18" fill="#307FF4" />
        </g>
        <path
          d="M11.177,5.867H6.734V.74a.829.829,0,0,0-1.649,0V5.867H.641A.89.89,0,0,0,0,6.818a.89.89,0,0,0,.641.951H5.085V12.9a.829.829,0,0,0,1.649,0V7.77h4.443a.89.89,0,0,0,.641-.951A.89.89,0,0,0,11.177,5.867Z"
          transform="translate(12 11)"
          fill="#fff"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

Add.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Add;
