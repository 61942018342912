import React from "react";
import CarouselReact from "../CarouselReact/CarouselReactContainer";
import Map from "../Map/MapContainer";
import styles from "./SidePanel.module.sass";
import Svg from "../../../Svg/Svg";
import { Scrollbars } from "react-custom-scrollbars";
// import Viewer3d from "../Viewer3d2/Viewer3dContainer";
import Viewer3d from "../Viewer3d/Viewer3dContainer";

const SidePanel = ({
  tablesForModel,
  isDemoModeOff,
  user,
  family,
  projectInfo,
}) => {
  const { familyLogoURL, family_name: familyName, brand_contact } = family;
  const { far, floors, height } = tablesForModel.design_numbers;
  const { parking_spaces, gltf_link } = tablesForModel;
  const {
    company,
    companyWebsiteURL,
    contactPhotoURL,
    email,
    firstName,
    jobTitle,
    lastName,
    linkedinURL,
    phone,
  } = brand_contact;

  return (
    <div className={styles.sidePanel}>
      <Scrollbars
        id="panContainer"
        autoHide
        autoHideTimeout={300}
        autoHideDuration={300}
        // style={{ minHeight: '850px', height: 'calc(100vh - 110px)' }}
        style={{ height: "calc(100vh - 60px)" }}
      >
        <Viewer3d />
        <p className={styles.hotelBrandContact}>
          {`${familyName} Development Contact`}
        </p>
        <div className={styles.contactCard}>
          <img
            id="contactPhotoURL"
            src={contactPhotoURL}
            alt="avatar"
            className={styles.avatar}
          />
          <div className={styles.cont}>
            <p
              className={styles.contactCard__name}
            >{`${firstName} ${lastName}`}</p>
            <p className={styles.contactCard__occupation}>{`${jobTitle}`}</p>
            <p className={styles.contactCard__occupation}>{`${company}`}</p>
            <p className={styles.contactCard__buttonsBar}>
              <a className={styles.contactCard__phone} href={`tel:${phone}`}>
                <Svg icon="Phone" className={styles.icon} />
                <span
                  className={styles.contactCard__phoneString}
                >{`${phone}`}</span>
                <span className="visuallyHidden">Phone</span>
              </a>
              <a className={styles.contactCard__email} href={`mailto:${email}`}>
                <Svg icon="Envelope" className={styles.icon} />
                <span
                  className={styles.contactCard__emailString}
                >{`${email}`}</span>
                <span className="visuallyHidden">Email</span>
              </a>
            </p>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default SidePanel;
