import Dashboard from "./Dashboard";
import { connect } from "react-redux";
import * as filtersSelectors from "../../redux/Filters/filtersSelectors";
import {
  changeHeaderDataDisplayFalse,
  changeHeaderDataDisplayTrue,
} from "../../redux/ProjectInfo/projectInfoActions";

const mapStateToProps = (state) => {
  return {
    projectInfo: state.projectInfo,
    filters: filtersSelectors.getFilters(state),
    isHeaderDataDisplay: state.projectInfo.projectInfo.isHeaderDataDisplay,
  };
};

export default connect(mapStateToProps, {
  changeHeaderDataDisplayTrue,
  changeHeaderDataDisplayFalse,
})(Dashboard);
