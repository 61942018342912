import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Reducers
import sessionReducer from "./Session/sessionReducer";
import projectInfoReducers from "./ProjectInfo/projectInfoReducer";
import filtersReducer from "./Filters/filtersReducer";
import familyReducer from "./Family/familyReducer";
import projectInfoLocalReducer from "./ProjectInfo/projectInfoLocalReducer";

// Middleware
import ReduxThunk from "redux-thunk";

const sessionPersistConfig = {
  key: "session",
  storage,
  whitelist: ["access", "refresh", "isDemoModeOff"],
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, sessionReducer),
  filters: filtersReducer,
  family: familyReducer,
  projectInfo: projectInfoReducers,
  projectInfoLocal: projectInfoLocalReducer,
});

const middleware = [ReduxThunk];
const enhancer = applyMiddleware(...middleware);

export const store = createStore(rootReducer, composeWithDevTools(enhancer));
export const persistor = persistStore(store);
