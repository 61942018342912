import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Svg from "../../../../Svg/Svg";
import styles from "./CarouselInner.module.scss";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import "./CarouselInner.scss";

export default class CarouselInner extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    current: PropTypes.number.isRequired,
  };

  static defaultProps = {
    tablesForModel: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      current: props.current,
      isNext: true,
    };
  }

  handlerIndex = (index) => (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    this.setState((prevState) => ({
      ...prevState,
      current: index,
      isNext: index > prevState.current,
    }));
  };

  handlerPrev = (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length;

    if (index < 1) {
      index = length;
    }

    index = index - 1;

    this.setState({
      current: index,
      isNext: false,
    });
  };

  handlerNext = (evt) => {
    evt && evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;

    let index = this.state.current,
      length = this.props.tablesForModel.images.length - 1;

    if (index === length) {
      index = -1;
    }

    index = index + 1;

    this.setState({
      current: index,
      isNext: true,
    });
  };

  componentDidMount = () => {
    this.playCarousel();
  };

  playCarousel = () => {
    // this.setState({ timerId: setInterval(this.handlerNext, 5000) });
  };

  stopCarousel = () => {
    const { timerId } = this.state;
    clearInterval(timerId);
    this.setState({ timerId: null });
  };

  componentWillUnmount() {
    const { timerId } = this.state;
    clearInterval(timerId);
  }

  render() {
    let index = this.state.current,
      isnext = this.state.isNext,
      src = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].url
        : "",
      name = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].name
        : "";

    return (
      <div
        id="PerspectiveView"
        className={styles.PerspectiveView}
        onMouseEnter={this.stopCarousel}
        onMouseLeave={this.playCarousel}
      >
        <div className={styles.headLine}>
          <p className={styles.headLine_title}>{`${name}`}</p>
          <button
            className={styles.headLine_button}
            onClick={this.props.onClose}
          >
            <Svg icon="Close" className={styles.headLine_icon} />
          </button>
        </div>
        <div className={styles.carousel}>
          <ReactCSSTransitionGroup
            transitionName={{
              enter: isnext ? `${styles.enter_next}` : `${styles.enter_prev}`,
              enterActive: `${styles.enter_active}`,
              leave: `${styles.leave}`,
              leaveActive: isnext
                ? `${styles.leave_active_next}`
                : `${styles.leave_active_prev}`,
            }}
          >
            <div className={styles.carousel_slide} key={index}>
              <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={0}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools">
                      <button onClick={zoomIn}>
                        <ZoomInIcon
                          style={{
                            fontSize: 30,
                          }}
                        />
                      </button>
                      <button onClick={zoomOut}>
                        <ZoomOutIcon
                          style={{
                            fontSize: 30,
                          }}
                        />
                      </button>
                      <button onClick={resetTransform}>
                        <ZoomOutMapIcon
                          style={{
                            fontSize: 30,
                          }}
                        />
                      </button>
                    </div>
                    <TransformComponent>
                      <img src={`${src}`} alt="img" />
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          </ReactCSSTransitionGroup>
        </div>
        <div className={styles.carousel_control_container}>
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__prev}`}
            onClick={this.handlerPrev}
          >
            <Svg icon="ArrowLeft" className={styles.ArrowLeft} />
          </button>
          {this.props.tablesForModel.images.map((image, index) => (
            <button
              key={index}
              className={`${styles.carousel_control} ${
                index === this.state.current
                  ? styles.carousel_control__active
                  : ""
              } ${styles.carousel_control__index}`}
              onClick={this.handlerIndex(index)}
              title={image.name}
            >
              {index === 0 && <Svg icon="Aerial" className={styles.Icon} />}
              {index === 1 && (
                <Svg icon="Perspective" className={styles.Icon} />
              )}
              {index > 1 && (
                <span className={styles.IconNumbers}>{index - 1}</span>
              )}
            </button>
          ))}
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__next}`}
            onClick={this.handlerNext}
          >
            <Svg icon="ArrowRight" className={styles.ArrowRight} />
          </button>
        </div>
        {/* <button
            className={`${styles.carousel_control} ${styles.carousel_control__prev}`}
            onClick={this.handlerPrev}
          >
            <Svg icon="ArrowLeft" className={styles.ArrowLeft} />
          </button>
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__next}`}
            onClick={this.handlerNext}
          >
            <Svg icon="ArrowRight" className={styles.ArrowRight} />
          </button> */}
      </div>
    );
  }
}
