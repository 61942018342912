import React from "react";
import PropTypes from "prop-types";

const Concept = ({ className }) => {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>List/original</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd">
        <g>
          <g>
            <circle
              // className={className}
              stroke="#FFFFFF"
              strokeWidth="2"
              cx="18"
              cy="18"
              r="17"
            ></circle>
            <g
              transform="translate(10.000000, 12.000000)"
              fill="#307FF4"
              fillRule="nonzero"
            >
              <path d="M15.0526113,12 L4.61069192,12 C4.18033026,12 3.83152174,11.66425 3.83152174,11.25 C3.83152174,10.83575 4.18033026,10.5 4.61069192,10.5 L15.0523516,10.5 C15.4827132,10.5 15.8315217,10.83575 15.8315217,11.25 C15.8315217,11.66425 15.4829729,12 15.0526113,12 Z"></path>
              <path d="M15.0526111,6.75 L4.61069191,6.75 C4.18033025,6.75 3.83152174,6.41425 3.83152174,6 C3.83152174,5.58575 4.18033025,5.25 4.61069191,5.25 L15.0523514,5.25 C15.4827131,5.25 15.8315217,5.58575 15.8315217,6 C15.8317813,6.41425 15.4829728,6.75 15.0526111,6.75 Z"></path>
              <path d="M15.0526113,1.5 L4.61069192,1.5 C4.18033026,1.5 3.83152174,1.16425 3.83152174,0.75 C3.83152174,0.33575 4.18033026,0 4.61069192,0 L15.0523516,0 C15.4827132,0 15.8315217,0.33575 15.8315217,0.75 C15.8315217,1.16425 15.4829729,1.5 15.0526113,1.5 Z"></path>
              <circle cx="1.20652174" cy="1.125" r="1.125"></circle>
              <circle cx="1.20652174" cy="6.375" r="1.125"></circle>
              <circle cx="1.20652174" cy="10.875" r="1.125"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Concept.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Concept;
