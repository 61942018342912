import React from "react";
import PropTypes from "prop-types";

const RestoreArchive = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          d="M343.864,398.864c11.231-4.087,21.935-9.496,31.888-16.112l-17.696-26.656c-7.832,5.212-16.26,9.466-25.104,12.672
            L343.864,398.864z"
        />
        <path
          d="M291.944,104c-83.947,0.006-151.995,68.063-151.99,152.01c0.004,63.438,39.406,120.202,98.838,142.39l11.2-29.968
            c-62.135-23.055-93.815-92.115-70.76-154.25s92.115-93.815,154.25-70.76c45.023,16.706,75.751,58.645,78.11,106.61
            l-42.784-42.784l-22.624,22.624l81.76,81.712l81.712-81.712l-22.624-22.624l-43.36,43.36
            C440.771,168.879,373.724,104.095,291.944,104z"
        />
        <path
          d="M277.352,375.104l-3.856,31.792c6.224,0.736,12.8,0.992,18.832,1.104c5.625-0.002,11.245-0.328,16.832-0.976
            l-3.632-31.776c-4.399,0.494-8.821,0.745-13.248,0.752C287.384,375.888,282.232,375.712,277.352,375.104z"
        />
        <path
          d="M402.344,480h-368V128h96V32h272v64h32V0H107.72L2.344,105.376V512h432V336h-32V480z M98.344,54.624V96H56.968
            L98.344,54.624z"
        />
      </g>
    </svg>
  );
};

RestoreArchive.propTypes = {
  className: PropTypes.string.isRequired,
};

export default RestoreArchive;
