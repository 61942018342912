import React from "react";
import PropTypes from "prop-types";

const PDF = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.375 9V2.8125C3.375 2.66332 3.43426 2.52024 3.53975 2.41475C3.64524 2.30926 3.78832 2.25 3.9375 2.25H10.6875L14.625 6.1875V9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6875 2.25V6.1875H14.625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.375 14.0625H5.5C5.79837 14.0625 6.08452 13.944 6.29549 13.733C6.50647 13.522 6.625 13.2359 6.625 12.9375C6.625 12.6391 6.50647 12.353 6.29549 12.142C6.08452 11.931 5.79837 11.8125 5.5 11.8125H4.375V15.1875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1875 11.8125H12.2188V15.1875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9062 13.7812H12.2188"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 15.1875C9.44755 15.1875 9.87678 15.0097 10.1932 14.6932C10.5097 14.3768 10.6875 13.9476 10.6875 13.5C10.6875 13.0524 10.5097 12.6232 10.1932 12.3068C9.87678 11.9903 9.44755 11.8125 9 11.8125H8.01562V15.1875H9Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

PDF.propTypes = {
  className: PropTypes.string.isRequired,
};

export default PDF;
