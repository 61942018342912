import React from "react";
import PropTypes from "prop-types";
import styles from "./TooltipLeftBottom.module.sass";

const TooltipLeftBottom = ({ title, text }) => {
  return (
    <div className={styles.tooltipLeftBottom}>
      <span className={styles.tooltipIcon}>?</span>
      <span className={styles.tooltipContent}>
        <span className={styles.tooltipTitle}>{title}</span>
        <span className={styles.tooltipText}> {text}</span>
      </span>
    </div>
  );
};

TooltipLeftBottom.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TooltipLeftBottom;
