import React from "react";
import PropTypes from "prop-types";

const Investment = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#174FA2" />
      <path
        d="M27.375 24.75H26.625V9.75C26.625 9.55109 26.546 9.36032 26.4053 9.21967C26.2647 9.07902 26.0739 9 25.875 9H20.625C20.4261 9 20.2353 9.07902 20.0947 9.21967C19.954 9.36032 19.875 9.55109 19.875 9.75V13.5H15.375C15.1761 13.5 14.9853 13.579 14.8447 13.7197C14.704 13.8603 14.625 14.0511 14.625 14.25V18H10.125C9.92609 18 9.73532 18.079 9.59467 18.2197C9.45402 18.3603 9.375 18.5511 9.375 18.75V24.75H8.625C8.42609 24.75 8.23532 24.829 8.09467 24.9697C7.95402 25.1103 7.875 25.3011 7.875 25.5C7.875 25.6989 7.95402 25.8897 8.09467 26.0303C8.23532 26.171 8.42609 26.25 8.625 26.25H27.375C27.5739 26.25 27.7647 26.171 27.9053 26.0303C28.046 25.8897 28.125 25.6989 28.125 25.5C28.125 25.3011 28.046 25.1103 27.9053 24.9697C27.7647 24.829 27.5739 24.75 27.375 24.75ZM16.125 15H19.875V24.75H16.125V15ZM10.875 19.5H14.625V24.75H10.875V19.5Z"
        fill="white"
      />
    </svg>
  );
};

Investment.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Investment;
