import React, { Component } from "react";
import { connect } from "react-redux";
import * as sessionSelectors from "../../redux/Session/sessionSelectors";
import "./Dashboard.sass";
import { Switch, Route } from "react-router-dom";
import Header from "./Header";
import Panel from "./Panel";
import Workspace from "./Workspace";
import NotFound from "../NotFound/NotFound";
import CircularProgress from "@material-ui/core/CircularProgress";
import Projects from "./Projects/ProjectsContainer";
import ArchiveProjects from "./ArchiveProjects/ArchiveProjectsContainer";
import Create from "./Create/CreateContainer";
import EditUser from "./../Auth/EditUser/EditUser";
import { changeHeaderDataDisplayTrue } from "../../redux/ProjectInfo/projectInfoActions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let isHeaderDataDisplay = this.props.isHeaderDataDisplay;
    let windowPath = window.location.pathname
      .split("/")
      .filter((x) => x !== "");
    if (
      !isHeaderDataDisplay &&
      windowPath.length < 2 &&
      windowPath[0] === "dashboard"
    )
      this.props.changeHeaderDataDisplayTrue();
    if (
      isHeaderDataDisplay &&
      windowPath.length > 2 &&
      windowPath[0] === "dashboard"
    )
      this.props.changeHeaderDataDisplayFalse();
    const { projectInfo } = this.props;

    return (
      <div className="dashboard">
        {projectInfo.loading && (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
        <Header history={this.props.history} />
        <div className="main">
          <Panel />
          <Switch>
            <Route
              exact
              path={`${this.props.match.path}/projects`}
              component={Projects}
            />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={`${this.props.match.path}/archive`}*/}
            {/*  component={ArchiveProjects}*/}
            {/*/>*/}
            <Route
              exact
              path={`${this.props.match.path}/create`}
              component={Create}
            />
            <Route
              exact
              path={`${this.props.match.path}`}
              component={Workspace}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps, { changeHeaderDataDisplayTrue })(
  Dashboard
);
