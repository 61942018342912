import Login from "./Login";
import { connect } from "react-redux";
import * as sessionSelectors from "../../../redux/Session/sessionSelectors";
// import * as sessionActions from "../../../redux/Session/sessionActions";

const mapStateToProps = (state) => {
  return {
    authenticated: sessionSelectors.getIsAuthenticated(state),
    error: sessionSelectors.getError(state),
    isLoggedOutDueToInactivityModalOpen:
      sessionSelectors.getIsLoggedOutDueToInactivityModalOpen(state),
    projectInfo: state.projectInfo.projectInfo,
  };
};

const mapDispatchToProps = {
  // setLoggedOutDueToInactivityModalClose:
  //   sessionActions.setLoggedOutDueToInactivityModalClose,
  // resetError: sessionActions.resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
