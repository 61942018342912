import React from "react";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.svg";
import Google from "../../../assets/img/icons/Google.svg";

const Login = (props) => {
  return (
    <div className={styles.login}>
      <div className={styles.leftSide}>
        <Link className={styles.logoLink} to={"/dashboard/projects"}>
          <img
            id="logo"
            src={logo}
            style={{ width: 227, height: 42 }}
            alt="logo"
          />
        </Link>
        <form>
          <h2>Login</h2>
          <p className={styles.welcome}>
            Welcome back! Please enter your details.
          </p>
          <label>Email</label>
          <input
            type="text"
            placeholder="Enter Your Email"
            value="test-user@gmail.com"
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter Your Password"
            value="xxxxxx"
          />
          <div className={styles.forgotPasswordContainer}>
            <label>
              <input type="checkbox" />
              Remember this account
            </label>
            <Link className={styles.logoLink} to={"/dashboard/projects"}>
              Forgot password
            </Link>
          </div>
          <Link className={styles.buttonSignIn} to={"/dashboard/projects"}>
            Sign in
          </Link>
          <Link
            className={styles.buttonSignInWithGoogle}
            to={"/dashboard/projects"}
          >
            <img
              id="Google"
              src={Google}
              style={{ width: 20, height: 20 }}
              alt="Google"
            />
            <span>Sign in with Google</span>
          </Link>
          <div className={styles.signUp}>
            <p>Don’t have an account?</p>
            <Link className={styles.buttonSignUn} to={"/dashboard/projects"}>
              Sign Un
            </Link>
          </div>
        </form>
      </div>
      <div className={styles.rightSide}></div>
    </div>
  );
};

export default Login;
