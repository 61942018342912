export const getModels = (state) => state.filters.models;

export const getPage = (state) => state.filters.page;

export const getActiveProgramId = (state) => state.filters.activeProgramId;

export const getRoomMixKeys = (state) => state.filters.roomMixKeys;

export const getError = (state) => state.filters.error;

export const getFilterParameters = (state) => state.filters.filterParameters;

export const getFilterHistograms = (state) => state.filters.filterHistograms;

export const getMinMaxHistograms = (state) => state.filters.minMaxHistograms;

export const getFilters = (state) => state.filters;

export const getTablesForModel = (state) => state.filters.tablesForModel;

export const getPreviousRequestForModels = (state) => {
  return state.filters.previousRequestForModels;
};
