import { combineReducers } from "redux";
import { ActionType } from "./projectInfoActions";
import toISOString from "../../helpers/toISOString";

let projects = localStorage.getItem("projects");
if (projects === null) {
  projects = [];
} else {
  projects = JSON.parse(projects);
}

const initialState = {
  isHeaderDataDisplay: false,
  validateMessage: false,
  logoSelected: false,
  brands: [
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
    {
      isActive: true,
      imageURL: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      brandName: "beatae vitae",
    },
  ],
  projects: [
    {
      name: "Lucky",
      created: "22.02.2020",
      lastEdited: "17.11.2020",
      lotArea: "184478 sf / 4.24 ac",
      area_sf: 184478,
      area_ac: 4.24,
      projectSite: {
        bbox: [
          -87.62409254908562, 41.886908457519354, -87.62246176600456,
          41.8880865635976,
        ],
        geometry: {
          coordinates: [
            [
              [-87.62321278452873, 41.88807158780921],
              [-87.62255430221558, 41.88800868945961],
              [-87.62248322367668, 41.88793580716754],
              [-87.6224698126316, 41.887324790708846],
              [-87.62246176600456, 41.88695837770922],
              [-87.62359902262688, 41.88694839367437],
              [-87.62359768152237, 41.88691245113597],
              [-87.62365534901619, 41.88691245113597],
              [-87.62369021773338, 41.88691145273185],
              [-87.62385383248329, 41.886910454327705],
              [-87.62405768036842, 41.886908457519354],
              [-87.62406572699547, 41.887264886819935],
              [-87.62402281165123, 41.887264886819935],
              [-87.62402415275574, 41.88729883236388],
              [-87.62402415275574, 41.887319798720256],
              [-87.62402683496475, 41.887471554999365],
              [-87.62403085827827, 41.887623310917974],
              [-87.62403219938278, 41.88769918874212],
              [-87.62408450245857, 41.88769918874212],
              [-87.6240885257721, 41.88782997793794],
              [-87.6240898668766, 41.88794978459987],
              [-87.62409254908562, 41.888070589423194],
              [-87.62409254908562, 41.88808157166849],
              [-87.6239624619484, 41.888082570054365],
              [-87.62362450361252, 41.888084566825995],
              [-87.62343272566795, 41.8880865635976],
              [-87.62321278452873, 41.88807158780921],
            ],
          ],
          type: "Polygon",
        },
        parcels: [
          {
            address: "151 E WACKER DR",
            fid: 329769,
            ogc_fid: 329769,
            owner: "DAN MC MENAMY",
            parcelnumb: "17103010140000",
            path: "/us/il/cook/chicago/329769",
          },
          {
            address: "111 E WACKER DR",
            fid: 329810,
            ogc_fid: 329810,
            owner: "IC 111 BUILDING CO LLC",
            parcelnumb: "17103010110000",
            path: "/us/il/cook/chicago/329810",
          },
          {
            address: "233 N MICHIGAN AVE",
            fid: 608740,
            ogc_fid: 608740,
            owner: "IC 233 BUILDING CO LLC",
            parcelnumb: "17103010130000",
            path: "/us/il/cook/chicago/608740",
          },
        ],
        properties: {
          address: "151 E WACKER DR",
          fid: 329769,
          ogc_fid: 329769,
          owner: "DAN MC MENAMY",
          parcelnumb: "17103010140000",
          path: "/us/il/cook/chicago/329769",
        },
        type: "Feature",
      },
      st: "Chicago Riverwalk",
      st1: "Chicago",
      st2: "",
      st3: "US",
      st4: "03456",
      img: `https://creazilla-store.fra1.digitaloceanspaces.com/emojis/56735/four-leaf-clover-emoji-clipart-md.png`,
    },
    {
      name: "beatae vitae",
      created: "22.02.2020",
      lastEdited: "17.11.2020",
      lotArea: "184,478 sf / 4.24 ac",
      area_sf: 184478,
      area_ac: 4.24,
      st: "Chicago Riverwalk",
      st1: "Chicago",
      st2: "",
      st3: "US",
      st4: "03456",
      img: `https://cdn.logo.com/hotlink-ok/logo-social.png`,
      projectSite: {
        bbox: [
          -87.62409254908562, 41.886908457519354, -87.62246176600456,
          41.8880865635976,
        ],
        geometry: {
          coordinates: [
            [
              [-87.62321278452873, 41.88807158780921],
              [-87.62255430221558, 41.88800868945961],
              [-87.62248322367668, 41.88793580716754],
              [-87.6224698126316, 41.887324790708846],
              [-87.62246176600456, 41.88695837770922],
              [-87.62359902262688, 41.88694839367437],
              [-87.62359768152237, 41.88691245113597],
              [-87.62365534901619, 41.88691245113597],
              [-87.62369021773338, 41.88691145273185],
              [-87.62385383248329, 41.886910454327705],
              [-87.62405768036842, 41.886908457519354],
              [-87.62406572699547, 41.887264886819935],
              [-87.62402281165123, 41.887264886819935],
              [-87.62402415275574, 41.88729883236388],
              [-87.62402415275574, 41.887319798720256],
              [-87.62402683496475, 41.887471554999365],
              [-87.62403085827827, 41.887623310917974],
              [-87.62403219938278, 41.88769918874212],
              [-87.62408450245857, 41.88769918874212],
              [-87.6240885257721, 41.88782997793794],
              [-87.6240898668766, 41.88794978459987],
              [-87.62409254908562, 41.888070589423194],
              [-87.62409254908562, 41.88808157166849],
              [-87.6239624619484, 41.888082570054365],
              [-87.62362450361252, 41.888084566825995],
              [-87.62343272566795, 41.8880865635976],
              [-87.62321278452873, 41.88807158780921],
            ],
          ],
          type: "Polygon",
        },
        parcels: [
          {
            address: "151 E WACKER DR",
            fid: 329769,
            ogc_fid: 329769,
            owner: "DAN MC MENAMY",
            parcelnumb: "17103010140000",
            path: "/us/il/cook/chicago/329769",
          },
          {
            address: "111 E WACKER DR",
            fid: 329810,
            ogc_fid: 329810,
            owner: "IC 111 BUILDING CO LLC",
            parcelnumb: "17103010110000",
            path: "/us/il/cook/chicago/329810",
          },
          {
            address: "233 N MICHIGAN AVE",
            fid: 608740,
            ogc_fid: 608740,
            owner: "IC 233 BUILDING CO LLC",
            parcelnumb: "17103010130000",
            path: "/us/il/cook/chicago/608740",
          },
        ],
        properties: {
          address: "151 E WACKER DR",
          fid: 329769,
          ogc_fid: 329769,
          owner: "DAN MC MENAMY",
          parcelnumb: "17103010140000",
          path: "/us/il/cook/chicago/329769",
        },
        type: "Feature",
      },
    },
    ...projects,
  ],
  user: {
    firstName: "Name",
    lastName: "Surname",
    email: "email",
    companyName: "Company name",
    families: "families",
  },
  "3d_bucket": true,
  isAuth: false,
  brand: {
    brandId: "607985ee9ebe69cc0b14bb02",
    brandName: "Sed ut perspiciatis",
    delay_enabled: true,
    familyId: "5cd743ca8f008a50e8b14ee9",
    familyLogoURL: "",
    headerImageURL:
      "http://online-altair.com/wp-content/uploads/2015/06/1111.png",
    imageURL: "http://online-altair.com/wp-content/uploads/2015/06/1111.png",
    isActive: true,
  },
  brandId: "607985ee9ebe69cc0b14bb02",
  brandName: "Sed ut perspiciatis",
  budget_assumptions: {
    budget_contingency: 0.052,
    budget_custom: [],
    budget_dev_fee: 0.041,
    budget_ffe: {
      double_01: 200003,
      double_ada_01: 220002,
      single_01: 220002,
      single_ada_01: 240003,
      suite_01: 230004,
      suite_02: 300001,
      suite_ada_01: 240004,
    },
    budget_ffe_keys: [
      "suite_ada_01",
      "single_01",
      "suite_01",
      "double_ada_01",
      "suite_02",
      "single_ada_01",
      "double_01",
    ],
    budget_ffe_objects: {
      double_01: { name: "unde omnis iste", value: 20000 },
      double_ada_01: { name: "natus error sit", value: 22000 },
      single_01: { name: "voluptatem accusantium", value: 22000 },
      single_ada_01: { name: "doloremque laudantium", value: 24000 },
      suite_01: { name: "totam rem aperiam", value: 23000 },
      suite_02: { name: "eaque ipsa quae", value: 30000 },
      suite_ada_01: { name: "ab illo inventore", value: 24000 },
    },
    budget_hard: { per_gsf: 200, per_room: 140000 },
    budget_hard_units: "veritatis et",
    budget_land: 3500000,
    budget_soft: { per_gsf: 20, percent_of_hard: 0.1 },
    budget_soft_units: "veritatis et",
  },
  dateCreated: "2021-06-09T04:04:38.182Z",
  dateLastEdited: "2021-06-16T10:49:41.271Z",
  familyId: "5cd743ca8f008a50e8b14ee9",
  investment_assumptions: {
    inv_c_loan_fee: 0.02,
    inv_c_loan_interest_rate: 0.07,
    inv_c_loan_ltc: 0.6,
    inv_c_loan_period: 30,
    inv_caprate: 0.08,
    inv_gop_margin: 0.55,
    inv_margin_selection: "quasi architecto",
    inv_noi_margin: 0.45,
    inv_op_ex: {
      op_ex_insurance: 0.01,
      op_ex_mgmt_fee: 0.035,
      op_ex_prop_tax: 3000,
      op_ex_reserves: 0.03,
    },
    inv_selling_costs: 0.03,
  },
  isActive: true,
  need_recalculation: false,
  projectName: "beatae vitae",
  revenue_assumptions: {
    blended_revenue: false,
    default_blended_adr: 165.0,
    default_blended_occ: 0.7,
    rev_custom: [],
    rev_fb: {
      per_gsf_f_b: 100,
      per_operating_room_night: 20,
      percent_rev_rooms: 0.2,
      total: 500000,
    },
    rev_fb_units: "ex ea commodi",
    rev_meetings: {
      per_gsf_meetings: 50,
      per_operating_room_night: 10,
      percent_rev_rooms: 0.05,
      total: 200000,
    },
    rev_meetings_units: "consequatur",
    rev_rooms_adr: {
      double_01: 140,
      double_ada_01: 140,
      single_01: 140,
      single_ada_01: 140,
      suite_01: 160,
      suite_02: 180,
      suite_ada_01: 160,
    },
    rev_rooms_adr_group: {
      "607985ee50b3b987d02886eb": {
        key: "607985ee50b3b987d02886eb",
        name: "Quis autem vel",
        value: 140,
      },
      "607985ee50b3b987d0288704": {
        key: "607985ee50b3b987d0288704",
        name: "eum iure reprehenderit",
        value: 140,
      },
      "607985ee50b3b987d028871c": {
        key: "607985ee50b3b987d028871c",
        name: "qui in ea voluptate",
        value: 180,
      },
      "607985ee50b3b987d0288726": {
        key: "607985ee50b3b987d0288726",
        name: "velit esse",
        value: 160,
      },
    },
    rev_rooms_adr_list: [
      {
        key: "607985ee50b3b987d0288704",
        name: "Sed ut perspiciatis",
        value: 140,
      },
      { key: "607985ee50b3b987d028871c", name: "spernatur aut", value: 180 },
      { key: "607985ee50b3b987d02886eb", name: "dolore magnam", value: 140 },
      {
        key: "607985ee50b3b987d0288726",
        name: "quaerat voluptatem",
        value: 160,
      },
    ],
    rev_rooms_occ: {
      double_01: 0.72,
      double_ada_01: 0.72,
      single_01: 0.72,
      single_ada_01: 0.72,
      suite_01: 0.72,
      suite_02: 0.72,
      suite_ada_01: 0.72,
    },
    rev_rooms_occ_group: {
      "607985ee50b3b987d02886eb": {
        key: "607985ee50b3b987d02886eb",
        name: "aut odit aut.",
        value: 0.72,
      },
      "607985ee50b3b987d0288704": {
        key: "607985ee50b3b987d0288704",
        name: "dolor sit amet",
        value: 0.72,
      },
      "607985ee50b3b987d028871c": {
        key: "607985ee50b3b987d028871c",
        name: "Ut enim ad minima",
        value: 0.72,
      },
      "607985ee50b3b987d0288726": {
        key: "607985ee50b3b987d0288726",
        name: "veniam, quis nostrum",
        value: 0.72,
      },
    },
    rev_rooms_occ_list: [
      {
        key: "607985ee50b3b987d0288704",
        name: "laboriosam",
        value: 0.72,
      },
      { key: "607985ee50b3b987d028871c", name: "ut aliquid ex", value: 0.72 },
      {
        key: "607985ee50b3b987d02886eb",
        name: "ed quia consequuntur",
        value: 0.72,
      },
      {
        key: "607985ee50b3b987d0288726",
        name: "aspernatur aut odit",
        value: 0.72,
      },
    ],
  },
  site_assumptions: {
    parking_plus_spaces: 0,
    parking_ratio: 1,
    sf_per_space: 350,
  },
  userId: "5d4db8ad455e09be36d17786",
  version: "1.0.0",
  view: "streets-v11",
  address: {
    city: "Chicago",
    country: "US",
    formatted_address: "Chicago Riverwalk, Chicago, IL, USA",
    state: "IL",
    street: "Chicago Riverwalk",
    streetNumber: "",
    zip: "",
  },
  area_ac: 4.2350219258132,
  area_sf: 184477.661347446,
  projectLocation: {
    geometry: { coordinates: [-87.6246317, 41.8881207], type: "Point" },
    type: "Feature",
  },
  projectSetback: {
    geometry: {
      coordinates: [
        [
          [-87.62409254908562, 41.88808157166849],
          [-87.6239624619484, 41.888082570054365],
          [-87.62362450361252, 41.888084566825995],
          [-87.62343272566795, 41.8880865635976],
          [-87.62321278452873, 41.88807158780921],
          [-87.62255430221558, 41.88800868945961],
          [-87.62248322367668, 41.88793580716754],
          [-87.6224698126316, 41.887324790708846],
          [-87.62246176600456, 41.88695837770922],
          [-87.62359902262688, 41.88694839367437],
          [-87.62359768152237, 41.88691245113597],
          [-87.62365534901619, 41.88691245113597],
          [-87.62369021773338, 41.88691145273185],
          [-87.62385383248329, 41.886910454327705],
          [-87.62405768036842, 41.886908457519354],
          [-87.62406572699547, 41.887264886819935],
          [-87.62402281165123, 41.887264886819935],
          [-87.62402415275574, 41.88729883236388],
          [-87.62402415275574, 41.887319798720256],
          [-87.62402683496475, 41.887471554999365],
          [-87.62403085827827, 41.887623310917974],
          [-87.62403219938278, 41.88769918874212],
          [-87.62408450245857, 41.88769918874212],
          [-87.6240885257721, 41.88782997793794],
          [-87.6240898668766, 41.88794978459987],
          [-87.62409254908562, 41.888070589423194],
          [-87.62409254908562, 41.88808157166849],
        ],
      ],
      type: "Polygon",
    },
    properties: {},
    type: "Feature",
  },
  projectSite: {
    bbox: [
      -87.62409254908562, 41.886908457519354, -87.62246176600456,
      41.8880865635976,
    ],
    geometry: {
      coordinates: [
        [
          [-87.62321278452873, 41.88807158780921],
          [-87.62255430221558, 41.88800868945961],
          [-87.62248322367668, 41.88793580716754],
          [-87.6224698126316, 41.887324790708846],
          [-87.62246176600456, 41.88695837770922],
          [-87.62359902262688, 41.88694839367437],
          [-87.62359768152237, 41.88691245113597],
          [-87.62365534901619, 41.88691245113597],
          [-87.62369021773338, 41.88691145273185],
          [-87.62385383248329, 41.886910454327705],
          [-87.62405768036842, 41.886908457519354],
          [-87.62406572699547, 41.887264886819935],
          [-87.62402281165123, 41.887264886819935],
          [-87.62402415275574, 41.88729883236388],
          [-87.62402415275574, 41.887319798720256],
          [-87.62402683496475, 41.887471554999365],
          [-87.62403085827827, 41.887623310917974],
          [-87.62403219938278, 41.88769918874212],
          [-87.62408450245857, 41.88769918874212],
          [-87.6240885257721, 41.88782997793794],
          [-87.6240898668766, 41.88794978459987],
          [-87.62409254908562, 41.888070589423194],
          [-87.62409254908562, 41.88808157166849],
          [-87.6239624619484, 41.888082570054365],
          [-87.62362450361252, 41.888084566825995],
          [-87.62343272566795, 41.8880865635976],
          [-87.62321278452873, 41.88807158780921],
        ],
      ],
      type: "Polygon",
    },
    parcels: [
      {
        address: "151 E WACKER DR",
        fid: 329769,
        ogc_fid: 329769,
        owner: "DAN MC MENAMY",
        parcelnumb: "17103010140000",
        path: "/us/il/cook/chicago/329769",
      },
      {
        address: "111 E WACKER DR",
        fid: 329810,
        ogc_fid: 329810,
        owner: "IC 111 BUILDING CO LLC",
        parcelnumb: "17103010110000",
        path: "/us/il/cook/chicago/329810",
      },
      {
        address: "233 N MICHIGAN AVE",
        fid: 608740,
        ogc_fid: 608740,
        owner: "IC 233 BUILDING CO LLC",
        parcelnumb: "17103010130000",
        path: "/us/il/cook/chicago/608740",
      },
    ],
    properties: {
      address: "151 E WACKER DR",
      fid: 329769,
      ogc_fid: 329769,
      owner: "DAN MC MENAMY",
      parcelnumb: "17103010140000",
      path: "/us/il/cook/chicago/329769",
    },
    type: "Feature",
  },
  setbackInput: [
    { frontage: "None", setback: 0, side: "A" },
    { frontage: "None", setback: 0, side: "B" },
    { frontage: "None", setback: 0, side: "C" },
    { frontage: "None", setback: 0, side: "D" },
    { frontage: "None", setback: 0, side: "E" },
    { frontage: "None", setback: 0, side: "F" },
    { frontage: "None", setback: 0, side: "G" },
    { frontage: "None", setback: 0, side: "H" },
    { frontage: "None", setback: 0, side: "I" },
    { frontage: "None", setback: 0, side: "J" },
    { frontage: "None", setback: 0, side: "K" },
  ],
  site_location: {
    location: { site_acreage: 4.2350219258132, site_sf: 184477.661347446 },
  },
};

const settings = {
  bearing: -139.2000000000001,
  buildingPolygon: {
    geometry: {
      coordinates: [
        [
          [-87.6237800404122, 41.88710277108636],
          [-87.62378899298646, 41.88710117672162],
          [-87.62380030940224, 41.887136393832],
          [-87.62383289681533, 41.88713059033001],
          [-87.62387638936367, 41.88726594018533],
          [-87.62384738292859, 41.88727110595103],
          [-87.62385858803357, 41.887305976460574],
          [-87.62384605438726, 41.88730820857939],
          [-87.62385331886941, 41.88733081578525],
          [-87.62366835782633, 41.887363755347025],
          [-87.6236839232183, 41.887412195522224],
          [-87.62370422776625, 41.887408579517796],
          [-87.62374873965412, 41.887547101890235],
          [-87.6237212729447, 41.887551993392194],
          [-87.6237474267373, 41.88763338462468],
          [-87.62356869581461, 41.88766521435041],
          [-87.6235649436739, 41.8876535375096],
          [-87.62354317079517, 41.887657414963876],
          [-87.62365093755966, 41.88798760473479],
          [-87.62362035509983, 41.887993051050245],
          [-87.62362249674666, 41.887999715903426],
          [-87.62357511899667, 41.88800815321127],
          [-87.62358288031439, 41.888032306650544],
          [-87.6234018204699, 41.88806455065129],
          [-87.62339405921671, 41.88804039719983],
          [-87.62334660978681, 41.88804884716804],
          [-87.6233444681671, 41.888042182309746],
          [-87.62331410051938, 41.88804759028018],
          [-87.62320454084599, 41.887701682513004],
          [-87.62319827398389, 41.887702798535024],
          [-87.62314648179151, 41.88754161539424],
          [-87.6231527486387, 41.887540499375035],
          [-87.62313473364753, 41.8874844345129],
          [-87.62315622001893, 41.88748060815184],
          [-87.6230757567877, 41.887230195733316],
          [-87.62308506751341, 41.88722853764879],
          [-87.62307753777422, 41.88720510399296],
          [-87.62377274173289, 41.88708005729152],
          [-87.6237800404122, 41.88710277108636],
        ],
      ],
      type: "Polygon",
    },
    properties: {},
    type: "Feature",
  },
  coordinates: [-87.62346168962401, 41.88752720500567],
  modelTransform: {
    rotateX: 1.5707963267948966,
    rotateY: -1.3359914655980354,
    rotateZ: 0,
    scale: 1,
    translateX: -87.62358120470242,
    translateY: 41.88755898612862,
    translateZ: 0,
  },
  pitch: 60,
  selected_contact: "626fdd83bf7f5df75db25c77",
  site_bearing: -84,
  site_coordinates: [-87.62346168962401, 41.88752720500567],
  site_zoom: 16.446664593044538,
  zoom: 17.410138699866003,
};

const projectInfoReducer = (state = initialState, { type, payload }) => {
  const dateNow = new Date();

  switch (type) {
    case ActionType.ADD_AREAAC_AND_AREASF: {
      return {
        ...state,
        area_ac: payload["acres"].toFixed(2),
        area_sf: Math.round(payload["sqft"]),
      };
    }
    case ActionType.ADD_PROJECT_LOGIC: {
      let lotArea = payload.lotArea.split(" ");
      return {
        ...state,
        projectName: payload.name,
        projectSite: payload.projectSite,
        address: { ...state.address, street: payload.st },
        area_ac: payload.area_ac,
        area_sf: payload.area_sf,
      };
    }
    case ActionType.CHANGE_HEADER_DATA_DISPLAY_TRUE: {
      return {
        ...state,
        isHeaderDataDisplay: true,
      };
    }
    case ActionType.CHANGE_HEADER_DATA_DISPLAY_FALSE: {
      return {
        ...state,
        isHeaderDataDisplay: false,
      };
    }
    case ActionType.LOGO_VALIDATE_MESSAGE_FALSE: {
      return {
        ...state,
        validateMessage: false,
      };
    }
    case ActionType.LOGO_VALIDATE_MESSAGE: {
      return {
        ...state,
        validateMessage: true,
      };
    }
    case ActionType.LOGO_SELECTED_SWITCH: {
      // if (state.logoSelected)
      //   return {
      //     ...state,
      //     logoSelected: false,
      //   };
      // else
      return {
        ...state,
        logoSelected: true,
      };
    }
    case ActionType.LOGO_SELECTED_SWITCH_TO_FALSE: {
      // if (state.logoSelected)
      //   return {
      //     ...state,
      //     logoSelected: false,
      //   };
      // else
      return {
        ...state,
        logoSelected: false,
      };
    }
    case ActionType.ADD_NEW_PROJECT_TO_PROJECTS:
      return {
        ...state,
        projects: [...state.projects, payload],
      };
    case ActionType.FETCH_PROJECT_INFO_SUCCESS:
      return payload.projectInfo;
    case ActionType.REMOVE_PROJECT_INFO:
      return initialState;
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          ...payload.projectInfoKey,
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_fb: {
            ...state.revenue_assumptions.rev_fb,
            ...payload.rev_fb,
          },
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_meetings: {
            ...state.revenue_assumptions.rev_meetings,
            ...payload.rev_meetings,
          },
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_rooms_adr_list: [...payload.projectInfoKey],
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_rooms_occ_list: [...payload.projectInfoKey],
        },
      };

    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          ...payload.projectInfoKey,
        },
      };

    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_ffe_objects: {
            ...state.budget_assumptions.budget_ffe_objects,
            ...payload.projectInfoKey,
          },
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_soft: {
            ...state.budget_assumptions.budget_soft,
            ...payload.projectInfoKey,
          },
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_hard: {
            ...state.budget_assumptions.budget_hard,
            ...payload.projectInfoKey,
          },
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS:
      return {
        ...state,
        investment_assumptions: {
          ...state.investment_assumptions,
          ...payload.projectInfoKey,
        },
      };
    case ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX:
      return {
        ...state,
        investment_assumptions: {
          ...state.investment_assumptions,
          inv_op_ex: {
            ...state.investment_assumptions.inv_op_ex,
            ...payload.projectInfoKey,
          },
        },
      };

    case ActionType.SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS:
      return {
        ...state,
        site_assumptions: {
          ...state.site_assumptions,
          ...payload.projectInfoKey,
        },
      };

    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_ON_BLUR:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          ...payload.projectInfoKey,
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_FB_ON_BLUR:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_fb: {
            ...state.revenue_assumptions.rev_fb,
            ...payload.rev_fb,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_MEETINGS_ON_BLUR:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_meetings: {
            ...state.revenue_assumptions.rev_meetings,
            ...payload.rev_meetings,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_ADR_LIST_ON_BLUR:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_rooms_adr_list: [...payload.projectInfoKey],
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_REVENUE_ASSUMPTIONS_KEY_REV_ROOMS_OCC_LIST_ON_BLUR:
      return {
        ...state,
        revenue_assumptions: {
          ...state.revenue_assumptions,
          rev_rooms_occ_list: [...payload.projectInfoKey],
        },
        dateLastEdited: toISOString(dateNow),
      };

    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_ON_BLUR:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          ...payload.projectInfoKey,
        },
        dateLastEdited: toISOString(dateNow),
      };

    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_BUDGET_FFE_ON_BLUR:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_ffe_objects: {
            ...state.budget_assumptions.budget_ffe_objects,
            ...payload.projectInfoKey,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_SOFT_COSTS_ON_BLUR:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_soft: {
            ...state.budget_assumptions.budget_soft,
            ...payload.projectInfoKey,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_BUDGET_ASSUMPTIONS_KEY_HARD_COSTS_ON_BLUR:
      return {
        ...state,
        budget_assumptions: {
          ...state.budget_assumptions,
          budget_hard: {
            ...state.budget_assumptions.budget_hard,
            ...payload.projectInfoKey,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };
    case ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_ON_BLUR:
      return {
        ...state,
        investment_assumptions: {
          ...state.investment_assumptions,
          ...payload.projectInfoKey,
        },
        dateLastEdited: toISOString(dateNow),
      };

    case ActionType.SET_PROJECT_INFO_KEY_INVESTMENT_ASSUMPTIONS_KEY_OP_EX_ON_BLUR:
      return {
        ...state,
        investment_assumptions: {
          ...state.investment_assumptions,
          inv_op_ex: {
            ...state.investment_assumptions.inv_op_ex,
            ...payload.projectInfoKey,
          },
        },
        dateLastEdited: toISOString(dateNow),
      };

    case ActionType.SET_PROJECT_INFO_KEY_SITE_ASSUMPTIONS_ON_BLUR:
      return {
        ...state,
        site_assumptions: {
          ...state.site_assumptions,
          ...payload.projectInfoKey,
        },
        dateLastEdited: toISOString(dateNow),
      };

    case ActionType.SET_PROJECT_INFO_KEY_VIEW:
      return {
        ...state,
        view: payload.view,
        dateLastEdited: toISOString(dateNow),
      };

    default:
      return state;
  }
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    // case ActionType.EDIT_PROJECT_INFO_START:
    case ActionType.FETCH_PROJECT_INFO_START:
      return true;

    case ActionType.FETCH_PROJECT_INFO_SUCCESS:
    case ActionType.FETCH_PROJECT_INFO_ERROR:
      // case ActionType.EDIT_PROJECT_INFO_SUCCESS:
      // case ActionType.EDIT_PROJECT_INFO_ERROR:
      return false;

    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_PROJECT_INFO_START:
      return null;

    case ActionType.FETCH_PROJECT_INFO_ERROR:
      return payload.error;

    default:
      return state;
  }
};

const settingsReducer = (state = settings, { type, payload }) => {
  switch (type) {
    case ActionType.FETCH_3D_SETTINGS_FOR_PROJECT_SUCCESS:
      return payload.settings;

    case ActionType.SET_3D_SETTINGS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default combineReducers({
  projectInfo: projectInfoReducer,
  "3d_settings": settingsReducer,
  loading: loadingReducer,
  error: errorReducer,
});
