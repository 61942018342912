import React from "react";
import PropTypes from "prop-types";

const Print = ({ className }) => {
  return (
    <svg
      data-name="Group 41 Copy 2"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={className}
    >
      <g
        className={className}
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14" fill="none" />
      </g>
      <g
        id="search_1_"
        data-name="search (1)"
        transform="translate(8 9)"
        stroke="none"
      >
        <g id="printer">
          <path
            d="M10.965,13H3.035a.4.4,0,0,1-.409-.381V10.384H1.012A.979.979,0,0,1,0,9.445V5.385A1.7,1.7,0,0,1,1.531,3.75v-1.2a.372.372,0,0,1,.122-.27.428.428,0,0,1,.289-.111l1.23,0V.381a.367.367,0,0,1,.121-.27A.426.426,0,0,1,3.582,0l6.837.021A.4.4,0,0,1,10.828.4v1.8l1.232,0a.4.4,0,0,1,.409.381v1.16a1.7,1.7,0,0,1,1.525,1.634v4.06a.979.979,0,0,1-1.012.94H11.375v2.235A.4.4,0,0,1,10.965,13ZM3.445,8.263v3.975h7.109V8.263Zm7.93,0v1.36h1.606a.186.186,0,0,0,.192-.178V5.385a.926.926,0,0,0-.958-.889H1.778a.926.926,0,0,0-.958.889v4.06a.186.186,0,0,0,.192.178H2.625V8.263H2.256a.382.382,0,1,1,0-.761h9.488a.382.382,0,1,1,0,.761Zm-.547-5.3v.767h.821V2.971l-.821,0ZM3.992.763V3.734h6.016V.781ZM2.352,2.934v.8h.82v-.8l-.82,0Z"
            transform="translate(0)"
            fill="#fff"
          />
          <path
            d="M2.733,0H.41A.4.4,0,0,0,0,.381.4.4,0,0,0,.41.762H2.733a.4.4,0,0,0,.41-.381A.4.4,0,0,0,2.733,0Z"
            transform="translate(6.591 10.537)"
            fill="#fff"
          />
          <path
            data-name="Path"
            d="M5.056,0H.41A.4.4,0,0,0,0,.381.4.4,0,0,0,.41.762H5.056a.4.4,0,0,0,.41-.381A.4.4,0,0,0,5.056,0Z"
            transform="translate(4.268 9.014)"
            fill="#fff"
          />
          <path
            data-name="Path"
            d="M1.189,0H.41A.4.4,0,0,0,0,.381.4.4,0,0,0,.41.762h.779A.4.4,0,0,0,1.6.381.4.4,0,0,0,1.189,0Z"
            transform="translate(10.746 5.257)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

Print.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Print;
