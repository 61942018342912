import percentFormat from "./percentFormat";
import dollarIntegerFormat from "./dollarIntegerFormat";
import dollarFloatFormat from "./dollarFloatFormat";
import stringFormat from "./stringFormat";
import withoutDollarFloatFormat from "./withoutDollarFloatFormat";
import withoutDollarIntegerFormat from "./withoutDollarIntegerFormat";

const guestroomsRevenueToArray = (guestrooms_revenue) => {
  const thead = [
    "Statistic",
    "Nuts",
    "Mux %",
    "HFD",
    "Pmm.",
    "ResSJJ",
    "OPDUSM",
  ];
  //Temporarry change for presentation

  // guestrooms_revenue.by_type.king = {};
  // guestrooms_revenue.by_type.king.keys =
  //   guestrooms_revenue.by_type.accessible_king.keys +
  //   guestrooms_revenue.by_type.standard_king.keys;
  // guestrooms_revenue.by_type.king.mix =
  //   guestrooms_revenue.by_type.accessible_king.mix +
  //   guestrooms_revenue.by_type.standard_king.mix;
  // guestrooms_revenue.by_type.king.revenue =
  //   guestrooms_revenue.by_type.accessible_king.revenue +
  //   guestrooms_revenue.by_type.standard_king.revenue;

  // guestrooms_revenue.by_type.king.adr =
  //   guestrooms_revenue.by_type.standard_king.adr;
  // guestrooms_revenue.by_type.king.occup =
  //   guestrooms_revenue.by_type.standard_king.occup;
  // guestrooms_revenue.by_type.king.rev_par =
  //   guestrooms_revenue.by_type.standard_king.rev_par;

  // guestrooms_revenue.by_type.queen = {};
  // guestrooms_revenue.by_type.queen.keys =
  //   guestrooms_revenue.by_type.standard_queen.keys +
  //   guestrooms_revenue.by_type.accessible_queen.keys;
  // guestrooms_revenue.by_type.queen.mix =
  //   guestrooms_revenue.by_type.standard_queen.mix +
  //   guestrooms_revenue.by_type.accessible_queen.mix;
  // guestrooms_revenue.by_type.queen.revenue =
  //   guestrooms_revenue.by_type.standard_queen.revenue +
  //   guestrooms_revenue.by_type.accessible_queen.revenue;

  // guestrooms_revenue.by_type.queen.adr =
  //   guestrooms_revenue.by_type.standard_queen.adr;
  // guestrooms_revenue.by_type.queen.occup =
  //   guestrooms_revenue.by_type.standard_queen.occup;
  // guestrooms_revenue.by_type.queen.rev_par =
  //   guestrooms_revenue.by_type.standard_queen.rev_par;

  // guestrooms_revenue.by_type.inline_qq_suite = {};
  // guestrooms_revenue.by_type.inline_qq_suite.keys =
  //   guestrooms_revenue.by_type.inline_queen_suite.keys +
  //   guestrooms_revenue.by_type.accessible_inline_queen_suite.keys;
  // guestrooms_revenue.by_type.inline_qq_suite.mix =
  //   guestrooms_revenue.by_type.inline_queen_suite.mix +
  //   guestrooms_revenue.by_type.accessible_inline_queen_suite.mix;
  // guestrooms_revenue.by_type.inline_qq_suite.revenue =
  //   guestrooms_revenue.by_type.inline_queen_suite.revenue +
  //   guestrooms_revenue.by_type.accessible_inline_queen_suite.revenue;

  // guestrooms_revenue.by_type.inline_qq_suite.adr =
  //   guestrooms_revenue.by_type.inline_queen_suite.adr;
  // guestrooms_revenue.by_type.inline_qq_suite.occup =
  //   guestrooms_revenue.by_type.inline_queen_suite.occup;
  // guestrooms_revenue.by_type.inline_qq_suite.rev_par =
  //   guestrooms_revenue.by_type.inline_queen_suite.rev_par;
  //////////////////////////////////////////End Temporary change for presentation
  // Standard King + Accessible King → King
  // Standard Queen/Queen + Accessible Queen/Queen → Queen/Queen
  // Inline Queen/Queen Suite + Accessible Inline QQ Suite → Inline QQ Suite

  const firstColBody = {
    king: "King",
    // standard_king: "Standard King",
    // accessible_king: "Accessible King",
    queen: "Queen/Queen",
    // standard_queen: "Standard Queen/Queen",
    // accessible_queen: "Accessible Queen/Queen",
    king_suite: "King Suite",
    inline_qq_suite: "Inline Q/Q Suite",
    // inline_queen_suite: "Inline Queen/Queen Suite",
    // accessible_inline_queen_suite: "Accessible Inline QQ Suite",
  };
  const firstColFoot = {
    total: "sit amet",
  };

  const tbody = [];
  let i = 0;
  for (let keyrow = 0; keyrow < guestrooms_revenue.by_type.length; keyrow++) {
    const tbodyRow = [
      // firstColBody[keyrow],
      stringFormat(guestrooms_revenue.by_type[keyrow].name),
      stringFormat(guestrooms_revenue.by_type[keyrow].keys),
      percentFormat(guestrooms_revenue.by_type[keyrow].mix),
      i === 0
        ? dollarFloatFormat(guestrooms_revenue.by_type[keyrow].adr)
        : withoutDollarFloatFormat(guestrooms_revenue.by_type[keyrow].adr),
      percentFormat(guestrooms_revenue.by_type[keyrow].occup),
      i === 0
        ? dollarFloatFormat(guestrooms_revenue.by_type[keyrow].rev_par)
        : withoutDollarFloatFormat(guestrooms_revenue.by_type[keyrow].rev_par),
      i === 0
        ? dollarIntegerFormat(guestrooms_revenue.by_type[keyrow].revenue)
        : withoutDollarIntegerFormat(
            guestrooms_revenue.by_type[keyrow].revenue
          ),
    ];
    if (
      tbodyRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0) ||
      tbodyRow[tbodyRow.length - 1] === 0
    ) {
      tbody.push([...tbodyRow.map((el) => (el === 0 ? "-" : el))]);
    }
    i++;
  }

  const tfoot = [];

  for (const keyrow in firstColFoot) {
    const tfootRow = [
      firstColFoot[keyrow],
      stringFormat(guestrooms_revenue[keyrow].keys),
      percentFormat(guestrooms_revenue[keyrow].mix),
      dollarFloatFormat(guestrooms_revenue[keyrow].adr),
      percentFormat(guestrooms_revenue[keyrow].occup),
      dollarFloatFormat(guestrooms_revenue[keyrow].rev_par),
      dollarIntegerFormat(guestrooms_revenue[keyrow].sum),
    ];
    if (tfootRow.reduce((a, b, i) => a + (i === 0 ? 0 : b), 0)) {
      tfoot.push([...tfootRow.map((el) => (el === 0 ? "-" : el))]);
    }
  }
  const guestroomsRevenue = {
    thead: thead,
    tbody: tbody,
    tfoot: tfoot,
  };
  return guestroomsRevenue;
};

export default guestroomsRevenueToArray;
