import { combineReducers } from "redux";
import { ActionType } from "./sessionActions";

const user = {
  _id: "5d4db8ad455e09be36d17786",
  companyName: "Demo Map Box 3d",
  email: "Name.Surname@gmail.com",
  families: ["5*cd743ca8f008a50e8b14ee9"],
  firstName: "Name",
  lastName: "Surname",
  password: "",
  password_set_date: "Tue, 11 May 2021 12:49:13 GMT",
  password_too_old: false,
};

const userReducer = (state = user, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS:
      return payload.response.user;

    case ActionType.EDIT_USER_SUCCESS:
      return { ...state, ...payload.user };

    case ActionType.REFRESH_USER_SUCCESS:
      return payload.user;

    default:
      return state;
  }
};

const authenticatedReducer = (state = true, { type }) => {
  switch (type) {
    case ActionType.LOGIN_SUCCESS:
    case ActionType.REFRESH_USER_SUCCESS:
      return true;

    default:
      return state;
  }
};

const errorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case ActionType.LOGIN_ERROR:
    case ActionType.SIGNUP_ERROR:
    case ActionType.REFRESH_USER_ERROR:
    case ActionType.EDIT_USER_ERROR:
      return payload.error;

    case ActionType.LOGIN_SUCCESS:
    case ActionType.SIGNUP_SUCCESS:
    case ActionType.REFRESH_USER_SUCCESS:
    case ActionType.EDIT_USER_SUCCESS:
    case ActionType.RESET_ERROR:
      return null;

    default:
      return state;
  }
};

const isDemoModeOffReducer = (state = false, { type }) => {
  switch (type) {
    case ActionType.DEMO_MODE_TOGGLE:
      return !state;

    default:
      return state;
  }
};

export default combineReducers({
  user: userReducer,
  authenticated: authenticatedReducer,
  isDemoModeOff: isDemoModeOffReducer,
  error: errorReducer,
});
