import React from "react";
import PropTypes from "prop-types";

const Perspective = ({ className }) => {
  return (
    <svg
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
      className={className}
    >
      <defs>
        <clipPath id="Perspective">
          <path
            d="M629 293 651 293 651 315 629 315Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#Perspective)" transform="translate(-629 -293)">
        <path
          d="M630 304C630 298.477 634.477 294 640 294 645.523 294 650 298.477 650 304 650 309.523 645.523 314 640 314 634.477 314 630 309.523 630 304Z"
          fillRule="evenodd"
        />
        <path
          d="M642.5 299.5 646.5 300.972 646.446 307.859 642.5 308.5 642.5 299.5Z"
          stroke="#333333"
          strokeWidth="0.666667"
          strokeMiterlimit="8"
          fill="#FFFFFF"
          fillRule="evenodd"
        />
        <path
          d="M641.5 308.5 634.5 307.16 634.5 302.523 641.5 299.5 641.5 308.5Z"
          stroke="#333333"
          strokeWidth="0.666667"
          strokeMiterlimit="8"
          fill="#FFFFFF"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

Perspective.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Perspective;
