import React, { Component } from "react";
import "./Steps.sass";

class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { step } = this.props;

    return (
      <section id="steps" className="steps mt-3 d-flex">
        <div className="step">
          <div className="number finished">1</div>
          <span className="title">Location</span>
        </div>
        <div className={`line ${step > 1 ? "finished" : null}`}></div>

        <div className="step">
          <div className={`number ${step > 1 ? "finished" : null}`}>2</div>
          <span className="title">Site</span>
        </div>
        <div className={`line ${step > 2 ? "finished" : null}`}></div>

        <div className="step">
          <div className={`number ${step > 2 ? "finished" : null}`}>3</div>
          <span className="title">Setback</span>
        </div>
        <div className={`line ${step > 3 ? "finished" : null}`}></div>

        <div className="step">
          <div className={`number ${step > 3 ? "finished" : null}`}>4</div>
          <span className="title">Brand</span>
        </div>
      </section>
    );
  }
}

export default Steps;
