import React, { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Modal from "../../../Modal/Modal";
import CarouselInner from "./CarouselInner/CarouselInnerContainer";
import Svg from "../../../Svg/Svg";
import styles from "./CarouselReact.module.scss";

export default class CarouselReact extends Component {
  static defaultProps = {
    tablesForModel: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      isNext: true,
      isModalOpen: false,
    };
  }

  handlerIndex = (index) => (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    this.setState((prevState) => ({
      ...prevState,
      current: index,
      isNext: index > prevState.current,
    }));
  };

  handlerPrev = (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length;

    if (index < 1) {
      index = length;
    }

    index = index - 1;

    this.setState({
      current: index,
      isNext: false,
    });
  };

  handlerNext = (evt) => {
    evt && evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length - 1;

    if (index === length) {
      index = -1;
    }

    index = index + 1;

    this.setState({
      current: index,
      isNext: true,
    });
  };

  goToHistoryClick = (curIndex, index) => {
    let next = curIndex < index;
    this.setState({
      current: index,
      isNext: next,
      timerId: null,
    });
  };
  onModalOpen = (evt) => {
    evt.stopPropagation();
    this.setState(() => {
      return { isModalOpen: true };
    });
  };
  onModalClose = (evt) => {
    evt.stopPropagation();
    this.setState(() => {
      return { isModalOpen: false };
    });
  };

  componentDidMount = () => {
    // this.playCarousel();
  };
  componentWillUnmount() {
    const { timerId } = this.state;
    clearInterval(timerId);
  }

  playCarousel = () => {
    this.setState({ timerId: setInterval(this.handlerNext, 5000) });
  };

  stopCarousel = () => {
    const { timerId } = this.state;
    clearInterval(timerId);
    this.setState({ timerId: null });
  };

  render() {
    const { isModalOpen } = this.state;
    let index = this.state.current,
      isnext = this.state.isNext,
      src = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].url
        : "",
      name = this.props.tablesForModel.images
        ? this.props.tablesForModel.images[index].name
        : "";

    return (
      <div
        id="PerspectiveView"
        className={styles.PerspectiveView}
        onClick={this.onModalOpen}
        // onMouseEnter={this.stopCarousel}
        // onMouseLeave={this.playCarousel}
      >
        {/* <p className={styles.carousel_title}>{`${name}`}</p> */}
        <div className={styles.carousel}>
          <ReactCSSTransitionGroup
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: isnext ? `${styles.enter_next}` : `${styles.enter_prev}`,
              enterActive: `${styles.enter_active}`,
              leave: `${styles.leave}`,
              leaveActive: isnext
                ? `${styles.leave_active_next}`
                : `${styles.leave_active_prev}`,
            }}
          >
            <div className={styles.carousel_slide} key={index}>
              <img
                alt={`carouselImg_${index}`}
                id={`carouselImg_${index}`}
                src={`${src}`}
                title={name}
              />
            </div>
          </ReactCSSTransitionGroup>
        </div>
        <div className={styles.carousel_control_container}>
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__prev}`}
            onClick={this.handlerPrev}
          >
            <Svg icon="ArrowLeft" className={styles.ArrowLeft} />
          </button>
          {this.props.tablesForModel.images.map((image, index) => (
            <button
              key={index}
              className={`${styles.carousel_control} ${
                index === this.state.current
                  ? styles.carousel_control__active
                  : ""
              } ${styles.carousel_control__index}`}
              onClick={this.handlerIndex(index)}
              title={image.name}
            >
              {index === 0 && <Svg icon="Aerial" className={styles.Icon} />}
              {index === 1 && (
                <Svg icon="Perspective" className={styles.Icon} />
              )}
              {index > 1 && (
                <span className={styles.IconNumbers}>{index - 1}</span>
              )}
            </button>
          ))}
          <button
            className={`${styles.carousel_control} ${styles.carousel_control__next}`}
            onClick={this.handlerNext}
          >
            <Svg icon="ArrowRight" className={styles.ArrowRight} />
          </button>
        </div>
        {isModalOpen && (
          <Modal onClose={this.onModalClose}>
            <CarouselInner
              onClose={this.onModalClose}
              current={this.state.current}
            />
          </Modal>
        )}
      </div>
    );
  }
}
