import React from "react";
import PropTypes from "prop-types";

const ShowMore = ({ className }) => {
  return (
    <svg
      width="3px"
      height="12px"
      viewBox="0 0 3 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Show More</title>
      <desc>Created with sketchtool.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-338.000000, -275.000000)"
          className={className}
          fillRule="nonzero"
        >
          <g transform="translate(125.000000, 260.000000)">
            <g transform="translate(213.000000, 15.000000)">
              <path d="M1.5,3 C2.325,3 3,2.325 3,1.5 C3,0.675 2.325,0 1.5,0 C0.675,0 0,0.675 0,1.5 C0,2.325 0.675,3 1.5,3 Z M1.5,4.5 C0.675,4.5 0,5.175 0,6 C0,6.825 0.675,7.5 1.5,7.5 C2.325,7.5 3,6.825 3,6 C3,5.175 2.325,4.5 1.5,4.5 Z M1.5,9 C0.675,9 0,9.675 0,10.5 C0,11.325 0.675,12 1.5,12 C2.325,12 3,11.325 3,10.5 C3,9.675 2.325,9 1.5,9 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ShowMore.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ShowMore;
