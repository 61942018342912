import Panel from "./Panel";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../redux/ProjectInfo/projectInfoSelectors";
import * as filtersSelectors from "../../../redux/Filters/filtersSelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    filterParameters: filtersSelectors.getFilterParameters(state),
    previousRequestForModels:
      filtersSelectors.getPreviousRequestForModels(state),
    filters: filtersSelectors.getFilters(state),
  };
};

export default connect(mapStateToProps)(Panel);
