import percentFormatTwo from "./percentFormatTwo";
import dollarIntegerFormat from "./dollarIntegerFormat";
import percentIntegerFormat from "./percentIntegerFormat";
import stringFormatFloatTwo from "./stringFormatFloatTwo";

const investmentReturnsToArray = (investment_returns) => {
  const thead = ["Investment Returnqqs", ""];

  const firstColBody = {
    stabilized_yield_cost: "Separated",
    pretax_profit_margin: "Waste",
    pretax_profit_margin_percent: "proident %",
    pretax_profit_margin_per_key: "Upset",
    multiple_on_invested_equity: "Production",
  };

  const tbody = [
    [
      firstColBody.stabilized_yield_cost,
      percentFormatTwo(investment_returns.stabilized_yield_cost),
    ],
    [
      firstColBody.pretax_profit_margin,
      dollarIntegerFormat(investment_returns.pretax_profit_margin),
    ],
    [
      firstColBody.pretax_profit_margin_percent,
      percentIntegerFormat(investment_returns.pretax_profit_margin_percent),
    ],
    [
      firstColBody.pretax_profit_margin_per_key,
      dollarIntegerFormat(investment_returns.pretax_profit_margin_per_key),
    ],
    [
      firstColBody.multiple_on_invested_equity,
      stringFormatFloatTwo(investment_returns.multiple_on_invested_equity),
    ],
  ];

  const tfoot = [];

  const investmentReturns = {
    thead: thead,
    tbody: tbody
      .filter((arr, i) => !!arr[1])
      .map((el) => (el === 0 ? "-" : el)),
    tfoot: tfoot,
  };
  return investmentReturns;
};

export default investmentReturnsToArray;
