import React, { Component } from "react";
import styles from "./Viewer3d.module.scss";
import Modal from "../../../Modal/Modal";
import MapInner from "./MapInner/MapInnerContainer";
import mapboxgl from "mapbox-gl";
import {
  fetch3dSettingsForProjectStart,
  fetch3dSettingsForProjectSuccess,
} from "../../../../redux/ProjectInfo/projectInfoActions";

mapboxgl.accessToken =
  "pk.eyJ1Ijoib2xla3NhbmRycGV0cnlrIiwiYSI6ImNrNGNudW91dzA4YnUzbnBmc3l5OGl3c3AifQ.GmdMReeE1fkKHjU1FE18Vw";

export default class Viewer3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isDrowObjectOnMap: false,
      map: false,
      current: 0,
      isNext: true,
      threeDView: true,
    };
  }

  handlerIndex = (index) => (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;

    this.setState((prevState) => ({
      ...prevState,
      current: index,
      isNext: index > prevState.current,
      threeDView: index === 0 ? true : false,
    }));
  };

  handlerPrev = (evt) => {
    evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length;

    if (index < 1) {
      index = length;
    }

    index = index - 1;

    this.setState({
      current: index,
      isNext: false,
    });
  };

  handlerNext = (evt) => {
    evt && evt.stopPropagation();
    if (!this.props.tablesForModel.images) return;
    let index = this.state.current,
      length = this.props.tablesForModel.images.length - 1;

    if (index === length) {
      index = -1;
    }

    index = index + 1;

    this.setState({
      current: index,
      isNext: true,
    });
  };

  goToHistoryClick = (curIndex, index) => {
    let next = curIndex < index;
    this.setState({
      current: index,
      isNext: next,
      timerId: null,
    });
  };

  toggleIsModalOpen = (evt) => {
    evt.stopPropagation();
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  render() {
    const { isModalOpen, current, isNext, threeDView } = this.state;

    return (
      <div className={`${styles.Map3d}`}>
        {isModalOpen && (
          <Modal onClose={this.toggleIsModalOpen}>
            <div className={styles.ViewerModal}>
              <MapInner
                current={current}
                isNext={isNext}
                threeDView={threeDView}
                toggleIsModalOpen={this.toggleIsModalOpen}
                setView={this.setView}
                showInfoColumns={false}
                isModal={true}
              />
            </div>
          </Modal>
        )}
        <div className={styles.MapInnerContainer}>
          {!isModalOpen && (
            <MapInner
              current={current}
              isNext={isNext}
              threeDView={threeDView}
              toggleIsModalOpen={this.toggleIsModalOpen}
              setView={this.setView}
              showInfoColumns={true}
              isModal={false}
            />
          )}
        </div>
      </div>
    );
  }
}
