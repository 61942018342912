import React from "react";
import PropTypes from "prop-types";

const ArrowRight = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}
    >
      <g transform="translate(0 20) rotate(-90)">
        <rect width="20" height="20" rx="10" />
        <g transform="translate(12.667 8.667) rotate(90)" fill="#333333">
          <path d="M3.944,2.562.2.022A.131.131,0,0,0,.069.014.127.127,0,0,0,0,.127V5.206a.127.127,0,0,0,.069.113A.131.131,0,0,0,.2,5.311l3.742-2.54a.126.126,0,0,0,0-.209Z" />
        </g>
      </g>
    </svg>
  );
};

ArrowRight.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ArrowRight;
