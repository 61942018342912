import React from "react";
import PropTypes from "prop-types";

const Star = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.27549 11.9188L11.4255 13.9188C11.8317 14.175 12.3317 13.7938 12.213 13.325L11.3005 9.73752C11.2758 9.63808 11.2797 9.53369 11.3118 9.43638C11.3439 9.33907 11.4028 9.2528 11.4817 9.18752L14.3067 6.83127C14.6755 6.52502 14.488 5.90627 14.0067 5.87502L10.3192 5.63752C10.2186 5.63166 10.1219 5.59663 10.0409 5.53669C9.95984 5.47675 9.89803 5.39451 9.86299 5.30002L8.48799 1.83752C8.45159 1.73745 8.38528 1.65102 8.29807 1.58994C8.21086 1.52886 8.10696 1.49609 8.00049 1.49609C7.89401 1.49609 7.79011 1.52886 7.7029 1.58994C7.61569 1.65102 7.54938 1.73745 7.51299 1.83752L6.13799 5.30002C6.10294 5.39451 6.04113 5.47675 5.9601 5.53669C5.87908 5.59663 5.78235 5.63166 5.68174 5.63752L1.99424 5.87502C1.51299 5.90627 1.32549 6.52502 1.69424 6.83127L4.51924 9.18752C4.5982 9.2528 4.6571 9.33907 4.68917 9.43638C4.72124 9.53369 4.72516 9.63808 4.70049 9.73752L3.85674 13.0625C3.71299 13.625 4.31299 14.0813 4.79424 13.775L7.72549 11.9188C7.80768 11.8665 7.90308 11.8387 8.00049 11.8387C8.09789 11.8387 8.19329 11.8665 8.27549 11.9188V11.9188Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Star.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Star;
