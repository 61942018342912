import Concept from "./Concept";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";

import {
  setActiveProgramId,
  setSorting,
  toggleModelStarred,
  toggleModelHidden,
  fetchDataForPDFStart,
  fetchDataForPDFSuccess,
  fetchDataForPDFError,
  resetPage,
  removeModels,
  removeTables,
  changeDataInTable,
} from "../../../../../redux/Filters/filtersActions";
import * as filtersSelectors from "../../../../../redux/Filters/filtersSelectors";
import * as sessionSelectors from "../../../../../redux/Session/sessionSelectors";
import * as familySeletcors from "../../../../../redux/Family/familySelectors";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    filteredResults: filtersSelectors.getModels(state),
    filterParameters: filtersSelectors.getFilterParameters(state),
    filters: filtersSelectors.getFilters(state),
    error: filtersSelectors.getError(state),
    activeProgramId: filtersSelectors.getActiveProgramId(state),
    page: filtersSelectors.getPage(state),
    user: sessionSelectors.getUser(state),
    family: familySeletcors.getFamily(state),
    tablesForModel: filtersSelectors.getTablesForModel(state),
  };
};

const mapDispatchToProps = {
  changeDataInTable: changeDataInTable,
  setActiveProgramId: setActiveProgramId,
  setSorting: setSorting,
  toggleModelStarred: toggleModelStarred,
  toggleModelHidden: toggleModelHidden,
  fetchDataForPDFStart: fetchDataForPDFStart,
  fetchDataForPDFSuccess: fetchDataForPDFSuccess,
  fetchDataForPDFError: fetchDataForPDFError,
  resetPage: resetPage,
  removeModels: removeModels,
  removeTables: removeTables,
};

export default connect(mapStateToProps, mapDispatchToProps)(Concept);
