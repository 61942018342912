import BuildingProgram from "./BuildingProgram";
import { connect } from "react-redux";
import { setFilterParameters } from "../../../../../redux/Filters/filtersActions";
import {
  setProjectInfoKeySiteAssumptions,
  setProjectInfoKeySiteAssumptionsOnBlur,
} from "../../../../../redux/ProjectInfo/projectInfoActions";
import { bindActionCreators } from "redux";
import * as filtersSelectors from "../../../../../redux/Filters/filtersSelectors";
import * as projectInfoSelectors from "../../../../../redux/ProjectInfo/projectInfoSelectors";

const mapStateToProps = (state) => {
  return {
    filterParameters: filtersSelectors.getFilterParameters(state),
    filterHistograms: filtersSelectors.getFilterHistograms(state),
    minMaxHistograms: filtersSelectors.getMinMaxHistograms(state),
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    projectInfoLocal: state.projectInfoLocal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilterParameters: bindActionCreators(setFilterParameters, dispatch),
    setProjectInfoKeySiteAssumptions: bindActionCreators(
      setProjectInfoKeySiteAssumptions,
      dispatch
    ),
    setProjectInfoKeySiteAssumptionsOnBlur: bindActionCreators(
      setProjectInfoKeySiteAssumptionsOnBlur,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingProgram);
