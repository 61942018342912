import React from "react";
import PropTypes from "prop-types";

const Brand = ({ className }) => {
  return (
    <svg
      width="36px"
      height="36px"
      //   viewBox="0 0 36 36"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Brand</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Users/original">
          <circle
            className={className}
            // stroke="#174FA3"
            strokeWidth="5.555555556%"
            cx="50%"
            cy="50%"
            r="47%"
          ></circle>
          <g transform-origin="50% 50%" transform="scale(0.6)">
            <g>
              <g>
                <path
                  d="m404.053 267.469c-23.68-21.958-57.88-26.328-87.02-25.978 26.38-27.008 38.69-54.536 44.42-74.114 7.22-24.648 5.91-42.257 5.78-43.757-2.15-28.548-14.04-51.136-35.33-67.135-15.4-11.579-31.16-16.319-37.23-17.829-12.89-3.19-27.23-5.5-42.82-6.909-31.75-2.88-68.69-2.06-109.09 2.53-18.47 2.1-37.14 4.91-55.5 8.359-3.73.7-6.35 4.06-6.1 7.849 2.35 36.727 6.29 112.092 6.29 204.195 0 91.573-4.4 162.368-7.03 196.405-.28 3.64 2.1 6.949 5.64 7.849 59.4 15.019 112.73 22.768 158.52 23.028.8 0 1.59.01 2.39.01 11.8 0 23.08-.51 33.85-1.53l.01-.01c.02 0 .04 0 .06-.01 42.4-3.97 76.74-15.799 102.36-35.267 48.4-36.797 50.15-88.993 50.19-94.833v-.05c0-36.847-9.89-64.705-29.39-82.803zm-220.82-63.395c2.22-53.216.03-96.693 0-97.123-.19-3.72 2.37-7.009 6.02-7.739 2.6-.52 63.88-12.479 89.66 12.439 9.64 9.329 13.19 22.138 10.56 38.077-5.2 31.368-24.4 52.056-55.54 59.846-10.34 2.58-20.32 3.3-28.2 3.3-9.36 0-15.74-1.01-16.21-1.09-3.75-.611-6.45-3.921-6.29-7.71zm43.09 212.863c-17.64 0-30.81-1.79-32.11-1.98-3.82-.54-6.61-3.88-6.45-7.739 2.22-53.166.03-96.683.01-97.112-.19-3.68 2.32-6.96 5.92-7.73 3.38-.72 83.14-17.319 118.49 11.289 11.23 9.079 16.92 21.528 16.92 36.987 0 17.859-6.43 32.488-19.1 43.497-21.73 18.869-57.59 22.788-83.68 22.788z"
                  fill="#ffffff"
                />
              </g>
              <path
                d="m433.443 350.272v.05c-.04 5.84-1.79 58.036-50.19 94.833-25.62 19.468-59.96 31.298-102.36 35.267 55.48-11.909 113.2-52.926 120.73-129.63 10.26-104.502-123.07-92.763-123.07-92.763 135.45-146.669 16.12-219.373-26.7-226.283 15.59 1.41 29.93 3.72 42.82 6.909 6.07 1.51 21.83 6.25 37.23 17.829 21.29 15.999 33.18 38.587 35.33 67.135.13 1.5 1.44 19.108-5.78 43.757-5.73 19.578-18.04 47.106-44.42 74.114 29.14-.35 63.34 4.02 87.02 25.978 19.5 18.099 29.39 45.957 29.39 82.804z"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Brand.propTypes = { className: PropTypes.string.isRequired };

export default Brand;
