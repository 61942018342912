import React, { Component } from "react";
import "./ArchiveProjects.sass";
import { Link } from "react-router-dom";
import Svg from "../../Svg/Svg";
import noImage from "../../../assets/img/no-image.png";
import addFirstZero from "../../../helpers/addFirstZero";
class ArchiveProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: -1,
    };
  }

  componentDidMount() {
    this.getAllArchiveProjects();
  }

  getAllArchiveProjects = () => {
    const { user } = this.props;
  };

  restoreArchiveProject = (projectId) => {};

  seeMore = (index, event) => {
    event.preventDefault();

    if (index === this.state.index) {
      this.setState({ index: null });
      return false;
    }

    this.setState({
      index,
    });
  };

  render() {
    const projects = this.props.projects.map((p) => (
      <Link
        className="wrapper col-6 col-xl-4 mb-3"
        key={1}
        to={`/dashboard/project`}
      >
        <div className="project col-12 h-100 d-flex justify-content-between">
          <div className="info text-left">
            <span className="more" onClick={(event) => this.seeMore(1, event)}>
              <Svg icon="ShowMore" className="icon" />
              {/*<div*/}
              {/*  className="more-controls"*/}
              {/*>*/}
              {/*  /!* <div>*/}
              {/*        <Svg icon="PrintThinCircle" className="icon" />*/}
              {/*        <span>Print</span>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <Svg icon="ExportThinCircle" className="icon" />*/}
              {/*        <span>Export</span>*/}
              {/*      </div> *!/*/}
              {/*  /!*<div onClick={() => this.archiveProject(1)}>*!/*/}
              {/*  /!*  <Svg icon="Archive" className="icon" />*!/*/}
              {/*  /!*  <span>Archive</span>*!/*/}
              {/*  /!*</div>*!/*/}
              {/*</div>*/}
            </span>
            <h5 className="title mt-4 mb-1" title={"Without name"}>
              {p.name}
            </h5>
            <p className="date mb-0 pb-0">
              <span className="gray">Created: </span>
              <span className="font-weight-bold">{p.created}</span>
            </p>
            <p className="date mb-1 pb-0">
              <span className="gray">Last Edited: </span>
              <span className="font-weight-bold">{p.lastEdited}</span>
            </p>
            <p className=" mb-3 pb-2">
              <span className="gray">Lot area: </span>
              <span className="font-weight-bold">{p.lotArea}</span>
            </p>
            <div className="location d-flex align-items-baseline">
              <Svg icon="Location" className="icon" />
              <div>
                <span className="d-block gray">{p.st}</span>
                <span className="font-weight-bold">{p.st1} </span>
                <span className="gray">{p.st2} </span>
                <span className="gray">{p.st3} </span>
                <span className="gray">{p.st4} </span>
              </div>
            </div>
          </div>
          <div className="logo">
            <img src={p.img} style={{ width: "150px" }} />
          </div>
        </div>
      </Link>
    ));
    return (
      <section className="projects container mb-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-end">
            <h2 className="my-5 pt-5">Archived Projects</h2>
            <Link className="link my-5 pt-5" to={"/dashboard/project"}>
              View My Projects
            </Link>
          </div>
        </div>
        <div className="row">{projects}</div>
      </section>
    );
  }
}

export default ArchiveProjects;
