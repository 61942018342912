import React from "react";
import PropTypes from "prop-types";

const ArrowLeft = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}
    >
      <g transform="translate(20 20) rotate(-90)">
        <rect width="20" height="20" rx="10" transform="translate(0 -20)" />
        <g transform="translate(12.667 -8.667) rotate(-90)" fill="#333333">
          <path d="M3.944-2.562.2-.022a.131.131,0,0,1-.133.008A.127.127,0,0,1,0-.127V-5.206a.127.127,0,0,1,.069-.113.132.132,0,0,1,.06-.014A.131.131,0,0,1,.2-5.311l3.742,2.54a.126.126,0,0,1,.056.1A.127.127,0,0,1,3.944-2.562Z" />
        </g>
      </g>
    </svg>
  );
};

ArrowLeft.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ArrowLeft;
