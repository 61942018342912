import React, { Component } from "react";
import "./Setback.sass";
import { lineString, buffer, difference } from "@turf/turf";
import { validate, validations } from "indicative/validator";
import ErrorNotification from "../ErrorNotification/ErrorNotification";
import NumberFormat from "react-number-format";
import mapboxgl from "mapbox-gl";
import $ from "jquery";
import toastr from "toastr";

const lovelandAPIToken =
  "s8s29o-XybbxRkZxj8xMXx2JzSSQ7GPrSav8VwALJz-69PnTvEr1eXxnNygQzrcv";

const rules = {
  adjacency: [validations.equals([false])],
};

const messages = {
  "adjacency.equals":
    "Please, select at least one street frontage before continuing to step 4.",
};

const AdjacencyInput = {
  STREET_FRONTAGE: "Street frontage",
  ADJACENT_BUILDING: "Adjacent building",
  NONE: "None",
};

class Setback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setbackInputs: this.props.setbackInputs,
      projectSetback: this.getSetbackPolygonFromLineString(
        this.props.siteMultiline,
        this.props.shiftedPolygon,
        this.props.setbackInputs
      ),
      errors: null,
    };
  }
  handleChangeSetback =
    (name) =>
    ({ floatValue }) => {
      this.setState((prevState) => {
        const setbackInputs = prevState.setbackInputs.map((setbackInput) =>
          setbackInput.side === name
            ? {
                ...setbackInput,
                ["setback"]: Number(floatValue),
              }
            : setbackInput
        );

        return { ...prevState, setbackInputs, errors: null };
      });
    };
  verificationSetback = (name, floatValue) => () => {
    this.setState((prevState) => {
      const setbackInputs = prevState.setbackInputs.map((setbackInput) =>
        setbackInput.side === name
          ? {
              ...setbackInput,
              ["setback"]: floatValue ? Number(floatValue) : 0,
            }
          : setbackInput
      );
      const projectSetback = this.getSetbackPolygonFromLineString(
        this.props.siteMultiline,
        this.props.shiftedPolygon,
        setbackInputs
      );
      if (projectSetback === null) {
        return {
          ...prevState,
          errors: {
            adjacency: `Your setbacks don’t leave any room to construct a building. In this configuration of setbacks value of setback side "${name}" can't be equal ${floatValue}. Please adjust your setbacks before proceeding to step 4.`,
          },
        };
      } else if (projectSetback.geometry.type === "MultiPolygon") {
        return {
          ...prevState,
          errors: {
            adjacency: `Your setbacks divide the site.  Setbacks must result in a contiguous polygon. Please adjust your setbacks before proceeding to step 4.`,
          },
        };
      } else {
        this.props.setProjectSetback(projectSetback);
        return { ...prevState, setbackInputs, projectSetback, errors: null };
      }
    });
  };
  handleChange = (e) => {
    const { name, value, type } = e.target;

    this.setState((prevState) => {
      const setbackInputs = prevState.setbackInputs.map((setbackInput) =>
        setbackInput.side === name
          ? {
              ...setbackInput,
              [type === "radio" ? "frontage" : "setback"]:
                type === "radio"
                  ? value
                  : Number(value) < 0
                  ? 0
                  : Number(value),
            }
          : setbackInput
      );
      const projectSetback = this.getSetbackPolygonFromLineString(
        this.props.siteMultiline,
        this.props.shiftedPolygon,
        setbackInputs
      );
      if (projectSetback === null) {
        return {
          ...prevState,
          errors: {
            adjacency: `Your setbacks don’t leave any room to construct a building. In this configuration of setbacks value of setback side "${name}" can't be equal ${value}. Please adjust your setbacks before proceeding to step 4.`,
          },
        };
      } else if (projectSetback.geometry.type === "MultiPolygon") {
        return {
          ...prevState,
          errors: {
            adjacency: `Your setbacks divide the site.  Setbacks must result in a contiguous polygon. Please adjust your setbacks before proceeding to step 4.`,
          },
        };
      } else {
        this.props.setProjectSetback(projectSetback);
        return { ...prevState, setbackInputs, projectSetback, errors: null };
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { setbackInputs, projectSetback } = this.state;

    validate(
      {
        adjacency: false,
      },
      rules,
      messages
    )
      .then(() => {
        this.props.handleSetback({ setbackInputs, projectSetback });
        this.props.nextStep();
      })
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach((error) => {
          formattedErrors[error.field] = error.message;
        });

        this.setState({
          errors: formattedErrors,
        });
      });
  };

  back = (e) => {
    e.preventDefault();
    const { map } = this.props;
    const coordinatesWindow = this.props.coordinatesWindow;
    if (coordinatesWindow.zoom < 12) {
      coordinatesWindow.zoom = coordinatesWindow.zoom - 1;
    } else {
      coordinatesWindow.zoom = coordinatesWindow.zoom - 2;
    }
    if (coordinatesWindow.zoom > 20) coordinatesWindow.zoom = 20;
    this.props.setCoordinatesWindow({ coordinatesWindow });
    map.flyTo({
      center: [coordinatesWindow.lng, coordinatesWindow.lat],
      zoom: coordinatesWindow.zoom,
    });
    const { setbackInputs, projectSetback } = this.state;
    this.props.handleSetback({ setbackInputs, projectSetback });
    this.props.prevStep();
  };

  initMap = () => {
    const { projectSite, shiftedPolygon, labelFeatureCollection, sitePolygon } =
      this.props;
    const { projectSetback } = this.state;

    const map = this.props.map.needReset
      ? this.props.mapInitBack()
      : this.props.map;

    const setElementsOnMapBack = () => {
      map.fitBounds(projectSite.bbox, {
        padding: 50,
      });

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());
      // Get parcel metadata
      var parcelCreate = $.ajax({
        url:
          "https://tiles.makeloveland.com/api/v1/parcels?format=mvt&token=" +
          lovelandAPIToken,
        type: "GET",
        contentType: "application/json",
        dataType: "json",
      });
      parcelCreate.fail(function (jqXHR, textStatus, errorThrown) {
        toastr.error(
          `Error getting parcel layer ${jqXHR} ${textStatus} ${errorThrown}`,
          "Error"
        );
      });
      $.when(parcelCreate).then(setup);

      var selectedParcels = {
        ...this.props.selectedParcels,
      };

      // Add the parcel data to the map
      function setup(layerData) {
        var data = layerData;

        // Register a Mapbox GL source using the tile URL we just got
        map.addSource(data.id, {
          type: "vector",
          tiles: data.tiles,
        });

        // Add parcel outlines to the map with basic styles
        map.addLayer({
          id: "parcels",
          type: "line",
          source: data.id,
          "source-layer": data.id,
          minzoom: 10,
          maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "hsl(203, 41%, 39%)",
          },
        });

        // Make an invisible fill layer so the shapes are more clickable
        map.addLayer({
          id: "parcel-touchable",
          type: "fill",
          source: data.id,
          "source-layer": data.id,
          minzoom: 10,
          maxzoom: 20,
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-opacity": 0,
          },
        });

        // Add a geojson layer to track selected parcels
        map.addSource("selected", { type: "geojson", data: selectedParcels });
        map.addLayer({
          id: "selected",
          type: "fill",
          source: "selected",
          paint: {
            "fill-color": "#088",
            "fill-opacity": 0.4,
          },
        });
        map.addSource("selecte", { type: "geojson", data: selectedParcels });
        map.addLayer({
          id: "selecte",
          type: "line",
          source: "selected",
          layout: {
            visibility: "visible",
          },
          paint: {
            "line-color": "#307FF4",
            "line-width": 3,
          },
        });
        map.addSource("parcelOutline", {
          type: "geojson",
          data: projectSite,
        });

        map.addLayer({
          id: "parcelFill",
          type: "fill",
          source: "parcelOutline",
          paint: {
            "fill-color": "#888888",
            "fill-opacity": 0.2,
          },
        });

        map.addLayer({
          id: "parcelLine",
          type: "line",
          source: "parcelOutline",
          paint: {
            "line-color": "#0000FF",
            "line-width": 1,
          },
        });

        map.addSource("simpParcelOutline", {
          type: "geojson",
          data: shiftedPolygon,
        });

        map.addLayer({
          id: "simpParcelFill",
          type: "fill",
          source: "simpParcelOutline",
          paint: {
            "fill-color": "#088",
            "fill-opacity": 0.4,
          },
        });

        map.addLayer({
          id: "simpParcelLine",
          type: "line",
          source: "simpParcelOutline",
          paint: {
            "line-color": "#307FF4",
            "line-width": 4,
          },
        });

        map.addSource("parcelMidpoints", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: labelFeatureCollection,
          },
        });

        map.addLayer({
          id: "midpoints",
          type: "circle",
          source: "parcelMidpoints",
          paint: {
            "circle-radius": 12,
            "circle-color": "#ffffff",
            "circle-stroke-width": 2,
            "circle-stroke-color": "#333333",
          },
        });

        map.addLayer({
          id: "midpointLetter",
          type: "symbol",
          source: "parcelMidpoints",
          filter: ["==", "$type", "Point"],
          layout: {
            "text-anchor": "center",
            "text-field": "{name}",
            "symbol-placement": "point",
            "text-size": 16,
            "text-max-width": 40,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          },
        });

        map.addSource("setbackOutline", {
          type: "geojson",
          data: projectSetback,
        });

        map.addLayer({
          id: "setbackFill",
          type: "fill",
          source: "setbackOutline",
          paint: {
            "fill-color": "#FA0",
            "fill-opacity": 0.6,
          },
        });

        map.addLayer({
          id: "setbackLine",
          type: "line",
          source: "setbackOutline",
          paint: {
            "line-color": "#333333",
            "line-width": 4,
          },
        });
        map.getSource("selected").setData(sitePolygon);
        map.moveLayer("midpoints");
        map.moveLayer("midpointLetter");
      }
      // Load Loveland's parcel data END
    };
    const setElementsOnMap = () => {
      map.fitBounds(projectSite.bbox, {
        padding: 50,
      });

      map.addSource("parcelOutline", {
        type: "geojson",
        data: projectSite,
      });

      map.addLayer({
        id: "parcelFill",
        type: "fill",
        source: "parcelOutline",
        paint: {
          "fill-color": "#888888",
          "fill-opacity": 0.2,
        },
      });

      map.addLayer({
        id: "parcelLine",
        type: "line",
        source: "parcelOutline",
        paint: {
          "line-color": "#0000FF",
          "line-width": 1,
        },
      });

      map.addSource("simpParcelOutline", {
        type: "geojson",
        data: shiftedPolygon,
      });

      map.addLayer({
        id: "simpParcelFill",
        type: "fill",
        source: "simpParcelOutline",
        paint: {
          "fill-color": "#088",
          "fill-opacity": 0.4,
        },
      });

      map.addLayer({
        id: "simpParcelLine",
        type: "line",
        source: "simpParcelOutline",
        paint: {
          "line-color": "#307FF4",
          "line-width": 4,
        },
      });

      map.addSource("parcelMidpoints", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: labelFeatureCollection,
        },
      });

      map.addLayer({
        id: "midpoints",
        type: "circle",
        source: "parcelMidpoints",
        paint: {
          "circle-radius": 12,
          "circle-color": "#ffffff",
          "circle-stroke-width": 2,
          "circle-stroke-color": "#333333",
        },
      });

      map.addLayer({
        id: "midpointLetter",
        type: "symbol",
        source: "parcelMidpoints",
        filter: ["==", "$type", "Point"],
        layout: {
          "text-anchor": "center",
          "text-field": "{name}",
          "symbol-placement": "point",
          "text-size": 16,
          "text-max-width": 40,
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        },
      });

      map.addSource("setbackOutline", {
        type: "geojson",
        data: projectSetback,
      });

      map.addLayer({
        id: "setbackFill",
        type: "fill",
        source: "setbackOutline",
        paint: {
          "fill-color": "#FA0",
          "fill-opacity": 0.6,
        },
      });

      map.addLayer({
        id: "setbackLine",
        type: "line",
        source: "setbackOutline",
        paint: {
          "line-color": "#333333",
          "line-width": 4,
        },
      });
      map.moveLayer("midpoints");
      map.moveLayer("midpointLetter");
    };
    if (this.props.map.needReset) {
      map.on("load", setElementsOnMapBack);
    } else {
      setElementsOnMap();
    }
  };

  unmountMap = () => {
    const { map } = this.props;

    // map.clearAllEventListeners();

    map.removeLayer("parcelLine");
    map.removeLayer("parcelFill");
    map.removeSource("parcelOutline");

    map.removeLayer("simpParcelFill");
    map.removeLayer("simpParcelLine");
    map.removeSource("simpParcelOutline");

    map.removeLayer("midpoints");
    map.removeLayer("midpointLetter");
    map.removeSource("parcelMidpoints");

    map.removeLayer("setbackFill");
    map.removeLayer("setbackLine");
    map.removeSource("setbackOutline");
  };

  getSetbackPolygonFromLineString(multiLineString, polygon, offsets) {
    let sides = multiLineString.geometry.coordinates.length;
    let setbackPolygon = polygon;
    for (var i = 0; i < sides; i++) {
      if (offsets[i].setback === 0.0) {
        continue;
      }
      let lineStringVar = lineString(multiLineString.geometry.coordinates[i]);
      let bufferedSegment = buffer(lineStringVar, offsets[i].setback / 3280.84);
      setbackPolygon = difference(setbackPolygon, bufferedSegment);
    }
    return setbackPolygon;
  }

  componentDidMount() {
    const { projectSetback } = this.state;
    this.initMap();
    this.props.setProjectSetback(projectSetback);
  }

  componentDidUpdate() {
    const { map } = this.props;
    if (map.getSource("setbackOutline")) {
      const { projectSetback } = this.state;
      map.getSource("setbackOutline").setData(projectSetback);
      map.moveLayer("midpoints");
      map.moveLayer("midpointLetter");
    }
  }

  componentWillUnmount() {
    this.unmountMap();
  }

  render() {
    const { errors, setbackInputs } = this.state;
    return (
      <form className="setBack text col-4 p-4" onSubmit={this.handleSubmit}>
        <h5 className="title text-right">Project Setback</h5>

        <div className="cont">
          <h2 className="mt-5 mb-5 pb-2">Street Frontage and Setbacks</h2>
          <div className="containerOfcontrols">
            {/* <h4 className="title">Street Frontage and Setbacks</h4> */}
            {errors && <ErrorNotification label={`${errors.adjacency}`} />}
            <div className="sides">
              <div className="sides__headline">
                <h6 className="title">Sides</h6>
                <h6 className="title">Street Frontage</h6>
                <h6 className="title">Setbacks</h6>
              </div>
              {setbackInputs.map((setbackInput) => (
                <div key={setbackInput.side} className="sides__line">
                  <p>{setbackInput.side}</p>
                  <div className="radio">
                    <input
                      type="radio"
                      id={`${setbackInput.side}One`}
                      checked={
                        setbackInput.frontage === AdjacencyInput.STREET_FRONTAGE
                      }
                      name={setbackInput.side}
                      value={AdjacencyInput.STREET_FRONTAGE}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={`${setbackInput.side}One`}>
                      {AdjacencyInput.STREET_FRONTAGE}
                    </label>
                    <input
                      type="radio"
                      id={`${setbackInput.side}Three`}
                      checked={setbackInput.frontage === AdjacencyInput.NONE}
                      name={setbackInput.side}
                      value={AdjacencyInput.NONE}
                      onChange={this.handleChange}
                    />
                    <label htmlFor={`${setbackInput.side}Three`}>
                      {AdjacencyInput.NONE}
                    </label>
                  </div>
                  <div className="inputContainer">
                    <NumberFormat
                      className="inputFT"
                      thousandSeparator={true}
                      decimalSeparator={"."}
                      thousandsGroupStyle={"thousand"}
                      displayType={"input"}
                      decimalScale={0}
                      allowNegative={false}
                      fixedDecimalScale={true}
                      allowEmptyFormatting={true}
                      allowLeadingZeros={false}
                      id={`setback${setbackInput.side}`}
                      placeholder="Setback (ft)"
                      value={setbackInput.setback.toString()}
                      onValueChange={this.handleChangeSetback(
                        setbackInput.side
                      )}
                      onBlur={this.verificationSetback(
                        setbackInput.side,
                        setbackInput.setback
                      )}
                    />
                    <label
                      htmlFor={`setback${setbackInput.side}`}
                      className="inputSuffixFT"
                    >
                      ft
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="controls">
          <button type="button" className="prev" onClick={this.back}>
            Prev
          </button>
          <button
            type="submit"
            className="next"
            disabled={!!errors && "disabled"}
          >
            Next
          </button>
        </div>
      </form>
    );
  }
}

export default Setback;
