import React from "react";
import PropTypes from "prop-types";
import Tab from "./Tab/Tab";
import styles from "./Tabs.module.sass";

const Tabs = ({ items = [], category, onSelect }) => {
  const indexSelected = category
    ? items.findIndex((tab) => tab.head === category)
    : 0;

  const onIndexSelect = (e) => {
    const { value } = e.currentTarget;

    onSelect(value === 0 ? "" : items[value - 1].head);
  };
  return (
    <ul id="tabs" className={styles.tabs}>
      {items.map((item, index) => (
        <li key={item.id}>
          <Tab
            {...item}
            isActive={index === indexSelected ? true : false}
            onIndexSelect={onIndexSelect}
            value={index + 1}
          />
        </li>
      ))}
    </ul>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    .isRequired,
  category: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default Tabs;
