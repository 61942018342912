import React from "react";
import PropTypes from "prop-types";

const Filter = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#174FA2" />
      <path
        d="M27.4215 10.6406C27.3047 10.3751 27.113 10.1494 26.8699 9.99121C26.6268 9.83301 26.3428 9.74919 26.0527 9.75001H9.94645C9.65647 9.75234 9.3733 9.83816 9.13081 9.99719C8.88832 10.1562 8.69678 10.3818 8.5791 10.6468C8.46141 10.9118 8.42257 11.2052 8.46721 11.4917C8.51185 11.7782 8.63809 12.0458 8.83083 12.2625L14.9996 19.0406V26.3531C14.9991 26.6252 15.0731 26.8922 15.2136 27.1252C15.3541 27.3583 15.5556 27.5484 15.7965 27.675C16.0133 27.7896 16.2544 27.8507 16.4996 27.8531C16.7966 27.8532 17.087 27.7651 17.334 27.6L20.334 25.5938C20.5394 25.4572 20.7078 25.2718 20.8239 25.0542C20.9401 24.8366 21.0005 24.5936 20.9996 24.3469V19.0406L27.1683 12.2625C27.3646 12.0466 27.4933 11.7779 27.5383 11.4895C27.5833 11.2012 27.5426 10.9061 27.4215 10.6406Z"
        fill="white"
      />
    </svg>
  );
};

Filter.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Filter;
