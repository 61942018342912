import React from "react";
import PropTypes from "prop-types";

const BuildingProgram = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" fill="#174FA2" />
      <path
        d="M28.5 25.5H27.75V15.75C27.75 15.3522 27.592 14.9706 27.3107 14.6893C27.0294 14.408 26.6478 14.25 26.25 14.25H20.25V9.75C20.25 9.35218 20.092 8.97064 19.8107 8.68934C19.5294 8.40804 19.1478 8.25 18.75 8.25H9.75C9.35218 8.25 8.97064 8.40804 8.68934 8.68934C8.40804 8.97064 8.25 9.35218 8.25 9.75V25.5H7.5C7.30109 25.5 7.11032 25.579 6.96967 25.7197C6.82902 25.8603 6.75 26.0511 6.75 26.25C6.75 26.4489 6.82902 26.6397 6.96967 26.7803C7.11032 26.921 7.30109 27 7.5 27H28.5C28.6989 27 28.8897 26.921 29.0303 26.7803C29.171 26.6397 29.25 26.4489 29.25 26.25C29.25 26.0511 29.171 25.8603 29.0303 25.7197C28.8897 25.579 28.6989 25.5 28.5 25.5ZM17.25 18.75C17.25 18.9489 17.171 19.1397 17.0303 19.2803C16.8897 19.421 16.6989 19.5 16.5 19.5H13.5C13.3011 19.5 13.1103 19.421 12.9697 19.2803C12.829 19.1397 12.75 18.9489 12.75 18.75C12.75 18.5511 12.829 18.3603 12.9697 18.2197C13.1103 18.079 13.3011 18 13.5 18H16.5C16.6989 18 16.8897 18.079 17.0303 18.2197C17.171 18.3603 17.25 18.5511 17.25 18.75ZM12 12H15C15.1989 12 15.3897 12.079 15.5303 12.2197C15.671 12.3603 15.75 12.5511 15.75 12.75C15.75 12.9489 15.671 13.1397 15.5303 13.2803C15.3897 13.421 15.1989 13.5 15 13.5H12C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75C11.25 12.5511 11.329 12.3603 11.4697 12.2197C11.6103 12.079 11.8011 12 12 12ZM12 21.75H15C15.1989 21.75 15.3897 21.829 15.5303 21.9697C15.671 22.1103 15.75 22.3011 15.75 22.5C15.75 22.6989 15.671 22.8897 15.5303 23.0303C15.3897 23.171 15.1989 23.25 15 23.25H12C11.8011 23.25 11.6103 23.171 11.4697 23.0303C11.329 22.8897 11.25 22.6989 11.25 22.5C11.25 22.3011 11.329 22.1103 11.4697 21.9697C11.6103 21.829 11.8011 21.75 12 21.75ZM20.25 15.75H26.25V25.5H20.25V15.75Z"
        fill="white"
      />
      <path
        d="M24 21.75H22.5C22.3011 21.75 22.1103 21.829 21.9697 21.9697C21.829 22.1103 21.75 22.3011 21.75 22.5C21.75 22.6989 21.829 22.8897 21.9697 23.0303C22.1103 23.171 22.3011 23.25 22.5 23.25H24C24.1989 23.25 24.3897 23.171 24.5303 23.0303C24.671 22.8897 24.75 22.6989 24.75 22.5C24.75 22.3011 24.671 22.1103 24.5303 21.9697C24.3897 21.829 24.1989 21.75 24 21.75Z"
        fill="white"
      />
      <path
        d="M22.5 19.5H24C24.1989 19.5 24.3897 19.421 24.5303 19.2803C24.671 19.1397 24.75 18.9489 24.75 18.75C24.75 18.5511 24.671 18.3603 24.5303 18.2197C24.3897 18.079 24.1989 18 24 18H22.5C22.3011 18 22.1103 18.079 21.9697 18.2197C21.829 18.3603 21.75 18.5511 21.75 18.75C21.75 18.9489 21.829 19.1397 21.9697 19.2803C22.1103 19.421 22.3011 19.5 22.5 19.5Z"
        fill="white"
      />
    </svg>
  );
};

BuildingProgram.propTypes = {
  className: PropTypes.string.isRequired,
};

export default BuildingProgram;
