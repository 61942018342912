import React from "react";
import PropTypes from "prop-types";

const Invite = ({ className }) => {
  return (
    <svg
      viewBox="8 8 45 45"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fillRule="evenodd">
        {/* <path d="m51 0h-42c-4.96827817.00551113-8.99448887 4.03172183-9 9v42c.00551113 4.9682782 4.03172183 8.9944889 9 9h42c4.9682782-.0055111 8.9944889-4.0317218 9-9v-42c-.0055111-4.96827817-4.0317218-8.99448887-9-9zm7 51c-.0044086 3.8641657-3.1358343 6.9955914-7 7h-42c-3.86416566-.0044086-6.99559136-3.1358343-7-7v-42c.00440864-3.86416566 3.13583434-6.99559136 7-7h42c3.8641657.00440864 6.9955914 3.13583434 7 7z" /> */}
        {/* <path d="m51 4h-42c-2.76005315.00330612-4.99669388 2.23994685-5 5v42c.00330612 2.7600532 2.23994685 4.9966939 5 5h42c2.7600532-.0033061 4.9966939-2.2399468 5-5v-42c-.0033061-2.76005315-2.2399468-4.99669388-5-5zm3 47c0 1.6568542-1.3431458 3-3 3h-42c-1.65685425 0-3-1.3431458-3-3v-42c0-1.65685425 1.34314575-3 3-3h42c1.6568542 0 3 1.34314575 3 3z" /> */}
        <path
          strokeWidth="2"
          d="m49 33h-5v-5c0-1.1045695-.8954305-2-2-2h-4c-1.1045695 0-2 .8954305-2 2v5h-1.833c-1.5199004-1.4077969-3.3154277-2.4848408-5.273-3.163 4.5733578-2.2722675 6.981435-7.3932466 5.8144199-12.3648536s-5.6021792-8.4862311-10.7089199-8.4862311-9.5419048 3.5146241-10.7089199 8.4862311 1.2410621 10.0925861 5.8144199 12.3648536c-6.0388139 2.0899217-10.09341271 7.7727799-10.105 14.163v4c0 1.6568542 1.3431458 3 3 3h24c1.6568542 0 3-1.3431458 3-3h3c1.1045695 0 2-.8954305 2-2v-5h5c1.1045695 0 2-.8954305 2-2v-4c0-1.1045695-.8954305-2-2-2zm-34-13c0-4.9705627 4.0294373-9 9-9s9 4.0294373 9 9-4.0294373 9-9 9c-4.9682782-.0055111-8.9944889-4.0317218-9-9zm21 29h-24c-.5522847 0-1-.4477153-1-1v-4c-.0062089-4.7343346 2.5651432-9.0967153 6.7102302-11.3841191s9.2066716-2.1371442 13.2087698.3921191c-1.0697229.0433539-1.9155958.9214043-1.919 1.992v4c0 1.1045695.8954305 2 2 2h5v5c.002145.7113268.3832487 1.3675874 1 1.722v.278c0 .5522847-.4477153 1-1 1zm13-10h-5c-1.1045695 0-2 .8954305-2 2v5h-4v-5c0-1.1045695-.8954305-2-2-2h-5v-4h5c1.1045695 0 2-.8954305 2-2v-5h4v5c0 1.1045695.8954305 2 2 2h5z"
        />
      </g>
    </svg>
  );
};

Invite.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Invite;
