export const getIsAuthenticated = (state) => state.session.authenticated;

export const getAccess = (state) => state.session.access;

export const getUser = (state) => state.session.user;

export const getIsDemoModeOff = (state) => state.session.isDemoModeOff;

export const getError = (state) => state.session.error;

export const getIsLoggedOutDueToInactivityModalOpen = (state) =>
  state.session.isLoggedOutDueToInactivityModalOpen;
