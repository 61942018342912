import React from "react";
import PropTypes from "prop-types";

const Logout = ({ className }) => {
  return (
    <svg
      width="12px"
      height="13px"
      viewBox="0 0 12 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>exit-to-app-button</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Layout"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.-My-Projects"
          transform="translate(-1412.000000, -41.000000)"
          className={className}
          stroke="none"
          //   fill="#333333"
          fillRule="nonzero"
        >
          <g
            id="exit-to-app-button"
            transform="translate(1412.000000, 41.000000)"
          >
            <path
              d="M4.73333333,9.32857143 L5.66666667,10.2619048 L9,6.92857143 L5.66666667,3.5952381 L4.73333333,4.52857143 L6.46666667,6.26190476 L0,6.26190476 L0,7.5952381 L6.46666667,7.5952381 L4.73333333,9.32857143 Z M10.6666667,0.928571429 L1.33333333,0.928571429 C0.6,0.928571429 0,1.52857143 0,2.26190476 L0,4.92857143 L1.33333333,4.92857143 L1.33333333,2.26190476 L10.6666667,2.26190476 L10.6666667,11.5952381 L1.33333333,11.5952381 L1.33333333,8.92857143 L0,8.92857143 L0,11.5952381 C0,12.3285714 0.6,12.9285714 1.33333333,12.9285714 L10.6666667,12.9285714 C11.4,12.9285714 12,12.3285714 12,11.5952381 L12,2.26190476 C12,1.52857143 11.4,0.928571429 10.6666667,0.928571429 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

Logout.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Logout;
