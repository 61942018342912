import Header from "./Header";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../redux/ProjectInfo/projectInfoSelectors";
import * as sessionSelectors from "../../../redux/Session/sessionSelectors";
import * as sessionActions from "../../../redux/Session/sessionActions";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    user: sessionSelectors.getUser(state),
    isDemoModeOff: sessionSelectors.getIsDemoModeOff(state),
    isHeaderDataDisplay: state.projectInfo.projectInfo.isHeaderDataDisplay,
  };
};

const mapDispatchToProps = {
  demoModeToggle: sessionActions.demoModeToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
