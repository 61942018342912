import React from "react";
import PropTypes from "prop-types";
import styles from "./TooltipWithReactTooltip.module.sass";
import ReactTooltip from "react-tooltip";

const TooltipWithReactTooltip = ({ title, text }) => {
  return (
    <>
      <ReactTooltip className={styles.tooltipContent} type="light" id={title}>
        <span className={styles.tooltipTitle}>{title}</span>{" "}
        <span className={styles.tooltipText}>{text}</span>
      </ReactTooltip>
      <span data-tip data-for={title} className={styles.tooltipIcon}>
        ?
      </span>
    </>
  );
};

TooltipWithReactTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TooltipWithReactTooltip;
