import React from "react";
import PropTypes from "prop-types";

const Phone = ({ className }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.41992 7.31237C5.90228 8.30847 6.70774 9.11187 7.70508 9.59166C7.77865 9.6265 7.86002 9.64157 7.94118 9.63541C8.02235 9.62925 8.10051 9.60205 8.16797 9.55651L9.63281 8.57799C9.69753 8.5341 9.77232 8.50731 9.85019 8.50014C9.92806 8.49297 10.0065 8.50564 10.0781 8.53698L12.8203 9.71471C12.914 9.75372 12.9923 9.82245 13.0432 9.91033C13.094 9.9982 13.1145 10.1004 13.1016 10.201C13.0146 10.8794 12.6835 11.5029 12.1702 11.9547C11.6568 12.4066 10.9964 12.656 10.3125 12.6561C8.19906 12.6561 6.17217 11.8166 4.67774 10.3221C3.18331 8.82769 2.34375 6.80081 2.34375 4.68737C2.3439 4.00346 2.59325 3.34304 3.04513 2.82968C3.49701 2.31633 4.12047 1.98522 4.79883 1.8983C4.89951 1.88537 5.00166 1.90589 5.08954 1.95671C5.17741 2.00754 5.24615 2.08583 5.28516 2.17955L6.46289 4.9276C6.49352 4.99812 6.50633 5.07508 6.5002 5.15172C6.49407 5.22835 6.46918 5.3023 6.42773 5.36705L5.44922 6.85534C5.40565 6.92265 5.38009 7.00001 5.37496 7.08003C5.36983 7.16005 5.38531 7.24004 5.41992 7.31237V7.31237Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

Phone.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Phone;
