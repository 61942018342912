import Viewer3d from "./Viewer3d";
import { connect } from "react-redux";
import * as projectInfoSelectors from "../../../../redux/ProjectInfo/projectInfoSelectors";
import * as filtersSelectors from "../../../../redux/Filters/filtersSelectors";
import * as sessionSelectors from "../../../../redux/Session/sessionSelectors";
import { setProjectInfoKeyView } from "../../../../redux/ProjectInfo/projectInfoActions";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {
    projectInfo: projectInfoSelectors.getProjectInfo(state),
    "3d_settings": projectInfoSelectors.get3dSettings(state),
    filters: filtersSelectors.getFilters(state),
    filterParameters: filtersSelectors.getFilterParameters(state),
    tablesForModel: filtersSelectors.getTablesForModel(state),
    isDemoModeOff: sessionSelectors.getIsDemoModeOff(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProjectInfoKeyView: bindActionCreators(setProjectInfoKeyView, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer3d);
