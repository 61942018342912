// Dashboard
import house from "../assets/img/icons/house.svg";
import budget from "../assets/img/icons/full-budget.svg";
import investment from "../assets/img/icons/invest.svg";

const svg = {
  // Dashboard
  house: house,
  budget: budget,
  investment: investment,
};

export default svg;
